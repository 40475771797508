import React, { useEffect, useState } from 'react';
import { API } from 'constants';
import organizationClient from '../../../services/organization-api';

import Modal from '../../../design-system/Modal/Modal';
import useUser from '../../../hooks/useUser';
import Select from '../../../design-system/Select/Select';
import { Button } from '../../../design-system';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const ReassignModal = ({ onClose, onReassign, onMultiActionOptionsPanelClose }) => {
    const { user } = useUser();
    const currentUserId = user.id;

    const [selectedMember, setSelectedMember] = useState(null);
    const [errorSelectedMemberField, setErrorSelectedMemberField] = useState(false);

    const [organizationMembersOptions, setOrganizationMembersOptions] = useState([]);
    const [areOptionsLoading, setAreOptionsLoading] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isReassignLoading, setIsReassignLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    useEffect(() => {
        const getOrganizationMembership = async () => {
            try {
                setAreOptionsLoading(true);
                const { data } = await organizationClient.get(
                    `${API.ROUTES.organization.organizationMembership}?organization=${user.organization.id}`
                );
                setOrganizationMembersOptions(
                    data.map(({ user, email }) => ({ id: user, name: email }))
                );
                setAreOptionsLoading(false);
            } catch (e) {
                setAreOptionsLoading(false);
            }
        };

        getOrganizationMembership();
    }, []);

    const handleReassign = async () => {
        if (!selectedMember) {
            setErrorSelectedMemberField(true);
            return;
        }

        try {
            setIsReassignLoading(true);

            await onReassign(selectedMember);

            setIsReassignLoading(false);
            onMultiActionOptionsPanelClose();
        } catch (error) {
            setIsReassignLoading(false);
            setErrorAlert({ show: true, statusCode: error.response.status });
        }
    };

    const handleSelectChange = (id) => {
        setSelectedMember(id);

        if (errorSelectedMemberField) {
            setErrorSelectedMemberField(false);
        }
    };

    const dropdownOptionsLength = organizationMembersOptions.length;
    const showDropdownClientFiltering = dropdownOptionsLength > 5;

    // used to increase the height of the modal so that the dropdown fits entirely on the screen
    const selectMarginBottom =
        dropdownOpen && !areOptionsLoading
            ? Math.min(
                  34 * dropdownOptionsLength + (showDropdownClientFiltering ? 45 : 0) - 10,
                  190
              )
            : 24;

    return (
        <Modal onClose={onClose} size="medium">
            <p className="font-heading-bold text-heading-bold-m text-neutral-500 mb-4">
                Reassign To
            </p>
            <div
                style={{
                    marginBottom: `${selectMarginBottom}px`,
                    transition: 'margin 0.2s ease-in-out',
                }}
            >
                <Select
                    size="md"
                    value={selectedMember}
                    options={organizationMembersOptions}
                    optionsLoading={areOptionsLoading}
                    name="assigned_to"
                    label="Select a member to send to"
                    placeholder="Select a member"
                    isRequired
                    onChange={handleSelectChange}
                    dropdownHeight={areOptionsLoading ? 74 : 200}
                    state={errorSelectedMemberField ? 'error' : 'default'}
                    errorMessage="Please select a member"
                    useExternalDropdownState
                    dropdownOpen={dropdownOpen}
                    setDropdownOpen={setDropdownOpen}
                    includeClientSideFiltering
                    startingOptionCountForFilterBar={6}
                />
            </div>
            <div className="flex items-center justify-between">
                <Button type="neutral" size="xs" text="Cancel" onClick={onClose} />
                <Button
                    type="primary"
                    size="xs"
                    text="Confirm"
                    state={
                        currentUserId === selectedMember
                            ? 'disabled'
                            : isReassignLoading
                            ? 'loading'
                            : 'default'
                    }
                    onClick={handleReassign}
                />
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </Modal>
    );
};

export default ReassignModal;
