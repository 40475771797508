import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/report-api';
import { useWindowSize } from '../../hooks/useWindowSize';

import Loading from '../../components/Loading';
import WorkRunDetailPageHeader from './WorkRunDetailPageHeader/WorkRunDetailPageHeader';
import RichTextArea from '../../design-system/RichTextArea/RichTextArea';
import ShareAiFeedbackModal from '../../components/ShareAIFeedbackModal/ShareAIFeedbackModal';
import Alert from '../../design-system/Alert/Alert';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';
import StartNewJobPanel from './StartNewJobPanel/StartNewJobPanel';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const WorkRunDetailPage = () => {
    const params = useParams();
    const threadId = Number(params.threadId);

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location?.state?.from ?? '/process/runs';

    const { width: screenWidth } = useWindowSize();

    const [runDetails, setRunDetails] = useState(null);

    const [startNewJobPanelOpened, setStartNewJobPanelOpened] = useState(false);
    const [showShareFeedbackModal, setShowShareFeedbackModal] = useState(false);
    const [successAlert, setSuccessAlert] = useState({ show: false });

    const [pageHeaderHeight, setPageHeaderHeight] = useState(0);
    const pageContentHeight =
        startNewJobPanelOpened && screenWidth >= 970
            ? `calc(100% - ${pageHeaderHeight}px)`
            : 'auto'; // height of the page without header

    useDocumentTitle(runDetails?.title);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.report.workReport}thread/${threadId}/`
                );
                setRunDetails(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        fetchData();
    }, []);

    return (
        <div
            className={`fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white flex flex-col ${
                (!startNewJobPanelOpened || screenWidth < 970) && 'overflow-y-auto'
            }`}
        >
            {runDetails ? (
                <>
                    <WorkRunDetailPageHeader
                        runDetails={runDetails}
                        openShareFeedbackModal={() => setShowShareFeedbackModal(true)}
                        setStartNewJobPanelOpened={setStartNewJobPanelOpened}
                        setPageHeaderHeight={setPageHeaderHeight}
                    />
                    <div
                        className="flex flex-grow"
                        style={{
                            height: pageContentHeight,
                        }}
                    >
                        {(!startNewJobPanelOpened || screenWidth >= 970) && (
                            <div
                                className={`h-full flex-grow px-5 pt-5 pb-[90px] sm:pb-7 flex justify-center ${
                                    startNewJobPanelOpened && 'overflow-y-auto'
                                }`}
                            >
                                <div className="w-full max-w-[800px] h-full">
                                    <RichTextArea
                                        value={runDetails.text}
                                        previewOnly
                                        minHeight={220}
                                        autoExpand={screenWidth < 1024}
                                    />
                                </div>
                            </div>
                        )}
                        <StartNewJobPanel
                            opened={startNewJobPanelOpened}
                            onClose={() => setStartNewJobPanelOpened(false)}
                        />
                    </div>
                </>
            ) : (
                <div className="w-full my-auto">
                    <Loading />
                </div>
            )}
            {showShareFeedbackModal && (
                <ShareAiFeedbackModal
                    target="thread"
                    threadId={runDetails.thread}
                    setAlert={setSuccessAlert}
                    onClose={() => setShowShareFeedbackModal(false)}
                />
            )}
            {successAlert?.show && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    handleClose={() => setSuccessAlert({ show: false })}
                />
            )}
        </div>
    );
};

export default WorkRunDetailPage;
