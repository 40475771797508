export const checkIfInputMissedInMessagesArray = (messages = [], inputKey) =>
    messages?.every((message) => !message?.includes(`[${inputKey}]`));

export const checkIfInputMissedInApiRequest = (apiRequest = [], inputKey) =>
    apiRequest?.every((item) => !JSON.stringify(item)?.includes(`{{${inputKey}}}`));

export const checkAndUpdateUserInputsIfUsedInMessagesArray = (
    messages = [],
    setUserInputs,
    warningKey
) => {
    // check if [input.key] is in messages array (user message, system message)
    setUserInputs((prevData) => {
        return prevData.map((item) => ({
            ...item,
            inputs: item.inputs.map((input) => {
                const isInputMissedInMessages = checkIfInputMissedInMessagesArray(
                    messages,
                    input.key
                );

                return {
                    ...input,
                    [warningKey]: isInputMissedInMessages,
                };
            }),
        }));
    });
};

export const removeRedundantFieldsFromInput = (input) => {
    const {
        state,
        value,
        missedInUserMessage,
        missedInUserAndSystemMessages,
        missedInApiRequest,
        ...rest
    } = input;
    return rest;
};
