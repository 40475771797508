import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/report-api';
import { useWindowSize } from '../../hooks/useWindowSize';
import { defaultErrorMessage } from '../../constants/errorMessages';

import ProcessWorkReportTemplatePageHeader from './ProcessWorkReportTemplatePageHeader/ProcessWorkReportTemplatePageHeader';
import ReportInputsPanel from './ReportInputsPanel/ReportInputsPanel';
import AvailableOutputsPanel from './AvailableOutputsPanel/AvailableOutputsPanel';
import { Button } from '../../design-system';
import Alert from '../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../design-system/Icons';
import Loading from '../../components/Loading';
import EditTemplateNameModal from './EditTemplateNameModal/EditTemplateNameModal';
import ToggleTemplateStatusModal from './ToggleTemplateStatusModal/ToggleTemplateStatusModal';

const ProcessWorkReportTemplateDetailPage = () => {
    const params = useParams();
    const processId = Number(params.processId);
    const templateId = Number(params.templateId);

    const navigate = useNavigate();
    const { width: screenWidth } = useWindowSize();

    const [templateDetails, setTemplateDetails] = useState(null);

    const [reportInputsData, setReportInputsData] = useState(null);
    const [reportErrorInputs, setReportErrorInputs] = useState({ title: false, text: false });
    const [changedReportInputs, setChangedReportInputs] = useState([]);
    const [saveReportInputsLoading, setSaveReportInputsLoading] = useState(false);

    const [errorAlert, setErrorAlert] = useState(null);

    const [actionModal, setActionModal] = useState({ type: null, opened: false }); // type can be "editName" or "toggleStatus"

    const [pageHeaderHeight, setPageHeaderHeight] = useState(0);
    const pageContentHeight = screenWidth >= 1024 ? `calc(100% - ${pageHeaderHeight}px)` : 'auto'; // height of the page without header

    useEffect(() => {
        const fetchTemplateData = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.report.template}${templateId}/`);
                setTemplateDetails(data);
                setReportInputsData(data.default_version);
            } catch (e) {
                navigate(`/configure/process/${processId}`);
            }
        };

        fetchTemplateData();
    }, []);

    const saveReportInputsData = async () => {
        const areEmptyFields = !reportInputsData.title || !reportInputsData.text;
        if (areEmptyFields) {
            setReportErrorInputs({
                title: !reportInputsData.title,
                text: !reportInputsData.text,
            });
            return;
        }

        try {
            setSaveReportInputsLoading(true);
            const requestBody = changedReportInputs.reduce(
                (result, name) => ({ ...result, [name]: reportInputsData[name] }),
                {}
            );
            await client.patch(`${API.ROUTES.report.template}${templateId}/`, requestBody);
            setChangedReportInputs([]);
            setSaveReportInputsLoading(false);
        } catch (e) {
            setSaveReportInputsLoading(false);
            setErrorAlert({
                message: defaultErrorMessage,
            });
        }
    };

    return (
        <div
            className={`fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white flex flex-col max-lg:overflow-y-auto`}
        >
            {templateDetails ? (
                <>
                    <ProcessWorkReportTemplatePageHeader
                        processId={processId}
                        templateDetails={templateDetails}
                        setPageHeaderHeight={setPageHeaderHeight}
                        setActionModal={setActionModal}
                    />
                    <div
                        className="w-full flex flex-col lg:flex-row"
                        style={{ height: pageContentHeight }}
                    >
                        <ReportInputsPanel
                            reportInputsData={reportInputsData}
                            setReportInputsData={setReportInputsData}
                            reportErrorInputs={reportErrorInputs}
                            setReportErrorInputs={setReportErrorInputs}
                            setChangedReportInputs={setChangedReportInputs}
                        />
                        <AvailableOutputsPanel goals={templateDetails.process?.goals} />
                    </div>
                    <div className="p-2 fixed right-[14px] bottom-[14px] z-10">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Save Changes"
                            state={
                                saveReportInputsLoading
                                    ? 'loading'
                                    : !!changedReportInputs.length
                                    ? 'default'
                                    : 'disabled'
                            }
                            onClick={saveReportInputsData}
                        />
                    </div>
                </>
            ) : (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
            {actionModal.opened && actionModal.type === 'editName' && (
                <EditTemplateNameModal
                    name={templateDetails.name}
                    setTemplateDetails={setTemplateDetails}
                    onClose={() => setActionModal({ opened: false })}
                />
            )}
            {actionModal.opened && actionModal.type === 'toggleStatus' && (
                <ToggleTemplateStatusModal
                    isLive={templateDetails.is_live}
                    name={templateDetails.name}
                    setTemplateDetails={setTemplateDetails}
                    onClose={() => setActionModal({ opened: false })}
                />
            )}
        </div>
    );
};

export default ProcessWorkReportTemplateDetailPage;
