import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';

import Table from '../../../design-system/Table/Table';
import Loading from '../../../components/Loading';
import { transformVersionDataForTable } from '../../../helpers/formatHistoryDataForTable';

const PromptVersionHistory = () => {
    const { id: promptId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '/library';
    const initialPageNumber = location.state?.historyVersionPageNumber || 1;

    const [versionHistoryData, setVersionHistoryData] = useState([]);
    const [versionHistoryTableData, setVersionHistoryTableData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(initialPageNumber);

    useEffect(() => {
        const fetchVersionHistory = async () => {
            try {
                setIsLoading(true);
                const { data } = await client.get(
                    `${
                        API.ROUTES.operate.promptVersionHistory
                    }${promptId}/?ordering=-created_at&limit=12&offset=${(page - 1) * 12}`
                );
                setVersionHistoryData(data.results);
                setVersionHistoryTableData(transformVersionDataForTable(data, page));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log('error', error);
            }
        };

        fetchVersionHistory();
    }, [page]);

    const handleViewClick = (id) => {
        navigate(`/library/prompt/${promptId}/version/${id}`, {
            state: { libraryLocation, historyVersionPageNumber: page },
        });
    };

    return (
        <>
            {!isLoading && versionHistoryTableData && versionHistoryData.length > 0 && (
                <div className="w-full relative">
                    <Table
                        data={versionHistoryTableData}
                        size="md"
                        rowActionButtons={[
                            {
                                action: 'view',
                                handleAction: handleViewClick,
                            },
                        ]}
                        columnsCount={1}
                        shift={1050}
                        includePagination={true}
                        onPaginationPreviousClick={() => setPage((prevPage) => prevPage - 1)}
                        onPaginationNextClick={() => setPage((prevPage) => prevPage + 1)}
                    />
                </div>
            )}
            {!isLoading && versionHistoryData.length === 0 && (
                <p className="font-body text-body-regular-l text-neutral-500">
                    Your Version History is empty.{' '}
                </p>
            )}
            {isLoading && (
                <div className="w-full flex justify-center items-center pt-8">
                    <Loading text="" />
                </div>
            )}
        </>
    );
};

export default PromptVersionHistory;
