import { defaultErrorMessage } from '../constants/errorMessages';
import CheckLineIcon from '../design-system/Icons/CheckLineIcon';
import { ErrorWarningLineIcon } from '../design-system/Icons';

export const handleCopy = (data, setAlert = () => {}) => {
    navigator.clipboard
        .writeText(data)
        .then(() => {
            setAlert({ status: 'positive', message: 'Copied to clipboard', icon: CheckLineIcon });
        })
        .catch(() => {
            setAlert({
                status: 'critical',
                message: defaultErrorMessage,
                icon: ErrorWarningLineIcon,
            });
        });
};
