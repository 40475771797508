import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { threadStatusBadge } from '../../../constants/threads';

import { Badge, Button, ButtonIcon } from '../../../design-system';
import {
    ArrowDownSLineIcon,
    ArrowGoBackLineIcon,
    ArrowUpSLineIcon,
    FlowChartIcon,
} from '../../../design-system/Icons';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import PlayCircleIcon from '../../../design-system/Icons/PlayCircleIcon';

const WorkRunDetailPageHeader = ({
    runDetails,
    openShareFeedbackModal,
    setStartNewJobPanelOpened,
    setPageHeaderHeight,
}) => {
    const { title, process, status, created_at, updated_at, thread } = runDetails;

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location?.state?.from ?? '/process/runs';

    const { width: screenWidth } = useWindowSize();
    const headerContainerRef = useRef(null);

    const [moreDetailsShown, setMoreDetailsShown] = useState(false);

    useEffect(() => {
        if (headerContainerRef.current && headerContainerRef.current?.offsetHeight) {
            setPageHeaderHeight(headerContainerRef.current.offsetHeight);
        }
    }, [headerContainerRef, screenWidth, moreDetailsShown]);

    const moreDetailsData = [
        { name: 'Status', value: threadStatusBadge[status]?.text },
        {
            name: 'Started At',
            value: DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - HH:mm:ss'),
        },
        {
            name: 'Updated At',
            value: DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - HH:mm:ss'),
        },
        {
            name: 'Thread ID',
            value: thread,
        },
    ];

    const toggleMoreDetailsShown = () => {
        setMoreDetailsShown((prevState) => !prevState);
    };

    const toggleStartNewJobPanelOpened = () => {
        setStartNewJobPanelOpened((prevState) => !prevState);
    };

    return (
        <div className={`w-full bg-neutral-50`} ref={headerContainerRef}>
            <div className="py-3 px-6 border-b-1 border-neutral-200 flex items-start sm:items-center justify-between gap-2">
                <div>
                    <Button
                        type="link"
                        size="xs"
                        text="Back to Work Runs"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(backLinkHref)}
                    />
                    <div className="flex flex-col items-start sm:flex-row sm:items-center gap-x-2 flex-wrap">
                        <p className="font-heading-bold text-heading-bold-s text-black">{title}</p>
                        <Badge
                            text={process.name}
                            color="white"
                            leadingIcon={PlayCircleFillIcon}
                            leadingIconColor={process.icon_color}
                        />
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <div className="fixed bottom-0 left-0 right-0 px-5 pt-3 pb-6 flex flex-col bg-white z-10 sm:static sm:p-0">
                        <Button
                            type="secondary"
                            size="xs"
                            leadingIcon={FlowChartIcon}
                            text="Share AI Feedback"
                            onClick={openShareFeedbackModal}
                        />
                    </div>
                    <div className="sm:hidden">
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={PlayCircleIcon}
                            onClick={toggleStartNewJobPanelOpened}
                        />
                    </div>
                </div>
            </div>
            <div className="px-6 py-2 sm:py-3 border-b-1 border-neutral-200">
                <div
                    className={`sm:hidden flex items-center justify-between ${
                        moreDetailsShown ? 'mb-3' : 'mb-0'
                    }`}
                >
                    <p className="font-body-bold text-body-bold-s text-black">More details</p>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={moreDetailsShown ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                        onClick={toggleMoreDetailsShown}
                    />
                </div>
                {(moreDetailsShown || screenWidth >= 640) && (
                    <div className="sm:flex sm:justify-between sm:items-center sm:gap-4">
                        <ul
                            className={`flex flex-col gap-y-3 sm:flex-row gap-x-[40px] sm:gap-y-1.5 sm:flex-wrap`}
                        >
                            {moreDetailsData?.map((item) => (
                                <li
                                    key={item.name}
                                    className="font-body text-body-regular-xs text-neutral-300"
                                >
                                    {item.name}{' '}
                                    <span className="font-body text-body-bold-xs text-neutral-500">
                                        {item.value}
                                    </span>
                                </li>
                            ))}
                        </ul>
                        <div className="hidden sm:block">
                            <ButtonIcon
                                type="link"
                                size="xs"
                                icon={PlayCircleIcon}
                                onClick={toggleStartNewJobPanelOpened}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WorkRunDetailPageHeader;
