import React, { useState } from 'react';
import classNames from 'classnames';

import { urlRegex } from '../../../../constants/regex_patterns';
import { emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import { DeleteBin4LineIcon } from '../../../Icons';
import { ButtonIcon, Input } from '../../../index';
import CheckLineIcon from '../../../Icons/CheckLineIcon';

const EditVideoUrlBlock = ({
    videoUrl,
    handleDeleteBlock,
    handleSaveVideoUrl,
    inputErrorMessage,
    isEditable,
    setInputErrorMessage,
}) => {
    const [inputtedVideoUrl, setInputtedVideoUrl] = useState(videoUrl);

    const handleInputChange = (e) => {
        setInputtedVideoUrl(e.target.value);

        if (inputErrorMessage) {
            setInputErrorMessage(null);
        }
    };

    const handleSave = () => {
        const isInputEmpty = !inputtedVideoUrl?.trim();
        if (isInputEmpty) {
            setInputErrorMessage(emptyFieldErrorMessage);
            return;
        }

        const isInputtedUrlInvalid = !urlRegex.test(inputtedVideoUrl?.trim());
        if (isInputtedUrlInvalid) {
            setInputErrorMessage('Please check the entered URL as it seems to be invalid.');
            return;
        }

        handleSaveVideoUrl(inputtedVideoUrl);
    };

    const inputState = !isEditable ? 'disabled' : inputErrorMessage ? 'error' : 'default';

    const inputContainerClassName = classNames('flex gap-2.5 p-2', {
        'items-center': inputErrorMessage && isEditable,
        'items-end': !inputErrorMessage || !isEditable,
    });

    return (
        <div className="relative bg-purple-100 rounded-[10px] border-1 border-dashed border-purple-500 p-4">
            {isEditable && (
                <div className="absolute top-2 right-2">
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={handleDeleteBlock}
                    />
                </div>
            )}

            <div className={inputContainerClassName}>
                <Input
                    size="xs"
                    name="video_url"
                    value={inputtedVideoUrl}
                    label="Enter video URL"
                    state={inputState}
                    errorMessage={inputErrorMessage}
                    placeholder="https://"
                    onChange={handleInputChange}
                />
                <ButtonIcon
                    type="secondary"
                    size="sm"
                    icon={CheckLineIcon}
                    state={!isEditable ? 'disabled' : 'default'}
                    onClick={handleSave}
                />
            </div>
        </div>
    );
};

export default EditVideoUrlBlock;
