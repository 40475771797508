import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IntegrationCard from '../../../components/IntegrationCard/IntegrationCard';
import SetupStepCard from '../../../components/SetupStepCard/SetupStepCard';
import { Button } from '../../../design-system';

const ZapierPromptSetupPage = () => {
    const navigate = useNavigate();
    const handleDoneClick = () => {
        navigate('/integrations');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="w-full max-sm:p-4 flex flex-col gap-3 sm:gap-5">
            <IntegrationCard
                title="Connecting a Prompt in a Zapier Automation"
                description="Access your Promptfolio directly from your Zapier automations"
                centeredContent
            />
            <div className="w-full py-[30px] sm:py-[80px] flex flex-col gap-[40px] sm:gap-[60px]">
                <SetupStepCard
                    title="Step 1: Select your connected Account and search your prompts"
                    image={'/images/zapier-screenshot-select-connection.png'}
                    imageAlt="Screenshot Select your connected Account and search your prompts"
                    description="Click Load More if you don’t see your prompt and it will repopulate."
                />
                <SetupStepCard
                    title="Step 2: Fill in the input variables and you’re good to go!"
                    image={'/images/zapier-screenshot-filled-inputs.png'}
                    imageAlt="Screenshot with filled in the input variables"
                />
                <div className="mx-auto">
                    <Button
                        type="secondary"
                        size="md"
                        text={'I’m Done!'}
                        onClick={handleDoneClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default ZapierPromptSetupPage;
