import React from 'react';
import { ListOption, ListOptionGroup } from '../../../../design-system';

const JobMoreOptionsPopup = ({ setActionModal, onClose }) => {
    const openModal = (action) => {
        setActionModal({ isOpened: true, action });
        onClose();
    };

    return (
        <div className="absolute top-[110%] right-0 z-20">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Rename"
                            onClick={() => openModal('rename')}
                        />
                        <ListOption
                            leadingIconName="skipBackIcon"
                            text="Restart"
                            onClick={() => openModal('restart')}
                        />
                        <ListOption
                            leadingIconName="inboxArchiveLineIcon"
                            text="Archive"
                            onClick={() => openModal('archive')}
                        />
                    </>
                }
                handleClose={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default JobMoreOptionsPopup;
