import React from 'react';
import { handleContactUs } from '../../../helpers/handleContactUs';
import { Button } from '../../../design-system';

const CustomProcessCta = () => {
    return (
        <div className="w-full max-w-[360px] lg:max-w-[400px] rounded-2 bg-purple-500 p-4 flex flex-col gap-4">
            <div>
                <h2 className="font-body-bold text-body-bold-m text-neutral-50 mb-1">
                    Need a Custom Process?
                </h2>
                <p className="font-body text-body-regular-xs text-neutral-50">
                    Get in touch to discuss your business needs.
                </p>
            </div>

            <Button type="primary" size="xs" text="Contact Us" onClick={handleContactUs} />
        </div>
    );
};

export default CustomProcessCta;
