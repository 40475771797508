import React, { useEffect, useRef, useState } from 'react';

import * as API from '../../constants/api';
import client from '../../services/library-api';
import { SORT_TYPES } from '../../constants/sort';

import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { useClickOutside } from '../../hooks';

import ClientSideFilteredSearchBar from '../ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import Loading from '../Loading';
import PlayCircleFillIcon from '../../design-system/Icons/PlayCircleFillIcon';
import FilteringPopupOption from '../../design-system/FilteringPopupOption/FilteringPopupOption';

const AddProcessFiltersPopup = ({
    selectedOptions,
    handleAddFilterOption,
    handleRemoveOption,
    popupPosition = 'top-[110%] right-0 min-[700px]:right-auto min-[700px]:left-[16px] z-20',
    onClose,
}) => {
    const popupRef = useRef(null);

    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, icon_color }) => ({
            id,
            name,
            iconColor: icon_color,
        }));

    const {
        options: processOptions,
        optionsLoading: processOptionsLoading,
        totalOptions,
        setPage,
        canLoadMoreOptions,
        isFirstRequestCompleted,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.process,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        formatResponseToOptions,
    });

    const [filteredProcessOptions, setFilteredProcessOptions] = useState([]);
    const showClientSideFilterBar = totalOptions >= 6;

    useEffect(() => {
        if (!showClientSideFilterBar) {
            setFilteredProcessOptions(processOptions || []);
        }
    }, [processOptions, showClientSideFilterBar, isFirstRequestCompleted]);

    useEffect(() => {
        if (showClientSideFilterBar && isFirstRequestCompleted) {
            setFilteredProcessOptions(processOptions || []);
        }
    }, [isFirstRequestCompleted]);

    const { guardRef, isNextPageLoading } = useIntersectionObserver({
        handleFetch: () => setPage((page) => page + 1),
        optionsLoading: processOptionsLoading,
        options: processOptions,
        canLoadMore: canLoadMoreOptions,
    });

    const handlePopupClose = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        onClose();
    };

    useClickOutside(popupRef, handlePopupClose);

    return (
        <div
            className={`absolute ${popupPosition} w-[85vw] min-[400px]:w-[80vw] min-[515px]:w-[392px] max-h-[330px] sm:max-h-[440px] rounded-2 bg-white shadow-l1 border-1 border-neutral-200 overflow-y-auto`}
            ref={popupRef}
        >
            <div className="overflow-y-auto p-4 flex flex-col gap-3">
                {!!processOptions?.length && (
                    <>
                        {showClientSideFilterBar && (
                            <ClientSideFilteredSearchBar
                                size="sm"
                                list={processOptions || []}
                                setFilteredList={setFilteredProcessOptions}
                                placeholder="Search ..."
                            />
                        )}
                        <p className="bont-body text-body-regular-xs text-neutral-300 uppercase">
                            All processes
                        </p>

                        {!!filteredProcessOptions?.length && (
                            <ul className="flex flex-col">
                                {filteredProcessOptions.map((process) => {
                                    const isProcessAdded = selectedOptions?.includes(process.id);
                                    const updatedOption = {
                                        ...process,
                                        leadingIcon: PlayCircleFillIcon,
                                        leadingIconColor: process.iconColor,
                                    };
                                    return (
                                        <FilteringPopupOption
                                            key={process.id}
                                            option={updatedOption}
                                            isAdded={isProcessAdded}
                                            showAsBadge
                                            badgeColor="neutral"
                                            handleAddFilterOption={handleAddFilterOption}
                                            handleRemoveOption={handleRemoveOption}
                                        />
                                    );
                                })}
                            </ul>
                        )}
                        <div ref={guardRef}></div>
                        {isNextPageLoading && (
                            <div className="h-[38px] flex justify-center items-center">
                                <Loading withText={false} />
                            </div>
                        )}
                    </>
                )}

                {!processOptions?.length && processOptionsLoading && (
                    <div className="h-[60px] flex justify-center items-center">
                        <Loading withText={false} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddProcessFiltersPopup;
