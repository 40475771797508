import React from 'react';
import { Button } from 'design-system';
// import TaskSharingBlock from 'pages/LibraryPage/SharePublishModal/TaskSharingBlock/TaskSharingBlock';
// import { useGetRequest } from 'hooks/request';
// import client from 'services/library-api';
// import { API } from 'constants';
// import { useEffect } from 'react';
// import useUser from '../../../../hooks/useUser';
// import { getOrganizationMembership } from '../../../../helpers/getOrganizationMembership';

const ShareBlock = ({ taskId, accessType, accessLevel }) => {
    const handleContactUsClick = () => {
        window.location.href = 'mailto:hello@promptfolio.ai';
    };

    // const sharedTaskDetails = useGetRequest({}, client);
    // useEffect(() => {
    //     if (taskId) {
    //         const { success } = sharedTaskDetails.request({
    //             url: `${API.ROUTES.library.sharedTaskDetails}${taskId}/`,
    //         });
    //     }
    //     // console.log(sharedTaskDetails);
    // }, [taskId]);

    return (
        <>
            <div className="flex flex-col gap-4 xs:gap-6">
                <h3 className="font-heading-bold text-heading-bold-m text-neutral-500">
                    Team Sharing
                </h3>
                <p className="font-body text-body-regular-m text-neutral-400">
                    You need to have a <strong>Pro Plan</strong> to enable teams and sharing.
                    Contact us if you are interested in these features.
                </p>
                <div>
                    <Button
                        type="primary"
                        size="xs"
                        text="Contact us"
                        onClick={handleContactUsClick}
                    />
                </div>
            </div>

            {/*<TaskSharingBlock*/}
            {/*    sharedTaskDetails={sharedTaskDetails}*/}
            {/*    orgId={organizationMembership.id}*/}
            {/*    taskId={taskId}*/}
            {/*    accessType={accessType}*/}
            {/*    accessLevel={accessLevel}*/}
            {/*/>*/}
        </>
    );
};

export default ShareBlock;
