import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as API from '../../constants/api';
import client from '../../services/library-api';
import operateClient from '../../services/operate-api';

import { ArrowGoBackLineIcon, ErrorWarningLineIcon } from '../../design-system/Icons';
import { Input } from '../../design-system';
import CornerUpRightDoubleLineIcon from '../../design-system/Icons/CornerUpRightDoubleLineIcon';
import Button from '../../design-system/Button/Button';
import Alert from '../../design-system/Alert/Alert';
import PaginatedSelect from '../../design-system/PaginatedSelect/PaginatedSelect';
import { defaultErrorMessage } from '../../constants/errorMessages';

const CreateRunPage = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const chainsSearchParams = location.state?.chainsSearchParams || '';

    const [formData, setFormData] = useState({ name: null, goal: null });
    const [formDataError, setFormDataError] = useState({});
    const [promptChainOptions, setPromptChainOptions] = useState([]);
    const [page, setPage] = useState(1);
    const [canLoadMoreOptions, setCanLoadMoreOptions] = useState(false);

    const [optionsLoading, setOptionsLoading] = useState(false);
    const [runCreateLoading, setRunCreateLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchPromptChains = async () => {
            try {
                setOptionsLoading(true);
                const { data } = await client.get(
                    `${API.ROUTES.library.workflow}?limit=12&offset=${(page - 1) * 12}`
                );
                setPromptChainOptions((prevData) => {
                    const newData =
                        data.results?.map((item) => ({ id: item.id, name: item.name })) || [];
                    return [...prevData, ...newData];
                });
                setCanLoadMoreOptions(!!data.next);
                setOptionsLoading(false);
            } catch (e) {
                setOptionsLoading(false);
            }
        };

        fetchPromptChains();
    }, [page]);

    const handleBackPressed = () => {
        navigate(`/chains${chainsSearchParams}`);
    };

    const handleSelectChange = (id) => {
        setFormData((prevData) => ({ ...prevData, goal: id }));
        if (formDataError.goal) {
            setFormDataError((prevState) => ({ ...prevState, goal: false }));
        }
    };

    const handleInputChange = (e) => {
        setFormData((prevData) => ({ ...prevData, name: e.target?.value }));
        if (formDataError.name) {
            setFormDataError((prevState) => ({ ...prevState, name: false }));
        }
    };

    const createRun = async () => {
        if (!formData.name || !formData.goal) {
            setFormDataError({ name: !formData.name, goal: !formData.goal });
            return;
        }

        setRunCreateLoading(true);
        try {
            const { data } = await operateClient.post(`${API.ROUTES.operate.worker}`, formData);
            setRunCreateLoading(false);
            if (data.id) {
                navigate(`/run/${data.id}/`);
            }
        } catch (e) {
            if (e.response.data.non_field_errors?.length) {
                setErrorAlert({
                    show: true,
                    message: 'You already have a chain run with this name.',
                });
            } else {
                setErrorAlert({ show: true, statusCode: e.response.status });
            }
            setRunCreateLoading(false);
        }
    };

    return (
        <div className="bg-white w-full flex justify-center h-full px-[14px] pb-[40px] min-h-[calc(100vh-64px)]">
            <div className="w-full max-w-[800px] py-5">
                <div className="mb-[20px]">
                    <Button
                        type="link"
                        size="sm"
                        text="Back to Prompt Chains"
                        onClick={handleBackPressed}
                        leadingIcon={ArrowGoBackLineIcon}
                    />
                </div>
                <div className="flex flex-col gap-[32px]">
                    <h1 className="font-heading-bold text-heading-bold-s text-black">
                        Create New Prompt Chain Run
                    </h1>
                    <div className="flex flex-col gap-5">
                        <PaginatedSelect
                            size="sm"
                            ref={dropdownRef}
                            name="promptChain"
                            options={promptChainOptions}
                            optionsLoading={optionsLoading}
                            value={formData.goal}
                            label="Select a Prompt Chain"
                            isRequired
                            placeholder="Choose a prompt chain"
                            onChange={handleSelectChange}
                            state={formDataError.goal ? 'error' : 'default'}
                            errorMessage={'Please select an option'}
                            fetchOptions={() => setPage((page) => page + 1)}
                            canLoadMore={canLoadMoreOptions}
                        />
                        <Input
                            size="sm"
                            name="name"
                            value={formData.name}
                            label="Give your Prompt Chain Run a name"
                            isRequired
                            placeholder="Prompt chain name"
                            onChange={handleInputChange}
                            state={formDataError.name ? 'error' : 'default'}
                            errorMessage={'Please fill in this field'}
                        />
                    </div>
                    <div>
                        <Button
                            type="primary"
                            size="sm"
                            text="Start Prompt Chain Run"
                            state={runCreateLoading ? 'loading' : 'default'}
                            leadingIcon={CornerUpRightDoubleLineIcon}
                            onClick={createRun}
                        />
                    </div>
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </div>
    );
};

export default CreateRunPage;
