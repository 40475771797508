import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { API } from 'constants';
import client from '../../../services/knowledge-api';
import { BASE_TYPES, knowledgeBasePropertyFormatMapping } from '../../../constants/base';

import Button from '../../../design-system/Button/Button';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import { Input, Select } from '../../../design-system';
import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';
import ErrorWarningLineIcon from '../../../design-system/Icons/ErrorWarningLineIcon';
import PropertyItem from './PropertyItem/PropertyItem';
import { useWindowSize } from '../../../hooks/useWindowSize';
import Alert from '../../../design-system/Alert/Alert';
import { useGetRequest } from '../../../hooks/request';
import Loading from '../../../components/Loading';
import { hasDuplicateValues } from '../../../helpers/checkDuplicatedFieldInArrayOfObjects';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const baseTypeSelectOptions = [{ id: BASE_TYPES.knowledgeBase, name: 'Knowledge Base' }];

const initialCreateProperty = {
    name: 'content',
    dataFormat: 'Multi line text',
    skip: false,
    editable: true,
};

const emptyProperty = {
    name: '',
    dataFormat: 'Single line text',
    skip: false,
    editable: true,
};

const BaseBuilderPage = () => {
    const navigate = useNavigate();
    const { baseId } = useParams();
    const action = baseId ? 'edit' : 'create';

    const { user } = useUser();
    const orgId = user && user?.organization?.id;

    const [inputsData, setInputsData] = useState({
        name: '',
        type: null,
    });
    const [properties, setProperties] = useState([initialCreateProperty]);
    const [fieldsErrorMessages, setFieldsErrorMessages] = useState({
        name: null,
        type: null,
        properties: null,
    });
    const [errorAlert, setErrorAlert] = useState({ show: false, message: null, statusCode: null });

    const [baseData, setBaseData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const { width: screenWidth } = useWindowSize();

    const baseDataRequest = useGetRequest(
        { url: `${API.ROUTES.knowledge.base}${baseId}/` },
        client
    );

    useDocumentTitle(action === 'create' ? 'Create New Base' : 'Editing Base');

    useEffect(() => {
        const fetchBaseData = async () => {
            const { response, success, error } = await baseDataRequest.request();
            if (success) {
                setBaseData(response);
                setProperties([
                    ...response.properties.map((item) => ({ ...item, editable: false })),
                    emptyProperty,
                ]);
                setInputsData({ name: response.name, type: response.type });
            }
            if (error) {
                navigate('/base');
            }
        };

        if (baseId) {
            fetchBaseData();
        }
    }, [orgId]);

    const title = {
        create: 'Create new base',
        edit: `Editing ${baseData?.name} Base`,
    };

    const handleInputChange = (e) => {
        setInputsData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
        if (fieldsErrorMessages[e.target.name]) {
            setFieldsErrorMessages((prevState) => ({ ...prevState, [e.target.name]: null }));
        }
    };

    const handleSelect = (field, value) => {
        setInputsData((prevData) => ({ ...prevData, [field]: value }));
        if (fieldsErrorMessages[field]) {
            setFieldsErrorMessages((prevState) => ({ ...prevState, [field]: null }));
        }
    };

    const addNewProperty = () => {
        setProperties((prevProperties) => [...prevProperties, emptyProperty]);
        if (fieldsErrorMessages.properties) {
            setFieldsErrorMessages((prevState) => ({ ...prevState, properties: null }));
        }
    };

    const handleCreateBase = async () => {
        const requestBody = {
            name: inputsData.name,
            type: inputsData.type,
            organization: orgId,
            properties: properties.map(({ editable, ...item }) => ({
                ...item,
                dataType: knowledgeBasePropertyFormatMapping[item.dataFormat],
            })),
        };
        await client.post(API.ROUTES.knowledge.base, requestBody);
    };

    const handleEditBase = async () => {
        const requestBody = {
            properties: properties.reduce(
                (acc, { editable, ...item }) =>
                    editable
                        ? [
                              ...acc,
                              {
                                  ...item,
                                  dataType: knowledgeBasePropertyFormatMapping[item.dataFormat],
                              },
                          ]
                        : acc,
                []
            ),
        };
        await client.patch(`${API.ROUTES.knowledge.base}${baseId}/`, requestBody);
    };

    const handleAction = {
        create: handleCreateBase,
        edit: handleEditBase,
    };

    const handleSave = async () => {
        const areEmptyFields = !inputsData.name || !inputsData.type || !properties.length;
        const areDuplicatedPropertiesName = hasDuplicateValues(properties, 'name');
        const areEmptyPropertyNames = properties.some((item) => !item.name);

        if (areEmptyFields || areDuplicatedPropertiesName || areEmptyPropertyNames) {
            setFieldsErrorMessages({
                name: !inputsData.name ? 'This field may not be blank.' : null,
                type: !inputsData.type ? 'Please select a base type.' : null,
                properties: !properties.length
                    ? 'At least one property should be added.'
                    : areDuplicatedPropertiesName
                    ? 'Two properties cannot have the same name.'
                    : areEmptyPropertyNames
                    ? 'The property name cannot be empty.'
                    : null,
            });
            return;
        }

        try {
            setIsLoading(true);
            await handleAction[action]();
            setIsLoading(false);
            navigate('/base');
        } catch (error) {
            setIsLoading(false);
            if (
                error.response &&
                (error.response.data?.name ||
                    error.response.data?.type ||
                    error.response.data?.properties)
            ) {
                setFieldsErrorMessages({
                    name: error.response.data?.name ? error.response.data?.name?.[0] : null,
                    type: error.response.data?.type ? error.response.data?.type?.[0] : null,
                    properties: error.response.data?.properties
                        ? error.response.data?.properties[0]
                        : null,
                });
            } else if (error.response && error.response.data?.non_field_errors) {
                setErrorAlert({ show: true, message: error.response.data.non_field_errors[0] });
            } else if (error.response && error.response.data?.organization?.name) {
                setFieldsErrorMessages((prevState) => ({
                    ...prevState,
                    name: error.response.data?.organization?.name,
                }));
            } else if (error.response && error.response.data?.error) {
                setFieldsErrorMessages({ properties: error.response.data.error });
            } else {
                setErrorAlert({
                    show: true,
                    message: defaultErrorMessage,
                    statusCode: error.response.status,
                });
            }
        }
    };

    return (
        <div className="bg-white w-full flex justify-center h-full px-5 pb-[40px] min-h-[calc(100vh-64px)] relative">
            {action === 'create' || (action === 'edit' && baseData) ? (
                <div className="w-full max-w-[800px] py-5">
                    <div className="mb-5">
                        <Button
                            type="link"
                            size="sm"
                            text="Back to All Bases"
                            onClick={() => navigate('/base')}
                            leadingIcon={ArrowGoBackLineIcon}
                        />
                    </div>
                    <div className="flex flex-col gap-8">
                        <p className="font-heading-bold text-heading-bold-m text-neutral-500">
                            {title[action]}
                        </p>
                        <div className="flex flex-col gap-5">
                            <Select
                                size="md"
                                name="type"
                                value={inputsData.type}
                                label="Base Type"
                                placeholder="Select a Base Type"
                                isRequired
                                state={
                                    action === 'edit'
                                        ? 'disabled'
                                        : fieldsErrorMessages.type
                                        ? 'error'
                                        : 'default'
                                }
                                options={baseTypeSelectOptions}
                                errorMessage={fieldsErrorMessages.type}
                                onChange={(value) => handleSelect('type', value)}
                            />
                            <Input
                                size="md"
                                name="name"
                                value={inputsData.name}
                                label="Base Name"
                                placeholder="BrandGuidelines"
                                isRequired
                                onChange={handleInputChange}
                                state={
                                    action === 'edit'
                                        ? 'disabled'
                                        : fieldsErrorMessages.name
                                        ? 'error'
                                        : 'default'
                                }
                                errorMessage={fieldsErrorMessages.name}
                                tipText={
                                    action === 'create'
                                        ? 'Base name must start with an uppercase letter, followed by any combination of underscores, digits, and letters.'
                                        : ''
                                }
                            />
                            {inputsData.type === BASE_TYPES.knowledgeBase && (
                                <div className="flex flex-col gap-2">
                                    {(screenWidth >= 850 || !properties?.length) && (
                                        <div className="flex gap-x-3 items-center w-full justify-between">
                                            <div className="w-[64%] min-[500px]:w-[calc(100%-172px)] flex items-center gap-x-3">
                                                <p className="text-small font-bold leading-1.25 max-[500px]:flex-grow min-[500px]:w-[55%] min-[980px]:min-w-[360px]">
                                                    Property name
                                                    <sup className="text-red-500 font-small leading-1 font-medium ml-0.5">
                                                        *
                                                    </sup>
                                                </p>
                                                <p className="text-small font-bold leading-1.25 flex-grow">
                                                    Property type
                                                    <sup className="text-red-500 font-small leading-1 font-medium ml-0.5">
                                                        *
                                                    </sup>
                                                </p>
                                            </div>
                                            <p className="text-small font-bold leading-1.25 min-[500px]:w-[172px]">
                                                Embed property
                                            </p>
                                        </div>
                                    )}
                                    {!!properties?.length && (
                                        <div className="flex flex-col gap-5 min-[850px]:gap-2">
                                            {properties.map((item, index) => (
                                                <PropertyItem
                                                    key={index}
                                                    item={item}
                                                    currentIndex={index}
                                                    setProperties={setProperties}
                                                    fieldsErrorMessages={fieldsErrorMessages}
                                                    setFieldsErrorMessages={setFieldsErrorMessages}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    <div className="flex items-start gap-1">
                                        <SvgIcon
                                            icon={ErrorWarningLineIcon}
                                            color={
                                                fieldsErrorMessages.properties
                                                    ? '#E95B69'
                                                    : '#5E6470'
                                            }
                                            size="medium"
                                        />
                                        <p
                                            className={`text-extraSmall leading-1.25 ${
                                                fieldsErrorMessages.properties
                                                    ? 'text-red-500'
                                                    : 'text-neutral-300'
                                            }`}
                                        >
                                            {fieldsErrorMessages.properties ||
                                                'Property name must start with an underscore, uppercase, or lowercase letter, followed by any mix of underscores, digits, and letters.'}
                                        </p>
                                    </div>
                                    <div>
                                        <Button
                                            size="sm"
                                            type="link"
                                            text="Add new property"
                                            onClick={addNewProperty}
                                        />
                                    </div>
                                </div>
                            )}
                            <div>
                                <Button
                                    type="primary"
                                    size="sm"
                                    state={isLoading ? 'loading' : 'default'}
                                    text={action === 'create' ? 'Create Base' : 'Save Base'}
                                    onClick={handleSave}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="absolute top-1/2 transform translate-y-[-50%] flex items-center">
                    <Loading />
                </div>
            )}
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                />
            )}
        </div>
    );
};

export default BaseBuilderPage;
