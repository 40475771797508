import React from 'react';

const IndicatorIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="3" fill={props.color} />
        </svg>
    );
};

export default IndicatorIcon;
