import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import operateClient from '../../../services/operate-api';
import { API } from 'constants';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import Panel from '../../../components/Panel';
import Alert from '../../../design-system/Alert/Alert';
import {
    CloseLineIcon,
    ErrorWarningLineIcon,
    InformationLineIcon,
    ShareBoxLineIcon,
} from '../../../design-system/Icons';
import { Button, ButtonIcon, SvgIcon } from '../../../design-system';
import Loading from '../../../components/Loading';
import ProcessForm from '../../../components/ProcessForm/ProcessForm';

const RestartSidePanel = ({ restartTarget, workerId, onClose, mutate }) => {
    const params = useParams();
    const threadId = Number(params.threadId);

    const [data, setData] = useState(null);
    const [formInputs, setFormInputs] = useState([]);

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isRestartLoading, setIsRestartLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const formattedTime = DateTime.fromISO(data?.last_updated_at).toFormat('M/d/yyyy @ h:mm a');

    const requestEndpoint = {
        thread: `${API.ROUTES.operate.restartThread}${threadId}/`,
        worker: `${API.ROUTES.operate.restartWorker}${workerId}/`,
    };

    useEffect(() => {
        const fetchPanelData = async () => {
            try {
                setIsDataLoading(true);
                const { data } = await operateClient.get(requestEndpoint[restartTarget]);

                // if inputs don't have value set initialValue
                const inputs = data.inputs?.map((item) => {
                    if (item.value === undefined || item.value === null) {
                        const initialValue =
                            item.options && (item.type === 'array' || item.type === 'object')
                                ? item.value ?? []
                                : item.value ?? '';
                        return { ...item, value: initialValue };
                    } else {
                        return item;
                    }
                });

                setData(data);
                setFormInputs(inputs);
                setIsDataLoading(false);
            } catch (e) {
                setErrorAlert({ statusCode: e.response?.status });
                setIsDataLoading(false);
            }
        };

        fetchPanelData();
    }, []);

    const handleRestart = async () => {
        const { areErrorFields, updatedFormData } = formInputs?.reduce(
            (acc, item) => {
                if (item.is_required && !item.value && item.value !== 0) {
                    return {
                        areErrorFields: true,
                        updatedFormData: [...acc.updatedFormData, { ...item, state: 'error' }],
                    };
                } else {
                    return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
                }
            },
            { areErrorFields: false, updatedFormData: [] }
        );

        if (areErrorFields) {
            setFormInputs(updatedFormData);
            return;
        }

        try {
            setIsRestartLoading(true);
            const inputsData = formInputs.map(({ state, ...input }) => input);
            await operateClient.post(requestEndpoint[restartTarget], {
                inputs: inputsData,
            });
            await mutate();
            setIsRestartLoading(false);
            onClose();
        } catch (e) {
            setErrorAlert({ statusCode: e.response?.status });
            setIsRestartLoading(false);
        }
    };

    const handleEditProcess = () => {
        const url = {
            thread: `/configure/process/${data.id}`,
            worker: `/configure/process/${data.process}/goal/${data.id}`,
        };
        window.open(url[restartTarget], '_blank');
    };

    const buttonText = {
        thread: 'Edit Process',
        worker: 'Edit Goal',
    };

    return (
        <Panel onClose={onClose} showControls={false}>
            {data && !isDataLoading && (
                <>
                    <div className="flex justify-between items-start gap-1 mb-3">
                        <div className="w-[calc(100%-44px)] flex sm:items-center gap-1 flex-col-reverse sm:flex-row sm:justify-between">
                            <p className="max-sm:w-[calc(100%+44px)] sm:flex-grow font-body text-body-regular-l text-black truncate">
                                <span className="text-body-bold-l">Restart: </span> {data.name}
                            </p>
                            <div className="min-w-fit">
                                <Button
                                    type="neutral"
                                    size="xs"
                                    text={buttonText[restartTarget]}
                                    trailingIcon={ShareBoxLineIcon}
                                    onClick={handleEditProcess}
                                />
                            </div>
                        </div>

                        <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                    </div>
                    <ProcessForm formData={formInputs} setFormData={setFormInputs} />
                    <div className="mt-5 border-t-1 border-neutral-200 pt-3 flex gap-1 mb-2">
                        <SvgIcon color="#CFD6E5" icon={InformationLineIcon} size="medium" />
                        <p className="font-body text-body-regular-xs text-neutral-200">
                            <span className="text-body-bold-xs">Process last updated: </span>{' '}
                            {formattedTime}
                        </p>
                    </div>
                    <div className="flex justify-end">
                        <Button
                            type="primary"
                            size="sm"
                            text="Restart"
                            state={isRestartLoading ? 'loading' : 'default'}
                            onClick={handleRestart}
                        />
                    </div>
                </>
            )}
            {isDataLoading && (
                <div className="h-full w-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Panel>
    );
};

RestartSidePanel.propTypes = {
    restartTarget: PropTypes.oneOf(['thread', 'worker']).isRequired,
    workerId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
};

export default RestartSidePanel;
