import React from 'react';
import { useNavigate } from 'react-router-dom';

import IntegrationCard from '../../../components/IntegrationCard/IntegrationCard';
import SetupStepCard from '../../../components/SetupStepCard/SetupStepCard';
import { Button } from '../../../design-system';

const MakeSetup1Page = () => {
    const navigate = useNavigate();

    const handleAcceptInvite = () => {
        window.open(
            'https://www.make.com/en/hq/app-invitation/5d4f2744582db71f0bfa4864c0badf5c',
            '_blank'
        );
    };
    const handleDoneClick = () => {
        navigate('/integrations/make/setup-2');
    };

    return (
        <div className="w-full max-sm:p-4 flex flex-col gap-3 sm:gap-5">
            <IntegrationCard
                title="Setting up Make.com Integration"
                description="Access your Promptfolio directly from your Make automations"
                centeredContent
            />
            <div className="w-full py-[30px] sm:py-[80px] flex flex-col gap-[40px] sm:gap-[60px]">
                <SetupStepCard
                    title="Step 1: Accept  the Promptfolio App Install Invite"
                    description="Sign in or create an account in Make.com if you don’t already have one."
                    actionButton={{ text: 'Accept Invite', handleClick: handleAcceptInvite }}
                />
                <SetupStepCard
                    title="Step 2: Click Install"
                    image={'/images/make-screenshot-install.png'}
                    imageAlt="Install Promptfolio screenshot"
                    description="This will add Promptfolio to your available Apps in Make.com"
                />
                <SetupStepCard
                    title="Step 3: Select Organization and ‘Install’"
                    image={'/images/make-screenshot-select-organization-and-install.png'}
                    imageAlt="Select organization and install Promptfolio screenshot"
                    description="You will need to be an admin of the Organization you’d like to install it in."
                />
                <div className="mx-auto">
                    <Button
                        type="secondary"
                        size="md"
                        text={'I’m Done!'}
                        onClick={handleDoneClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default MakeSetup1Page;
