import React from 'react';

import DetailContainer from '../../../../design-system/DetailContainer/DetailContainer';
import PlayCircleFillIcon from '../../../../design-system/Icons/PlayCircleFillIcon';
import UserBadge from '../../../../design-system/UserBadge/UserBadge';
import { Badge } from '../../../../design-system';

const JobProcessAndCreatedByDetail = ({ process, created_by }) => {
    const createdBy = created_by.first_name || created_by.email;

    return (
        <>
            <DetailContainer label="Process" maxWidth="58%">
                <Badge
                    text={process.name}
                    color="neutral"
                    leadingIcon={PlayCircleFillIcon}
                    leadingIconColor="#1F2125"
                />
            </DetailContainer>

            <DetailContainer label="Started by" maxWidth="32%">
                <UserBadge name={createdBy} />
            </DetailContainer>
        </>
    );
};

export default JobProcessAndCreatedByDetail;
