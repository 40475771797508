import React, { forwardRef } from 'react';
import { JOB_STATUS_HEX_COLOR, JOB_STATUS_LABEL } from '../../../../constants/jobs';

import ProgressBar from '../../../../design-system/ProgressBar/ProgressBar';

const JobDetailProgressStatus = ({ status, progress: { completed, total } }, ref) => {
    // if total === 0 and status === 'success' Progress Bar is filled, if status !== 'success' Progress Bar is empty
    const defaultProgressIfTotalEqualsZero = status === 'success' ? 100 : 0;
    const progressPercentage = (completed / total) * 100 || defaultProgressIfTotalEqualsZero;

    return (
        <div className="flex items-center lg:items-end gap-x-3 xs:gap-x-4 gap-y-2" ref={ref}>
            <div className="flex-grow lg:w-[188px] lg:max-w-[188px]">
                <p className="hidden lg:block font-body text-body-regular-xs text-neutral-300 mb-1">
                    Progress Status
                </p>
                <div className="flex items-center gap-2">
                    <ProgressBar
                        size="lg"
                        progressPercentage={progressPercentage}
                        color={completed === total ? 'lime' : 'black'}
                    />
                    <p className="font-body-bold text-body-bold-xs text-neutral-500">
                        {completed}/{total}
                    </p>
                </div>
            </div>
            <div className="px-2 py-1 bg-neutral-500 flex items-center gap-2 rounded-2">
                <div
                    className="w-2 h-2 min-w-2 rounded-full bg-red-500"
                    style={{ backgroundColor: JOB_STATUS_HEX_COLOR[status] }}
                ></div>
                <p className="font-body-bold text-body-bold-xs text-peach-100 whitespace-nowrap">
                    {JOB_STATUS_LABEL[status]}
                </p>
            </div>
        </div>
    );
};

const ForwardedJobDetailProgressStatus = forwardRef(JobDetailProgressStatus);

export default ForwardedJobDetailProgressStatus;
