import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';
import {
    getStartNewJobFormDataInitialState,
    startNewJob,
} from '../../../helpers/startNewJobFormUtils';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

import { ArrowGoBackLineIcon, ErrorWarningLineIcon } from '../../../design-system/Icons';
import Button from '../../../design-system/Button/Button';
import Loading from '../../../components/Loading';
import Alert from '../../../design-system/Alert/Alert';
import SuccessStartNewJobState from './SuccessStartNewJobState/SuccessStartNewJobState';
import StartNewJobFormBlock from './StartNewJobFormBlock/StartNewJobFormBlock';

const StartNewJobPage = () => {
    const params = useParams();
    const processId = Number(params.processId);

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from ?? '/process';

    const [process, setProcess] = useState(null);
    const [formData, setFormData] = useState(null);

    const [newJobId, setNewJobId] = useState(null);
    const [startNewJobState, setStartNewJobState] = useState('default'); // states are "default", "loading", "success"
    const [errorAlert, setErrorAlert] = useState(null);

    useEffect(() => {
        const fetchProcessData = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.library.process}${processId}/`);
                setProcess(data);
                setFormData(getStartNewJobFormDataInitialState(data.inputs));
            } catch (e) {
                //if error occurs get user back to Process List
                navigate(backLinkHref);
            }
        };

        resetProcessState();
        fetchProcessData();
    }, [processId]);

    useDocumentTitle('Start New Job');

    const resetProcessState = () => {
        setProcess(null);
        setFormData(null);
        setNewJobId(null);
    };

    const handleStartNewJob = async () => {
        await startNewJob({
            formData,
            setFormData,
            processId,
            setNewJobId,
            setStartNewJobState,
            setErrorAlert,
        });
    };

    const onStartFromSameProcess = () => {
        // reset form data
        setFormData(getStartNewJobFormDataInitialState(process.inputs));
        setStartNewJobState('default');
        setNewJobId(null);
    };

    return (
        <div className="page-position flex flex-col bg-neutral-50 overflow-y-auto">
            {process && (
                <>
                    <div className="py-3 px-4 sm:px-6 bg-white border-b-1 border-neutral-200 flex flex-col items-start min-[380px]:flex-row min-[380px]:items-center gap-x-5 gap-y-0.5">
                        <Button
                            type="link"
                            size="xs"
                            text="Back"
                            leadingIcon={ArrowGoBackLineIcon}
                            onClick={() => navigate(backLinkHref)}
                        />
                        <p className="font-body text-body-regular-s text-neutral-300">
                            Process / Process Overview
                        </p>
                    </div>
                    {startNewJobState !== 'success' && (
                        <StartNewJobFormBlock
                            process={process}
                            formData={formData}
                            setFormData={setFormData}
                            startNewJobState={startNewJobState}
                            handleStartNewJob={handleStartNewJob}
                        />
                    )}

                    {startNewJobState === 'success' && (
                        <SuccessStartNewJobState
                            jobId={newJobId}
                            processName={process?.name}
                            viewMode="fullScreen"
                            onStartFromNewProcess={() => navigate('/process')}
                            onStartFromSameProcess={onStartFromSameProcess}
                        />
                    )}
                </>
            )}

            {!process && (
                <div className="w-full h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default StartNewJobPage;
