import React, { useEffect, useRef, useState } from 'react';
import PublishedPromptPageHeader from '../PublishedPromptPageHeader/PublishedPromptPageHeader';
import NewTextAreaBox from '../../../design-system/NewTextAreaBox/NewTextAreaBox';
import { getFormattedInputs } from '../../../helpers/getFormattedInputs';
import SideBar from '../../../components/SideBar/SideBar';

const PublishedPromptPageLoggedInUserView = ({ publishedPromptData }) => {
    const prompt = publishedPromptData ? publishedPromptData?.prompt_version?.prompt : {};
    const promptText = publishedPromptData
        ? publishedPromptData?.prompt_version?.messages[0]?.content
        : '';
    const promptSettings = publishedPromptData ? publishedPromptData?.prompt_version?.settings : {};
    const creator = publishedPromptData ? publishedPromptData?.creator : {};
    const publishNote = publishedPromptData ? publishedPromptData?.info?.publish_note : '';

    const pageHeaderRef = useRef(null);
    const [pageHeaderHeight, setPageHeaderHeight] = useState(1);
    const bodyHeight = `calc(100% - ${pageHeaderHeight}px)`;

    useEffect(() => {
        if (pageHeaderRef.current) {
            setPageHeaderHeight(pageHeaderRef.current?.offsetHeight);
        }
    }, [pageHeaderRef, publishedPromptData]);

    return (
        <>
            <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-[#F7F5FC] overflow-auto lg:overflow-hidden flex flex-col">
                <div className="w-full h-full">
                    <PublishedPromptPageHeader
                        prompt={prompt}
                        creator={creator}
                        promptSettings={promptSettings}
                        promptVersionId={
                            publishedPromptData && publishedPromptData?.prompt_version?.id
                        }
                        ref={pageHeaderRef}
                    />
                    <div style={{ height: bodyHeight }} className={`flex flex-col lg:flex-row`}>
                        <div className="h-auto lg:h-full bg-white w-full lg:w-1/2">
                            <div className="h-full flex flex-col overflow-y-scroll px-[12px] py-[16px] sm:px-[20px] sm:py-[24px]">
                                <div className="px-[8px] py-[10px] sm:px-[16px] sm:py-[20px] flex-grow h-full">
                                    <NewTextAreaBox
                                        name={'prompt'}
                                        value={promptText}
                                        label={'Prompt'}
                                        state={'disabled'}
                                        withCopyButton
                                        fullHeight
                                        darkBg={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="h-auto lg:h-full bg-[#F7F5FC] max-lg:flex-grow w-full lg:w-1/2">
                            <div className="h-full w-full flex flex-col gap-[12px] px-[20px] py-[26px] sm:py-[40px] sm:px-[32px] overflow-y-auto">
                                {publishNote && (
                                    <div className="mb-[16px] sm:mb-[24px] border-b-1 border-neutral-200 pb-[20px]">
                                        <h3 className="font-heading-bold text-heading-bold-m text-black mb-[16px] sm:mb-[24px]">
                                            {creator.first_name || 'Creator'}'s Notes
                                        </h3>
                                        <p className="font-body text-body-regular-m text-neutral-300 ">
                                            {publishNote}
                                        </p>
                                    </div>
                                )}
                                <div className="flex flex-col gap-[16px] sm:gap-[24px] border-b-1 border-neutral-200 pb-[20px]">
                                    <h3 className="font-body text-body-bold-m text-neutral-500 uppercase">
                                        How to use
                                    </h3>
                                    <ol className="font-body text-body-regular-m text-neutral-300 pl-[20px]">
                                        {prompt?.task?.inputs && prompt.task.inputs?.length > 0 && (
                                            <li className="list-decimal mb-[2px]">
                                                Replace {getFormattedInputs(prompt.task.inputs)}{' '}
                                                with the inputs you want to use for the prompt
                                            </li>
                                        )}
                                        <li className="list-decimal">
                                            Run with the settings below for best results
                                        </li>
                                    </ol>
                                    <p className="font-body text-body-bold-m text-neutral-500">
                                        Recommended prompt settings
                                    </p>
                                    <ul className="font-body text-body-regular-m text-neutral-300 list-disc pl-[20px]">
                                        <li className="mb-[2px]">Model: {promptSettings?.model}</li>
                                        <li>Temperature: {promptSettings?.temperature}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PublishedPromptPageLoggedInUserView;
