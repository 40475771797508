import React, { useState } from 'react';
import classNames from 'classnames';
import { useViewType } from '../../../hooks/useViewType';
import { decorationLineColor, mobMenuBgColor, SIDEBAR_ITEMS } from '../../../constants/sideBar';

import SideBarNavItem from '../SideBarNavItem/SideBarNavItem';
import ViewTypeSwitcher from '../ViewTypeSwitcher/ViewTypeSwitcher';
import SideBarMobVersionHeader from '../SideBarMobVersionHeader/SideBarMobVersionHeader';
import OrganizationIconBlock from '../OrganizationIconBlock/OrganizationIconBlock';
import SideBarNotNavLinkItem from '../SideBarNotNavLinkItem/SideBarNotNavLinkItem';

const SideBarMobVersion = ({ inboxMessagesTotal }) => {
    const { viewType } = useViewType();
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const handleMenuClose = () => {
        setIsMenuOpened(false);
    };

    const menuStyles = classNames(
        `sm:hidden fixed top-[60px] bottom-0 w-full z-[31] pt-6 pl-4 pr-6 pb-[24px] transition-all duration-300 overflow-y-auto`,
        {
            'left-[-110%]': !isMenuOpened,
            'left-0': isMenuOpened,
        },
        mobMenuBgColor[viewType]
    );

    const mobVersionProps = {
        mobileVersion: true,
        handleMobMenuClose: handleMenuClose,
    };

    return (
        <>
            <SideBarMobVersionHeader
                viewType={viewType}
                isMenuOpened={isMenuOpened}
                setIsMenuOpened={setIsMenuOpened}
            />
            <nav className={menuStyles}>
                <OrganizationIconBlock mobileVersion />
                <ul
                    className={`flex flex-col gap-2 py-2 border-b-1 ${decorationLineColor[viewType]}`}
                >
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.dashboard} {...mobVersionProps} />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.library} {...mobVersionProps} />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.configure} {...mobVersionProps} />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.threads} {...mobVersionProps} />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.process} {...mobVersionProps} />
                    <SideBarNavItem
                        itemData={SIDEBAR_ITEMS.inbox}
                        label={inboxMessagesTotal}
                        {...mobVersionProps}
                    />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.jobs} {...mobVersionProps} />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.discover} {...mobVersionProps} />
                    {/*<SideBarNavItem itemData={SIDEBAR_ITEMS.docs} {...mobVersionProps} />*/}
                    {/*<SideBarNavItem itemData={SIDEBAR_ITEMS.assistant} {...mobVersionProps} />*/}
                    <SideBarNotNavLinkItem
                        itemData={SIDEBAR_ITEMS.clientSearch}
                        handleClick={() => {}}
                        {...mobVersionProps}
                    />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.base} {...mobVersionProps} />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.integrations} {...mobVersionProps} />
                </ul>
                <ul className="flex flex-col gap-2 py-2">
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.templates} {...mobVersionProps} />
                    <ViewTypeSwitcher mobileVersion handleMobMenuClose={handleMenuClose} />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.profile} {...mobVersionProps} />
                    <SideBarNavItem itemData={SIDEBAR_ITEMS.logout} {...mobVersionProps} />
                </ul>
            </nav>
        </>
    );
};

export default SideBarMobVersion;
