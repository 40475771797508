import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { parseKnowledgeBaseSearchParams } from '../../../helpers/parseKnowledgeBaseSearchParams';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import { Button } from '../../../design-system';
import { AddCircleLineIcon } from '../../../design-system/Icons';
import Loading from '../../../components/Loading';
import { EmptyIndexPage } from '../EmptyIndex/EmptyIndex';
import ContextCard from '../ContextCard/ContextCard';
import ContextVariablePanel from '../ContextVariablePanel/ContextVariablePanel';

const ContextIndexPage = ({ data, loading, pageIndex, requestKeysToMutate }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedSearchParams = parseKnowledgeBaseSearchParams(searchParams);

    const [searchQuery, setSearchQuery] = useState('');
    const [contextPanel, setContextPanel] = useState({ opened: false, action: null });

    const handleSearch = () => {
        setSearchParams({ page: 1, search: searchQuery });
    };

    const handleSearchClear = () => {
        setSearchQuery('');
        setSearchParams({ page: 1 });
    };

    const createContext = () => {
        setContextPanel({ opened: true, action: 'create' });
    };

    return (
        <div className="">
            <div className="px-4 sm:px-6">
                <h1 className="font-heading-bold text-heading-bold-s text-black mb-2">
                    Context Variables
                </h1>
                <div className="flex justify-between items-center gap-x-4 gap-y-3 flex-wrap mb-5">
                    <div className="flex-grow min-w-[280px] max-w-[680px]">
                        <SearchBar
                            size="sm"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            state={!data?.length && loading ? 'disabled' : 'default'}
                            placeholder="Search..."
                            onSubmit={handleSearch}
                            onClear={handleSearchClear}
                            withSubmitIcon
                            withClearIcon
                        />
                    </div>
                    <div>
                        <Button
                            type="secondary"
                            size="xs"
                            text="New Context"
                            leadingIcon={AddCircleLineIcon}
                            onClick={createContext}
                        />
                    </div>
                </div>
                {!data?.length && loading && (
                    <div className="flex flex-col p-8 m-8">
                        <Loading />
                    </div>
                )}
                {!data?.length && pageIndex === 0 && !loading && !parsedSearchParams.search && (
                    <EmptyIndexPage onCreatePressed={createContext} type="context" />
                )}
                {!data?.length && pageIndex === 0 && !loading && parsedSearchParams.search && (
                    <p className="font-body text-body-bold-m text-neutral-500">
                        Unfortunately, no results were found for your query{' '}
                        {parsedSearchParams.search}.
                    </p>
                )}
            </div>

            {!!data?.length && (
                <div className="max-[940px]:overflow-x-auto bg-neutral-50 pt-4 px-4 sm:px-6">
                    <ul
                        className={
                            'flex flex-col gap-2 max-[940px]:min-w-[840px] max-[940px]:w-[840px]'
                        }
                    >
                        {data.map((item) => (
                            <div key={item.id} className="cursor-pointer">
                                <ContextCard
                                    item={item}
                                    key={item.id}
                                    setContextPanel={setContextPanel}
                                />
                            </div>
                        ))}
                    </ul>
                </div>
            )}
            {contextPanel.opened && (
                <ContextVariablePanel
                    context={contextPanel.item}
                    action={contextPanel.action}
                    page={pageIndex + 1}
                    requestKeysToMutate={requestKeysToMutate}
                    handleSearchClear={handleSearchClear}
                    onClose={() => setContextPanel({ opened: false })}
                />
            )}
        </div>
    );
};

export default ContextIndexPage;
