import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SvgIcon from '../SvgIcon/SvgIcon';
import Tag from '../Tag/Tag';
import { Badge } from '../index';

SelectOption.propTypes = {
    option: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        logAs: PropTypes.string,
        badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
        leadingIcon: PropTypes.func,
        leadingIconColor: PropTypes.string,
        trailingBadge: PropTypes.shape({
            text: PropTypes.string.isRequired,
            color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
        }),
        trailingTagText: PropTypes.string,
    }).isRequired,
    isSelected: PropTypes.bool,
    showAsABadge: PropTypes.bool,
};

function SelectOption({ option, isSelected = false, showAsABadge = false }) {
    return (
        <div
            className={classNames(
                isSelected ? 'bg-neutral-50' : 'text-neutral-500',
                'py-2 px-2.5 rounded-2'
            )}
        >
            {showAsABadge ? (
                <div>
                    <Badge text={option.name} color={option.badgeColor || 'blue'} />
                </div>
            ) : (
                <div className="flex items-center justify-between gap-2">
                    <div className={'truncate flex items-center gap-2 font-normal'}>
                        {option.leadingIcon && (
                            <SvgIcon
                                icon={option.leadingIcon}
                                color={option.leadingIconColor || '#1F2125'}
                                size="large"
                            />
                        )}
                        <p className="truncate">{option.name}</p>
                        {option.trailingBadge && (
                            <Badge
                                text={option.trailingBadge.text}
                                color={option.trailingBadge.color}
                            />
                        )}
                    </div>
                    {option.trailingTagText && <Tag text={option.trailingTagText} />}
                </div>
            )}
        </div>
    );
}

export default SelectOption;
