import React, { Fragment, useMemo } from 'react';
import { JOB_TASK_CONTENT_TYPE } from '../../../../constants/jobs';

import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';
import KnowledgeBaseContentPiece from '../KnowledgeBaseContentPiece/KnowledgeBaseContentPiece';
import InboxContentPiece from '../InboxContentPiece/InboxContentPiece';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import ApiContentPiece from '../ApiContentPiece/ApiContentPiece';

const JobTaskPanelContent = ({ contentArray, setShouldClosePanelOnOutsideClick }) => {
    const sortedContentArray = useMemo(
        () => contentArray.sort((a, b) => a.order - b.order),
        [contentArray]
    );

    return (
        <div className="flex flex-col gap-5">
            {sortedContentArray?.map((content) => {
                const { order, content_type, value, is_filled, label } = content;

                if (content_type === JOB_TASK_CONTENT_TYPE.static) {
                    return (
                        <RichTextArea
                            key={order}
                            value={value}
                            previewOnly
                            autoExpand
                            isBorderHidden
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.info) {
                    const notFilledStateMessage = '*Not filled yet*';

                    return (
                        <RichTextArea
                            key={order}
                            value={is_filled ? value : notFilledStateMessage}
                            label={label}
                            previewOnly
                            autoExpand
                            darkBorderColor
                            customPadding="px-3 py-[18px]"
                            withCopyButton={is_filled}
                            markdownColor={is_filled ? '#1F2125' : '#CFD6E5'}
                        />
                    );
                }

                if (!is_filled) {
                    return (
                        <FormFieldWrapper label={label} gap={6}>
                            <div className="px-4 py-8 rounded-2 border-1 border-neutral-200 border-dashed flex justify-center font-body text-body-regular-m text-neutral-200 italic text-center">
                                Not filled yet
                            </div>
                        </FormFieldWrapper>
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.kb) {
                    return (
                        <KnowledgeBaseContentPiece
                            key={order}
                            content={content}
                            setShouldClosePanelOnOutsideClick={setShouldClosePanelOnOutsideClick}
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.inbox) {
                    return (
                        <InboxContentPiece
                            key={order}
                            content={content}
                            setShouldClosePanelOnOutsideClick={setShouldClosePanelOnOutsideClick}
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.api) {
                    return <ApiContentPiece key={order} content={content} />;
                }

                return <Fragment key={order}></Fragment>;
            })}
        </div>
    );
};

export default JobTaskPanelContent;
