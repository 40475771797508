import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/report-api';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import { useFormState } from '../../../../hooks/useFormState';
import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';
import { mutateJobsIndexPageDataAfterRenamingJob } from '../../../../helpers/jobsUtils';

import Modal from '../../../../design-system/Modal/Modal';
import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import Alert from '../../../../design-system/Alert/Alert';
import { Button, Input } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const RenameJobModal = ({ jobName, jobId, mutate, onClose }) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { name: jobName }
    );

    const {
        requestKeysToMutate: { jobs: jobsRequestKeysToMutateFromContext },
    } = useSwrRequestKeysToMutateFromContext();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleRename = async () => {
        const isNameEmpty = !formData.name;
        if (isNameEmpty) {
            setFieldErrorMessages({ name: emptyFieldErrorMessage });
            return;
        }

        try {
            setIsLoading(true);
            const { data } = await client.patch(`${API.ROUTES.report.job}${jobId}/`, {
                name: formData.name,
            });
            mutate((currentData) => ({ ...currentData, name: data.name }), { revalidate: false });
            // mutate data in jobs library
            mutateJobsIndexPageDataAfterRenamingJob(
                jobsRequestKeysToMutateFromContext,
                jobId,
                data.name
            );
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="sm:p-2 flex flex-col gap-6 sm:gap-8">
                <ModalHeader title="Rename Job" onClose={onClose} />
                <Input
                    name="name"
                    size="md"
                    value={formData.name}
                    label="Job Name"
                    isRequired
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                />
                <div className="flex items-center gap-3 justify-between">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="sm"
                        text="Save"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleRename}
                    />
                </div>
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default RenameJobModal;
