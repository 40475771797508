import React from 'react';
import { useDrop } from 'react-dnd';

import { API } from 'constants';
import client from '../../../services/library-api';
import {
    mutateRequestDataAfterDrop,
    revalidateAllRequestsKey,
} from '../../../helpers/processUtils';
import { useWindowSize } from '../../../hooks/useWindowSize';

import GroupHeader from '../GroupHeader/GroupHeader';
import DraggableProcessCard from '../DraggableProcessCard/DraggableProcessCard';
import ProcessDragAndDropTarget from '../ProcessDragAndDropTarget/ProcessDragAndDropTarget';

const GroupDetails = ({ group, requestKeysToMutate }) => {
    const isUnsortedGroup = group.id === 0;
    const { width: screenWidth } = useWindowSize();

    const handleDrop = async (draggableItem) => {
        try {
            const targetGroupId = group.id;

            mutateRequestDataAfterDrop(requestKeysToMutate, draggableItem, targetGroupId);

            await client.patch(`${API.ROUTES.library.process}${draggableItem.processId}/`, {
                playbook: targetGroupId || null,
            });
        } catch (e) {
            revalidateAllRequestsKey(requestKeysToMutate);
        }
    };

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'PROCESS_CARD',
        drop: handleDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item) => item.groupId !== group.id,
    });

    return (
        <div className="flex flex-col gap-5">
            <GroupHeader group={group} />

            <div className="grid grid-cols-1 min-[600px]:grid-cols-2 sm:grid-cols-1 gap-x-3 gap-y-4 min-[704px]:grid-cols-2 lg:grid-cols-3 l:gap-x-4 l:gap-y-5 min-[1400px]:grid-cols-4">
                {group.processes?.map((process) => {
                    const updatedProcessData = { ...process, icon_color: group.icon_color };
                    return (
                        <DraggableProcessCard
                            key={process.id}
                            groupId={group.id}
                            process={updatedProcessData}
                        />
                    );
                })}

                {screenWidth >= 1024 && (
                    <ProcessDragAndDropTarget
                        ref={drop}
                        isBorderHighlighted={isOver && canDrop}
                        isUnsortedGroup={isUnsortedGroup}
                        groupIconColor={group.icon_color}
                    />
                )}
            </div>
        </div>
    );
};

export default GroupDetails;
