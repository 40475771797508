import React, { useState } from 'react';
import client from '../../../services/user-api';
import { API } from 'constants';

import Modal from '../../../design-system/Modal/Modal';
import { Button } from '../../../design-system';
import useUser from '../../../hooks/useUser';
import FileInput from '../../../design-system/FileInput/FileInput';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const UpdateProfilePictureModal = ({ onClose, setUserData }) => {
    const { mutateUser } = useUser();
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, message: '', statusCode: null });

    const handleFile = (file) => {
        setFile(file);
    };

    const handleUploadPhoto = async () => {
        if (!file) {
            setErrorAlert({ show: true, message: 'Please upload a photo to save.' });
            return;
        }
        try {
            const authToken = localStorage.getItem('authKey');
            if (!authToken || !authToken.length) {
                throw new Error('User not logged in.'); // TODO: handle more gracefully
            }
            const options = {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            };

            setIsLoading(true);
            const formData = new FormData();
            formData.append('profile_image', file);

            const { data } = await client.patch(
                API.ROUTES.user.uploadProfileImage,
                formData,
                options
            );

            setUserData((prevData) => ({ ...prevData, profile_image: data.profile_image }));

            mutateUser(null, {
                revalidate: true,
            });
            setIsLoading(false);
            onClose();
        } catch (e) {
            setErrorAlert({
                show: true,
                message: defaultErrorMessage,
                statusCode: e.response.status,
            });
            setIsLoading(false);
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="flex flex-col gap-[40px]">
                <div>
                    <h3 className="font-heading-bold text-heading-bold-m text-neutral-400 mb-4 text-center">
                        Update profile picture
                    </h3>
                    <p className="font-body text-body-regular-s text-neutral-400 text-center">
                        250px by 250px preferred; maximum upload size of 1MB
                    </p>
                </div>

                <FileInput
                    file={file}
                    handleFile={handleFile}
                    maxSizeInBytes={1000000}
                    maxSizeLabel="1 MB"
                    formatsArray={['.jpg', '.jpeg', '.png']}
                />

                <div className="mx-auto">
                    <Button
                        type="primary"
                        size="sm"
                        text="Upload photo"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleUploadPhoto}
                    />
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                    autoCloseInMS={3000}
                />
            )}
        </Modal>
    );
};

export default UpdateProfilePictureModal;
