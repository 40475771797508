import React from 'react';
import GoalOutputsCard from '../GoalOutputsCard/GoalOutputsCard';

const AvailableOutputsPanel = ({ goals }) => {
    return (
        <div className="lg:w-[36%] lg:max-w-[500px] lg:h-full px-8 pt-5 pb-[80px] bg-white lg:overflow-y-auto">
            <p className="font-body-bold text-body-bold-m text-neutral-500 mb-4">
                Available Outputs
            </p>
            {goals?.length ? (
                <div className="flex flex-col gap-4">
                    {goals.map((goal) => (
                        <GoalOutputsCard key={goal.id} goal={goal} />
                    ))}
                </div>
            ) : (
                <p className="font-body text-body-regular-s">No Outputs are available yet.</p>
            )}
        </div>
    );
};

export default AvailableOutputsPanel;
