import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../design-system';
import LogoIcon from '../../../design-system/Icons/LogoIcon';
import { Sparkling2FillIcon } from '../../../design-system/Icons';

const PublishedPromptPageNotLoggedInUserView = () => {
    const navigate = useNavigate();
    const handleExternalLinkNavigate = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div className="w-full h-[calc(100vh-64px)] flex justify-center items-center">
            <div className="fixed top-0 left-0 right-0 h-[56px] bg-neutral-500 px-[12px] sm:px-[32px] flex items-center justify-between">
                <Link to="/" className="w-[40px] h-[40px] flex justify-center items-center">
                    <LogoIcon width={28} height={28} color="#D9E56E" />
                </Link>
                <Button
                    type="secondary"
                    size="sm"
                    text="Get Access"
                    leadingIcon={Sparkling2FillIcon}
                    onClick={() =>
                        handleExternalLinkNavigate(
                            'https://airtable.com/appEYjgDEhlQby74y/shrl0ds6NDvEIltI9'
                        )
                    }
                />
            </div>
            <div className="flex flex-col items-center gap-5 px-[12px]">
                <h2 className="font-heading-bold text-heading-bold-l text-neutral-500 text-center">
                    Log in to access this prompt
                </h2>
                <Button
                    type="primary"
                    size="xs"
                    text="Log In to Promptfolio"
                    onClick={() => {
                        navigate('/login');
                    }}
                />
                <Button
                    type="secondary"
                    size="xs"
                    text="Don’t have an account yet? Join the waitlist"
                    onClick={() =>
                        handleExternalLinkNavigate(
                            'https://airtable.com/appEYjgDEhlQby74y/shrl0ds6NDvEIltI9'
                        )
                    }
                />
            </div>
        </div>
    );
};

export default PublishedPromptPageNotLoggedInUserView;
