import React from 'react';

const IntegrationsEmptyState = () => {
    return (
        <div className="w-full py-[80px] px-[20px]">
            <p className="font-heading-bold text-heading-bold-m text-black text-center mb-1">
                You don’t have any Integration Runs yet.
            </p>
            <p className="font-body text-body-regular-m text-black text-center max-w-[380px] mx-auto">
                Set up an integration above to use your prompts in an automation tool.
            </p>
        </div>
    );
};

export default IntegrationsEmptyState;
