import React from 'react';
import { Link } from 'react-router-dom';

import ProcessCard from '../ProcessCard/ProcessCard';

const GroupContainer = ({ group, handleModalClose }) => {
    const isUnsortedGroup = group.id === 0;

    return (
        <div>
            <p className="font-body-bold text-body-bold-s text-neutral-300 uppercase mb-4">
                {group.name}
            </p>
            <div className="flex flex-col gap-1">
                {group.processes?.map((process) => {
                    return (
                        <Link
                            key={process.id}
                            to={`/process/${process.id}/start`}
                            onClick={handleModalClose}
                        >
                            <ProcessCard
                                process={process}
                                iconColor={group.icon_color}
                                isUnsortedGroup={isUnsortedGroup}
                            />
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default GroupContainer;
