import {
    EMPTY_BLOCK_PROPERTIES,
    EMPTY_PLAYBOOK_SECTION,
    PLAYBOOK_BUILDER_MODE,
} from '../constants/playbookBuilder';
import {
    insertItemAtSpecificPositionWithOrderUpdate,
    updateOrderKeys,
} from './insertItemAtSpecificPositionWithOrderUpdate';
import { generateUUID } from './generateUUID';

export const getNewPlaybookSectionObject = (order = 1, block_content = []) => {
    const temporaryId = generateUUID();
    return { ...EMPTY_PLAYBOOK_SECTION, order, temporaryId, block_content };
};

export const removeTemporaryIdFromList = (list) => {
    return list.map(({ temporaryId, ...item }) => item);
};

const addTemporaryIdsAndSortByOrder = (list) => {
    return list
        ?.map((item) => ({
            ...item,
            temporaryId: item.id,
        }))
        ?.sort((a, b) => a.order - b.order);
};

export const getPlaybookContentInitialState = (playbook_content) => {
    const sortedPlaybookContentWithTemporaryId = playbook_content
        ?.map((section) => ({
            ...section,
            temporaryId: section.id,
            block_content: addTemporaryIdsAndSortByOrder(section.block_content),
        }))
        ?.sort((a, b) => a.order - b.order);

    return !!playbook_content.length
        ? sortedPlaybookContentWithTemporaryId
        : [getNewPlaybookSectionObject()];
};

export const getAreSectionsExpandedInitialState = (sections) => {
    return sections.reduce((acc, section) => ({ ...acc, [section.temporaryId]: true }), {});
};

// Since the backend assigns IDs to every new section, this function replaces the old form data state with a new one,
// where each section has a backend-assigned ID and the temporary IDs are updated accordingly
// Temporary IDs are changed only in this part of the code (in this function)
export const updatePlaybookContentFormDataAfterSaveChanges = (
    updatedPlaybookContent,
    playbookContentFormData,
    setPlaybookContentFormData,
    updateOriginalPlaybookContentFormData,
    areSectionsExpandedState
) => {
    const [areSectionsExpanded, setAreSectionsExpanded] = areSectionsExpandedState;

    // update temporary IDs based on response
    const playbookContentWithUpdatedTemporaryId =
        getPlaybookContentInitialState(updatedPlaybookContent);

    // make a dictionary to easy get outdated temporaryId property for every section
    const outdatedTemporaryIdsMap = playbookContentFormData.reduce(
        (acc, section) => ({ ...acc, [section.order]: section.temporaryId }),
        {}
    );
    // replace old temporaryIds by new ones in areSectionsExpandedState
    const updatedAreSectionExpandedEntries = playbookContentWithUpdatedTemporaryId.map(
        ({ temporaryId, order }) => [
            temporaryId,
            areSectionsExpanded[outdatedTemporaryIdsMap[order]],
        ]
    );
    setAreSectionsExpanded(Object.fromEntries(updatedAreSectionExpandedEntries));

    setPlaybookContentFormData(playbookContentWithUpdatedTemporaryId);
    updateOriginalPlaybookContentFormData(playbookContentWithUpdatedTemporaryId);
};

export const deleteSection = ({ temporaryId, setPlaybookContentFormData }) => {
    setPlaybookContentFormData((prevData) => {
        const filteredDataWithUpdatedOrders = prevData
            .filter((item) => item.temporaryId !== temporaryId)
            .map((item, index) => ({ ...item, order: index + 1 }));
        return filteredDataWithUpdatedOrders;
    });
};

export const duplicateSection = ({
    sectionData,
    setPlaybookContentFormData,
    setAreSectionsExpanded,
}) => {
    const { id, version_id, order, temporaryId, block_content, ...sectionRest } = sectionData;

    const duplicatedSectionOrder = order + 1;
    const duplicatedBlockContent = block_content.map((block) => ({
        ...block,
        id: null,
        temporaryId: generateUUID(),
    }));

    const duplicatedSectionData = {
        ...sectionRest,
        id: null,
        version_id: null,
        order: duplicatedSectionOrder,
        temporaryId: generateUUID(),
        block_content: duplicatedBlockContent,
    };

    setPlaybookContentFormData((prevData) => {
        return insertItemAtSpecificPositionWithOrderUpdate(
            prevData,
            duplicatedSectionData,
            duplicatedSectionOrder
        );
    });

    // duplicated section's isExpanded property is the same as the section being duplicated
    setAreSectionsExpanded((prevData) => ({
        ...prevData,
        [duplicatedSectionData.temporaryId]: prevData[temporaryId],
    }));
};

export const checkIfPlaybookContentFormDataChanged = (originalFormData, currentFormData) => {
    const formDataHasDifferentLength = currentFormData?.length !== originalFormData?.length;

    if (formDataHasDifferentLength) {
        return true;
    }

    const isFormChanged = JSON.stringify(originalFormData) !== JSON.stringify(currentFormData);
    return isFormChanged;
};

export const checkIfViewModeWithEmptyPlaybookContent = (mode, playbookContent) => {
    return (
        mode === PLAYBOOK_BUILDER_MODE.view &&
        playbookContent.length === 1 &&
        !playbookContent[0].id &&
        !playbookContent[0].name &&
        !playbookContent[0].block_content?.length
    );
};

export const getNewBlockObject = ({ blockType, order = 1 }) => {
    const temporaryId = generateUUID();
    return {
        id: null,
        order,
        temporaryId,
        block_type: blockType,
        ...EMPTY_BLOCK_PROPERTIES[blockType],
    };
};

export const removeBlockFromSection = (section, blockTemporaryId) => {
    const filteredBlockData = section.block_content.filter(
        (block) => block.temporaryId !== blockTemporaryId
    );
    const blockDataWithUpdatedOrders = updateOrderKeys(filteredBlockData);
    return { ...section, block_content: blockDataWithUpdatedOrders };
};

export const getTargetBlockData = ({
    currentSectionTemporaryId,
    blockTemporaryId,
    sectionsList,
}) => {
    const currentSection = sectionsList.find(
        (section) => section.temporaryId === currentSectionTemporaryId
    );
    const blockData = currentSection?.block_content?.find(
        (block) => block.temporaryId === blockTemporaryId
    );

    return blockData;
};

export const moveBlockBetweenSections = ({
    currentSectionTemporaryId,
    targetSectionTemporaryId,
    blockTemporaryId,
    targetOrder,
    setPlaybookContentFormData,
}) => {
    setPlaybookContentFormData((prevData) => {
        const blockData = getTargetBlockData({
            currentSectionTemporaryId,
            blockTemporaryId,
            sectionsList: prevData,
        });
        if (!blockData) {
            return prevData;
        }

        return prevData.map((section) => {
            if (section.temporaryId === currentSectionTemporaryId) {
                return removeBlockFromSection(section, blockTemporaryId);
            }

            if (section.temporaryId === targetSectionTemporaryId) {
                let updatedBlockContent;

                if (targetOrder) {
                    updatedBlockContent = insertItemAtSpecificPositionWithOrderUpdate(
                        section.block_content,
                        blockData,
                        targetOrder
                    );
                }

                if (!targetOrder) {
                    const newOrder = section.block_content.length + 1;
                    updatedBlockContent = [
                        ...section.block_content,
                        { ...blockData, order: newOrder },
                    ];
                }

                return { ...section, block_content: updatedBlockContent };
            }

            return section;
        });
    });
};
