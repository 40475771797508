import React, { useEffect, useState } from 'react';
import Modal from '../../../design-system/Modal/Modal';
import { Button, Input, SvgIcon } from '../../../design-system';
import EscapeIcon from '../../../design-system/Icons/EscapeIcon';
import operateClient from '../../../services/operate-api';
import { API } from 'constants';
import { useParams } from 'react-router-dom';
import Alert from '../../../design-system/Alert/Alert';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const ThreadEditModal = ({ onClose, editingTarget, name, setThread }) => {
    const params = useParams();
    const threadId = Number(params.threadId);

    const [inputsData, setInputsData] = useState(null);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null, message: null });

    useEffect(() => {
        if (editingTarget === 'name') {
            setInputsData({ name });
        }
    }, []);

    const handleInputChange = (e) => {
        setInputsData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    const handleSaveChanges = async () => {
        try {
            setIsEditLoading(true);

            await operateClient.patch(`${API.ROUTES.operate.thread}${threadId}/`, inputsData);
            setThread((prevData) => ({ ...prevData, name: inputsData.name }));
            onClose();

            setIsEditLoading(false);
        } catch (e) {
            setIsEditLoading(false);
            setErrorAlert({
                show: true,
                message: defaultErrorMessage,
                statusCode: e.response.status,
            });
        }
    };

    return (
        <Modal onClose={onClose} size="medium">
            <div className="sm:p-[8px] flex flex-col gap-4">
                <div className="flex items-center justify-between gap-3">
                    <p className="font-heading-bold text-heading-bold-m text-neutral-400">
                        Edit Thread
                    </p>
                    <button type="button" onClick={onClose} className="cursor-pointer">
                        <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
                    </button>
                </div>
                {editingTarget === 'name' && inputsData && (
                    <Input
                        size="md"
                        name="name"
                        value={inputsData?.name}
                        label="Thread Name"
                        isRequired
                        onChange={handleInputChange}
                    />
                )}
                <div className="flex items-center justify-center gap-4">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Save Changes"
                        state={isEditLoading ? 'loading' : 'default'}
                        onClick={handleSaveChanges}
                    />
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false })}
                />
            )}
        </Modal>
    );
};

export default ThreadEditModal;
