import FlagFillLineIcon from '../design-system/Icons/FlagFillLineIcon';
import FileEditLineIcon from '../design-system/Icons/FileEditLineIcon';
import CheckboxCircleFillLineIcon from '../design-system/Icons/CheckboxCircleFillLineIcon';

export const JOB_STATUS = {
    pending: 'pending',
    started: 'started',
    success: 'success',
    failure: 'failure',
    canceled: 'canceled',
    archived: 'archived',
};

export const JOB_STATUS_LABEL = {
    [JOB_STATUS.pending]: 'Pending',
    [JOB_STATUS.started]: 'In Progress',
    [JOB_STATUS.success]: 'Completed',
    [JOB_STATUS.failure]: 'In Review',
    [JOB_STATUS.canceled]: 'Canceled',
    [JOB_STATUS.archived]: 'Archived',
};

export const JOB_STATUS_BADGE_COLOR = {
    [JOB_STATUS.pending]: 'neutral',
    [JOB_STATUS.started]: 'peach',
    [JOB_STATUS.success]: 'lime',
    [JOB_STATUS.failure]: 'red',
    [JOB_STATUS.canceled]: 'neutral',
    [JOB_STATUS.archived]: 'neutral',
};

export const JOB_STATUS_HEX_COLOR = {
    [JOB_STATUS.pending]: '#CFD6E5',
    [JOB_STATUS.started]: '#F2AA85',
    [JOB_STATUS.success]: '#D9E56E',
    [JOB_STATUS.failure]: '#E95B69',
    [JOB_STATUS.canceled]: '#CFD6E5',
    [JOB_STATUS.archived]: '#CFD6E5',
};

export const jobsStatusBadge = Object.entries(JOB_STATUS)
    .map(([status]) => {
        return [status, { text: JOB_STATUS_LABEL[status], color: JOB_STATUS_BADGE_COLOR[status] }];
    })
    .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {});

export const jobStatusFilterOptions = [
    JOB_STATUS.pending,
    JOB_STATUS.started,
    JOB_STATUS.success,
    JOB_STATUS.failure,
];

export const jobStatusFilterBadges = jobStatusFilterOptions.map((status) => ({
    value: status,
    label: JOB_STATUS_LABEL[status],
    color: JOB_STATUS_BADGE_COLOR[status],
}));

export const JOB_TASK_BUCKET_STATUS = {
    pending: 'pending',
    processing: 'processing',
    completed: 'completed',
};

export const JOB_TASK_BUCKET_STATUS_ICON = {
    pending: FlagFillLineIcon,
    processing: FileEditLineIcon,
    completed: CheckboxCircleFillLineIcon,
};

export const JOB_TASK_CONTENT_TYPE = {
    info: 'info',
    static: 'static',
    inbox: 'inbox',
    kb: 'kb',
    api: 'api',
};
