import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Button } from '../../../design-system';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const ProcessCard = ({ process }) => {
    const { id, name, last_run_at, icon_color } = process;
    const formattedTime = DateTime.fromISO(last_run_at).toRelative() || 'No runs yet';

    const navigate = useNavigate();
    const location = useLocation();

    const handleViewProcessDetail = (e) => {
        e.preventDefault();
        navigate(`/process/${id}`, { state: { from: location } });
    };

    const handleNewJobClick = (e) => {
        e.preventDefault();
        navigate(`/process/${id}/start`, { state: { from: location } });
    };

    return (
        <Link to={`/process/${id}`} state={{ from: location }} className="self-stretch">
            <div className="relative rounded-2 p-4 self-stretch flex flex-col gap-4 justify-between z-[10]">
                <div
                    className="absolute inset-0 rounded-2 z-[-1]"
                    style={{ backgroundColor: icon_color, opacity: 0.2 }}
                ></div>
                <div className="">
                    <p className="font-body text-body-bold-m text-black mb-1 truncate">{name}</p>
                    <p className="font-body text-body-regular-xs text-neutral-500">
                        {formattedTime}
                    </p>
                </div>

                <div className="flex items-center justify-between gap-2">
                    <Button
                        type="link"
                        size="xs"
                        text="View Details"
                        onClick={handleViewProcessDetail}
                    />
                    <Button
                        type="primary"
                        size="xs"
                        text="New Job"
                        trailingIcon={FlashlightFillIcon}
                        onClick={handleNewJobClick}
                    />
                </div>
            </div>
        </Link>
    );
};

export default ProcessCard;
