import React from 'react';
import IssueDetailsBlock from '../IssueDetailsBlock/IssueDetailsBlock';
import TemplateFileBlock from '../TemplateFileBlock/TemplateFileBlock';

const TemplateVersionDetailBlock = ({
    templateVersionData,
    isEditAccess,
    versionChangeableData,
    setVersionChangeableData,
    setChangedVersionFields,
    versionInputsErrorMessages,
    setVersionInputsErrorMessages,
    issueDetailsExpanded,
    setIssueDetailsExpanded,
    shouldRefreshTemplateFile,
    setShouldRefreshTemplateFile,
    isGoalTemplatesSidePanelDisplayed,
}) => {
    return (
        <div
            className={`flex-grow flex flex-col gap-4 ${
                isGoalTemplatesSidePanelDisplayed && 'pb-5'
            }`}
        >
            <IssueDetailsBlock
                editable={isEditAccess}
                templateVersionData={templateVersionData}
                versionChangeableData={versionChangeableData}
                setVersionChangeableData={setVersionChangeableData}
                setChangedVersionFields={setChangedVersionFields}
                isExpanded={issueDetailsExpanded}
                toggleExpand={() => setIssueDetailsExpanded((prevState) => !prevState)}
                versionInputsErrorMessages={versionInputsErrorMessages}
                setVersionInputsErrorMessages={setVersionInputsErrorMessages}
            />
            <div className="flex-grow flex flex-col gap-4">
                <TemplateFileBlock
                    content={versionChangeableData.file_content}
                    editable={isEditAccess}
                    errorMessage={versionInputsErrorMessages.file_content}
                    setVersionChangeableData={setVersionChangeableData}
                    setChangedVersionFields={setChangedVersionFields}
                    setVersionInputsErrorMessages={setVersionInputsErrorMessages}
                    shouldRefreshTemplateFile={shouldRefreshTemplateFile}
                    setShouldRefreshTemplateFile={setShouldRefreshTemplateFile}
                />
            </div>
        </div>
    );
};

export default TemplateVersionDetailBlock;
