import React, { useState } from 'react';
import { Button, Badge, ButtonIcon } from 'design-system';
import { EditIcon, More2FillIcon, AddCircleLineIcon } from 'design-system/Icons';
import PromptDetailCard from 'components/PromptDetailCard/PromptDetailCard';
import { useNavigate } from 'react-router-dom';
import { SubtractLineIcon, AddLineIcon } from 'design-system/Icons';

const TaskDetailCard = ({ step, handleEditTask, handleCreatePrompt }) => {
    const navigate = useNavigate();

    const handleRunPrompt = (promptId) => {
        navigate(`/library/prompt/${promptId}`);
    };

    const [isVisible, setIsVisible] = useState(false);

    const handleCollapse = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="flex flex-col space-y-2 p-4 bg-neutral-50 rounded-lg">
            <div className="flex flex-row justify-between">
                <div className="">
                    <span className="font-body-bold text-body-bold-l text-neutral-500">
                        Task:&nbsp;
                    </span>
                    <span className="font-body text-body-regular-l">{step.task_name}</span>
                </div>
                <div className="flex flex-row space-x-2 items-center">
                    {isVisible ? (
                        <Button
                            type="link"
                            size="xs"
                            text="Collapse"
                            leadingIcon={SubtractLineIcon}
                            onClick={() => {
                                handleCollapse();
                            }}
                        />
                    ) : (
                        <Button
                            type="link"
                            size="xs"
                            text="Expand"
                            leadingIcon={AddLineIcon}
                            onClick={() => {
                                handleCollapse();
                            }}
                        />
                    )}
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={() => console.log('Reorder Task')} // Future TODO: this is where we'll re-order tasks when we add ordering
                    />
                </div>
            </div>
            {isVisible && (
                <div className="flex flex-row gap-x-4 items-stretch p-4 rounded-md bg-white border-black border divide-x divide-black">
                    <div className="flex flex-col space-y-4 items-start w-1/4">
                        <div className="font-body-bold text-body-bold-m">Inputs: </div>
                        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2 items-center">
                            {step.task_inputs.map((input, index) => (
                                <Badge text={input.name} color="purple" />
                            ))}
                        </div>
                        <div className="font-body-bold text-body-bold-m">Output: </div>
                        {step.task_outputs.map((output, index) => (
                            <Badge text={output.name} color="lime" />
                        ))}
                        <Button
                            type="ghost"
                            size="xs"
                            text="Edit Task"
                            leadingIcon={EditIcon}
                            onClick={() => handleEditTask(step.task_id)}
                        />
                    </div>
                    <div className="flex flex-col w-full justify-between pl-4">
                        <div className="flex flex-col space-y-2">
                            <div className="font-body-bold text-body-bold-m">
                                Prompts for this Task:{' '}
                            </div>
                            <div className="flex flex-col w-full justify-between">
                                {step.prompts.map((prompt, index) => (
                                    <PromptDetailCard
                                        key={index}
                                        prompt={prompt}
                                        handleRunPrompt={handleRunPrompt}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-row justify-end items-center px-3">
                            <Button
                                type="link"
                                text="Add Prompt"
                                size="xs"
                                leadingIcon={AddCircleLineIcon}
                                onClick={() => handleCreatePrompt(step.task_id)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TaskDetailCard;
