import React, { useEffect, useMemo, useState } from 'react';

import client from '../../services/library-api';
import { API } from 'constants';

import ChainSidebar from './ChainSidebar/ChainSidebar';
import ChainDashboard from './ChainDashboard/ChainDashboard';
import { useParams } from 'react-router-dom';
import { LIBRARY_SORT_TYPES } from '../../constants/library';

// navigate('/chain/new', {state: { from: location }});  //go to this page like this

const EditingPromptChainPage = () => {
    const { id } = useParams();

    const [data, setData] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isChainLoading, setIsChainLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [canLoadMoreTasks, setCanLoadMoreTasks] = useState(false); //for infinity scroll in the sidebar
    const [areMoreTasksLoading, setAreMoreTasksLoading] = useState(false); //for infinity scroll in the sidebar

    const [dashboardTasksId, setDashboardTasksId] = useState([]); //from the initial request
    const [chainData, setChainData] = useState({
        name: '',
        description: '',
        icon_text: '2699-fe0f',
        steps: [],
    });

    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                setIsLoading(true);
                const { data } = await client.get(
                    `${API.ROUTES.library.task}?limit=12&offset=0&ordering=${LIBRARY_SORT_TYPES.alphabetical}`
                );
                const formattedData = data.results.map((item) => ({
                    ...item,
                    tabIndex: 0,
                    position: 'sidebar',
                }));
                setData(formattedData);
                setIsLoading(false);
            } catch (e) {
                console.log('error', e);
                setIsLoading(false);
            }
        };

        if (!id) {
            fetchTaskData();
        }
    }, []);

    const getFormattedDashBoardData = (taskArray, dashboardTasksId) => {
        return taskArray
            .filter((item) => !dashboardTasksId.includes(item.id))
            .map((item) => ({
                ...item,
                tabIndex: 0,
                position: 'sidebar',
            }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsChainLoading(true);
                const chainRequest = client.get(`${API.ROUTES.library.workflow}${id}/`);
                const tasksRequest = client.get(
                    `${API.ROUTES.library.task}?limit=12&offset=0&ordering=${LIBRARY_SORT_TYPES.alphabetical}`
                );

                const [{ data: chainData }, { data: tasksData }] = await Promise.all([
                    chainRequest,
                    tasksRequest,
                ]);
                setCanLoadMoreTasks(!!tasksData.next);

                const dashboardData = [];
                const dashboardTasksId = [];

                const steps = chainData.steps.map((item) => {
                    const { task, auto, mapping, order } = item;
                    dashboardData.push({
                        ...task,
                        step: order,
                        position: 'dashboard',
                        tabIndex: 0,
                    });
                    dashboardTasksId.push(task.id);
                    return { task: task.id, auto, order, mapping };
                });
                setChainData({
                    name: chainData.name,
                    description: chainData.description,
                    icon_text: chainData.icon_text,
                    skipStepsUpdating: true,
                    steps,
                });
                setDashboardTasksId(dashboardTasksId);

                const formattedTasksData = getFormattedDashBoardData(
                    tasksData.results,
                    dashboardTasksId
                );
                setData([...dashboardData, ...formattedTasksData]);
                setIsChainLoading(false);
            } catch (e) {
                console.log('error', e);
                setIsChainLoading(false);
            }
        };

        if (id) {
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        const fetchNextTaskData = async () => {
            try {
                setAreMoreTasksLoading(true);
                const { data } = await client.get(
                    `${API.ROUTES.library.task}?limit=12&offset=${12 * (page - 1)}&ordering=${
                        LIBRARY_SORT_TYPES.alphabetical
                    }`
                );
                const formattedTasksData = getFormattedDashBoardData(
                    data.results,
                    dashboardTasksId
                );
                setData((prevData) => [...prevData, ...formattedTasksData]);
                setCanLoadMoreTasks(!!data.next);
                setAreMoreTasksLoading(false);
            } catch (e) {
                setAreMoreTasksLoading(false);
            }
        };

        if (page > 1 && !areMoreTasksLoading) {
            fetchNextTaskData();
        }
    }, [page]);

    const dataInSidebar = useMemo(() => {
        return data.filter((item) => item.position === 'sidebar' && item.tabIndex === tabIndex);
    }, [data, tabIndex]);

    const dataInDashBoard = useMemo(() => {
        return [...data]
            .filter((item) => item.position === 'dashboard')
            .sort((a, b) => a.step - b.step);
    }, [data]);

    return (
        <div
            className={`
        fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-neutral-50 overflow-auto lg:overflow-hidden flex flex-col lg:flex-row `}
        >
            <ChainSidebar
                data={dataInSidebar}
                setData={setData}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                isLoading={isLoading || isChainLoading}
                setPage={setPage}
                canLoadMoreTasks={canLoadMoreTasks}
                areMoreTasksLoading={areMoreTasksLoading}
            />
            <ChainDashboard
                data={dataInDashBoard}
                setData={setData}
                requestData={chainData}
                setRequestData={setChainData}
                isChainLoading={isChainLoading}
            />
        </div>
    );
};

export default EditingPromptChainPage;
