import React from 'react';
import Button from 'design-system/Button/Button';
import { ArrowGoBackLineIcon } from 'design-system/Icons';
import EditableTextArea from 'components/EditableTextArea/EditableTextArea';
import AddCircleLineIcon from 'design-system/Icons/AddCircleLineIcon';

function ViewPageHeader({
    headerText,
    emojiCode,
    subHeaderText,
    handleBackPressed,
    handleSave,
    handleEmojiUpdated,
    buttonText,
    handleButtonClick,
}) {
    function onEmojiUpdated(emojiCode) {
        handleEmojiUpdated(emojiCode);
    }

    return (
        <div className="flex flex-col space-y-5 justify-start">
            <div className="">
                <Button
                    type="link"
                    size="sm"
                    text="Back to Library"
                    onClick={handleBackPressed}
                    leadingIcon={ArrowGoBackLineIcon}
                />
            </div>
            <div className="flex flex-col space-y-4 grow">
                <EditableTextArea
                    initialText={headerText}
                    textStyle="font-body-bold text-body-bold-xl"
                    onSave={(value) => handleSave('name', value)}
                    emojiCode={emojiCode || '2699-fe0f'}
                    onEmojiUpdated={onEmojiUpdated}
                />
                <EditableTextArea
                    initialText={subHeaderText}
                    textStyle="font-body text-body-regular-m"
                    onSave={(value) => handleSave('description', value)}
                />
            </div>
        </div>
    );
}

export default ViewPageHeader;
