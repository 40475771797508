import React from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { addUniqueElementToArray } from '../../../helpers/addUniqueElementToArray';
import { Input } from '../../../design-system';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';

const ReportInputsPanel = ({
    reportInputsData,
    setReportInputsData,
    reportErrorInputs,
    setReportErrorInputs,
    setChangedReportInputs,
}) => {
    const { title, text } = reportInputsData;
    const { width: screenWidth } = useWindowSize();

    const handleInputChange = (name, value) => {
        setReportInputsData((prevData) => ({ ...prevData, [name]: value }));
        if (reportErrorInputs[name]) {
            setReportErrorInputs((prevData) => ({ ...prevData, [name]: false }));
        }
        setChangedReportInputs((prevData) => addUniqueElementToArray(name, prevData || []));
    };

    return (
        <div className="lg:flex-grow lg:h-full bg-neutral-50 border-r-1 border-neutral-200">
            <div className="px-6 pt-5 pb-7 h-full flex flex-col gap-5 lg:overflow-y-auto">
                <Input
                    size="md"
                    name="title"
                    value={title}
                    label="REPORT TITLE"
                    state={reportErrorInputs.title ? 'error' : 'default'}
                    errorMessage={reportErrorInputs.title ? 'Please fill in this field.' : ''}
                    isRequired
                    tipText="Add mapped variables with {{variable_key || fallback_value}}"
                    onChange={(e) => handleInputChange('title', e.target.value)}
                />
                <div className="flex-grow">
                    <RichTextArea
                        name="text"
                        value={text}
                        label="REPORT TEXT"
                        isRequired
                        state={reportErrorInputs.text ? 'error' : 'default'}
                        errorMessage={'Please fill in this field.'}
                        tipText="Add mapped variables with {{variable_key || fallback_value}}"
                        autoExpand={screenWidth < 1024}
                        minHeight={260}
                        setValue={(value) => handleInputChange('text', value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReportInputsPanel;
