import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import CustomProcessCta from '../CustomProcessCTA/CustomProcessCTA';

const DiscoverPageHeader = () => {
    const navigate = useNavigate();

    return (
        <div className="bg-purple-100 p-5 sm:p-8 lg:px-[40px] lg:py-[48px] flex gap-x-4 gap-y-5 justify-between flex-wrap">
            <div className="flex flex-col items-start justify-between gap-2">
                <Button
                    type="link"
                    size="xs"
                    text="Back to Your Processes"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate('/process')}
                />
                <div className="">
                    <h1 className="font-heading-bold text-heading-bold-l text-black mb-2">
                        Process Library
                    </h1>
                    <p className="font-body text-body-regular-m text-neutral-500">
                        Explore more ways to streamline your business operations
                    </p>
                </div>
            </div>
            <CustomProcessCta />
        </div>
    );
};

export default DiscoverPageHeader;
