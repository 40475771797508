import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { parseProcessPageSearchParams } from '../../helpers/parseProcessPageSearchParams';

import useSWRRequest from '../../hooks/useSWRRequest';
import { API } from 'constants';
import client from '../../services/template-api';

import DiscoverPageHeader from './DiscoverPageHeader/DiscoverPageHeader';
import Loading from '../../components/Loading';
import LabelGroup from './LabelGroup/LabelGroup';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import DiscoverPageTopControls from './DiscoverPageTopControls/DiscoverPageTopControls';

const DiscoverPage = () => {
    const [searchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseProcessPageSearchParams(searchParams), [searchParams]);

    const { data: labels, loading } = useSWRRequest({
        client,
        route: API.ROUTES.template.processDiscover,
        searchParams: parsedParams.search ? { search: parsedParams.search } : {},
    });

    // if filter is not empty show just filtered Labels
    const filteredLabels = useMemo(() => {
        const labelFilter = parsedParams.filter;
        if (labelFilter) {
            return labels?.filter((label) => labelFilter.includes(label.id));
        }
        return labels;
    }, [labels, parsedParams.filter]);

    useDocumentTitle('Process Library');

    return (
        <div className="page-position bg-white overflow-y-auto">
            <DiscoverPageHeader />

            <div className="px-5 sm:px-6 pt-5 lg:px-8 lg:pt-6 xl:px-[66px] pb-[40px] flex flex-col gap-5">
                <DiscoverPageTopControls
                    labels={labels}
                    filteredLabels={filteredLabels}
                    areLabelsLoading={loading}
                />
                {labels && (
                    <>
                        {!!filteredLabels.length && (
                            <div className="flex flex-col gap-5">
                                {filteredLabels.map((label) => (
                                    <LabelGroup key={label.id} label={label} />
                                ))}
                            </div>
                        )}
                        {!filteredLabels.length && parsedParams.search && (
                            <p className="font-body-bold text-body-bold-s text-[#25324B]">
                                No matching results found
                            </p>
                        )}

                        {!filteredLabels.length && !parsedParams.search && (
                            <p className="font-body-bold text-body-bold-s text-[#25324B]">
                                No processes found
                            </p>
                        )}
                    </>
                )}
            </div>

            {!labels && (
                <div className="pt-8 w-full flex justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default DiscoverPage;
