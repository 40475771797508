import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'design-system';
import apiClient from 'services/user-api';
import Loading from 'components/Loading';
import { API } from 'constants';

const intialOptions = {
    method: 'POST',
    url: API.ROUTES.user.resendEmail,
    headers: { 'Content-Type': 'application/json' },
};

export default function VerifyEmail() {
    const { email } = useParams();
    const [formData, setFormData] = useState({ email: email || '' });
    const [requestState, setRequestState] = useState({
        loading: false,
        success: false,
        error: null,
    });

    const handleSubmit = async () => {
        setRequestState({
            loading: true,
            success: false,
            error: null,
        });
        const options = {
            ...intialOptions,
            data: {
                email: formData.email,
            },
        };

        const response = await apiClient(options);
        setRequestState({
            loading: false,
            success: response.status === 200,
            error: null,
        });
    };

    let content;
    if (requestState.loading) {
        content = <Loading text={`Sending you another verification email to: ${formData.email}`} />;
    } else if (requestState.success) {
        content = (
            <h1 className="font-heading-bold text-heading-bold-m text-center mb-[4px]">
                An email has been sent to your account for verification.
            </h1>
        );
    } else {
        content = (
            <>
                <h1 className="font-heading-bold text-heading-bold-m text-center mb-[4px]">
                    Verify Account
                </h1>
                <p className="font-body text-body-regular-m text-center mb-[32px]">
                    Click the button below to verify your account
                </p>
                <div className="flex justify-center">
                    <Button type="primary" text="Verify Account" size="sm" onClick={handleSubmit} />
                </div>
            </>
        );
    }

    return <div className="VerifyEmail py-[80px]">{content}</div>;
}
