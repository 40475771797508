import React from 'react';

import { providersCardData } from '../../../constants/organizationConnections';
import { Button } from '../../../design-system';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import AddLineIcon from '../../../design-system/Icons/AddLineIcon';

const ProviderCard = ({
    provider,
    connectionData,
    hasCreateAccess,
    comingSoon = false,
    setConnectionActionModal,
    oauthUrl = null,
}) => {
    const { logoPath, title, description } = providersCardData[provider];

    const handleCreateConnection = () => {
        // setConnectionActionModal({ opened: true, action: 'create', provider });
        // If oauthUrl is provided, redirect to OAuth page
        if (oauthUrl) {
            window.location.href = oauthUrl; // Redirect user to OAuth URL
        } else {
            // Otherwise, open the connection action modal as before
            setConnectionActionModal({ opened: true, action: 'create', provider });
        }
    };

    const handleEditConnection = () => {
        setConnectionActionModal({ opened: true, action: 'edit', data: connectionData, provider });
    };

    return (
        <div className="w-full h-[400px] px-8 py-[40px] rounded-2 bg-neutral-50 flex flex-col gap-3 items-center justify-between">
            <div className="flex flex-col gap-3 items-center">
                <img
                    src={logoPath}
                    width={100}
                    height={100}
                    alt={`${title} logo`}
                    className="max-h-[100px]"
                />

                <h2 className={`font-heading-bold text-heading-bold-l text-neutral-500`}>
                    {title}
                </h2>
                <p className={`font-body text-body-regular-xs text-neutral-500 text-center`}>
                    {description}
                </p>
            </div>

            <div className="flex flex-col gap-[10px] items-center">
                {comingSoon ? (
                    <Button type="primary" size="xs" text="Coming Soon" state="disabled" />
                ) : connectionData ? (
                    <Button type="secondary" size="xs" text="Active" trailingIcon={CheckLineIcon} />
                ) : (
                    <Button
                        type="primary"
                        size="xs"
                        text="Connect"
                        trailingIcon={AddLineIcon}
                        state={hasCreateAccess ? 'default' : 'disabled'}
                        onClick={handleCreateConnection}
                    />
                )}

                <Button
                    type="link"
                    size="xs"
                    text="View Details"
                    state={
                        hasCreateAccess && connectionData && !comingSoon ? 'default' : 'disabled'
                    }
                    onClick={handleEditConnection}
                />
            </div>
        </div>
    );
};

export default ProviderCard;
