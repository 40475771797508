import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { BLOCK_TYPE } from '../../../constants/playbookBuilder';

import EditVideoUrlBlock from './EditVideoUrlBlock/EditVideoUrlBlock';
import EditLineIcon from '../../Icons/EditLineIcon';
import { ButtonIcon } from '../../index';
import { DeleteBin4LineIcon } from '../../Icons';

VideoBlock.propTypes = {
    blockData: PropTypes.shape({
        order: PropTypes.number,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        temporaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        block_type: PropTypes.oneOf([BLOCK_TYPE.video]),
        properties: PropTypes.shape({
            video_url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        }).isRequired,
    }),
    editMode: PropTypes.bool,
    handleDeleteBlock: PropTypes.func.isRequired,
    handleBlockPropertyChange: PropTypes.func.isRequired,
};

function VideoBlock({ blockData, editMode = false, handleDeleteBlock, handleBlockPropertyChange }) {
    const {
        properties: { video_url },
    } = blockData;

    const [isEditingVideoUrl, setIsEditingVideoUrl] = useState(!video_url);
    const [inputtedVideoUrlErrorMessage, setInputtedVideoUrlErrorMessage] = useState(null);

    const setVideoUrlInBlockData = (value) => {
        handleBlockPropertyChange('video_url', value);
    };

    const handleSaveVideoUrl = (url) => {
        setVideoUrlInBlockData(url);
        setIsEditingVideoUrl(false);
    };

    const handleEditVideoUrl = () => {
        setIsEditingVideoUrl(true);
    };

    const handleVideoPlaybackError = () => {
        setIsEditingVideoUrl(true);
        setInputtedVideoUrlErrorMessage(
            'This URL does not seem to be a valid video link. Please enter a correct video URL.'
        );
    };

    return (
        <>
            {isEditingVideoUrl && (
                <EditVideoUrlBlock
                    videoUrl={video_url}
                    handleDeleteBlock={handleDeleteBlock}
                    handleSaveVideoUrl={handleSaveVideoUrl}
                    inputErrorMessage={inputtedVideoUrlErrorMessage}
                    isEditable={editMode}
                    setInputErrorMessage={setInputtedVideoUrlErrorMessage}
                />
            )}

            {!isEditingVideoUrl && video_url && (
                <div className="relative w-full pt-[56%]">
                    <ReactPlayer
                        url={video_url}
                        controls
                        width="100%"
                        height="100%"
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        onError={handleVideoPlaybackError}
                    />
                    {editMode && (
                        <div className="absolute top-2 right-2 flex items-center gap-2">
                            <ButtonIcon
                                type="neutral"
                                size="xs"
                                icon={EditLineIcon}
                                onClick={handleEditVideoUrl}
                            />

                            <ButtonIcon
                                type="neutral"
                                size="xs"
                                icon={DeleteBin4LineIcon}
                                onClick={handleDeleteBlock}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default VideoBlock;
