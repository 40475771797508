import React from 'react';
import PropTypes from 'prop-types';

import EscapeIcon from '../Icons/EscapeIcon';
import { ButtonIcon } from '../index';
import { CloseLineIcon } from '../Icons';

ModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
    titleStyles: PropTypes.string,
};

function ModalHeader({
    title,
    onClose,
    size = 'sm', // size of button icon
    titleStyles = 'font-heading-bold text-heading-bold-m text-neutral-500',
}) {
    return (
        <div className="flex items-center justify-between gap-4">
            <p className={titleStyles}>{title}</p>
            <div className="flex items-center gap-1">
                <button onClick={onClose} className="cursor-pointer">
                    <EscapeIcon width={28} height={18} color="#5E6470" />
                </button>
                <ButtonIcon type="link" size={size} icon={CloseLineIcon} onClick={onClose} />
            </div>
        </div>
    );
}

export default ModalHeader;
