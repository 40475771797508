import React from 'react';
import NewRunContainer from 'components/NewRunContainer/NewRunContainer';

const OutputContainer = ({
    promptOutputs,
    updatePromptOutputs = () => {},
    withFullScreenIcon = false,
    onFullScreenIconClick,
    isRunning = false,
    scrollInsideTextArea,
    outputRating,
    updateOutputRating,
    resultId,
}) => {
    return (
        <div className="flex-grow flex flex-col pb-[10px] pt-2 pb-[72px]">
            <h3 className="font-body text-body-bold-m text-neutral-500 uppercase mb-[18px]">
                Output
            </h3>
            {/* <p className="font-body text-body-regular-s text-neutral-300 mb-[16px]">
        Feel free to make edits to your output
      </p> */}
            <NewRunContainer
                textAreaData={promptOutputs}
                withCopyButton
                withFullScreenIcon={withFullScreenIcon}
                fullHeight
                onFullScreenIconClick={onFullScreenIconClick}
                simplified={isRunning}
                updateTextAreaData={updatePromptOutputs}
                placeholder="Output will be added here"
                scrollInsideTextArea={scrollInsideTextArea}
                fixedMinHeightOfTextArea={240}
                outputRating={outputRating}
                updateOutputRating={updateOutputRating}
                resultId={resultId}
            />
        </div>
    );
};

export default OutputContainer;
