//Checks for duplicated values within an array of objects based on a specific field

export const hasDuplicateValues = (arrayOfObjects, fieldName) => {
    const uniqueValues = new Set();

    for (const obj of arrayOfObjects) {
        const fieldValue = obj[fieldName];

        if (uniqueValues.has(fieldValue)) {
            return true;
        }

        uniqueValues.add(fieldValue);
    }

    return false;
};
