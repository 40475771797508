import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from 'hooks/useDocumentTitle';

import { Badge, ButtonIcon, Input, TextArea } from 'design-system';
import {
    AddLineIcon,
    ArrowGoBackLineIcon,
    ErrorWarningLineIcon,
    TerminalBoxLineIcon,
} from 'design-system/Icons';

// import EmojiButtonWithSelection from 'pages/LibraryPage/EmojiButtonWithSelection';
import Button from 'design-system/Button/Button';
import Alert from 'design-system/Alert/Alert';

import { TemperatureOptions } from 'constants';
import modelOptions from 'constants/modelOptions';
import client from 'services/library-api';
import { defaultErrorMessage } from '../../constants/errorMessages';
// import { formatToKey } from 'helpers/formatLabelToKey';

const CreateNewPromptTaskPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '/library';

    const [isLoading, setIsLoading] = useState(false);
    const [errorFields, setErrorFields] = useState({
        taskName: false,
        taskDescription: false,
        inputs: false,
        outputs: false,
    });
    // const [inputs, setInputs] = useState([]);
    // const [outputs, setOutputs] = useState([]);
    //
    // const [enteredData, setEnteredData] = useState({
    //     taskName: '',
    //     taskDescription: '',
    //     inputLabel: '',
    //     outputLabel: '',
    //     icon_text: '2699-fe0f',
    // });
    const [name, setName] = useState('');

    const [alert, setAlert] = useState({
        isShown: false,
        message: '',
        status: null,
        statusCode: null,
        icon: null,
    });

    useDocumentTitle('Create Prompt');

    const handlePromptNameChange = (e) => {
        setName(e.target.value);
        if (errorFields.taskName) {
            setErrorFields((prevFields) => ({
                ...prevFields,
                taskName: false,
            }));
        }
    };

    const handleAlertHide = () => {
        setAlert({ isShown: false, message: '', status: null, icon: null, statusCode: null });
    };

    const handleNav = (id) => {
        navigate(`/library/prompt/${id}`, { state: { libraryLocation } });
    };

    const handleBackPressed = () => {
        navigate(libraryLocation);
    };

    const handlePromptTaskCreate = async () => {
        // const allFieldsAreFilled =
        //     enteredData.taskName &&
        //     enteredData.taskDescription &&
        //     inputs.length > 0 &&
        //     outputs.length > 0;

        // if (!allFieldsAreFilled) {
        //     setErrorFields({
        //         taskName: !enteredData.taskName,
        //         taskDescription: !enteredData.taskDescription,
        //         inputs: inputs.length === 0,
        //         outputs: outputs.length === 0,
        //     });
        //     return;
        // }

        if (!name) {
            setErrorFields((prevData) => ({ ...prevData, taskName: true }));
            return;
        }

        // const requestBody = {
        //     task: {
        //         name: enteredData.taskName,
        //         description: enteredData.taskDescription,
        //         inputs,
        //         outputs,
        //         icon_text: enteredData.icon_text,
        //         icon_color: '#F0F5FE',
        //     },
        //     prompt: {
        //         name: `${enteredData.taskName} Prompt`,
        //         description: `Default prompt for ${enteredData.taskName}`,
        //         is_default: true,
        //     },
        //     settings: {
        //         model: modelOptions[0].model,
        //         provider: modelOptions[0].provider,
        //         type: modelOptions[0].type,
        //         max_tokens: 1000,
        //         temperature: TemperatureOptions[0].value,
        //     },
        //     messages: [{ role: 'user', content: '' }],
        // };

        const requestBody = {
            task: {
                name,
                description: `This is a prompt for ${name}`,
                inputs: [],
                outputs: [
                    {
                        key: 'output',
                        label: 'output',
                        type: 'string',
                        value: '',
                        is_required: true,
                    },
                ],
                icon_text: '270d-fe0f',
                icon_color: '#F0F5FE',
            },
            prompt: {
                name: `${name} Prompt`,
                description: `Default prompt for ${name}`,
                is_default: true,
            },
            settings: {
                model: modelOptions[0].model,
                provider: modelOptions[0].provider,
                type: modelOptions[0].type,
                max_tokens: 1000,
                temperature: TemperatureOptions[0].value,
            },
            messages: [{ role: 'user', content: '' }],
        };

        try {
            setIsLoading(true);
            const { data } = await client.post('create-prompt/', requestBody);
            if (data.id) {
                handleNav(data.id);
            }
            setIsLoading(false);
        } catch (error) {
            setAlert({
                isShown: true,
                status: 'critical',
                message: defaultErrorMessage,
                statusCode: error.response.status,
                icon: ErrorWarningLineIcon,
            });
            setIsLoading(false);
        }
    };

    // const handleChange = (e) => {
    //     setEnteredData((prevData) => ({
    //         ...prevData,
    //         [e.target.name]: e.target.value,
    //     }));
    //
    //     if (
    //         errorFields[e.target.name] &&
    //         (e.target.name === 'taskName' || e.target.name === 'taskDescription')
    //     ) {
    //         setErrorFields((prevFields) => ({
    //             ...prevFields,
    //             [e.target.name]: false,
    //         }));
    //     }
    // };

    // const handleAddNewInput = () => {
    //     if (alert.isShown) {
    //         handleAlertHide();
    //     }
    //     if (!enteredData.inputLabel.trim()) {
    //         return;
    //     }
    //     const inputKey = formatToKey(enteredData.inputLabel);
    //     const alreadyExist = inputs.some((input) => input.key === inputKey);
    //     if (alreadyExist) {
    //         setAlert({
    //             isShown: true,
    //             message: 'Such Input already exists',
    //             status: 'critical',
    //             icon: ErrorWarningLineIcon,
    //         });
    //         return;
    //     }
    //     const newInput = {
    //         label: enteredData.inputLabel,
    //         key: inputKey,
    //         type: 'string',
    //         value: '',
    //          is_required: true,
    //     };
    //     setInputs((prevInputs) => [...prevInputs, newInput]);
    //     setEnteredData((prevData) => ({
    //         ...prevData,
    //         inputLabel: '',
    //     }));
    //     if (errorFields.inputs) {
    //         setErrorFields((prevFields) => ({ ...prevFields, inputs: false }));
    //     }
    // };

    // const handleRemoveInputBadge = (id) => {
    //     setInputs((prevInputs) => prevInputs.filter((input) => input.key !== id));
    // };

    // const handleAddNewOutput = () => {
    //     if (!enteredData.outputLabel.trim()) {
    //         return;
    //     }
    //     const newOutput = {
    //         label: enteredData.outputLabel,
    //         key: formatToKey(enteredData.outputLabel),
    //         type: 'string',
    //         value: '',
    //          is_required: true,
    //     };
    //     setOutputs((prevOutputs) => [...prevOutputs, newOutput]);
    //     setEnteredData((prevData) => ({
    //         ...prevData,
    //         outputLabel: '',
    //     }));
    //     if (errorFields.outputs) {
    //         setErrorFields((prevFields) => ({ ...prevFields, outputs: false }));
    //     }
    // };

    // const handleEmojiUpdate = (emojiCode) => {
    //     setEnteredData((prevData) => ({ ...prevData, icon_text: emojiCode }));
    // };

    // const clearOutput = () => {
    //     setOutputs([]);
    // };

    return (
        <div className="bg-white w-full flex justify-center h-full px-[14px] pb-[40px] min-h-[calc(100vh-64px)]">
            <div className="w-full max-w-[800px] py-5">
                <div className="mb-[20px]">
                    <Button
                        type="link"
                        size="sm"
                        text="Back to Library"
                        onClick={handleBackPressed}
                        leadingIcon={ArrowGoBackLineIcon}
                    />
                </div>
                <div className="flex flex-col gap-[32px]">
                    <div className="flex items-center gap-[10px]">
                        {/*<EmojiButtonWithSelection*/}
                        {/*    initiallySelectedEmoji={enteredData.icon_text}*/}
                        {/*    onEmojiUpdated={handleEmojiUpdate}*/}
                        {/*/>*/}
                        <h1 className="font-heading-bold text-heading-bold-s text-black">
                            Create New Prompt
                        </h1>
                    </div>
                    <div className="flex flex-col gap-[20px]">
                        <Input
                            size="md"
                            label="Give your Prompt a name"
                            name="taskName"
                            value={name}
                            state={errorFields.taskName ? 'error' : 'default'}
                            isRequired
                            placeholder="Linkedin Hook Generator"
                            onChange={handlePromptNameChange}
                            errorMessage="Please fill in this field."
                        />

                        {/*<TextArea*/}
                        {/*    label="Describe what your Prompt will do"*/}
                        {/*    name="taskDescription"*/}
                        {/*    value={enteredData.taskDescription}*/}
                        {/*    state={errorFields.taskDescription ? 'error' : 'default'}*/}
                        {/*    placeholder="Describe your Prompt and use the AI assistant to help you with that"*/}
                        {/*    isRequired*/}
                        {/*    onChange={handleChange}*/}
                        {/*    errorMessage="Please fill in this field."*/}
                        {/*/>*/}
                        {/*<div*/}
                        {/*    className={`flex gap-[8px] ${*/}
                        {/*        errorFields.inputs ? 'items-center' : 'items-end'*/}
                        {/*    }`}*/}
                        {/*>*/}
                        {/*    <Input*/}
                        {/*        size="sm"*/}
                        {/*        label="What are the required Inputs for the Prompt?"*/}
                        {/*        value={enteredData.inputLabel}*/}
                        {/*        state={errorFields.inputs ? 'error' : 'default'}*/}
                        {/*        name="inputLabel"*/}
                        {/*        isRequired*/}
                        {/*        placeholder="Name your inputs here and click + to add"*/}
                        {/*        onChange={handleChange}*/}
                        {/*        errorMessage="Please add an Input."*/}
                        {/*    />*/}
                        {/*    <ButtonIcon*/}
                        {/*        type="ghost"*/}
                        {/*        size="md"*/}
                        {/*        icon={AddLineIcon}*/}
                        {/*        onClick={handleAddNewInput}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/*{inputs.length > 0 && (*/}
                        {/*    <div className="flex flex-wrap gap-x-[8px] gap-y-[4px]">*/}
                        {/*        {inputs.map((input, index) => (*/}
                        {/*            <span key={index}>*/}
                        {/*                <Badge*/}
                        {/*                    text={*/}
                        {/*                        <>*/}
                        {/*                            <span className="text-body-bold-m">*/}
                        {/*                                Input:{' '}*/}
                        {/*                            </span>{' '}*/}
                        {/*                            <span className="font-body text-body-regular-m">*/}
                        {/*                                {input.label}*/}
                        {/*                            </span>*/}
                        {/*                        </>*/}
                        {/*                    }*/}
                        {/*                    color="peach"*/}
                        {/*                    removeable*/}
                        {/*                    id={input.key}*/}
                        {/*                    handleRemove={handleRemoveInputBadge}*/}
                        {/*                />*/}
                        {/*            </span>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*)}*/}

                        {/*{outputs.length === 0 ? (*/}
                        {/*    <div*/}
                        {/*        className={`flex gap-[8px] ${*/}
                        {/*            errorFields.outputs ? 'items-center' : 'items-end'*/}
                        {/*        }`}*/}
                        {/*    >*/}
                        {/*        <Input*/}
                        {/*            size="sm"*/}
                        {/*            label="What is the Output for the Prompt?"*/}
                        {/*            value={enteredData.outputLabel}*/}
                        {/*            state={errorFields.outputs ? 'error' : 'default'}*/}
                        {/*            name="outputLabel"*/}
                        {/*            isRequired*/}
                        {/*            placeholder="Name your output here"*/}
                        {/*            onChange={handleChange}*/}
                        {/*            errorMessage="Please add an Output."*/}
                        {/*        />*/}
                        {/*        <ButtonIcon*/}
                        {/*            type="ghost"*/}
                        {/*            size="md"*/}
                        {/*            icon={AddLineIcon}*/}
                        {/*            onClick={handleAddNewOutput}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*) : (*/}
                        {/*    <div>*/}
                        {/*        <p className="font-body text-body-bold-s text-neutral-500 mb-[20px]">*/}
                        {/*            What is the Output for the Prompt?*/}
                        {/*            <sup className="text-red-500 font-small leading-1 font-medium ml-0.5">*/}
                        {/*                **/}
                        {/*            </sup>*/}
                        {/*        </p>*/}
                        {/*        {outputs.map((output, index) => (*/}
                        {/*            <span key={index}>*/}
                        {/*                <Badge*/}
                        {/*                    text={*/}
                        {/*                        <>*/}
                        {/*                            <span className="text-body-bold-m">*/}
                        {/*                                Output:{' '}*/}
                        {/*                            </span>{' '}*/}
                        {/*                            <span className="font-body text-body-regular-m">*/}
                        {/*                                {output.label}*/}
                        {/*                            </span>*/}
                        {/*                        </>*/}
                        {/*                    }*/}
                        {/*                    color="blue"*/}
                        {/*                    removeable*/}
                        {/*                    handleRemove={clearOutput}*/}
                        {/*                />*/}
                        {/*            </span>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                    <div>
                        <Button
                            type="primary"
                            size="sm"
                            text="Create Prompt"
                            state={isLoading ? 'loading' : 'default'}
                            leadingIcon={TerminalBoxLineIcon}
                            onClick={handlePromptTaskCreate}
                        />
                    </div>
                </div>
            </div>
            {alert.isShown && (
                <Alert
                    status={alert.status || 'critical'}
                    message={alert.message}
                    statusCode={alert.statusCode}
                    icon={alert.icon || ErrorWarningLineIcon}
                    handleClose={handleAlertHide}
                />
            )}
        </div>
    );
};

export default CreateNewPromptTaskPage;
