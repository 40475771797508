import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import BlockHeader from '../BlockHeader/BlockHeader';
import JobCard from '../../JobsPage/JobCard/JobCard';
import Loading from '../../../components/Loading';

const RecentJobsBlock = ({ jobs }) => {
    const location = useLocation();

    return (
        <div className="p-5 sm:p-8 bg-neutral-50 flex-grow">
            {jobs && (
                <div className="bg-white rounded-2 min-h-full p-4 sm:p-6 flex flex-col gap-5">
                    <BlockHeader
                        title="Recent Jobs"
                        description="Most recent jobs that you’ve run"
                        viewAllNavPath="/jobs"
                    />
                    {!!jobs.length && (
                        <div className="flex flex-col max-[815px]:gap-1">
                            {jobs.map((job) => (
                                <Link key={job.id} to={`/job/${job.id}`} state={{ from: location }}>
                                    <JobCard job={job} pageName="dashboard" />
                                </Link>
                            ))}
                        </div>
                    )}
                    {!jobs.length && (
                        <p className="font-body text-body-regular-m text-neutral-400">
                            It looks like you haven't run any jobs recently. Run a job now to get
                            started.
                        </p>
                    )}
                </div>
            )}
            {!jobs && <Loading withText={false} />}
        </div>
    );
};

export default RecentJobsBlock;
