import React, { useState } from 'react';
import Modal from '../../../design-system/Modal/Modal';
import SharedPublishModalHeader from './SharedPublishModalHeader/SharedPublishModalHeader';
import ShareBlock from './ShareBlock/ShareBlock';
import PublishBlock from './PublishBlock/PublishBlock';
import useUser from '../../../hooks/useUser';
import { ORGANIZATION_PLAN } from '../../../constants/organization';

export const TABS = {
    share: 'share',
    publish: 'publish',
};
const SharePublishModal = ({
    onClose,
    promptId,
    setPrompt,
    taskId,
    taskName,
    publishId,
    defaultVersion,
    accessLevel,
    accessType,
    updateTaskPublishId,
    type = 'prompt',
}) => {
    const { orgPlan } = useUser();
    const [activeTab, setActiveTab] = useState(
        orgPlan === ORGANIZATION_PLAN.plus ? TABS.publish : TABS.share
    );

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Modal onClose={onClose} size="medium" resetPadding={true}>
            <div className="w-full flex flex-col">
                <SharedPublishModalHeader
                    activeTab={activeTab}
                    handleTabSelect={handleTabSelect}
                    onModalClose={onClose}
                    type={type}
                    orgPlan={orgPlan}
                />
                <div className="p-4 xs:p-8">
                    {activeTab === TABS.share && (
                        <ShareBlock
                            taskId={taskId}
                            accessLevel={accessLevel}
                            accessType={accessType}
                        />
                    )}
                    {activeTab === TABS.publish && (
                        <PublishBlock
                            promptId={promptId}
                            setPrompt={setPrompt}
                            updateTaskPublishId={updateTaskPublishId}
                            taskName={taskName}
                            defaultVersion={defaultVersion}
                            accessLevel={accessLevel}
                            publishId={publishId}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default SharePublishModal;
