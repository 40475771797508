import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

import Loading from '../../../components/Loading';
import Button from '../../../design-system/Button/Button';
import ConfigureTabSection from './ConfigureTabSection/ConfigureTabSection';
import FormTabSection from './FormTabSection/FormTabSection';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import { Tabs } from '../../../design-system';
import SettingsTabSection from './SettingsTabSection/SettingsTabSection';
import Banner from '../../../design-system/Banner/Banner';

const tabs = [{ name: 'Configure' }, { name: 'Form' }, { name: 'Settings' }];

const ProcessDetailPage = () => {
    const { processId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const backLinkHref = location.state?.from ?? '/configure';
    const keysToMutate = location.state?.keysToMutate || [];

    const [processDetail, setProcessDetail] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const isProcessEditable = !!processDetail?.is_editable; // isProcessEditable === false if the process was deployed from a Process Template and should not be allowed to be edited

    useDocumentTitle(processDetail && processDetail.name);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.library.process}${processId}/`);
                setProcessDetail(data);
            } catch (e) {
                navigate(backLinkHref, { state: { keysToMutate } });
            }
        };

        fetchData();
    }, []);

    const handleTabChanged = (tab, index) => {
        setActiveTabIndex(index);
    };

    const handleNavigateToTemplate = () => {
        const templateId = processDetail?.default_template?.id;
        navigate(`/configure/process/${processId}/template/${templateId}`);
    };

    const isFormTabDisplayedStyles = activeTabIndex !== 1 ? 'h-0 max-h-0 overflow-hidden' : '';
    const isSettingsTabDisplayedStyles = activeTabIndex !== 2 ? 'h-0 max-h-0 overflow-hidden' : '';

    const uneditableBannerText = (
        <>
            This process was deployed from the{' '}
            <span className="font-body-bold text-body-bold-s">
                {processDetail?.default_version?.process_template_name}
            </span>{' '}
            template and is uneditable.
        </>
    );

    return (
        <div className="page-position overflow-y-auto bg-neutral-50">
            {processDetail ? (
                <>
                    <div className="py-3 px-4 sm:px-6 bg-white border-b-1 border-neutral-200 flex flex-col items-start min-[380px]:flex-row min-[380px]:items-center gap-x-5 gap-y-0.5">
                        <Button
                            type="link"
                            size="xs"
                            text="Back"
                            leadingIcon={ArrowGoBackLineIcon}
                            onClick={() => navigate(backLinkHref, { state: { keysToMutate } })}
                        />
                        <p className="font-body text-body-regular-s text-neutral-300">
                            Configure / Process Overview
                        </p>
                    </div>
                    <div className="w-full flex flex-col gap-5 p-5 sm:p-8">
                        <div className="max-w-[800px]">
                            <p className="font-heading-bold text-heading-bold-l text-black mb-1">
                                {processDetail.name}
                            </p>
                            <p className="font-body text-body-regular-s text-neutral-400">
                                {processDetail.description}
                            </p>
                        </div>

                        <div className="flex items-center justify-between gap-x-4 gap-y-2 flex-wrap">
                            <div className="max-w-max">
                                <Tabs
                                    tabs={tabs}
                                    tabIndex={activeTabIndex}
                                    onTabChanged={handleTabChanged}
                                />
                            </div>
                            {activeTabIndex === 2 && (
                                <Button
                                    type="ghost"
                                    size="xs"
                                    text="Edit Work Report"
                                    onClick={handleNavigateToTemplate}
                                />
                            )}
                        </div>

                        {!isProcessEditable && (
                            <Banner
                                title="Important Note"
                                text={uneditableBannerText}
                                color="blue"
                            />
                        )}

                        <div>
                            {activeTabIndex === 0 && (
                                <ConfigureTabSection
                                    processDetail={processDetail}
                                    setProcessDetail={setProcessDetail}
                                    isEditable={isProcessEditable}
                                />
                            )}
                            <div className={isFormTabDisplayedStyles}>
                                <FormTabSection
                                    processDetail={processDetail}
                                    isEditable={isProcessEditable}
                                />
                            </div>
                            <div className={isSettingsTabDisplayedStyles}>
                                <SettingsTabSection
                                    processDetail={processDetail}
                                    setProcessDetail={setProcessDetail}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ProcessDetailPage;
