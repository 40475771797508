import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_TYPES } from '../../../constants/base';
import { Badge, ButtonIcon } from '../../../design-system';
import { capitalizeFirstLetter } from '../../../services/strings';
import { More2FillIcon } from '../../../design-system/Icons';
import BaseCardMoreOptions from '../BaseCardMoreOptions/BaseCardMoreOptions';
import DeleteBaseModal from '../DeleteBaseModal/DeleteBaseModal';

const badgeColor = {
    [BASE_TYPES.context]: 'blue',
    [BASE_TYPES.database]: 'lime',
    [BASE_TYPES.knowledgeBase]: 'purple',
};

const BaseCard = ({ base: { id, name, type, records }, hasEditAccess, setBases }) => {
    const navigate = useNavigate();

    const [moreOptionsOpened, setMoreOptionsOpened] = useState(false);
    const [deleteBaseModalOpened, setDeleteBaseModalOpened] = useState(false);

    const handleCardClick = () => {
        navigate(`/base/${type}/${id}`);
    };

    return (
        <>
            <div
                className="p-5 rounded-[6px] border-1 border-neutral-100 bg-white cursor-pointer"
                onClick={handleCardClick}
            >
                <div className="w-full flex items-center justify-between gap-2 mb-4">
                    <p
                        className={`font-body text-body-bold-m text-black truncate ${
                            hasEditAccess ? 'w-[calc(100%-36px)]' : 'w-full'
                        }`}
                    >
                        {name}
                    </p>
                    {hasEditAccess && (
                        <div className="relative">
                            <ButtonIcon
                                type="link"
                                size="xs"
                                icon={More2FillIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setMoreOptionsOpened(true);
                                }}
                            />
                            {moreOptionsOpened && (
                                <BaseCardMoreOptions
                                    baseId={id}
                                    handleClose={() => setMoreOptionsOpened(false)}
                                    openDeleteModal={() => setDeleteBaseModalOpened(true)}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className="flex items-center gap-3 justify-between">
                    <div className="flex-grow truncate">
                        <Badge text={capitalizeFirstLetter(type)} color={badgeColor[type]} />
                    </div>
                    <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap">
                        <span className="text-body-bold-xs text-neutral-500">
                            {records?.toLocaleString()}
                        </span>{' '}
                        {`${records === 1 ? 'record' : 'records'}`}
                    </p>
                </div>
            </div>
            {deleteBaseModalOpened && (
                <DeleteBaseModal
                    baseId={id}
                    baseName={name}
                    setBases={setBases}
                    onClose={() => {
                        setDeleteBaseModalOpened(false);
                    }}
                />
            )}
        </>
    );
};

export default BaseCard;
