import React from 'react';
import { Input, Select, Tabs } from 'design-system';

const tabs = [{ name: 'Use Existing Workflow' }, { name: 'Create New Workflow' }];

function CrudWorkflowComponent({
    handleNewWorkflowTab,
    workflowTabIndex,
    showNewWorkflow,
    newGoal,
    handleNewGoalChange,
    goals,
    chosenGoalId,
    handleGoalChoice,
}) {
    return (
        <div className="w-full space-y-4">
            <div>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-start">
                        <span class="pr-4 bg-white font-body-bold text-body-bold-l leading-10 text-gray-900">
                            Workflow
                        </span>
                    </div>
                </div>
                <div className="font-body-regular text-body-regular-m">
                    A workflow is like a folder of work that contains tasks. You can create a new
                    workflow or use an existing one.
                </div>
            </div>
            <div className="flex flex-row space-x-2">
                <Tabs
                    onTabChanged={(tab, index) => handleNewWorkflowTab(index === 0)}
                    tabIndex={workflowTabIndex}
                    tabs={tabs}
                />
            </div>
            {showNewWorkflow ? (
                <div className="w-full">
                    <Input
                        state="default"
                        size="md"
                        name="workflowTitle"
                        label="Give your workflow a name"
                        value={newGoal || ''}
                        placeholder="Enter workflow title"
                        onChange={handleNewGoalChange}
                    />
                </div>
            ) : (
                <div className="w-full">
                    <Select
                        label="Select a workflow"
                        name="workflow"
                        options={goals}
                        value={chosenGoalId}
                        onChange={handleGoalChoice}
                        size="md"
                        state="default"
                    />
                </div>
            )}
        </div>
    );
}

export default CrudWorkflowComponent;
