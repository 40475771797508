import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { parseProcessPageSearchParams } from '../../../helpers/parseProcessPageSearchParams';
import { handleOpenStartNewJobModal } from '../../../helpers/handleOpenStartNewJobModal';
import { processPageSortOptions } from '../../../constants/sort';
import {
    handleAddNewFilterOption,
    handleClearSearchBar,
    handleRemoveFilterOption,
    handleSearch,
    handleSortOptionSelect,
} from '../../../helpers/handleUpdateSearchParams';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import SortDropdown from '../../../components/SortDropdown/SortDropdown';
import { Badge, Button } from '../../../design-system';
import {
    ArrowDownSLineIcon,
    ArrowUpSLineIcon,
    FlashlightFillIcon,
} from '../../../design-system/Icons';
import FilteringPopup from '../../../design-system/FilteringPopup/FilteringPopup';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';

const ProcessPageTopControls = ({ groups, filteredGroups, areGroupsLoading }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseProcessPageSearchParams(searchParams), [searchParams]);

    const [searchValue, setSearchValue] = useState(parsedParams.search || '');
    const [actionDropdown, setActionDropdown] = useState({ opened: false, action: null }); // action can be "filter" or "sort"

    const filterGroupsOptions = useMemo(() => {
        return groups?.map((group) => ({
            id: group.id,
            name: group.name,
            icon_color: group.icon_color,
            trailingBadge: { text: group.processes?.length || 0, color: 'neutral' },
        }));
    }, [groups]);

    const totalFilteredProcesses = useMemo(
        () => filteredGroups?.reduce((acc, item) => acc + (item.processes?.length || 0), 0),
        [filteredGroups]
    );

    const { width: screenWidth } = useWindowSize();

    const handleSearchProcesses = () => {
        handleSearch({ searchParams, setSearchParams, searchValue, usePagination: false });
    };

    const handleClearSearchQuery = () => {
        handleClearSearchBar({
            searchParams,
            setSearchParams,
            setSearchValue,
            usePagination: false,
        });
    };

    const handleProcessesSortOptionSelect = (sortOption) => {
        handleSortOptionSelect({
            sortOption,
            setSearchParams,
            searchParams,
            usePagination: false,
            onSearchDropdownClose: closeActionDropdown,
        });
    };

    const handleAddNewFilter = (group) => {
        handleAddNewFilterOption({
            searchParams,
            setSearchParams,
            currentFilters: parsedParams.filter,
            newFilter: group.id,
            usePagination: false,
        });
    };

    const handleRemoveFilter = (group) => {
        handleRemoveFilterOption({
            searchParams,
            setSearchParams,
            currentFilters: parsedParams.filter,
            filterToDelete: group.id,
            usePagination: false,
        });
    };

    function closeActionDropdown(e) {
        e?.stopPropagation();
        setActionDropdown({ opened: false, action: null });
    }

    useEffect(() => {
        const currentGroupsFilter = parsedParams.filter;

        if (currentGroupsFilter && filteredGroups) {
            const areGroupsInFilterThatDoesNotExistInFilteredGroups =
                currentGroupsFilter.length > filteredGroups.length;

            // this situation can happen when a group is selected in filter, but when the user submits a search, the group is not present in the search results
            // in such cases, we remove this group from the filter to display the correct data.
            if (areGroupsInFilterThatDoesNotExistInFilteredGroups) {
                // filter without groups that are not present in the backend response
                const newGroupsFilter = filteredGroups.reduce((acc, group) => {
                    if (currentGroupsFilter.includes(group.id)) {
                        return [...acc, group.id];
                    }
                    return acc;
                }, []);

                const urlSearchParams = new URLSearchParams(searchParams);
                if (!!newGroupsFilter?.length) {
                    urlSearchParams.set('filter', newGroupsFilter.join(','));
                } else {
                    urlSearchParams.delete('filter');
                }
                setSearchParams(urlSearchParams);
            }
        }
    }, [parsedParams.filter, filteredGroups]);

    const sortDropdownPosition = {
        top: '120%',
        left: screenWidth < 634 ? 0 : (screenWidth < 1024 || screenWidth >= 1200) && '70px',
        right: screenWidth > 1024 && screenWidth < 1200 ? 0 : 'auto',
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col lg:flex-row lg:items-center gap-x-5 gap-y-2 w-full relative max-w-[600px] lg:max-w-[770px]">
                <SearchBar
                    size="sm"
                    value={searchValue}
                    withSubmitIcon
                    withClearIcon
                    placeholder="Search by process name..."
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSubmit={handleSearchProcesses}
                    onClear={handleClearSearchQuery}
                />
                <div className="flex items-center gap-2 relative">
                    <Button
                        type="link"
                        size="xs"
                        text="Filter"
                        trailingIcon={
                            actionDropdown.opened && actionDropdown.action === 'filter'
                                ? ArrowUpSLineIcon
                                : ArrowDownSLineIcon
                        }
                        onClick={() => setActionDropdown({ opened: true, action: 'filter' })}
                    />
                    <Button
                        type="link"
                        size="xs"
                        text="Sort"
                        trailingIcon={
                            actionDropdown.opened && actionDropdown.action === 'sort'
                                ? ArrowUpSLineIcon
                                : ArrowDownSLineIcon
                        }
                        onClick={() => setActionDropdown({ opened: true, action: 'sort' })}
                    />
                    <div className="lg:hidden ml-2">
                        <Button
                            type="primary"
                            size="sm"
                            text="Start New Job"
                            trailingIcon={FlashlightFillIcon}
                            onClick={handleOpenStartNewJobModal}
                        />
                    </div>
                    {actionDropdown.opened && actionDropdown.action === 'sort' && (
                        <SortDropdown
                            options={processPageSortOptions}
                            selectedOption={parsedParams.sort}
                            handleOptionSelect={handleProcessesSortOptionSelect}
                            onClose={closeActionDropdown}
                            dropdownPosition={sortDropdownPosition}
                        />
                    )}
                    {actionDropdown.opened && actionDropdown.action === 'filter' && (
                        <FilteringPopup
                            label="All groups"
                            options={filterGroupsOptions}
                            selectedOptionsIds={parsedParams.filter || []}
                            areOptionsLoading={areGroupsLoading}
                            handleAddFilterOption={handleAddNewFilter}
                            handleRemoveOption={handleRemoveFilter}
                            includeClientSideFiltering
                            totalOptionsCount={filterGroupsOptions?.length}
                            onClose={closeActionDropdown}
                            position="absolute top-[120%] left-0 lg:left-auto lg:right-0 min-[1180px]:right-auto min-[1180px]:left-0"
                        />
                    )}
                </div>
            </div>
            {parsedParams.filter && filteredGroups && (
                <div className="flex justify-between gap-3">
                    <div className="flex items-center gap-2 flex-wrap">
                        {filteredGroups?.map((group) => (
                            <Badge
                                key={group.id}
                                text={group.name}
                                color="neutral"
                                leadingIcon={PlayCircleFillIcon}
                                leadingIconColor={group.icon_color}
                                removeable
                                handleRemove={() => handleRemoveFilter(group)}
                            />
                        ))}
                    </div>
                    <p className="font-body text-body-regular-s text-neutral-300 min-w-fit py-1">
                        {totalFilteredProcesses}{' '}
                        {totalFilteredProcesses === 1 ? 'process' : 'processes'}
                    </p>
                </div>
            )}
        </div>
    );
};

export default ProcessPageTopControls;
