import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTextareaAutoResize } from '../../hooks/useTextareaAutoResize';

const TextFieldWithCustomTextStyles = ({
    value,
    isSaveOnBlur = false,
    handleSave = () => {}, // if isSaveOnBlur = true this function will be executed on blur
    handleChange = () => {}, // if isSaveOnBlur = true this function will handle textarea change
    isDisabled = false,
    minHeight = 64,
    placeholder,
    textStyle = 'font-body text-body-regular-s text-neutral-500',
}) => {
    const [stagedText, setStagedText] = useState(value); // this state is used when isSaveOnBlur = true (main state is updated on blur)
    const valueToDisplay = isSaveOnBlur ? stagedText : value;

    const { textareaRef, textareaContainerRef } = useTextareaAutoResize(valueToDisplay, minHeight);

    useEffect(() => {
        if (stagedText !== value && isSaveOnBlur) {
            setStagedText(value);
        }
    }, [value, isSaveOnBlur]);

    const handleBlur = () => {
        if (isSaveOnBlur) {
            handleSave(stagedText);
        }
    };

    const handleTextAreaChange = (e) => {
        if (isSaveOnBlur) {
            setStagedText(e.target.value);
        }

        if (!isSaveOnBlur) {
            handleChange(e);
        }
    };

    return (
        <div
            ref={textareaContainerRef}
            className="flex flex-col"
            style={{ minHeight: `${minHeight}px` }}
        >
            <textarea
                value={valueToDisplay}
                rows={1}
                ref={textareaRef}
                placeholder={placeholder}
                readOnly={isDisabled}
                className={`focus:outline-0 resize-none bg-transparent placeholder-neutral-300 ${textStyle}`}
                onChange={handleTextAreaChange}
                onBlur={handleBlur}
            />
        </div>
    );
};

TextFieldWithCustomTextStyles.propTypes = {
    value: PropTypes.string,
    isSaveOnBlur: PropTypes.bool,
    handleSave: PropTypes.func,
    handleChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    minHeight: PropTypes.number,
    placeholder: PropTypes.string,
    textStyle: PropTypes.string,
};

export default TextFieldWithCustomTextStyles;
