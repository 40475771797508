import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { API } from 'constants';
import client from '../../services/library-api';

import { Button } from '../../design-system';
import { FlowChartIcon } from '../../design-system/Icons';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useCustomPagination from '../../hooks/useCustomPagination';
import ConfigurePaginatedContainer from './ConfigurePaginatedContainer/ConfigurePaginatedContainer';

const ConfigurePage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const page = Number.parseInt(searchParams.get('page'));

    const [requestKeys, setRequestKeys] = useState(location.state?.keysToMutate || []); // this state is used to mutate cashed requests

    useDocumentTitle('Configure');

    const {
        data: processes,
        count,
        total,
        loading,
        key,
    } = useCustomPagination({
        pageIndex: page - 1 || 0,
        client: client,
        route: API.ROUTES.library.process,
    });

    useEffect(() => {
        let pageNumber = Number.isNaN(page) ? 1 : page;

        if (Number.isNaN(page)) {
            setSearchParams({
                page: pageNumber,
            });
        }
    }, [page, setSearchParams]);

    useEffect(() => {
        setRequestKeys((prevKeys) => {
            const keyAlreadyInList = !!prevKeys.find((item) => item.key === key);
            if (keyAlreadyInList) {
                return prevKeys;
            }
            return [...prevKeys, { key, page: page || 1 }];
        });
    }, [key]);

    const createNewProcess = () => {
        navigate('/configure/process/new', {
            state: { configureSearchParams: location.search, keysToMutate: requestKeys },
        });
    };

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-neutral-50 overflow-y-auto p-4 xs:p-5 sm:p-8">
            <div className="flex flex-col xs:flex-row justify-between items-start gap-4 pb-5">
                <div>
                    <p className="font-heading-bold text-heading-bold-l">Configure</p>
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        Collection of pre-designed goals with instructions configured to guide
                        interactions with AI.
                    </p>
                </div>
                <Button
                    type="secondary"
                    size="sm"
                    text="New Process"
                    leadingIcon={FlowChartIcon}
                    onClick={createNewProcess}
                />
            </div>
            <ConfigurePaginatedContainer
                processes={processes}
                total={total}
                count={count}
                loading={loading}
                keysToMutate={requestKeys}
                createNewProcess={createNewProcess}
            />
        </div>
    );
};

export default ConfigurePage;
