import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

Radio.propTypes = {
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    isSelected: PropTypes.bool.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    logAs: PropTypes.string,
};

function Radio(props) {
    const { state = 'default', isSelected, label, name, onChange, logAs } = props;

    const isRadioSelected = state === 'error' ? false : isSelected;

    const colors = {
        default: isRadioSelected
            ? 'border-purple-500'
            : `border-neutral-300 peer-hover:border-neutral-400 peer-focus:border-purple-500`,
        disabled: 'border-neutral-300',
        error: 'border-red-500',
    };

    const selectedStyles = {
        true: state === 'disabled' ? 'after:bg-neutral-300' : 'after:bg-purple-500',
        false: 'after:bg-transparent',
    };

    const transitionStyles = {
        transitionProperty: 'background-color, border-color, color',
    };

    const radioColors = colors[state];
    const selectedRadio = selectedStyles[isRadioSelected];

    let className = classNames(
        `w-4.5 h-4.5 peer border-1 bg-white rounded-full after:absolute after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:h-3 after:w-3 transition-all after:transition-all after:duration-250`,
        radioColors,
        selectedRadio,
        transitionStyles
    );

    return (
        <label className="cursor-pointer flex items-center gap-2">
            <div className="relative h-6 w-6 flex items-center justify-center">
                <input
                    type="radio"
                    className="sr-only peer"
                    onChange={onChange}
                    name={name}
                    checked={isRadioSelected}
                    disabled={state === 'disabled'}
                    {...(logAs ? { 'data-dd-action-name': logAs } : {})}
                />
                <div className={className}></div>
            </div>

            {label && <span className="font-inter font-base leading-[125%]">{label}</span>}
        </label>
    );
}

export default Radio;
