export const getFormattedInputs = (inputsList = []) => {
    const inputsKeys = inputsList.map((input) => `[${input.key}]`);
    if (inputsList.length === 0) {
        return null;
    }
    if (inputsList.length === 1) {
        return inputsKeys[0];
    }
    if (inputsList.length > 1) {
        return `${inputsKeys.slice(0, -1).join(',')} and ${inputsKeys.slice(-1)}`;
    }
};
