import React from 'react';
import { Link } from 'react-router-dom';
import Input from 'design-system/Input/Input';
import Select from 'design-system/Select/Select';
import { TemperatureOptions } from 'constants';
import useUser from 'hooks/useUser';
import { getModelOptions } from 'helpers/getModelOptions';


const PromptSettingsContainer = ({ promptSettings, setPromptSettings }) => {
    const { user } = useUser();
    const modelOptions = getModelOptions(user);

    const handleOptionChange = (name, id, options) => {
        const selectedOption = options.find((option) => option.id === id);
        setPromptSettings((prevSettings) => ({
            ...prevSettings,
            [name]: selectedOption,
        }));
        console.log(promptSettings);
    };

    const handleInputChange = (name, value) => {
        setPromptSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    return (
        <div className="flex flex-col w-full space-y-5">
            <div className="w-full flex flex-col gap-[4px]">
                <Select
                    label="Choose a Model"
                    name="model"
                    options={modelOptions}
                    value={promptSettings.model.id}
                    onChange={(value) => handleOptionChange('model', value, modelOptions)}
                    size="md"
                    state={modelOptions.length ? 'default' : 'disabled'}
                />
                {!modelOptions.length ? (
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        <Link to="/profile">Please add an API key to select a model</Link>
                    </p>
                ): (
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        {promptSettings.model.name} can handle up to {promptSettings.model.token_limit} tokens including prompt, inputs, and outputs.
                    </p>
               
                )
            }
            </div>
            <Input
                label="Set a Max Response Length (tokens)"
                type="number"
                value={promptSettings.maxTokens}
                size="sm"
                name="maxTokens"
                state="default"
                placeholder="1000"
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
            <Select
                label="Select a Temperature"
                name="temperature"
                options={TemperatureOptions}
                value={promptSettings.temperature.id}
                onChange={(value) => handleOptionChange('temperature', value, TemperatureOptions)}
                size="md"
                state="default"
            />
        </div>
    );
};

export default PromptSettingsContainer;
