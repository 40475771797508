import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDrop } from 'react-dnd';

import client from '../../../services/library-api';
import { API } from 'constants';

import Button from '../../../design-system/Button/Button';
import { ArrowGoBackLineIcon, ErrorWarningLineIcon } from '../../../design-system/Icons';
import DraggableStepCard from '../DraggableStepCard/DraggableStepCard';
import EditableTextArea from '../../../components/EditableTextArea/EditableTextArea';
import DragAndDropTarget from '../../../components/DragAndDropTarget/DragAndDropTarget';
import Alert from '../../../design-system/Alert/Alert';
import Loading from '../../../components/Loading';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const ChainDashboard = ({ data, setData, requestData, setRequestData, isChainLoading }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from ?? '/library';

    const [alert, setAlert] = useState({ show: false, statusCode: null });
    const [isLoading, setIsLoading] = useState(false);
    const [chainChanges, setChainChanges] = useState({});

    useEffect(() => {
        setRequestData((prevData) => {
            if (prevData.skipStepsUpdating) {
                const { skipStepsUpdating, ...updatedData } = prevData;
                return updatedData;
            }
            const steps = data.map(({ id, step, inputs }) => {
                const mapping = inputs.reduce((acc, input) => {
                    acc[input.id] = '';
                    return acc;
                }, {});
                return { task: id, order: step, auto: false, mapping };
            });
            return { ...prevData, steps };
        });
    }, [data]);

    const [{ isOver }, drop] = useDrop({
        accept: 'SIDEBAR_CARD',
        drop: (item) => item,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const outputs = data.reduce((acc, item) => {
        const result = item.outputs.map((output) => ({
            step: item.step,
            id: output.id,
            name: `Step ${item.step}: ${output.label}`,
            type: 'output',
        }));
        return [...acc, ...result];
    }, []);

    const inputs = data.reduce((acc, item) => {
        const result = item.inputs.map((input) => ({
            step: item.step,
            id: input.id,
            name: `Step ${item.step}: ${input.label}`,
            type: 'input',
        }));
        return [...acc, ...result];
    }, []);

    const getReferenceOptions = (step) => {
        if (step === 1) return [];
        const matchingOutputs = outputs.reduce((acc, item) => {
            if (item.step < step) {
                return [...acc, { id: item.id, name: item.name, type: item.type }];
            }
            return acc;
        }, []);
        const matchingInputs = inputs.reduce((acc, item) => {
            if (item.step < step) {
                const inputStepMapping =
                    requestData.steps.find((step) => step.order === item.step)?.mapping || {};
                if (inputStepMapping[item.id]) {
                    return acc;
                }
                return [...acc, { id: item.id, name: item.name, type: item.type }];
            }
            return acc;
        }, []);
        return [...matchingOutputs, ...matchingInputs];
    };

    const getReference = (step) => {
        const stepEl = requestData.steps.find((item) => item.order === step);
        if (stepEl) {
            return stepEl.mapping;
        }
        return {};
    };

    const handleReferenceChange = (referenceId, inputId, cardId) => {
        const allMappings = requestData.steps.reduce((acc, step) => {
            const stepMappings = [];
            for (const inputId in step.mapping) {
                if (step.mapping[inputId]) {
                    stepMappings.push({
                        inputId,
                        referenceId: step.mapping[inputId],
                        cardId: step.task,
                    });
                }
            }
            return [...acc, ...stepMappings];
        }, []);

        // check whether Input which the user is currently linking, is already a reference of another Input. If it is, the reference of that another Input clears
        const linkedInputMatchReference = allMappings.find((item) => item.referenceId === inputId);

        setRequestData((prevData) => {
            const steps = prevData.steps.map((item) => {
                if (item.task === cardId) {
                    item.mapping[inputId] = referenceId;
                }
                if (linkedInputMatchReference && item.task === linkedInputMatchReference.cardId) {
                    item.mapping[linkedInputMatchReference.inputId] = '';
                }
                return item;
            });
            return { ...prevData, steps };
        });
    };

    const handleGoBackButtonClick = () => {
        navigate(backLinkHref);
    };

    const handleSave = async (field, value) => {
        setRequestData((prevData) => ({ ...prevData, [field]: value }));
        if (id) {
            setChainChanges((prevData) => ({ ...prevData, [field]: value }));
        }
    };

    const handleUpdateGoal = async (field, value, updatedId) => {
        if (updatedId) {
            console.log('updating');
            await client.patch(`${API.ROUTES.library.workflow}${updatedId}/`, {
                [field]: value,
            });
        }
    };

    const handleCreatePromptChain = async () => {
        if (!requestData.name) {
            setAlert({ show: true, message: 'Give your Prompt Chain a name' });
            return;
        }
        if (!requestData.description) {
            setAlert({ show: true, message: 'Give a description for your Prompt Chain' });
            return;
        }
        if (requestData.steps.length === 0) {
            setAlert({ show: true, message: 'Add steps for your Prompt Chain' });
            return;
        }

        try {
            setIsLoading(true);
            const { skipStepsUpdating, ...requestBody } = requestData;
            if (id) {
                await client.patch(`${API.ROUTES.library.workflow}${id}/`, {
                    ...chainChanges,
                    steps: requestBody.steps,
                });
            } else {
                const { data } = await client.post(API.ROUTES.library.workflow, requestBody);
                navigate(`/chain/${data.id}/`);
            }

            setIsLoading(false);
            if (id) {
                setAlert({
                    show: true,
                    status: 'positive',
                    message: `Prompt Chain was successfully saved`,
                    icon: CheckLineIcon,
                });
            }
        } catch (e) {
            setIsLoading(false);
            setAlert({
                show: true,
                statusCode: e.response.status,
                message: defaultErrorMessage,
            });
        }
    };

    return (
        <div className="h-auto lg:h-full lg:min-w-[320px] flex-grow bg-neutral-50 lg:overflow-y-auto p-[32px]">
            <div className="w-full min-h-full bg-white px-[10px] pb-[40px] flex justify-center">
                <div className="max-w-200 w-full w-200 transform translate-x-[14px]">
                    <div className="py-[20px]">
                        <Button
                            type="link"
                            size="sm"
                            text="Back"
                            onClick={handleGoBackButtonClick}
                            leadingIcon={ArrowGoBackLineIcon}
                        />
                    </div>
                    {isChainLoading ? (
                        <Loading text="" />
                    ) : (
                        <div className="py-5 flex flex-col gap-5">
                            <div className="px-5 flex flex-col gap-2">
                                <EditableTextArea
                                    initialText={
                                        requestData.name || 'Give your Prompt Chain a name'
                                    }
                                    textStyle="font-heading-bold text-heading-bold-s text-black"
                                    onSave={(value) => {
                                        handleUpdateGoal('name', value, id);
                                    }}
                                    emojiCode={requestData.icon_text}
                                    onEmojiUpdated={(emoji) => {
                                        handleUpdateGoal('icon_text', emoji, id);
                                    }}
                                />
                                <EditableTextArea
                                    initialText={
                                        requestData.description ||
                                        'Give a description for your Prompt Chain'
                                    }
                                    textStyle="font-body text-body-regular-m"
                                    onSave={(value) => {
                                        handleUpdateGoal('description', value, id);
                                    }}
                                />
                            </div>

                            <div className="py-[20px] flex flex-col gap-[16px]">
                                {!!data.length &&
                                    data.map((item) => (
                                        <DraggableStepCard
                                            key={item.id}
                                            item={item}
                                            setData={setData}
                                            referenceOptions={getReferenceOptions(item.step)}
                                            reference={getReference(item.step)}
                                            handleReferenceChange={handleReferenceChange}
                                        />
                                    ))}

                                <div className="px-5">
                                    <DragAndDropTarget
                                        ref={drop}
                                        isOver={isOver}
                                        text="Drag & drop from your prompts"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {!isChainLoading && (
                    <div className="fixed bottom-[48px] right-[48px]">
                        <Button
                            type="primary"
                            size="sm"
                            text={id ? 'Save Prompt Chain' : 'Create Prompt Chain'}
                            state={isLoading ? 'loading' : 'default'}
                            onClick={handleCreatePromptChain}
                        />
                    </div>
                )}
            </div>
            {alert.show && (
                <Alert
                    status={alert.status || 'critical'}
                    message={alert.message || defaultErrorMessage}
                    statusCode={alert.statusCode}
                    icon={alert.icon || ErrorWarningLineIcon}
                    handleClose={() => setAlert({ show: false, statusCode: null })}
                    autoCloseInMS={3000}
                />
            )}
        </div>
    );
};

export default ChainDashboard;
