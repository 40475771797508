import { TASK_TYPES } from '../constants/library';
import {
    checkIfInputMissedInApiRequest,
    checkIfInputMissedInMessagesArray,
} from './taskPlaygroundUtils';

export const determineRequiredInputs = (func_def = {}, inputs, setIsExpanded) => {
    const { func_data, func_mapping } = func_def;
    const requiredInputs = func_data?.required_inputs || [];
    const result = requiredInputs.reduce((acc, key) => {
        const id = func_mapping[key]['id'];
        const correspondedInput = inputs.find((input) => input.id === id);
        return correspondedInput
            ? [...acc, { ...correspondedInput, state: 'default', value: '' }]
            : acc;
    }, []);
    setIsExpanded((prevData) => ({ ...prevData, requiredInputs: !!result.length }));
    return result;
};

export const determineUserInputs = (
    func_def = {},
    inputs,
    setIsExpanded,
    taskType,
    default_prompt
) => {
    const { func_data, func_mapping } = func_def;

    const userMessage = default_prompt?.default_prompt_version?.messages?.[1]?.content;
    const systemMessage = default_prompt?.default_prompt_version?.messages?.[0]?.content;
    const apiRequest = [
        func_data?.request_url,
        func_data?.request_headers,
        func_data?.request_data,
    ];

    const userInputs = func_data?.user_inputs || [];
    return userInputs.map((userInput) => {
        setIsExpanded((prevData) => ({ ...prevData, [userInput.key]: true }));
        const correspondedInputsArray = func_mapping[userInput.key] || [];
        const result = correspondedInputsArray.reduce((acc, item) => {
            const correspondedInput = inputs.find((input) => input.id === item.id);

            if (!correspondedInput) {
                return acc;
            }

            const formattedInput = {
                ...correspondedInput,
                state: 'default',
                value: '',
            };

            // check if [input.key] is in userMessage
            if (taskType === TASK_TYPES.assistant) {
                formattedInput.missedInUserMessage = checkIfInputMissedInMessagesArray(
                    [userMessage],
                    correspondedInput.key
                );
            }

            // check if [input.key] is in userMessage or systemMessage
            if (taskType === TASK_TYPES.agent) {
                formattedInput.missedInUserAndSystemMessages = checkIfInputMissedInMessagesArray(
                    [userMessage, systemMessage],
                    correspondedInput.key
                );
            }

            // check if {{input.key}} is in apiRequest
            if (taskType === TASK_TYPES.api) {
                formattedInput.missedInApiRequest = checkIfInputMissedInApiRequest(
                    apiRequest,
                    correspondedInput.key
                );
            }

            return [...acc, formattedInput];
        }, []);
        return { ...userInput, inputs: result };
    }, []);
};

const mergeExistingValuesIntoNewInputs = (
    newInputs,
    existingInputs = [],
    shouldCheckForInputsInUserMessage = false,
    shouldCheckForInputsInUserOrSystemMessages = false,
    messagesArray = [],
    shouldCheckForInputsInApiRequest = false,
    apiRequest = []
) => {
    // Create a map of existing inputs for faster lookup
    const existingInputMap = new Map(existingInputs.map((input) => [input.id, input]));

    // Merge new inputs into existing inputs
    const updatedInputs = newInputs.map((newInput) => {
        const existingInput = existingInputMap.get(newInput.id);

        if (existingInput) {
            // If the input with the same id exists, merge the new data into it
            return {
                ...existingInput,
                ...newInput,
                value: existingInput.value,
                state: 'default',
            };
        } else {
            // If it doesn't exist in the existing inputs, add it to the result
            const result = {
                ...newInput,
                state: 'default',
            };
            if (shouldCheckForInputsInUserMessage) {
                // check if [input.key] is in userMessage
                result.missedInUserMessage = checkIfInputMissedInMessagesArray(
                    messagesArray,
                    newInput.key
                );
            }
            if (shouldCheckForInputsInUserOrSystemMessages) {
                // check if [input.key] is in userMessage or in systemMessage
                result.missedInUserAndSystemMessages = checkIfInputMissedInMessagesArray(
                    messagesArray,
                    newInput.key
                );
            }
            if (shouldCheckForInputsInApiRequest) {
                // check if {{input.key}} is in apiRequest
                result.missedInApiRequest = checkIfInputMissedInApiRequest(
                    apiRequest,
                    newInput.key
                );
            }
            return result;
        }
    });

    return updatedInputs;
};

export const updateSpecificUserInputs = (
    userInputKey,
    func_def = {},
    inputsFromResponse,
    existingInputs,
    taskType,
    messagesArray = [],
    apiRequest
) => {
    const { func_mapping } = func_def;
    const correspondedInputsArray = func_mapping[userInputKey];
    const updatedInputsArray = correspondedInputsArray.reduce((acc, item) => {
        const correspondedInput = inputsFromResponse.find((input) => input.id === item.id);
        return correspondedInput ? [...acc, correspondedInput] : acc;
    }, []);
    const shouldCheckForInputsInUserMessage = taskType === TASK_TYPES.assistant;
    const shouldCheckForInputsInUserOrSystemMessages = taskType === TASK_TYPES.agent;
    const shouldCheckForInputsInApiRequest = taskType === TASK_TYPES.api;
    return mergeExistingValuesIntoNewInputs(
        updatedInputsArray,
        existingInputs,
        shouldCheckForInputsInUserMessage, // for checking if [input.key] is in userMessage
        shouldCheckForInputsInUserOrSystemMessages, // for checking if [input.key] is in userMessage or systemMessage
        messagesArray, // from assistant or agent task
        shouldCheckForInputsInApiRequest, // for checking if {{input.key}} is in apiRequest
        apiRequest // from api task
    );
};

export const updateRequiredInputs = (func_def = {}, inputs, existingRequiredInputs = []) => {
    // get required inputs from response and merge them into existing inputs (if existing inputs have values they will stay)
    const requiredInputsFromResponse = determineRequiredInputs(func_def, inputs, () => {});
    return mergeExistingValuesIntoNewInputs(requiredInputsFromResponse, existingRequiredInputs);
};

export const fillNewValuesIntoExistingRequiredInputs = (existingInputs, newInputs) => {
    // Create a map of new inputs for faster lookup
    const newInputsMap = new Map(newInputs.map((input) => [input.id, input]));

    // Merge new inputs value into existing inputs
    const updatedInputs = existingInputs?.map((input) => {
        const newInput = newInputsMap.get(input.id);

        if (newInput) {
            // If the input with the same id exists, merge the new value into it
            return {
                ...input,
                value: newInput.value,
            };
        } else {
            // If it doesn't exist return the current input
            return input;
        }
    });

    return updatedInputs;
};

export const fillNewValuesIntoExistingUserInputs = (existingInputsData, newInputs) => {
    // Create a map of new inputs for faster lookup
    const newInputsMap = new Map(newInputs.map((input) => [input.id, input]));

    return existingInputsData.map((userInput) => {
        // Merge new inputs value into existing inputs
        const updatedInputs = userInput?.inputs?.map((input) => {
            const newInput = newInputsMap.get(input.id);

            if (newInput) {
                // If the input with the same id exists, merge the new value into it
                return {
                    ...input,
                    value: newInput.value,
                };
            } else {
                // If it doesn't exist return the current input
                return input;
            }
        });

        return { ...userInput, inputs: updatedInputs };
    });
};
