import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '../../../design-system';
import IndicatorIcon from '../../../design-system/Icons/IndicatorIcon';
import { indicatorColors } from '../../../constants/runStatus';

ChainStepCard.propTypes = {
    step: PropTypes.number.isRequired,
    status: PropTypes.oneOf(['pending', 'success', 'started']).isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
};

function ChainStepCard({ stepId, step, status, name, selected = false, handleStepSelect }) {
    return (
        <div
            onClick={() => handleStepSelect(stepId)}
            className={`p-4 rounded-2 w-full hover:bg-neutral-50 hover:cursor-pointer transition-colors ${
                selected && 'shadow-inset-2 shadow-purple-300'
            }`}
        >
            <div className="flex items-center gap-1 mb-2">
                <SvgIcon
                    color={indicatorColors[status] || '#CFD6E5'}
                    icon={IndicatorIcon}
                    size="medium"
                />
                <p className="font-body text-body-bold-xs text-neutral-300">Step {step}</p>
            </div>
            <p className="font-body text-body-regular-xs text-neutral-500 line-clamp-2">{name}</p>
        </div>
    );
}

export default ChainStepCard;
