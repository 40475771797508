import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as API from '../../constants/api';
import client from '../../services/knowledge-api';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import ClientSideFilteredSearchBar from '../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import Alert from '../../design-system/Alert/Alert';
import { AddCircleLineIcon, ErrorWarningLineIcon } from '../../design-system/Icons';
import BaseCard from './BaseCard/BaseCard';
import Loading from '../../components/Loading';
import useUser from '../../hooks/useUser';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';
import { Button } from '../../design-system';
import { DEFAULT_LIMIT } from '../../hooks/useCustomPagination';
import { defaultErrorMessage } from '../../constants/errorMessages';

const KnowledgeBasePage = () => {
    const navigate = useNavigate();

    const { orgPlan, userRole, user } = useUser();
    const [bases, setBases] = useState([]);
    const [filteredBases, setFilteredBases] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const hasCreateEditAccess =
        orgPlan === ORGANIZATION_PLAN.agency &&
        (userRole === USER_ROLE.admin || userRole === USER_ROLE.owner);
    const orgId = user && user.organization.id;

    useDocumentTitle('Knowledge Base');

    useEffect(() => {
        const fetchNextBases = async (page) => {
            const { data } = await client.get(
                `${API.ROUTES.knowledge.base}?limit=${DEFAULT_LIMIT}&offset=${
                    (page - 1) * DEFAULT_LIMIT
                }`
            );
            setBases((prevData) => [...prevData, ...data.results]);
            if (data.next) {
                await fetchNextBases(page + 1);
            }
        };

        const fetchBases = async () => {
            try {
                setIsLoading(true);
                const { data } = await client.get(
                    `${API.ROUTES.knowledge.base}?limit=${DEFAULT_LIMIT}&offset=0`
                );
                setBases(data.results);
                if (data.next) {
                    await fetchNextBases(2);
                }
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                setErrorAlert({ show: true, statusCode: e.response.status });
            }
        };

        if (orgId) {
            setBases([]);
            fetchBases();
        }
    }, [orgId]);

    return (
        <div className="w-full sm:w-[calc(100%-68px)] max-sm:px-[12px] max-sm:py-[16px] flex flex-col gap-5">
            <div>
                <p className="font-heading-bold text-heading-bold-l">All Bases</p>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Store reusable context to use across your prompts.
                </p>
            </div>
            <div className="flex flex-col-reverse xs:items-center xs:justify-between gap-x-4 gap-y-2 xs:flex-row">
                <div
                    className={`w-full max-w-[680px] ${
                        hasCreateEditAccess ? 'xs:w-[calc(100%-146px)]' : 'w-full'
                    }`}
                >
                    <ClientSideFilteredSearchBar
                        size="sm"
                        list={bases}
                        state={!bases ? 'disabled' : 'default'}
                        setFilteredList={setFilteredBases}
                        placeholder="Search..."
                    />
                </div>
                {hasCreateEditAccess && (
                    <Button
                        type="primary"
                        size="xs"
                        text="New Base"
                        leadingIcon={AddCircleLineIcon}
                        onClick={() => navigate('/base/new')}
                    />
                )}
            </div>
            {bases && (
                <div className="grid grid-cols-1 gap-x-5 gap-y-5 md:grid-cols-2 l:grid-cols-3 xl:gap-x-5 3xl:grid-cols-4">
                    {filteredBases?.map((base) => (
                        <BaseCard
                            key={base.id}
                            base={base}
                            hasEditAccess={hasCreateEditAccess}
                            setBases={setBases}
                        />
                    ))}
                </div>
            )}
            {(isLoading || !orgId) && <Loading withText={!bases} />}
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </div>
    );
};

export default KnowledgeBasePage;
