import React from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';
import { useNavigate } from 'react-router-dom';

const BaseCardMoreOptions = ({ baseId, handleClose, openDeleteModal }) => {
    const navigate = useNavigate();
    const handleEditClick = () => {
        navigate(`/base/${baseId}`);
    };

    const handleDeleteClick = () => {
        openDeleteModal();
        handleClose();
    };

    return (
        <div className="absolute top-[36px] right-[-4px]">
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Edit Base"
                            onClick={handleEditClick}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete Base"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={handleDeleteClick}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    handleClose();
                }}
            />
        </div>
    );
};

export default BaseCardMoreOptions;
