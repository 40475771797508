import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import { Button, ButtonIcon } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import EyeIcon from '../../../design-system/Icons/EyeIcon';

const PlaybookBuilderTopControls = ({ backLinkHref, setPageTopControlsHeight }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const mode = searchParams.get('mode');

    const containerRef = useRef(null);
    const { width: screenWidth } = useWindowSize();

    const navigate = useNavigate();

    useEffect(() => {
        const isParsedModeCorrect = Object.values(PLAYBOOK_BUILDER_MODE).includes(mode);

        if (!isParsedModeCorrect) {
            const defaultMode = PLAYBOOK_BUILDER_MODE.view;
            changeMode(defaultMode, true);
        }
    }, [mode]);

    const changeMode = (newMode, shouldReplace = false) => {
        if (mode === newMode) {
            return;
        }

        const urlSearchParams = new URLSearchParams(searchParams);
        urlSearchParams.set('mode', newMode);
        setSearchParams(urlSearchParams, { replace: shouldReplace });
    };

    useEffect(() => {
        if (containerRef.current) {
            setPageTopControlsHeight(containerRef.current?.clientHeight || 0);
        }
    }, [containerRef.current, screenWidth]);

    return (
        <div className="flex items-center justify-between" ref={containerRef}>
            <div className="flex items-center gap-5">
                <Button
                    type="link"
                    size="xs"
                    text="Back"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backLinkHref)}
                />
                <p className="font-body text-body-regular-s text-neutral-300">Playbook /</p>
            </div>

            <div className="flex items-center gap-0.5">
                <ButtonIcon
                    size="xs"
                    type={mode === PLAYBOOK_BUILDER_MODE.edit ? 'primary' : 'grey'}
                    icon={EditLineIcon}
                    onClick={() => changeMode(PLAYBOOK_BUILDER_MODE.edit)}
                />
                <ButtonIcon
                    size="xs"
                    type={mode === PLAYBOOK_BUILDER_MODE.view ? 'primary' : 'grey'}
                    icon={EyeIcon}
                    onClick={() => changeMode(PLAYBOOK_BUILDER_MODE.view)}
                />
            </div>

            <div className="flex items-center gap-4">
                <Button type="neutral" size="xs" text="Share" state="disabled" />
                <Button type="primary" size="xs" text="Submit" state="disabled" />
            </div>
        </div>
    );
};

export default PlaybookBuilderTopControls;
