import React from 'react';
import { useNavigate } from 'react-router-dom';
import { handleOpenStartNewJobModal } from '../../helpers/handleOpenStartNewJobModal';

import { Button } from '../../design-system';
import { FlashlightFillIcon } from '../../design-system/Icons';
import GlobeIcon from '../../design-system/Icons/GlobeIcon';

const ClientPageTitleBlock = ({ title, hideButtonsOnMobile = false }) => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-wrap gap-4 justify-between pb-4 sm:pb-5 border-b-1 border-neutral-200">
            <p className="font-heading-bold text-heading-bold-m sm:text-heading-bold-l text-black">
                {title}
            </p>
            <div
                className={`items-center gap-4 ${hideButtonsOnMobile ? 'hidden lg:flex' : 'flex'}`}
            >
                <Button
                    type="secondary"
                    size="sm"
                    text="Discover Processes"
                    trailingIcon={GlobeIcon}
                    onClick={() => navigate('/discover')}
                />
                <Button
                    type="primary"
                    size="sm"
                    text="Start New Job"
                    helperText="⌘J"
                    trailingIcon={FlashlightFillIcon}
                    onClick={handleOpenStartNewJobModal}
                />
            </div>
        </div>
    );
};

export default ClientPageTitleBlock;
