import React, { forwardRef, useState } from 'react';
import {
    ArrowGoBackLineIcon,
    ErrorWarningLineIcon,
    Settings6LineIcon,
} from '../../../design-system/Icons';
import { Badge, Button } from '../../../design-system';
import { useNavigate } from 'react-router-dom';
import AddToLibraryIcon from '../../../design-system/Icons/AddToLibraryIcon';
import { duplicateTask } from 'helpers/duplicateTask';
import Alert from '../../../design-system/Alert/Alert';
import DuplicateTaskSuccessModal from '../../../components/DuplicateTaskSuccessModal/DuplicateTaskSuccessModal';
import useUser from '../../../hooks/useUser';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { TASK_TYPES } from '../../../constants/library';

const PublishedPromptPageHeader = (
    { prompt = {}, creator = {}, promptSettings = {}, promptVersionId },
    ref
) => {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ show: false });
    const [addedToMyLibrary, setAddedToMyLibrary] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [duplicatedTaskSuccessModal, setDuplicatedTaskSuccessModal] = useState({
        opened: false,
        id: null,
        taskName: null,
        taskType: null,
    });
    const { user, orgPlan } = useUser();

    const creatorName =
        creator.first_name || creator.last_name
            ? creator.first_name
                ? `${creator.first_name} ${creator.last_name || ''}`
                : `${creator.last_name}`
            : 'Anonymous User';

    const handleBackPressed = () => {
        navigate('/library');
    };

    const handleAddToMyLibrary = async () => {
        try {
            await duplicateTask({
                taskId: prompt.task?.id,
                promptId: prompt.id,
                promptVersionId,
                setAlert,
                setAddedToMyLibrary,
                setIsLoading,
                setSuccessModal: setDuplicatedTaskSuccessModal,
                orgId: user?.organization?.id,
                taskType: TASK_TYPES.prompt,
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <div className="w-full bg-purple-100 p-4 md:p-8" ref={ref}>
            <div className="flex items-center gap-x-3 gap-y-1 justify-between flex-wrap mb-4 md:mb-6">
                <Button
                    type="link"
                    size="sm"
                    text="To Library"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={handleBackPressed}
                />
                <Button
                    type="primary"
                    size="sm"
                    text={'Add to my library'}
                    state={isLoading ? 'loading' : addedToMyLibrary ? 'disabled' : 'default'}
                    leadingIcon={AddToLibraryIcon}
                    onClick={handleAddToMyLibrary}
                />
            </div>
            <div className="w-full max-w-[640px] flex flex-col gap-2">
                <h1 className="font-heading-bold text-heading-bold-l text-black">
                    {prompt.task?.name}
                </h1>
                <div className="flex min-[420px]:items-center gap-x-2 sm:gap-x-6 gap-y-1 flex-wrap flex-col min-[420px]:flex-row ">
                    <div className="max-w-full min-[420px]:max-w-[calc(100%-150px)] flex items-center gap-x-2 gap-y-1">
                        <div className="w-[24px] min-w-[24px] h-[24px] rounded-[50%] bg-purple-500 overflow-hidden">
                            {creator.profile_image ? (
                                <img
                                    src={creator.profile_image}
                                    alt={`Profile image of ${creatorName}`}
                                    className="object-contain"
                                />
                            ) : (
                                <div className="w-full h-full font-body text-body-regular-xs text-white flex items-center justify-center">
                                    {creatorName[0]}
                                </div>
                            )}
                        </div>
                        <p className="flex-grow font-body text-body-regular-xs text-neutral-300 truncate">
                            By{' '}
                            <span className="text-body-bold-xs text-neutral-500">
                                {creatorName}
                            </span>
                        </p>
                    </div>
                    <div className="">
                        <Badge
                            text={promptSettings.model}
                            color="neutral"
                            leadingIcon={Settings6LineIcon}
                        />
                    </div>
                </div>
                <p className="font-body text-body-regular-m text-neutral-500">
                    {prompt.task?.description}
                </p>
            </div>
            {alert.show && (
                <Alert
                    status={alert.status || 'critical'}
                    message={alert.message || defaultErrorMessage}
                    statusCode={alert.statusCode}
                    icon={alert.icon || ErrorWarningLineIcon}
                    handleClose={() => {
                        setAlert({ show: false });
                    }}
                    autoCloseInMS={3000}
                />
            )}
            {duplicatedTaskSuccessModal.opened && (
                <DuplicateTaskSuccessModal
                    handleClose={() => {
                        setDuplicatedTaskSuccessModal({
                            opened: false,
                            promptId: null,
                            taskName: null,
                        });
                    }}
                    id={duplicatedTaskSuccessModal.id}
                    taskName={duplicatedTaskSuccessModal.taskName}
                    taskType={duplicatedTaskSuccessModal.taskType}
                />
            )}
        </div>
    );
};

const ForwardedPublishedPromptPageHeader = forwardRef(PublishedPromptPageHeader);
export default ForwardedPublishedPromptPageHeader;
