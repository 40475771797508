import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { API } from 'constants';
import organizationClient from '../../../services/organization-api';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';
import { ORGANIZATION_ID_LS_KEY } from '../../../constants/organization';

import { useClickOutside } from '../../../hooks';
import { useNavigationRestrictionContext } from '../../../hooks/useNavigationRestrictionContext';

import ClientSideFilteredSearchBar from '../../ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import Loading from '../../Loading';
import Alert from '../../../design-system/Alert/Alert';
import ConfirmNavigationModal from '../../ConfirmNavigationModal/ConfirmNavigationModal';
import { ButtonIcon, SvgIcon } from '../../../design-system';
import { CloseLineIcon, ErrorWarningLineIcon } from '../../../design-system/Icons';

const OrganizationSwitcher = ({ mobileVersion = false, onClose }) => {
    const orgSwitcherRef = useRef(null);

    const [organizations, setOrganizations] = useState([]);
    const [filteredOrganizations, setFilteredOrganizations] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [orgChangeLoadingId, setOrgChangeLoadingId] = useState(null);
    const [errorAlert, setErrorAlert] = useState({ message: null, statusCode: null });

    const [targetOrganizationData, setTargetOrganizationData] = useState(null); // used when user want to change organization in BlockingModal

    const location = useLocation();
    const isPromptViewPageOpened = location.pathname?.includes('/library/prompt/');
    const { isNavigationRestricted, setIsNavigationRestricted } = useNavigationRestrictionContext();
    const [isConfirmNavigationModalShown, setIsConfirmNavigationModalShown] = useState(false);

    const closeOrganizationSwitcher = (e) => {
        if (isConfirmNavigationModalShown) {
            return;
        }
        onClose(e);
    };

    useClickOutside(orgSwitcherRef, closeOrganizationSwitcher);

    useEffect(() => {
        const fetchNextOrganizations = async (page) => {
            const { data } = await organizationClient.get(
                `${API.ROUTES.organization.memberships}?limit=${DEFAULT_LIMIT}&offset=${
                    (page - 1) * DEFAULT_LIMIT
                }`
            );
            setOrganizations((prevData) => [...prevData, ...data.results]);
            if (data.next) {
                await fetchNextOrganizations(page + 1);
            }
        };
        const fetchOrganizations = async () => {
            try {
                setIsLoading(true);
                const { data } = await organizationClient.get(
                    `${API.ROUTES.organization.memberships}?limit=${DEFAULT_LIMIT}&offset=0`
                );
                setOrganizations(data.results);
                if (data.next) {
                    await fetchNextOrganizations(2);
                }
            } catch (e) {
                setErrorAlert({ message: defaultErrorMessage, statusCode: e.response.status });
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrganizations();
    }, []);

    const changeTheDefaultOrganization = async (
        membershipId,
        isDefault,
        skipBlockingCheck = false
    ) => {
        if (isNavigationRestricted && !skipBlockingCheck) {
            setIsConfirmNavigationModalShown(true);
            setTargetOrganizationData({ membershipId, isDefault });
            return;
        }

        if (isDefault) {
            return;
        }

        try {
            setOrgChangeLoadingId(membershipId);
            const { data } = await organizationClient.patch(
                `${API.ROUTES.organization.organizationMembership}${membershipId}/`,
                { is_default: true }
            );
            setOrganizations((organizations) =>
                organizations.map((item) =>
                    item.id === membershipId
                        ? { ...item, is_default: true }
                        : { ...item, is_default: false }
                )
            );
            const newOrgId = data.organization;
            localStorage.setItem(ORGANIZATION_ID_LS_KEY, newOrgId);
            setOrgChangeLoadingId(null);
            setTargetOrganizationData(null);
            window.location.reload();
            onClose();
        } catch (e) {
            setOrgChangeLoadingId(null);
            setErrorAlert({
                message:
                    'Oops! Something went wrong while changing organization. Please try again.',
                statusCode: e.response.status,
            });
        }
    };

    const containerStyles = {
        true: 'fixed bottom-0 top-[40px] left-0 right-0 rounded-t-2 px-6 py-8',
        false: 'absolute top-1/2 transform translate-y-[-50%] left-[78px] w-[360px] h-[calc(100vh-40px)] shadow-l1 rounded-2 px-4 pt-4 pb-8 border-1 border-neutral-200',
    };

    const changeOrganizationInBlockingModal = async () => {
        try {
            if (targetOrganizationData) {
                await changeTheDefaultOrganization(
                    targetOrganizationData.membershipId,
                    targetOrganizationData.isDefault,
                    true
                );
                setTargetOrganizationData(null);
            }
        } catch (e) {
            console.log('error', e);
        }
    };

    const handleCancelNavigation = (e) => {
        e?.stopPropagation();
        setIsConfirmNavigationModalShown(false);
        setTargetOrganizationData(null);
    };

    const handleConfirmNavigation = () => {
        setIsNavigationRestricted(false);
        changeOrganizationInBlockingModal();
        setIsConfirmNavigationModalShown(false);
    };

    const confirmNavigationModalText = {
        true: `You have unsaved changes. If you change the organization now, all your changes will be lost.`,
        false: 'Are you sure you want to change the organization? We’ll keep loading your process in the background.',
    };

    return (
        <div
            className={`${mobileVersion && 'fixed top-0 left-0 right-0 bottom-0 bg-black/50'}`}
            ref={orgSwitcherRef}
        >
            <div
                className={`bg-white z-[32] overflow-y-auto flex flex-col gap-1 ${containerStyles[mobileVersion]}`}
            >
                <div className={`absolute top-[8px] right-[8px]`}>
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>
                <p className="font-body text-body-bold-m text-black py-2">Organizations</p>
                {!!organizations.length && (
                    <>
                        <ClientSideFilteredSearchBar
                            list={organizations}
                            setFilteredList={setFilteredOrganizations}
                            size="md"
                            keyToFilter={['organization', 'name']}
                        />
                        <ul className="flex flex-col gap-1">
                            {filteredOrganizations.map(({ id, is_default, organization }) => {
                                const name = organization.name;

                                return (
                                    <li
                                        className="w-full py-[10px] pl-2 pr-2 flex items-center justify-between gap-2 rounded-2 hover:bg-neutral-50 transition-colors cursor-pointer"
                                        key={id}
                                        onClick={() => changeTheDefaultOrganization(id, is_default)}
                                    >
                                        <div
                                            className={`flex items-center gap-[16px] ${
                                                is_default || orgChangeLoadingId === id
                                                    ? 'w-[calc(100%-32px)]'
                                                    : 'w-full'
                                            }`}
                                        >
                                            <div className="w-[36px] h-[36px] bg-purple-500 rounded-2 border-1 border-neutral-50 flex justify-center items-center uppercase font-body font-semibold text-[20px] text-white">
                                                {name[0]}
                                            </div>
                                            <p className="font-body text-body-regular-m text-neutral-500 w-[calc(100%-52px)] truncate">
                                                {name}
                                            </p>
                                        </div>
                                        {is_default && (
                                            <SvgIcon
                                                color="#754DCF"
                                                icon={CheckLineIcon}
                                                size="large"
                                            />
                                        )}
                                        {orgChangeLoadingId === id && (
                                            <div className="w-[24px] h-[24px]">
                                                <Loading withText={false} />
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
                {isLoading && (
                    <div className={`${!organizations.length && 'pt-4'}`}>
                        <Loading withText={!organizations.length} />
                    </div>
                )}
            </div>
            {errorAlert.message && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ message: null, statusCode: null })}
                />
            )}
            {isConfirmNavigationModalShown && (
                <ConfirmNavigationModal
                    text={confirmNavigationModalText[isPromptViewPageOpened]}
                    onCancel={handleCancelNavigation}
                    onConfirm={handleConfirmNavigation}
                    confirmButtonText={
                        isPromptViewPageOpened ? 'Change organization' : 'Yes, change organization'
                    }
                    cancelButtonText={isPromptViewPageOpened ? 'Stay on Page' : 'No, Stay on Page'}
                    buttonsTheme={isPromptViewPageOpened ? 'colored' : 'dark'}
                />
            )}
        </div>
    );
};

export default OrganizationSwitcher;
