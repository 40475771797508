import { Button } from 'design-system';
import { AddCircleLineIcon, TerminalBoxLineIcon } from 'design-system/Icons';
import { capitalizeFirstLetter } from '../../../services/strings';

export function EmptyIndexPage({ onCreatePressed, type }) {
    const dynamicTitlePart = {
        context: 'Contexts',
        entry: 'Entries',
    };

    return (
        <section
            id="empty-index"
            className="flex flex-col py-[80px] items-center gap-4 self-stretch"
        >
            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full">
                <TerminalBoxLineIcon width={32} height={32} />
            </div>
            <div className="flex flex-col items-center gap-2">
                <p className="font-heading-bold text-heading-bold-m">{`You don’t have any ${dynamicTitlePart[type]} yet.`}</p>
                <p className="font-body text-bold-m">
                    {`Try creating one by clicking "New ${capitalizeFirstLetter(type)}"`}.
                </p>
            </div>
            <Button
                onClick={onCreatePressed}
                leadingIcon={AddCircleLineIcon}
                text={`New ${capitalizeFirstLetter(type)}`}
                type="primary"
                size="sm"
            />
        </section>
    );
}
