import React from 'react';
import { ListOption, ListOptionGroup } from '../../../../design-system';
import { useNavigate, useParams } from 'react-router-dom';

const TestRunMoreOptionsPopup = ({ data, onClose, topPosition = '40px' }) => {
    const params = useParams();
    const goalTemplateId = Number(params.goalTemplateId);

    const navigate = useNavigate();

    const openNewTestUsingSettingsPage = () => {
        navigate(`/templates/goal/${goalTemplateId}/test?testrun=${data.id}`);
    };
    return (
        <div className="absolute right-[4px] sm:right-[36px] z-30" style={{ top: topPosition }}>
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        {/* <ListOption
                            leadingIconName="closeCircleLineIcon"
                            text="Cancel Test Run"
                            onClick={onClose}
                        /> */}
                        <ListOption
                            leadingIconName="plusCircleIcon"
                            text="New Test using Settings"
                            onClick={openNewTestUsingSettingsPage}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default TestRunMoreOptionsPopup;
