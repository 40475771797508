import React from 'react';
import { Button } from '../../../design-system';
import { FlashlightFillIcon } from '../../../design-system/Icons';
import { handleOpenStartNewJobModal } from '../../../helpers/handleOpenStartNewJobModal';
import PlusCircleIcon from '../../../design-system/Icons/PlusCircleIcon';

const ProcessPageTitleBlock = ({ openCreatePlaybookModal }) => {
    return (
        <div className="flex flex-wrap gap-4 justify-between items-center pb-4 sm:pb-5 border-b-1 border-neutral-200">
            <p className="font-heading-bold text-heading-bold-m sm:text-heading-bold-l text-black">
                Your Processes
            </p>
            <div className="items-center gap-4 hidden lg:flex">
                <Button
                    type="secondary"
                    size="sm"
                    text="New Playbook"
                    trailingIcon={PlusCircleIcon}
                    onClick={openCreatePlaybookModal}
                />
                <Button
                    type="primary"
                    size="sm"
                    text="Start New Job"
                    helperText="⌘J"
                    trailingIcon={FlashlightFillIcon}
                    onClick={handleOpenStartNewJobModal}
                />
            </div>
        </div>
    );
};

export default ProcessPageTitleBlock;
