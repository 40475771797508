import React from 'react';

import UpdatePublishedPromptBlock from '../UpdatePublishedPromptBlock/UpdatePublishedPromptBlock';
import CreatePublishedPromptBlock from '../CreatePublishedPromptBlock/CreatePublishedPromptBlock';

const PublishBlock = ({
    promptId,
    setPrompt,
    updateTaskPublishId,
    defaultVersion,
    accessLevel,
    publishId,
    taskName,
}) => {
    const allowMakeAnyChanges = accessLevel === 'owner';

    return (
        <div className="flex flex-col gap-4 xs:gap-6">
            {publishId ? (
                <UpdatePublishedPromptBlock
                    publishId={publishId}
                    defaultPromptVersionId={defaultVersion.id}
                    setPrompt={setPrompt}
                    updateTaskPublishId={updateTaskPublishId}
                    allowMakeAnyChanges={allowMakeAnyChanges}
                />
            ) : (
                <CreatePublishedPromptBlock
                    promptId={promptId}
                    setPrompt={setPrompt}
                    updateTaskPublishId={updateTaskPublishId}
                    defaultVersion={defaultVersion}
                    taskName={taskName}
                    allowMakeAnyChanges={allowMakeAnyChanges}
                />
            )}
        </div>
    );
};

export default PublishBlock;
