import React from 'react';
import { ButtonIcon, Input, Select } from '../../../../design-system';
import Tabs from '../../../../design-system/Tabs/Tabs';
import { DeleteBin4LineIcon } from '../../../../design-system/Icons';
import { useWindowSize } from '../../../../hooks/useWindowSize';

const propertyTypeSelectOptions = [
    { id: 'Single line text', name: 'Single line text' },
    { id: 'Multi line text', name: 'Multi line text' },
    { id: 'Checkbox', name: 'Checkbox' },
    { id: 'Integer', name: 'Integer' },
    // { id: 'Decimal', name: 'Decimal' },
];

const PropertyItem = ({
    item,
    currentIndex,
    setProperties,
    fieldsErrorMessages,
    setFieldsErrorMessages,
}) => {
    const { width } = useWindowSize();

    const handleInputChange = (e) => {
        setProperties((properties) =>
            properties.map((property, index) =>
                index === currentIndex ? { ...property, [e.target.name]: e.target.value } : property
            )
        );
        if (fieldsErrorMessages.properties) {
            setFieldsErrorMessages((prevState) => ({ ...prevState, properties: null }));
        }
    };

    const handleSelectChange = (value) => {
        setProperties((properties) =>
            properties.map((property, index) =>
                index === currentIndex ? { ...property, dataFormat: value } : property
            )
        );
    };

    const handleEmbeddedPropertyChange = (_, selectedTabindex) => {
        if (!item.editable) {
            return;
        }
        setProperties((properties) =>
            properties.map((property, index) =>
                index === currentIndex ? { ...property, skip: !!selectedTabindex } : property
            )
        );
    };

    const handlePropertyDelete = () => {
        setProperties((properties) =>
            properties.filter((property, index) => index !== currentIndex)
        );
    };

    return (
        <>
            <div className="flex flex-col min-[500px]:flex-row gap-y-2 gap-x-3 min-[500px]:items-end w-full">
                <div className="flex flex-col gap-y-1 gap-x-3 min-[850px]:flex-row min-[500px]:w-[calc(100%-172px)]">
                    <div className="w-full min-[850px]:w-[55%] min-[980px]:min-w-[360px]">
                        <Input
                            size="xs"
                            label={width < 850 ? 'Property name' : null}
                            value={item.name}
                            name="name"
                            placeholder="Name"
                            state={!item.editable ? 'disabled' : 'default'}
                            isRequired
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex-grow">
                        <Select
                            size="xs"
                            name="dataFormat"
                            value={item.dataFormat}
                            label={width < 850 ? 'Property type' : null}
                            placeholder="Select a Property Type"
                            state={!item.editable ? 'disabled' : 'default'}
                            isRequired
                            options={propertyTypeSelectOptions}
                            onChange={handleSelectChange}
                        />
                    </div>
                </div>
                <div className="flex items-end gap-3 w-[172px]">
                    <div className="pb-[1px]">
                        {width < 850 && (
                            <p className="font-body-bold text-body-bold-s text-neutral-500 mb-[5px]">
                                Embed property
                            </p>
                        )}
                        <Tabs
                            tabs={[{ name: 'Yes' }, { name: 'No' }]}
                            tabIndex={item.skip ? 1 : 0}
                            onTabChanged={handleEmbeddedPropertyChange}
                        />
                    </div>
                    <ButtonIcon
                        type="link"
                        size="sm"
                        state={!item.editable ? 'disabled' : 'default'}
                        icon={DeleteBin4LineIcon}
                        onClick={handlePropertyDelete}
                    />
                </div>
            </div>
        </>
    );
};

export default PropertyItem;

// <div className="flex flex-col min-[500px]:flex-row gap-y-2 gap-x-3 min-[500px]:items-end w-full">
//     <div className="flex-grow flex flex-col gap-y-1 gap-x-3 min-[850px]:flex-row">
//         <div className="flex-grow min-[850px]:max-w-[360px] min-[980px]:min-w-[360px]">
//             <Input
//                 size="xs"
//                 label={currentIndex === 0 || width <= 850 ? 'Property name' : null}
//                 value={item.name}
//                 name="name"
//                 placeholder="Name"
//                 state={!item.editable ? 'disabled' : 'default'}
//                 isRequired
//                 onChange={handleInputChange}
//             />
//         </div>
//         <div className="flex-grow">
//             <Select
//                 size="xs"
//                 name="dataFormat"
//                 value={item.dataFormat}
//                 label={currentIndex === 0 || width <= 850 ? 'Property type' : null}
//                 placeholder="Select a Property Type"
//                 state={!item.editable ? 'disabled' : 'default'}
//                 isRequired
//                 options={propertyTypeSelectOptions}
//                 onChange={handleSelectChange}
//             />
//         </div>
//     </div>
//     <div className="flex items-end gap-3">
//         <div className="pb-[1px]">
//             {(currentIndex === 0 || width <= 850) && (
//                 <p className="font-body-bold text-body-bold-s text-neutral-500 mb-[5px]">
//                     Embed property
//                 </p>
//             )}
//             <Tabs
//                 tabs={[{ name: 'Yes' }, { name: 'No' }]}
//                 tabIndex={item.skip ? 1 : 0}
//                 onTabChanged={handleEmbeddedPropertyChange}
//             />
//         </div>
//         <ButtonIcon
//             type="link"
//             size="sm"
//             state={!item.editable ? 'disabled' : 'default'}
//             icon={DeleteBin4LineIcon}
//             onClick={handlePropertyDelete}
//         />
//     </div>
// </div>
