import React from 'react';
import { integerRegex } from '../../../constants/regex_patterns';

import { ButtonIcon, Input, Select, TextArea } from '../../../design-system';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import JsonTextareaBox from '../../../design-system/JsonTextareaBox/JsonTextareaBox';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import OptionsInput from '../../../design-system/OptionsInput/OptionsInput';
import Radio from '../../../design-system/Radio/Radio';
import Checkbox from '../../../design-system/Checkbox/Checkbox';
import MultiSelect from '../../../design-system/MultiSelect/MultiSelect';
import Toggle from '../../../design-system/Toggle/Toggle';
import AddToLibraryIcon from '../../../design-system/Icons/AddToLibraryIcon';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import Trash2Icon from '../../../design-system/Icons/Trash2Icon';

const NewJobFormField = ({
    input,
    setFormData,
    handleDuplicateInput,
    handleDeleteInput,
    handleEditInput,
    withActionButtons,
}) => {
    const {
        key,
        label,
        description,
        type,
        action,
        is_required,
        options,
        value,
        state,
        errorMessage,
        order,
    } = input;

    const formattedOptions =
        options &&
        Object.keys(options)?.map((item) => ({
            id: options[item],
            name: item,
        }));

    const handleInputChange = (order, value) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.order === order
                    ? { ...item, value, state: 'default', errorMessage: null }
                    : item
            )
        );
    };

    const handleIntegerInputChange = (order, event) => {
        const inputValue = event.target.value;

        if (integerRegex.test(inputValue)) {
            setFormData((prevData) =>
                prevData.map((item) =>
                    item.order === order
                        ? { ...item, value: +inputValue, errorMessage: null }
                        : item
                )
            );
        }
    };

    const handleAddNewOption = (order, newOption) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.order === order
                    ? {
                          ...item,
                          value: [...(item.value || []), newOption],
                          state: 'default',
                          errorMessage: null,
                      }
                    : item
            )
        );
    };

    const handleRemoveOption = (order, optionToRemove) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.order === order
                    ? {
                          ...item,
                          value: item.value?.filter((option) => option !== optionToRemove),
                          state: 'default',
                          errorMessage: null,
                      }
                    : item
            )
        );
    };

    const handleRadioButtonChange = (order, optionId) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.order === order
                    ? { ...item, value: optionId, state: 'default', errorMessage: null }
                    : item
            )
        );
    };

    const handleMultiSelectChange = (order, optionId) => {
        setFormData((prevData) =>
            prevData.map((item) => {
                if (item.order === order) {
                    const newValue = item.value?.includes(optionId)
                        ? item.value.filter((el) => el !== optionId)
                        : [...(item.value || []), optionId];
                    return { ...item, value: newValue, state: 'default', errorMessage: null };
                } else {
                    return item;
                }
            })
        );
    };

    return (
        <>
            {action !== 'toggle' && (
                <FormFieldWrapper
                    label={label}
                    isRequired={is_required}
                    state={state}
                    errorMessage={errorMessage}
                    tipText={description}
                    withDuplicateButton={withActionButtons}
                    withEditButton={withActionButtons}
                    withDeleteButton={withActionButtons}
                    onDuplicateButtonClick={() => handleDuplicateInput(input)}
                    onDeleteButtonClick={() => handleDeleteInput(input)}
                    onEditButtonClick={() => handleEditInput(input)}
                    gap={0}
                    bottomSpace={4}
                    labelMarginBottom={withActionButtons ? 0 : 7}
                >
                    {action === 'single-input' && type === 'string' && (
                        <Input
                            size="sm"
                            name={key}
                            value={value || ''}
                            placeholder="Fill this input"
                            state={state}
                            type="text"
                            onChange={(e) => handleInputChange(order, e.target.value)}
                        />
                    )}
                    {action === 'single-input' && type === 'integer' && (
                        <Input
                            size="sm"
                            name={key}
                            value={value}
                            placeholder="0"
                            state={state}
                            onChange={(e) => handleIntegerInputChange(order, e)}
                        />
                    )}
                    {action === 'single-input' && type === 'number' && (
                        <Input
                            size="sm"
                            name={key}
                            value={value}
                            placeholder="0.1"
                            type="number"
                            step="0.1"
                            state={state}
                            onChange={(e) => handleInputChange(order, e.target.value)}
                        />
                    )}
                    {action === 'json-input' && (
                        <JsonTextareaBox
                            mode="code"
                            value={value}
                            state={state}
                            containerMinHeight={200}
                            setValue={(value) => handleInputChange(order, value)}
                        />
                    )}
                    {action === 'textarea-input' && (
                        <TextArea
                            name={key}
                            value={value || ''}
                            placeholder="Fill this input"
                            state={state}
                            onChange={(e) => handleInputChange(order, e.target.value)}
                        />
                    )}
                    {action === 'richtext-input' && (
                        <RichTextArea
                            name={key}
                            value={value || ''}
                            placeholder="Fill this input"
                            state={state}
                            setValue={(value) => handleInputChange(order, value)}
                            autoExpand
                            minHeight={170}
                            darkBorderColor
                        />
                    )}
                    {action === 'multi-input' && (
                        <OptionsInput
                            name={key}
                            options={value || []}
                            placeholder="Add your input and click + to add them"
                            state={state}
                            handleAddNewOption={(option) => handleAddNewOption(order, option)}
                            handleRemoveOption={(option) => handleRemoveOption(order, option)}
                            containerGap={16}
                            optionsBadgeColor="neutral"
                            optionsBadgeFontStyle="text-body-regular-xs"
                        />
                    )}
                    {action === 'single-select' && (
                        <ul className="flex flex-col gap-1 pt-0.5">
                            {formattedOptions.map((option) => (
                                <li key={option.id} className="py-1 px-2">
                                    <Radio
                                        isSelected={option.id === value}
                                        name={option.id}
                                        label={option.name}
                                        state={state}
                                        onChange={() => handleRadioButtonChange(order, option.id)}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                    {action === 'multi-select' && (
                        <ul className="flex flex-col gap-1 pt-0.5">
                            {formattedOptions.map((option) => (
                                <li key={option.id} className="py-1 px-2">
                                    <Checkbox
                                        name={option.id}
                                        label={option.name}
                                        isChecked={!!value?.includes(option.id)}
                                        state={state}
                                        onChange={() => handleMultiSelectChange(order, option.id)}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                    {action === 'single-dropdown' && (
                        <Select
                            size="sm"
                            name={key}
                            value={value}
                            options={formattedOptions || []}
                            placeholder="Select an option"
                            state={state}
                            onChange={(optionId) => handleInputChange(order, optionId)}
                            includeClientSideFiltering
                        />
                    )}
                    {action === 'multi-dropdown' && (
                        <MultiSelect
                            name={key}
                            addedOptions={value || []}
                            optionsForSelect={formattedOptions || []}
                            placeholder="Select an option and click + to add them"
                            state={state}
                            handleAddNewOption={(option) => handleAddNewOption(order, option)}
                            handleRemoveOption={(option) => handleRemoveOption(order, option)}
                            includeClientSideFiltering
                        />
                    )}
                </FormFieldWrapper>
            )}
            {action === 'toggle' && (
                <FormFieldWrapper state={state} errorMessage={errorMessage} tipText={description}>
                    <div className="flex justify-between gap-x-7 gap-y-2 flex-wrap">
                        <div className="flex items-center gap-x-7 gap-y-2 flex-wrap">
                            <div className="font-body text-body-bold-s text-neutral-500">
                                {label}
                                {is_required && (
                                    <sup className="text-red-500 leading-1 ml-0.5">*</sup>
                                )}
                            </div>
                            <Toggle
                                name={key}
                                isDisabled={state === 'disabled'}
                                isSelected={value === true}
                                size="sm"
                                onChange={(state) => handleInputChange(order, state)}
                            />
                        </div>
                        {withActionButtons && (
                            <div className="flex items-center gap-1 sm:gap-2">
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state="default"
                                    icon={AddToLibraryIcon}
                                    onClick={() => handleDuplicateInput(input)}
                                />

                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state="default"
                                    icon={EditLineIcon}
                                    onClick={() => handleEditInput(input)}
                                />

                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state="default"
                                    icon={Trash2Icon}
                                    onClick={() => handleDeleteInput(input)}
                                />
                            </div>
                        )}
                    </div>
                </FormFieldWrapper>
            )}
        </>
    );
};

export default NewJobFormField;
