import React, { useState } from 'react';
import CollapsibleStatusCardContainer from '../CollapsibleStatusCardContainer/CollapsibleStatusCardContainer';
import { threadStatusBadge } from '../../../constants/threads';
import { Badge } from '../../../design-system';
import TaskRunCard from '../TaskRunCard/TaskRunCard';
import WorkerMoreOptionsDropdown from '../WorkerMoreOptionsDropdown/WorkerMoreOptionsDropdown';
import AddToDatasetModal from '../AddToDatasetModal/AddToDatasetModal';

const GoalCard = ({
    worker,
    processId,
    setThread,
    setRestartSidePanel,
    setCancelConfirmationModal,
    smallerWidth,
    setShareThreadFeedbackModal,
}) => {
    const { id, goal, status, triggered_by, config, inputs, outputs } = worker;

    const statusBadge = threadStatusBadge[status];
    const [isAddToDatasetModalOpened, setIsAddToDatasetModalOpened] = useState(false);

    const openCancelConfirmationModal = () => {
        setCancelConfirmationModal({
            opened: true,
            cancelTarget: 'worker',
            workerId: id,
            goalName: goal.name,
        });
    };

    const openRestartSidePanel = () => {
        setRestartSidePanel({ opened: true, restartTarget: 'worker', workerId: id });
    };

    // header of the card (collapsed view)
    const goalInfoBlock = (
        <div
            className={`flex flex-col gap-x-2 gap-y-2 ${
                smallerWidth
                    ? 'min-[1020px]:flex-row min-[1020px]:items-center min-[1020px]:gap-x-4'
                    : 'min-[730px]:flex-row min-[730px]:items-center min-[730px]:gap-x-4'
            }`}
        >
            <div className="flex-grow">
                <p className="font-body-bold text-body-bold-s text-black line-clamp-2">
                    {goal.name}
                </p>
                <p
                    className={`font-body text-body-regular-xs text-neutral-300 line-clamp-2 ${
                        smallerWidth ? 'min-[1020px]:mb-1' : 'min-[730px]:mb-1'
                    }`}
                >
                    {goal.description}
                </p>
                <p
                    className={`hidden font-body text-body-regular-xs text-neutral-300 ${
                        smallerWidth ? 'min-[1020px]:block' : 'min-[730px]:block'
                    }`}
                >
                    Worker ID <span className="text-neutral-500">{id}</span>
                </p>
            </div>
            {triggered_by && (
                <div
                    className={`flex items-center flex-wrap gap-0.5 ${
                        smallerWidth
                            ? 'min-[1020px]:flex-nowrap min-[1020px]:flex-col min-[1020px]:items-start min-[1020px]:w-[170px] min-[1020px]:min-w-[170px]'
                            : 'min-[730px]:flex-nowrap min-[730px]:flex-col min-[730px]:items-start min-[730px]:w-[170px] min-[730px]:min-w-[170px]'
                    }`}
                >
                    <p className="font-body text-body-regular-xs text-neutral-300">Triggered by</p>
                    <Badge text={triggered_by.name} color="neutral" />
                </div>
            )}
            <div className="flex items-center justify-between gap-3">
                <div
                    className={`flex items-center gap-0.5 ${
                        smallerWidth
                            ? 'min-[1020px]:flex-col min-[1020px]:items-start min-[1020px]:w-[94px] min-[1020px]:min-w-[94px]'
                            : 'min-[730px]:flex-col min-[730px]:items-start min-[730px]:w-[94px] min-[730px]:min-w-[94px]'
                    }`}
                >
                    <p className="font-body text-body-regular-xs text-neutral-300">Status</p>
                    {statusBadge && <Badge text={statusBadge.text} color={statusBadge.color} />}
                </div>
                <p
                    className={`font-body text-body-regular-xs text-neutral-300 ${
                        smallerWidth ? 'min-[1020px]:hidden' : 'min-[730px]:hidden '
                    }`}
                >
                    Worker ID <span className="text-neutral-500">{id}</span>
                </p>
            </div>
        </div>
    );

    // main card content (expanded view)
    const expandedCardBlock = (
        <ul className="flex flex-col gap-[10px] mt-[10px]">
            {[...config]
                .sort((a, b) => a.order - b.order)
                .map((item) => (
                    <TaskRunCard
                        key={item.step_id}
                        step={item}
                        workerId={id}
                        setThread={setThread}
                        setShareThreadFeedbackModal={setShareThreadFeedbackModal}
                    />
                ))}
        </ul>
    );

    // worker more options popup
    const workerMoreOptionsBlock = (onClose) => (
        <WorkerMoreOptionsDropdown
            goalId={goal.id}
            processId={processId}
            openRestartSidePanel={openRestartSidePanel}
            openCancelConfirmationModal={openCancelConfirmationModal}
            openAddToDatasetModal={() => setIsAddToDatasetModalOpened(true)}
            onClose={onClose}
        />
    );

    return (
        <>
            <CollapsibleStatusCardContainer
                status={status}
                collapsedCardBlock={goalInfoBlock}
                expandedCardBlock={expandedCardBlock}
                moreOptionsBlock={workerMoreOptionsBlock}
            />
            {isAddToDatasetModalOpened && (
                <AddToDatasetModal
                    workerId={id}
                    workerInputs={inputs || []}
                    workerOutputs={outputs || []}
                    onClose={() => setIsAddToDatasetModalOpened(false)}
                />
            )}
        </>
    );
};

export default GoalCard;
