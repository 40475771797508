import React from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import { getNewBlockObject, moveBlockBetweenSections } from '../../../helpers/playbookBuilderUtils';
import { insertItemAtSpecificPositionWithOrderUpdate } from '../../../helpers/insertItemAtSpecificPositionWithOrderUpdate';

const BlockSeparatorDropZone = ({
    targetOrder,
    targetSectionTemporaryId,
    setContentBlock,
    setPlaybookContentFormData,
}) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ['NEW_BLOCK', 'BLOCK'],
        drop: handleDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item) => item.sectionTemporaryId !== targetSectionTemporaryId,
    });

    function handleDrop(item) {
        const { dndType, blockType, blockTemporaryId } = item;

        if (dndType === 'NEW_BLOCK') {
            addNewBlock(blockType);
        }

        if (dndType === 'BLOCK') {
            const currentSectionTemporaryId = item.sectionTemporaryId;
            moveBlockBetweenSections({
                currentSectionTemporaryId,
                targetSectionTemporaryId,
                blockTemporaryId,
                targetOrder,
                setPlaybookContentFormData,
            });
        }
    }

    function addNewBlock(blockType) {
        setContentBlock((prevData) => {
            const newBlock = getNewBlockObject({ blockType, order: targetOrder });

            return insertItemAtSpecificPositionWithOrderUpdate(prevData, newBlock, targetOrder);
        });
    }

    const isDndActive = isOver && canDrop;

    const dropLineClassName = classNames(
        'w-full h-[3px] rounded-full transition-colors mx-[-10px]',
        {
            'bg-purple-300': isDndActive,
            'bg-transparent': !isDndActive,
        }
    );

    return (
        <div className="w-full h-6 min-h-6 flex items-center" ref={drop}>
            <div className={dropLineClassName}></div>
        </div>
    );
};

export default BlockSeparatorDropZone;
