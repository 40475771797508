import { useParams, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import { Button, Form } from 'design-system';
import { useState } from 'react';
import apiClient from 'services/user-api';
import Loading from 'components/Loading';
import { API } from 'constants';
import Input from 'design-system/Input/Input';

const initialOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    url: API.ROUTES.user.resetPasswordConfirm,
};

export default function ResetPasswordConfirmation() {
    const navigate = useNavigate();
    const { id, confirmationId } = useParams();
    const [formData, setFormData] = useState({
        password1: '',
        password2: '',
    });
    const [apiResponse, setApiResponse] = useState({
        loading: false,
        success: false,
        error: null,
    });
    const handleSubmit = async (e) => {
        e.preventDefault();

        setApiResponse({
            loading: true,
            success: false,
            error: null,
        });

        const options = {
            ...initialOptions,
            data: {
                new_password1: formData.password1,
                new_password2: formData.password2,
                uid: id,
                token: confirmationId,
            },
        };
        const response = await apiClient(options);
        setApiResponse({
            loading: false,
            success: response.status === 200,
            error: null,
        });
        localStorage.setItem('authKey', null);
        mutate('/user/view/', null);
    };

    const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    let formBody;
    if (apiResponse.loading) {
        formBody = <Loading text="Resetting your password" />;
    } else if (apiResponse.success) {
        formBody = (
            <div>
                <h2 className="font-heading-bold text-heading-bold-m text-center mb-[32px]">
                    Your password has been successfully reset. Click the button below to login.
                </h2>
                <div className="flex justify-center">
                    <Button
                        type="link"
                        size="sm"
                        text="Go to Login"
                        onClick={() => {
                            navigate('/login');
                        }}
                    />
                </div>
            </div>
        );
    } else {
        formBody = (
            <div className="flex flex-col gap-[16px]">
                <Input
                    size="xs"
                    label="New Password"
                    value={formData.password1}
                    name="password1"
                    isRequired={true}
                    type="password"
                    onChange={handleInputChange}
                />
                <Input
                    size="xs"
                    label="Confirm Password"
                    value={formData.password2}
                    name="password2"
                    isRequired={true}
                    type="password"
                    onChange={handleInputChange}
                />
                <div className="mx-auto">
                    <Button type="primary" text="Reset Password" size="sm" isSubmit={true} />
                </div>
            </div>
        );
    }

    return (
        <div className="ResetPasswordConfirmation mx-auto py-[80px] w-[300px] flex flex-col gap-[32px]">
            {apiResponse.success && formBody}
            {!apiResponse.success && <Form onSubmit={handleSubmit}>{formBody}</Form>}
        </div>
    );
}
