import React, { useState } from 'react';
import { Badge, Button } from '../../../../design-system';
import { formatKeyToLabel } from '../../../../helpers/formatKeyToLabel';
import { formatOutputValue } from '../../../../helpers/formatOutputValue';
import OutputRatingControls from '../../../../components/OutputRatingControls/OutputRatingControls';
import AdditionalResultDataModal from '../AdditionalResultDataModal/AdditionalResultDataModal';

const OutputBlock = ({
    outputs,
    resultId,
    outputRating,
    setOutputRating,
    additionalResultData,
}) => {
    const [additionalResultDataModalOpened, setAdditionalResultDataModalOpened] = useState(false);

    return (
        <>
            <div className="w-full border-b border-neutral-200 flex flex-col gap-5 pb-5">
                <div className="flex justify-between items-center gap-3">
                    <h3 className="font-body text-body-bold-m text-neutral-500 uppercase">
                        Output
                    </h3>
                    <div className="flex items-center gap-3">
                        {resultId && (
                            <Button
                                type="link"
                                size="xs"
                                text="View Data"
                                onClick={() => setAdditionalResultDataModalOpened(true)}
                            />
                        )}
                        {outputRating !== null && outputRating !== undefined && resultId && (
                            <OutputRatingControls
                                outputRating={outputRating}
                                updateOutputRating={setOutputRating}
                                resultId={resultId}
                                useExternalState
                                view="buttonIcon"
                                size="xs"
                            />
                        )}
                    </div>
                </div>
                {!!outputs.length ? (
                    <ul className="flex flex-col gap-4">
                        {outputs.map((item) => {
                            const formattedValue = formatOutputValue(item.value);
                            return (
                                <li key={item.id} className="flex flex-col gap-4">
                                    <div className="flex justify-between items-center">
                                        <p className="font-body text-body-bold-s text-neutral-500">
                                            [{item.key}]
                                        </p>
                                        <Badge text={formatKeyToLabel(item.type)} color="neutral" />
                                    </div>
                                    {formattedValue && (
                                        <pre className="font-body text-body-regular-s text-neutral-500 whitespace-pre-wrap">
                                            {formattedValue}
                                        </pre>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <p className="font-body text-body-regular-s text-neutral-500 italic">None</p>
                )}
            </div>
            {additionalResultDataModalOpened && (
                <AdditionalResultDataModal
                    resultId={resultId}
                    data={additionalResultData}
                    onClose={() => setAdditionalResultDataModalOpened(false)}
                />
            )}
        </>
    );
};

export default OutputBlock;
