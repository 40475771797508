export const BASE_TYPES = {
    context: 'context',
    database: 'database',
    knowledgeBase: 'knowledge-base',
};

export const knowledgeBasePropertyFormatMapping = {
    'Single line text': 'text',
    'Multi line text': 'text',
    Checkbox: 'boolean',
    Integer: 'int',
    // Decimal: 'number',
};

export const PROPERTY_DATA_FORMAT = {
    singleLineText: 'Single line text',
    multiLineText: 'Multi line text',
    checkbox: 'Checkbox',
    integer: 'Integer',
    // decimal: 'Decimal',
    url: 'URL',
    email: 'Email',
};
