import React from 'react';
import { usePagination } from '../../../hooks';
import { DEFAUlT_LIMIT } from '../../../hooks/usePagination';

import { API } from 'constants';
import libraryClient from '../../../services/library-api';
import operateClient from '../../../services/operate-api';

import Loading from '../../../components/Loading';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';
import PromptChainIndexPage from '../PromptChainIndexPage/PromptChainIndexPage';

const PromptChainPaginatedContainer = ({
    path,
    sortType,
    pageIndex,
    onPreviousPressed,
    onNextPressed,
}) => {
    const { count, total, loading, sortedData } = usePagination({
        sortType,
        pageIndex,
        client: path === 'chains' ? libraryClient : operateClient,
        route: path === 'chains' ? API.ROUTES.library.workflow : API.ROUTES.operate.worker,
    });

    if (loading) {
        return <Loading text="" />;
    }

    const start = pageIndex === 0 ? 1 : pageIndex * DEFAUlT_LIMIT + 1;
    const end = total - start < count ? total : start + count - 1;
    const hasData = total > 0;

    return (
        <div>
            <PromptChainIndexPage
                data={sortedData}
                path={path}
                loading={loading}
                pageIndex={pageIndex}
            />
            {hasData && (
                <div className="flex flex-col mt-[20px]">
                    <PaginationFooter
                        start={start}
                        end={end}
                        total={total}
                        onNextPressed={onNextPressed}
                        onPreviousPressed={onPreviousPressed}
                    />
                </div>
            )}
        </div>
    );
};

export default PromptChainPaginatedContainer;
