import React from 'react';
import NavigationGuide from '../NavigationGuide/NavigationGuide';
import PlaybookHistory from '../PlaybookHistory/PlaybookHistory';

import { pageColumnsCommonStyles } from '../PlaybookBuilderIndex/PlaybookBuilderIndex';

const PlaybookPageLeftPanel = ({
    mode,
    playbookDetail,
    playbookContentFormData,
    areSectionsExpandedState,
}) => {
    return (
        <div
            className={`w-[200px] min-w-[200px] flex flex-col gap-6 overflow-y-auto ${pageColumnsCommonStyles}`}
        >
            <NavigationGuide
                mode={mode}
                playbookContent={playbookContentFormData || []}
                areSectionsExpandedState={areSectionsExpandedState}
            />
            <PlaybookHistory mode={mode} playbookDetail={playbookDetail} />
        </div>
    );
};

export default PlaybookPageLeftPanel;
