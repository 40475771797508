import React from 'react';

const CornerUpRightDoubleLineIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 32 32" fill="none">
            <path
                d="M5.32831 13.3337L5.32812 25.3336H7.99479L7.99494 16.0004L16.224 16.0003L10.9578 21.2665L12.8434 23.1523L21.3287 14.6669L12.8434 6.18164L10.9577 8.06725L16.2241 13.3336L5.32831 13.3337ZM20.3289 6.18164L18.4433 8.06725L25.0429 14.6669L18.4433 21.2665L20.3289 23.1523L28.8141 14.6669L20.3289 6.18164Z"
                fill={props.color}
            />
        </svg>
    );
};

export default CornerUpRightDoubleLineIcon;
