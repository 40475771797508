import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API } from 'constants';
import operateClient from '../../../services/operate-api';

import EditableTextArea from '../../../components/EditableTextArea/EditableTextArea';
import { Badge, Select } from '../../../design-system';
import { statusOptions } from '../../../constants/runStatus';
import { DateTime } from 'luxon';

const PromptChainRunOverviewPageHeader = ({
    workerDetails,
    setWorkerDetails,
    workerId,
    setErrorAlert,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [status, setStatus] = useState(workerDetails.status);

    const {
        created_at,
        name: chainName,
        goal,
        goal_name: goalName,
        step_count: stepCount,
    } = workerDetails;

    const formattedTime = DateTime.fromISO(created_at).toLocaleString() || 'No runs yet';

    const handleWorkerChanges = async (field, value) => {
        try {
            const requestBody = {
                [field]: value,
            };
            if (field === 'status') {
                setStatus(value);
            }
            const { data } = await operateClient.patch(
                `${API.ROUTES.operate.worker}${workerId}/`,
                requestBody
            );
            setWorkerDetails(data);
        } catch (e) {
            if (field === 'status') {
                setStatus(workerDetails.status);
                setErrorAlert({
                    show: true,
                    message:
                        'Oops! Something went wrong while updating the status, please try again.',
                    statusCode: e.response.status,
                });
            } else {
                setErrorAlert({
                    show: true,
                    message:
                        'Oops! Something went wrong while updating the Chain Run Name, please try again.',
                    statusCode: e.response.status,
                });
            }
        }
    };

    return (
        <div className="w-full flex flex-col gap-4 mb-8">
            <EditableTextArea
                initialText={chainName}
                textStyle="font-heading-bold text-heading-bold-m text-black"
                trailingButton={{
                    type: 'neutral',
                    size: 'xs',
                    text: 'View Chain Setup',
                    minWidth: '157px',
                    onClick: () =>
                        navigate(`/chain/${goal}`, {
                            state: { from: location },
                        }),
                }}
                onSave={(value) => handleWorkerChanges('name', value)}
            />
            <div className="flex gap-4 justify-between items-end flex-wrap">
                <div className="w-full max-w-[300px]">
                    <p className="font-body text-body-bold-s text-neutral-500 mb-1">Run Status</p>
                    <Select
                        size="xs"
                        onChange={(value) => handleWorkerChanges('status', value)}
                        name="status"
                        options={statusOptions}
                        value={status}
                        showAsBadge
                        badgeColor="peach"
                    />
                </div>
                <div className="flex items-center gap-2 sm:gap-4 flex-wrap">
                    <p className="font-body text-body-regular-xs text-neutral-300 w-[43px] min-w-[43px] truncate">
                        ID {goal}
                    </p>
                    <p className="max-w-[150px] font-body text-body-regular-xs text-black truncate">
                        {goalName}
                    </p>
                    <p className="font-body text-body-regular-xs text-neutral-300 min-w-[120px] w-[120px]">
                        Last Run{' '}
                        <span className="font-body text-body-bold-xs text-neutral-500">
                            {formattedTime}
                        </span>
                    </p>
                    <div className="w-[82px] min-w-[82px]">
                        <Badge
                            text={`${stepCount} ${stepCount === 1 ? 'Prompt' : 'Prompts'}`}
                            color="blue"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromptChainRunOverviewPageHeader;
