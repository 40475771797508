import React, { useEffect, useRef } from 'react';

import { useDrag, useDrop } from 'react-dnd';

import StepCardContainer from '../../../components/StepCardContainer/StepCardContainer';
import StepCard from '../StepCard/StepCard';

const DraggableStepCard = ({
    item,
    setData,
    referenceOptions,
    handleReferenceChange,
    reference,
}) => {
    const ref = useRef();

    const changeCardSteps = (dragStep, hoverStep) => {
        if (dragStep === hoverStep) return;

        if (hoverStep < dragStep) {
            setData((prevData) =>
                prevData.map((item) => {
                    if (item.step >= hoverStep && item.step < dragStep) {
                        return { ...item, step: item.step + 1 };
                    } else if (item.step === dragStep) {
                        return { ...item, step: hoverStep };
                    } else return item;
                })
            );
        }

        if (dragStep < hoverStep) {
            setData((prevData) =>
                prevData.map((item) => {
                    if (item.step > dragStep && item.step <= hoverStep) {
                        return { ...item, step: item.step - 1 };
                    } else if (item.step === dragStep) {
                        return { ...item, step: hoverStep };
                    } else {
                        return item;
                    }
                })
            );
        }
    };

    const [{ handlerId }, drop] = useDrop({
        accept: 'STEP_CARD',
        item: { id: item.id, step: item.step, position: item.position },
        drop: () => ({ id: item.id, step: item.step, position: item.position }),
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(draggingItem, monitor) {
            if (!ref.current) {
                return;
            }

            const dragStep = draggingItem.step;
            const hoverStep = item.step;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragStep < hoverStep && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragStep > hoverStep && hoverClientY > hoverMiddleY) {
                return;
            }

            changeCardSteps(dragStep, hoverStep);
            draggingItem.step = hoverStep;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'STEP_CARD',
        item: { id: item.id, step: item.step, position: item.position, tabIndex: item.tabIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handleCardDelete = (id, step) => {
        setData((prevData) => {
            const newData = prevData.map((item) => {
                if (item.id === id) {
                    const { step, position, ...rest } = item;
                    return {
                        ...rest,
                        position: 'sidebar',
                    };
                } else if (item.step > step) {
                    return { ...item, step: item.step - 1 };
                } else return item;
            });

            return newData;
        });
    };

    drag(drop(ref));

    return (
        <StepCardContainer step={item.step}>
            <StepCard
                item={item}
                step={item.step}
                ref={ref}
                isDragging={isDragging}
                handlerId={handlerId}
                referenceOptions={referenceOptions}
                reference={reference}
                handleReferenceChange={handleReferenceChange}
                handleCardDelete={handleCardDelete}
            />
        </StepCardContainer>
    );
};

export default DraggableStepCard;
