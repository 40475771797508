import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import client from '../../services/library-api';
import * as API from '../../constants/api';

import Button from '../../design-system/Button/Button';
import Alert from '../../design-system/Alert/Alert';
import { Input } from '../../design-system';
import {
    ArrowGoBackLineIcon,
    ErrorWarningLineIcon,
    FlowChartIcon,
} from '../../design-system/Icons';
import { defaultErrorMessage } from '../../constants/errorMessages';

const CreateNewPromptChainPage = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const chainsSearchParams = location.state?.chainsSearchParams || '';

    const [formData, setFormData] = useState({ name: null });
    const [formDataError, setFormDataError] = useState({});
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });
    const [isCreatePromptChainLoading, setIsCreatePromptChainLoading] = useState(false);

    const handleInputChange = (e) => {
        setFormData((prevData) => ({ ...prevData, name: e.target?.value }));
        if (formDataError.name) {
            setFormDataError((prevState) => ({ ...prevState, name: false }));
        }
    };

    const handleBackPressed = () => {
        navigate(`/chains${chainsSearchParams}`);
    };

    const handleCreatePromptChain = async () => {
        if (!formData.name) {
            setFormDataError({ name: true });
            return;
        }
        try {
            setIsCreatePromptChainLoading(true);
            const requestData = {
                name: formData.name,
                description: `This is a prompt chain for ${formData.name}`,
            };
            const { data } = await client.post(`${API.ROUTES.library.workflow}`, requestData);
            setIsCreatePromptChainLoading(false);

            if (data.id) {
                navigate(`/chain/${data.id}/`);
            }
        } catch (e) {
            if (
                e.response.data?.name &&
                e.response.data?.name?.includes('You already have a goal with this name.')
            ) {
                setErrorAlert({
                    show: true,
                    message: 'You already have a Prompt Chain with this name.',
                });
            } else {
                setErrorAlert({ show: true, statusCode: e.response.status });
            }
            setIsCreatePromptChainLoading(false);
        }
    };

    return (
        <div className="bg-white w-full flex justify-center h-full px-[14px] pb-[40px] min-h-[calc(100vh-64px)]">
            <div className="w-full max-w-[800px] py-5">
                <div className="mb-[20px]">
                    <Button
                        type="link"
                        size="sm"
                        text="Back to Prompt Chains"
                        onClick={handleBackPressed}
                        leadingIcon={ArrowGoBackLineIcon}
                    />
                </div>
                <div className="flex flex-col gap-[32px]">
                    <h1 className="font-heading-bold text-heading-bold-s text-black">
                        Create New Prompt Chain
                    </h1>
                    <Input
                        size="sm"
                        name="name"
                        value={formData.name}
                        label="Give your Prompt Chain a name"
                        isRequired
                        placeholder="Prompt chain name"
                        onChange={handleInputChange}
                        state={formDataError.name ? 'error' : 'default'}
                        errorMessage={'Please fill in this field'}
                    />
                    <div>
                        <Button
                            type="primary"
                            size="sm"
                            text="Create Prompt Chain"
                            state={isCreatePromptChainLoading ? 'loading' : 'default'}
                            leadingIcon={FlowChartIcon}
                            onClick={handleCreatePromptChain}
                        />
                    </div>
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </div>
    );
};

export default CreateNewPromptChainPage;
