import { LIBRARY_SORT_TYPES, LIBRARY_SORT_TYPES_LABELS, TASK_TYPES } from './library';
import { getTaskTypeBadge } from '../helpers/getTaskTypeBadge';

export const LIBRARY_SORT_INDEX_LOOKUP = [
    LIBRARY_SORT_TYPES.alphabetical,
    LIBRARY_SORT_TYPES.created,
    LIBRARY_SORT_TYPES.lastRun,
];

export const sortItems = [
    {
        text: LIBRARY_SORT_TYPES_LABELS.alphabetical,
        value: LIBRARY_SORT_TYPES.alphabetical,
    },
    {
        text: LIBRARY_SORT_TYPES_LABELS.created,
        value: LIBRARY_SORT_TYPES.created,
    },
    {
        text: LIBRARY_SORT_TYPES_LABELS.lastRun,
        value: LIBRARY_SORT_TYPES.lastRun,
    },
];

export const LIBRARY_FILTER_INDEX_LOOKUP = [
    TASK_TYPES.prompt,
    TASK_TYPES.inbox,
    TASK_TYPES.assistant,
    TASK_TYPES.record,
    TASK_TYPES.retrieval,
    TASK_TYPES.trigger,
    TASK_TYPES.webhook,
    TASK_TYPES.api,
    TASK_TYPES.regex,
    TASK_TYPES.agent,
];

export const filterItems = LIBRARY_FILTER_INDEX_LOOKUP.map((taskType) => ({
    value: taskType,
    badgeInfo: getTaskTypeBadge({
        taskType,
        usePromptLabelInsteadOfModel: true,
        usePluralLabels: true,
    }),
}));
