import React, { useMemo } from 'react';
import { JOB_STATUS, JOB_TASK_BUCKET_STATUS } from '../../../../constants/jobs';

import StatusBuckets from '../StatusBuckets/StatusBuckets';

const JobTasksTabSection = ({ milestones, view }) => {
    const { pendingMilestones, processingMilestones, completedMilestones } = useMemo(() => {
        const { pendingMilestones, processingMilestones, completedMilestones } =
            groupMilestonesByStatusHeader(milestones);

        const allMilestonesLength = milestones.length;
        return {
            pendingMilestones: sortMilestonesByOrder(pendingMilestones, allMilestonesLength),
            processingMilestones: sortMilestonesByOrder(processingMilestones, allMilestonesLength),
            completedMilestones: sortMilestonesByOrder(completedMilestones, allMilestonesLength),
        };
    }, [milestones]);

    function groupMilestonesByStatusHeader(milestones) {
        return milestones.reduce(
            (acc, milestone) => {
                switch (milestone.status) {
                    case JOB_STATUS.pending:
                        acc.pendingMilestones.push(milestone);
                        break;
                    case JOB_STATUS.started:
                        acc.processingMilestones.push(milestone);
                        break;
                    case JOB_STATUS.failure:
                        acc.processingMilestones.push(milestone);
                        break;
                    case JOB_STATUS.success:
                        acc.completedMilestones.push(milestone);
                        break;
                    default:
                        break;
                }
                return acc;
            },
            {
                pendingMilestones: [],
                processingMilestones: [],
                completedMilestones: [],
            }
        );
    }

    function sortMilestonesByOrder(milestones, allMilestonesLength) {
        return milestones.sort((a, b) => {
            // if order === null consider it as arrayLength to place this item at the bottom of the list
            return (a.order || allMilestonesLength) - (b.order || allMilestonesLength);
        });
    }

    const containerClassName = {
        column: 'flex gap-x-5 overflow-x-auto overflow-y-hidden absolute top-[56px] left-0 right-0 bottom-0',
        row: 'flex flex-col gap-5',
    };

    return (
        <div className={containerClassName[view]}>
            <StatusBuckets
                bucketStatus={JOB_TASK_BUCKET_STATUS.pending}
                milestones={pendingMilestones || []}
                view={view}
            />
            <StatusBuckets
                bucketStatus={JOB_TASK_BUCKET_STATUS.processing}
                milestones={processingMilestones || []}
                view={view}
            />
            <StatusBuckets
                bucketStatus={JOB_TASK_BUCKET_STATUS.completed}
                milestones={completedMilestones || []}
                view={view}
            />
        </div>
    );
};

export default JobTasksTabSection;
