import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../services/library-api';
import { PLAYBOOK_BUILDER_MODE } from '../../constants/playbookBuilder';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import PlaybookBuilderTopControls from './PlaybookBuilderTopControls/PlaybookBuilderTopControls';
import PlaybookBuilderIndex from './PlaybookBuilderIndex/PlaybookBuilderIndex';
import Loading from '../../components/Loading';

const PlaybookBuilderPage = () => {
    const params = useParams();
    const playbookId = Number(params.playbookId);

    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from || '/process';

    const [playbookDetail, setPlaybookDetail] = useState(null);

    const [pageTopControlsHeight, setPageTopControlsHeight] = useState(36);

    useEffect(() => {
        const fetchPlaybookData = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.library.playbook}${playbookId}/`);
                setPlaybookDetail(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        fetchPlaybookData();
    }, []);

    useDocumentTitle(playbookDetail?.name);

    const pageContainerClassName = classNames(
        'page-position pt-5 sm:pt-6 lg:pt-8 xl:pt-[40px] px-4 sm:px-5 min-[1124px]:px-[40px] xl:px-[60px] flex flex-col overflow-hidden transition-colors hide-scrollbar',
        {
            'bg-white': mode === PLAYBOOK_BUILDER_MODE.edit,
            'bg-neutral-50': mode === PLAYBOOK_BUILDER_MODE.view,
        }
    );

    return (
        <div className={pageContainerClassName}>
            {playbookDetail ? (
                <>
                    <PlaybookBuilderTopControls
                        backLinkHref={backLinkHref}
                        setPageTopControlsHeight={setPageTopControlsHeight}
                    />
                    <PlaybookBuilderIndex
                        playbookDetail={playbookDetail}
                        setPlaybookDetail={setPlaybookDetail}
                        mode={mode}
                        height={
                            pageTopControlsHeight
                                ? `calc(100% - ${pageTopControlsHeight}px)`
                                : 'auto'
                        }
                    />
                </>
            ) : (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default PlaybookBuilderPage;
