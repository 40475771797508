import React from 'react';
import { Button, Badge } from 'design-system';
import { FlashlightFillIcon, Settings6LineIcon } from 'design-system/Icons';
import { DateTime } from 'luxon';

const PromptDetailCard = ({ prompt, handleRunPrompt }) => {
    const halfdayPadding = 1000 * 60 * 60 * 12;
    const formattedTime = DateTime.fromISO(prompt.prompt_version_updated_at).toRelative({
        padding: halfdayPadding,
    });

    return (
        <div className="flex flex-col p-3 bg-white rounded-md">
            <div className="flex flex-row justify-between items-start">
                <div className="flex flex-row space-x-2 items-center">
                    <div className="font-body-bold text-body-bold-s text-neutral-500">
                        {prompt.name}
                    </div>
                </div>
                <Button
                    type="primary"
                    size="xs"
                    text="Run Prompt"
                    onClick={() => handleRunPrompt(prompt.id)}
                    leadingIcon={FlashlightFillIcon}
                />
            </div>
            <div className="flex flex-row space-x-2 items-center">
                <Badge
                    text={prompt.prompt_version_model}
                    color="neutral"
                    leadingIcon={Settings6LineIcon}
                />
                <div className="font-body text-body-regular-xs">Last Updated: {formattedTime}</div>
            </div>
        </div>
    );
};

export default PromptDetailCard;
