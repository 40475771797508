import React, { useState } from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { duplicateTask } from '../../../helpers/duplicateTask';
import { TASK_TYPES } from '../../../constants/library';
import useUser from '../../../hooks/useUser';
import { ORGANIZATION_PLAN } from '../../../constants/organization';
import { copyTaskConfig } from '../../../helpers/copyTaskConfig';

const TaskCardMoreOptions = ({
    task,
    swrKey,
    openActionModal,
    setDuplicateSuccessModal,
    setAlert,
    setShowMoreOptionsOpened,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { orgPlan } = useUser();

    const taskType = task.task_type;
    const taskId = task.id;
    const promptId = task.default_prompt?.id || null;
    const promptVersionId = task.default_prompt?.default_prompt_version.id || null;

    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);

    const navigateToViewPage = () => {
        if (taskType === TASK_TYPES.prompt) {
            navigate(`/library/prompt/${promptId}`, {
                state: { libraryLocation: location },
            });
        } else {
            navigate(`/library/task/${task.id}`, {
                state: { libraryLocation: location },
            });
        }
    };

    const handleDuplicateToMyLibrary = async () => {
        try {
            await duplicateTask({
                taskId,
                taskType,
                swrKey,
                promptId,
                promptVersionId,
                setAlert: setAlert,
                setIsLoading: setIsDuplicateLoading,
                setSuccessModal: setDuplicateSuccessModal,
            });
            setShowMoreOptionsOpened(false);
        } catch (error) {
            console.log('error', error);
        }
    };

    // copy task config to clipboard
    const handleCopyTaskConfig = () => {
        copyTaskConfig(task);
        setShowMoreOptionsOpened(false);
    };

    return (
        <div className="absolute top-[30px] right-[-4px] z-55">
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editIcon"
                            text="Edit & Run"
                            onClick={navigateToViewPage}
                        />
                        <ListOption
                            leadingIconName="shareBoxLine"
                            text="Share"
                            onClick={() => openActionModal('share')}
                        />
                        <ListOption
                            leadingIconName="addToLibrary"
                            text="Duplicate"
                            isLoading={isDuplicateLoading}
                            onClick={handleDuplicateToMyLibrary}
                        />
                        {orgPlan === ORGANIZATION_PLAN.agency && (
                            <ListOption
                                leadingIconName="fileCopyLineIcon"
                                text="Copy Config"
                                onClick={handleCopyTaskConfig}
                            />
                        )}
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={() => openActionModal('delete')}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    setShowMoreOptionsOpened(false);
                }}
            />
        </div>
    );
};

export default TaskCardMoreOptions;
