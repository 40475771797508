import React from 'react';
import CornerUpRightDoubleLineIcon from '../../../design-system/Icons/CornerUpRightDoubleLineIcon';
import FlowChartIcon from '../../../design-system/Icons/FlowChartIcon';

const EmptyIndexPage = ({ path }) => {
    return (
        <div className="flex flex-col py-[80px] items-center gap-4 self-stretch">
            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full">
                {path === 'runs' ? (
                    <CornerUpRightDoubleLineIcon width={32} height={32} color={'#5E6470'} />
                ) : (
                    <FlowChartIcon width={32} height={32} color={'#5E6470'} />
                )}
            </div>
            <div className="flex flex-col items-center gap-2">
                <p className="font-heading-bold text-heading-bold-m text-center">{`You don’t have any Prompt Chains ${
                    path === 'runs' ? 'to Run' : 'yet'
                }.`}</p>
                <p className="font-body text-bold-m text-center">
                    {path === 'runs'
                        ? 'Set up a Prompt Chain first, then you can run it.'
                        : 'Try creating one by clicking “New Prompt Chain”.'}
                </p>
            </div>
        </div>
    );
};

export default EmptyIndexPage;
