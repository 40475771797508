import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';

import ProcessCard from '../ProcessCard/ProcessCard';
import Loading from '../../../components/Loading';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';
import EmptyView from '../EmptyView/EmptyView';

const ConfigurePaginatedContainer = ({
    total,
    processes,
    loading,
    count,
    keysToMutate,
    createNewProcess,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = Number.parseInt(searchParams.get('page')) || 1;

    if (loading) {
        return (
            <div className="absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] flex items-center">
                <Loading />
            </div>
        );
    }

    if (total === 0) {
        return <EmptyView type="process" handleClick={createNewProcess} />;
    }

    const start = page - 1 === 0 ? 1 : (page - 1) * DEFAULT_LIMIT + 1;
    const end = total - start < count ? total : start + count - 1;
    const hasData = total > 0;

    const handleNextPressed = () => {
        setSearchParams({ page: page + 1 });
    };

    const handlePreviousPressed = () => {
        if (page > 0) {
            setSearchParams({ page: page - 1 });
        }
    };

    return (
        <div>
            {hasData && (
                <div className="max-md:overflow-x-auto">
                    <ul className="flex flex-col gap-2 max-md:min-w-[680px] max-md:w-[680px]">
                        {processes.map((process) => (
                            <ProcessCard
                                key={process.id}
                                process={process}
                                keysToMutate={keysToMutate}
                            />
                        ))}
                    </ul>
                </div>
            )}
            {hasData && (
                <div className="flex flex-col mt-[20px]">
                    <PaginationFooter
                        start={start}
                        end={end}
                        total={total}
                        onNextPressed={handleNextPressed}
                        onPreviousPressed={handlePreviousPressed}
                    />
                </div>
            )}
        </div>
    );
};

export default ConfigurePaginatedContainer;
