import React, { useState, useEffect } from 'react';
import { Button } from 'design-system';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowGoBackLineIcon } from 'design-system/Icons';
import client from 'services/library-api';
import CrudWorkflowComponent from 'components/CrudWorkflowContainer/CrudWorkflowContainer';
import CrudTaskComponent from 'components/CrudTaskContainer/CrudTaskContainer';
import { ModelOptions, TemperatureOptions } from 'constants';
import CrudPromptContainer from 'components/CrudPromptContainer/CrudPromptContainer';
import { useParams } from 'react-router-dom';
import useDocumentTitle from 'hooks/useDocumentTitle';

function CreatePromptPage() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const libraryLocation = location.state?.libraryLocation || '/library';

    const navigate = useNavigate();
    const [showNewWorkflow, setShowNewWorkflow] = useState(false);
    const [showNewTask, setShowNewTask] = useState(false);
    const [taskInputKeys, setTaskInputKeys] = useState('');
    const [taskOutputKeys, setTaskOutputKeys] = useState('');
    const [goals, setGoals] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [taskInputs, setTaskInputs] = useState([]);
    const [taskOutputs, setTaskOutputs] = useState([]);
    const [chosenGoalId, setChosenGoalId] = useState(
        query.get('workflow') ? parseInt(query.get('workflow')) : null
    );
    const [chosenTaskId, setChosenTaskId] = useState(
        query.get('task') ? parseInt(query.get('task')) : null
    );
    const inputBadges = taskInputKeys.split(',').map((item) => item.trim());
    const [promptSettings, setPromptSettings] = useState({
        model: ModelOptions[0],
        maxTokens: 1000,
        temperature: TemperatureOptions[0],
    });
    const [promptText, setPromptText] = useState('');
    const [promptName, setPromptName] = useState('');
    const [newGoal, setNewGoal] = useState('');
    const [newTask, setNewTask] = useState('');
    const params = useParams();

    useDocumentTitle('Create Prompt');

    useEffect(() => {
        client
            .get('goal-list/?ordering=name')
            .then(function (res) {
                setGoals(res.data.results);

                if (chosenGoalId && chosenTaskId) {
                    client
                        .get(`filtered-tasks-on-goal/${chosenGoalId}/?ordering=name`)
                        .then(function (res) {
                            setTasks(res.data.results);
                            const task = res.data.results.find((task) => task.id === chosenTaskId);
                            const inputKeys = task.inputs.map((input) => input.key).join(', ');
                            setTaskInputKeys(inputKeys);
                            setTaskInputs(task.inputs);
                            const outputKeys = task.outputs.map((output) => output.key).join(', ');
                            setTaskOutputKeys(outputKeys);
                            setTaskOutputs(task.outputs);
                        })
                        .catch(function (error) {
                            setTasks(null);
                        });
                }
            })
            .catch(function (error) {
                setGoals(null);
            });
    }, []);

    const handleNav = (id) => {
        navigate(`/library/prompt/${id}`, { state: { libraryLocation } });
    };

    const handleGoalChoice = (goalId) => {
        console.log(goalId);
        setChosenGoalId(goalId);
        client
            .get(`filtered-tasks-on-goal/${goalId}/?ordering=name`)
            .then(function (res) {
                setTasks(res.data.results);
                setTaskInputKeys('');
                setTaskOutputKeys('');
            })
            .catch(function (error) {
                setTasks(null);
            });
    };

    const getTasks = () => {
        client
            .get(`filtered-tasks-on-goal/${chosenGoalId}/?ordering=name`)
            .then(function (res) {
                setTasks(res.data.results);
                setTaskInputKeys('');
                setTaskOutputKeys('');
            })
            .catch(function (error) {
                setTasks(null);
            });
    };

    // everytime goalId changes, we need to reset the task choices
    useEffect(() => {
        if (chosenGoalId) {
            getTasks();
        }
    }, [chosenGoalId]);

    const handleTaskChoice = (taskId) => {
        setChosenTaskId(taskId);
        const task = tasks.find((task) => task.id === taskId);
        const inputKeys = task.inputs.map((input) => input.key).join(', ');
        setTaskInputKeys(inputKeys);
        setTaskInputs(task.inputs);
        const outputKeys = task.outputs.map((output) => output.key).join(', ');
        setTaskOutputKeys(outputKeys);
        setTaskOutputs(task.outputs);
    };

    const handleNewWorkflowTab = (showNewWorkflow) => {
        setShowNewWorkflow(!showNewWorkflow);
        setShowNewTask(true);
        setTasks([]);
        setChosenTaskId(null);
        setTaskInputKeys('');
        setTaskOutputKeys('');
    };

    const handleNewTaskTab = (showNewTask) => {
        setShowNewTask(!showNewTask);
        setChosenTaskId(null);
        setTaskInputKeys('');
        setTaskOutputKeys('');
    };

    const handleInputChange = (e) => {
        setTaskInputKeys(e.target.value);
        if (showNewTask) {
            const inputKeys = e.target.value.split(',').map((item) => item.trim());
            const inputs = inputKeys.map((item) => ({
                key: item,
                value: '',
                type: 'str',
                name: item.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
            }));
            setTaskInputs(inputs);
        } else {
            return;
        }
    };

    const handleOutputChange = (e) => {
        setTaskOutputKeys(e.target.value);
        if (showNewTask) {
            const outputKeys = e.target.value.split(',').map((item) => item.trim());
            const outputs = outputKeys.map((item) => ({
                key: item,
                value: '',
                type: 'str',
                name: item.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
            }));
            setTaskOutputs(outputs);
        }
    };

    const handlePromptTextChange = (e) => {
        setPromptText(e.target.value);
    };

    const handlePromptNameChange = (e) => {
        setPromptName(e.target.value);
    };

    const handleNewGoalChange = (e) => {
        setNewGoal(e.target.value);
    };

    const handleNewTaskChange = (e) => {
        setNewTask(e.target.value);
    };

    const setGoalData = () => {
        if (showNewWorkflow) {
            return {
                name: newGoal,
                description: `This is a workflow for ${newGoal}`,
            };
        } else {
            return {
                id: chosenGoalId,
            };
        }
    };

    const setTaskData = () => {
        if (showNewTask) {
            return {
                name: newTask,
                description: `This is a task for ${newTask}`,
                inputs: taskInputs,
                outputs: taskOutputs,
            };
        } else {
            return {
                id: chosenTaskId,
            };
        }
    };

    const setPromptData = () => {
        return {
            name: promptName,
            description: `This is a prompt for ${promptName}`,
        };
    };

    const CreatePrompt = () => {
        const promptData = {
            goal: setGoalData(),
            task: setTaskData(),
            prompt: setPromptData(),
            settings: {
                model: promptSettings.model.value,
                provider: promptSettings.model.provider,
                type: promptSettings.model.type,
                max_tokens: parseInt(promptSettings.maxTokens),
                temperature: parseFloat(promptSettings.temperature.value),
            },
            messages: [{ role: 'user', content: promptText }],
        };
        client
            .post('create-prompt/', promptData)
            .then(function ({ data }) {
                handleNav(data.id);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handleBackPressed = () => {
        navigate(libraryLocation);
    };

    return (
        <div className="flex flex-col bg-white px-8 py-4 w-full items-center">
            <div className="w-200 items-start flex flex-col space-y-10 min-h-screen">
                <Button
                    type="link"
                    size="sm"
                    text="Back to Library"
                    onClick={handleBackPressed}
                    leadingIcon={ArrowGoBackLineIcon}
                />
                <div className="font-heading-bold text-heading-bold-m">
                    Let's create a new prompt!
                </div>
                <CrudWorkflowComponent
                    handleNewWorkflowTab={handleNewWorkflowTab}
                    workflowTabIndex={showNewWorkflow ? 1 : 0}
                    showNewWorkflow={showNewWorkflow}
                    newGoal={newGoal}
                    handleNewGoalChange={handleNewGoalChange}
                    goals={goals}
                    chosenGoalId={chosenGoalId}
                    handleGoalChoice={handleGoalChoice}
                />
                <CrudTaskComponent
                    handleNewTaskTab={handleNewTaskTab}
                    taskTabIndex={showNewTask ? 1 : 0}
                    showNewTask={showNewTask}
                    newTask={newTask}
                    handleNewTaskChange={handleNewTaskChange}
                    tasks={tasks}
                    chosenTaskId={chosenTaskId}
                    handleTaskChoice={handleTaskChoice}
                    taskInputKeys={taskInputKeys}
                    handleInputChange={handleInputChange}
                    taskOutputKeys={taskOutputKeys}
                    handleOutputChange={handleOutputChange}
                    inputBadges={inputBadges}
                />
                <CrudPromptContainer
                    promptName={promptName}
                    handlePromptNameChange={handlePromptNameChange}
                    promptText={promptText}
                    handlePromptTextChange={handlePromptTextChange}
                    promptSettings={promptSettings}
                    setPromptSettings={setPromptSettings}
                />
                <div className="w-full flex justify-end">
                    <Button type="primary" size="md" text="Create Prompt" onClick={CreatePrompt} />
                </div>
            </div>
        </div>
    );
}

export default CreatePromptPage;
