import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { capitalizeFirstLetter } from '../../../../services/strings';
import { JOB_TASK_BUCKET_STATUS_ICON } from '../../../../constants/jobs';

import EyeIcon from '../../../../design-system/Icons/EyeIcon';
import ToolTip from '../../../../design-system/ToolTip/ToolTip';
import UserBadge from '../../../../design-system/UserBadge/UserBadge';
import { Badge, Button, ButtonIcon } from '../../../../design-system';
import { CloseLineIcon } from '../../../../design-system/Icons';

const JobTaskPanelHeader = ({ milestone, bucketStatus, onClose }) => {
    const { name, participants, created_at, updated_at, description } = milestone;

    const [isToolTipShown, setIsToolTipShown] = useState(false);

    const details = [
        {
            label: 'Started At',
            value: DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm a'),
        },
        {
            label: 'Updated At',
            value: DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a'),
        },
    ];

    if (description) {
        details.push({ label: 'Description', value: description });
    }

    const detailLabelClassName = 'font-body text-body-regular-xs text-neutral-300 min-w-[124px]';
    const detailValueClassName = 'font-body-bold text-body-bold-xs text-neutral-500';

    const bucketStatusBadgeIcon = {
        pending: 'neutral',
        processing: 'peach',
        completed: 'lime',
    };

    const bucketStatusBadgeText = (
        <p className="text-neutral-500">{capitalizeFirstLetter(bucketStatus)}</p>
    );

    return (
        <div className="flex flex-col gap-5 mb-5 border-b-1 border-neutral-200">
            <div className="flex justify-between gap-4 items-center">
                <ToolTip
                    text="Get notifications for this job task"
                    position="center-left"
                    isShown={isToolTipShown}
                >
                    <div
                        onMouseEnter={() => setIsToolTipShown(true)}
                        onMouseLeave={() => setIsToolTipShown(false)}
                    >
                        <Button
                            type="neutral"
                            size="sm"
                            text="Subscribe"
                            state="disabled"
                            leadingIcon={EyeIcon}
                            onClick={() => {}}
                        />
                    </div>
                </ToolTip>
                <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
            </div>
            <p className="font-heading-regular text-heading-regular-m text-neutral-500">{name}</p>
            <div className="py-2.5 flex flex-col gap-4">
                <div className="flex items-center gap-1">
                    <p className={detailLabelClassName}>Status</p>
                    <Badge
                        text={bucketStatusBadgeText}
                        color={bucketStatusBadgeIcon[bucketStatus]}
                        leadingIcon={JOB_TASK_BUCKET_STATUS_ICON[bucketStatus]}
                        leadingIconColor="#1F2125"
                    />
                </div>

                <div className="flex items-center gap-1">
                    <p className={detailLabelClassName}>Participants</p>
                    {!!participants.length && (
                        <ul className="flex items-center gap-1 flex-wrap max-w-[calc(100%-128px)]">
                            {participants.map(({ id, first_name, email }) => {
                                const name = first_name || email;
                                return (
                                    <li
                                        key={id}
                                        className="flex items-center gap-1 whitespace-nowrap truncate"
                                    >
                                        <UserBadge name={name} />
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {!participants.length && (
                        <p className="font-body text-body-regular-xs text-neutral-500 italic">
                            None
                        </p>
                    )}
                </div>

                {details.map(({ label, value }) => (
                    <div key={label} className="flex items-center gap-1">
                        <p className={detailLabelClassName}>{label}</p>
                        <p className={detailValueClassName}>{value}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JobTaskPanelHeader;
