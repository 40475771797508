import React from 'react';
import PropTypes from 'prop-types';
import TabItem from './TabItem';

export default function Tabs({ tabs, tabIndex, onTabChanged, vertical }) {
    function onTabPressed(tab, index) {
        onTabChanged && onTabChanged(tab, index);
    }

    const containerClasses = `border rounded-lg border-neutral-500 bg-neutral-500 flex ${
        vertical
            ? 'flex-col items-start min-w-[200px] max-w-[335px]'
            : 'flex-row justify-center items-center'
    } p-0.5 gap-[2px]`;

    return (
        <div className={containerClasses}>
            {tabs.map((tab, index) => (
                <TabItem
                    key={index}
                    onClick={() => onTabPressed(tab, index)}
                    vertical={vertical}
                    shortcut={tab.shortcut}
                    name={tab.name}
                    current={tabIndex === index}
                />
            ))}
        </div>
    );
}

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            shortcut: PropTypes.string,
        })
    ).isRequired,
    tabIndex: PropTypes.number,
    onTabChanged: PropTypes.func.isRequired,
    vertical: PropTypes.bool,
};
