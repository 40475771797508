import React from 'react';
import { capitalizeFirstLetter } from 'services/strings';

import SharedWithIcon from '../../../design-system/Icons/SharedWithIcon';
import { Button } from '../../../design-system';
import { AddCircleLineIcon } from '../../../design-system/Icons';

const EmptyView = ({ type, handleClick }) => {
    const capitalizedType = capitalizeFirstLetter(type);

    const errorSnippet = {
        process: 'Processes yet.',
        goal: 'Goals in this Process yet.',
    };

    return (
        <div className="py-[80px]">
            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full mb-4 mx-auto">
                <SharedWithIcon width={32} height={32} color="#5E6470" />
            </div>
            <div className="flex flex-col items-center gap-1 text-black mb-4">
                <p className="font-heading-bold text-heading-bold-m text-center max-w-[400px]">
                    You don’t have any {errorSnippet[type]}
                </p>
                <p className="font-body text-body-regular-m max-w-[400px] text-center">
                    Try creating one by clicking “New {capitalizedType}”.
                </p>
            </div>
            <div className="flex justify-center">
                <Button
                    type="primary"
                    size="xs"
                    text={`New ${capitalizedType}`}
                    leadingIcon={AddCircleLineIcon}
                    onClick={handleClick}
                />
            </div>
        </div>
    );
};

export default EmptyView;
