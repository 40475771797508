export const parseProcessPageSearchParams = (searchParams) => {
    const sort = searchParams.get('sort');
    const search = searchParams.get('search');
    const filter = searchParams.get('filter');

    const result = { sort };

    if (search) {
        result.search = search;
    }

    if (filter) {
        const parsedGroups = filter.split(',')?.map((id) => +id) || [];
        result.filter = parsedGroups;
    }

    return result;
};
