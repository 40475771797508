import React from 'react';

import ProcessCard from '../ProcessCard/ProcessCard';
import BlockHeader from '../BlockHeader/BlockHeader';
import Loading from '../../../components/Loading';
import { Button } from '../../../design-system';

const YourTopProcessesBlock = ({ processes, loading, loadNextProcessesPage, hasNextPage }) => {
    return (
        <div className="flex flex-col gap-5">
            <BlockHeader
                title="Your Top Processes"
                description="Explore the frequently utilized processes, click on ”view all” to access the
                        complete list."
                viewAllNavPath="/process"
            />

            {!!processes.length && (
                <div className="grid grid-cols-1 min-[600px]:grid-cols-2 sm:grid-cols-1 gap-x-3 gap-y-4 min-[704px]:grid-cols-2 lg:grid-cols-3 l:gap-x-4 l:gap-y-5 min-[1400px]:grid-cols-4">
                    {processes.map((process) => (
                        <ProcessCard key={process.id} process={process} />
                    ))}
                </div>
            )}
            {!processes.length && (
                <p className="font-body text-body-regular-m text-neutral-400">
                    Currently, there are no top processes.
                </p>
            )}

            {hasNextPage && !loading && (
                <Button type="link" size="sm" text="View More" onClick={loadNextProcessesPage} />
            )}

            {loading && <Loading withText={false} />}
        </div>
    );
};

export default YourTopProcessesBlock;
