import React from 'react';
import PropTypes from 'prop-types';
import './Loading.css';

const defaultText = "We're cooking up something good...";
const Loading = ({ withText = true, text: _text }) => {
    const text = _text || defaultText;
    return (
        <div className="loading">
            <div className="spinner"></div>
            {withText && <p>{text}</p>}
        </div>
    );
};

Loading.propTypes = {
    withText: PropTypes.bool,
    text: PropTypes.string,
};

export default Loading;
