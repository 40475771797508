export const THREADS_SORT_TYPES = {
    created: '-created_at',
    updated: '-updated_at',
    alphabetical: 'name',
};

export const THREADS_SORT_TYPES_LABELS = {
    created: 'Most Recently Created',
    updated: 'Most Recently Updated',
    alphabetical: 'Alphabetical',
};

export const threadStatusBadge = {
    pending: { text: 'Pending', color: 'neutral' },
    started: { text: 'In Progress', color: 'peach' },
    success: { text: 'Completed', color: 'lime' },
    failure: { text: 'Failed', color: 'red' },
    canceled: { text: 'Canceled', color: 'neutral' },
    archived: { text: 'Archived', color: 'neutral' },
};

export const sortOptions = [
    {
        text: THREADS_SORT_TYPES_LABELS.created,
        value: THREADS_SORT_TYPES.created,
    },
    {
        text: THREADS_SORT_TYPES_LABELS.updated,
        value: THREADS_SORT_TYPES.updated,
    },
    {
        text: THREADS_SORT_TYPES_LABELS.alphabetical,
        value: THREADS_SORT_TYPES.alphabetical,
    },
];
