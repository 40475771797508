import { createContext, useState } from 'react';

export const NavigationRestrictionContext = createContext();

export const NavigationRestrictionProvider = ({ children }) => {
    const [isNavigationRestricted, setIsNavigationRestricted] = useState(false);

    return (
        <NavigationRestrictionContext.Provider
            value={{
                isNavigationRestricted,
                setIsNavigationRestricted,
            }}
        >
            {children}
        </NavigationRestrictionContext.Provider>
    );
};
