import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { getTaskTypeBadge } from '../../../helpers/getTaskTypeBadge';
import { TASK_TYPES } from '../../../constants/library';

import CollapsibleStatusCardContainer from '../CollapsibleStatusCardContainer/CollapsibleStatusCardContainer';
import { Badge, Button } from '../../../design-system';
import NewTextAreaBox from '../../../design-system/NewTextAreaBox/NewTextAreaBox';
import { formatOutputValue } from '../../../helpers/formatOutputValue';
import OutputRatingControls from '../../../components/OutputRatingControls/OutputRatingControls';
import ErrorMessage from '../../../design-system/ErrorMessage/ErrorMessage';

const TaskRunCard = ({ step, workerId, setThread, setShareThreadFeedbackModal }) => {
    const { task, status, order, errors, inputs, outputs, result, updated_at } = step;
    const { width: screenWidth } = useWindowSize();
    const navigate = useNavigate();

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');

    const badge = getTaskTypeBadge({
        taskType: task.task_type,
        usePromptLabelInsteadOfModel: true,
    });

    const onSuccessTaskResultRatingUpdate = (data) => {
        // open a Thread Feedback Modal
        setShareThreadFeedbackModal({ opened: true, state: 'filled', initialRating: data.rating });
    };

    const updateRating = (newRating) => {
        // in threads state find a corresponded worker and step and then replace rating
        setThread((prevData) => ({
            ...prevData,
            workers: prevData.workers.map((worker) => {
                if (worker.id !== workerId) {
                    return worker;
                }
                return {
                    ...worker,
                    config: worker.config.map((step) => {
                        if (step.order !== order) {
                            return step;
                        }
                        return { ...step, result: { ...step.result, rating: newRating } };
                    }),
                };
            }),
        }));
    };

    const handleViewNav = () => {
        if (task.task_type === TASK_TYPES.prompt) {
            navigate(`/library/prompt/${task.default_prompt_id}`);
        } else {
            navigate(`/library/task/${task.id}`);
        }
    };

    // header of the card (collapsed view)
    const stepInfoBlock = (
        <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1.5">
                <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase">
                    Task {order}
                </p>
                {badge && (
                    <div className="sm:hidden">
                        <Badge
                            text={<span className="text-body-bold-xs">{badge.label}</span>}
                            color={badge.color}
                            leadingIcon={badge.icon}
                            leadingIconColor={badge.iconColor}
                        />
                    </div>
                )}
            </div>

            <div className="flex items-center gap-2">
                {badge && (
                    <div className="hidden sm:block">
                        <Badge
                            text={<span className="text-body-bold-xs">{badge.label}</span>}
                            color={badge.color}
                            leadingIcon={badge.icon}
                            leadingIconColor={badge.iconColor}
                        />
                    </div>
                )}
                <p className="font-body text-body-regular-s text-neutral-500 flex-grow line-clamp-1">
                    {task.name}
                </p>
            </div>

            <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2">
                {task.description}
            </p>

            {!!errors?.length && (
                <>
                    {errors.map((error, index) => (
                        <ErrorMessage
                            key={index}
                            message={error.message}
                            highlightedStart={error.type}
                        />
                    ))}
                </>
            )}
        </div>
    );

    // main card content (task inputs, outputs and rating) (expanded view)
    const expandedTaskBlock = (
        <div className="px-3 sm:px-4 pb-3 bg-white rounded-b-2">
            <div className="py-4 sm:border-t-1 border-[#D9D9D9]">
                <div className="flex flex-col gap-3">
                    {!!inputs.length &&
                        inputs.map((input) => {
                            const badge = {
                                text: (
                                    <>
                                        <span className="font-body text-body-bold-xs">Input: </span>
                                        {input.label}
                                    </>
                                ),
                                color: 'peach',
                            };
                            return (
                                <div key={input.id} className="flex flex-col gap-1.5 items-start">
                                    <NewTextAreaBox
                                        name={input.id}
                                        value={input.value}
                                        size="sm"
                                        state={input?.error ? 'error' : 'disabled'}
                                        readOnlyWhileError
                                        errorMessage={input?.error?.message}
                                        errorMessageHighlightedStart={input?.error?.type}
                                        withCopyButton={true}
                                        labelBadge={badge}
                                        lightBorder
                                    />
                                </div>
                            );
                        })}
                    {!!outputs.length &&
                        outputs.map((output) => {
                            const badge = {
                                text: (
                                    <>
                                        <span className="font-body text-body-bold-xs">
                                            Output:{' '}
                                        </span>
                                        {output.label}
                                    </>
                                ),
                                color: 'blue',
                            };
                            return (
                                <div key={output.id} className="flex flex-col gap-1.5 items-start">
                                    <NewTextAreaBox
                                        name={output.id}
                                        value={formatOutputValue(output.value)}
                                        size="sm"
                                        state="disabled"
                                        withCopyButton={true}
                                        labelBadge={badge}
                                        lightBorder
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="pt-3 border-t-1 border-neutral-200 flex gap-2 justify-between sm:justify-end">
                <OutputRatingControls
                    resultId={result.id}
                    outputRating={result.rating}
                    view="buttonIcon"
                    updateOutputRating={updateRating}
                    onSuccessRatingUpdate={onSuccessTaskResultRatingUpdate}
                />
                <Button
                    type="ghost"
                    size={screenWidth >= 640 ? 'sm' : 'xs'}
                    text="View Task"
                    onClick={handleViewNav}
                />
            </div>
        </div>
    );

    return (
        <CollapsibleStatusCardContainer
            status={status}
            lastUpdatedInfo={formattedTime}
            collapsedCardBlock={stepInfoBlock}
            expandedCardBlock={expandedTaskBlock}
        />
    );
};

export default TaskRunCard;
