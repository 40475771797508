import { useEffect, useState } from 'react';
import WebSocketInstance from '../services/websocket';

// in order to use this hook add message type to static typesForListeningLoadingState array in WebSocketService at /services/websocket.js
export const useWebSocketListeningLoadingState = ({
    messageType,
    onSuccess,
    onError,
    shouldConnectWebsocket = true, // if WebSocket connection on the page occurs conditionally, use the prop 'shouldConnectWebSocket'
}) => {
    // requestUuid is used for websocket messages
    const [requestUuid, setRequestUuid] = useState(null);

    const callback = (data) => {
        const { message } = data;

        if (message.status === 'error') {
            onError(message);
            setRequestUuid(null);
        }

        if (message.status === 'success') {
            onSuccess(message);
            setRequestUuid(null);
        }
    };

    useEffect(() => {
        if (shouldConnectWebsocket) {
            WebSocketInstance.connect();
            waitForSocketConnection(() => {
                WebSocketInstance.addCallbackForSpecificType(messageType, callback);
            });
        }

        return () => {
            if (WebSocketInstance.isOpen()) {
                WebSocketInstance.disconnect();
            }
        };
    }, [shouldConnectWebsocket]);

    useEffect(() => {
        WebSocketInstance.setCurrentRequestUuid(requestUuid);
    }, [requestUuid]);

    const waitForSocketConnection = (callback) => {
        const checkConnection = () => {
            if (WebSocketInstance.state() === 1) {
                console.log('connection is secure');
                callback();
            } else {
                setTimeout(checkConnection, 300);
            }
        };

        checkConnection();
    };

    const tryReconnectIfWebsocketClosed = () => {
        if (!WebSocketInstance.isOpen()) {
            WebSocketInstance.attempt_reconnect();
        }
    };

    return { setRequestUuid, tryReconnectIfWebsocketClosed };
};
