import React from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { parseThreadsSearchParams } from '../../../helpers/parseThreadsSearchParams';

import { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';
import ThreadCard from '../ThreadCard/ThreadCard';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';
import Loading from '../../../components/Loading';

const ThreadsDesktopPaginatedContainer = ({ threads, total, count, pageIndex, loading }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parseThreadsSearchParams(searchParams);

    const location = useLocation();

    const start = pageIndex * DEFAULT_LIMIT + 1;
    const end = total - start < count ? total : start + count - 1;

    const handleNextPressed = () => {
        setSearchParams({ ...parsedParams, page: parsedParams.page + 1 });
    };

    const handlePreviousPressed = () => {
        if (parsedParams.page > 0) {
            setSearchParams({ ...parsedParams, page: parsedParams.page - 1 });
        }
    };

    return (
        <>
            {!!total && (
                <div className="flex flex-col gap-6">
                    <div className="w-full flex flex-col gap-2">
                        {threads.map((thread) => (
                            <Link
                                key={thread.id}
                                to={`/thread/${thread.id}`}
                                state={{ from: location }}
                                className="cursor-pointer"
                            >
                                <ThreadCard id={thread.id} thread={thread} />
                            </Link>
                        ))}
                    </div>

                    <div className="flex flex-col">
                        <PaginationFooter
                            start={start}
                            end={end}
                            total={total}
                            onNextPressed={handleNextPressed}
                            onPreviousPressed={handlePreviousPressed}
                        />
                    </div>
                </div>
            )}
            {!total && loading && (
                <div className="pt-5">
                    <Loading />
                </div>
            )}
        </>
    );
};

export default ThreadsDesktopPaginatedContainer;
