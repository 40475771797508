import React, { useEffect, useRef, useState } from 'react';
import { checkIfAllRequiredFieldsFilled } from '../../../../helpers/settingsFormUtils';

import SettingsForm from '../../../CustomizeGoalPage/SettingsForm/SettingsForm';
import InstructionsModal from '../../../../components/InstructionsModal/InstructionsModal';
import ErrorBanner from '../../../../design-system/ErrorBanner/ErrorBanner';
import { Button } from '../../../../design-system';

const SettingStepForm = ({
    stepFormData,
    setStepFormData,
    stepOrder,
    setAreStepsRequiredFieldsFilled,
    currentStepErrorData,
    instructions,
    tabContainerRef,
}) => {
    const viewInstructionButtonRef = useRef(null);
    const [isInstructionModalOpened, setIsInstructionModalOpened] = useState(false);

    useEffect(() => {
        // when a field of the current step is changed, it checks the stepFormData to determine if any field was removed.
        setAreStepsRequiredFieldsFilled((prevData) => {
            const updatedFieldState = checkIfAllRequiredFieldsFilled(stepFormData);

            // ff the updatedFieldState hasn't changed, return the previous state (prevData) to avoid unnecessary changes to the areStepsRequiredFieldsFilled state.
            if (prevData[stepOrder] === updatedFieldState) {
                return prevData;
            }

            return {
                ...prevData,
                [stepOrder]: updatedFieldState,
            };
        });
    }, [stepFormData]);

    return (
        <div className="flex flex-col gap-5">
            {currentStepErrorData?.errorMessage && (
                <ErrorBanner errorMessage={currentStepErrorData.errorMessage} />
            )}

            <SettingsForm formData={stepFormData} setFormData={setStepFormData} containerGap={20} />

            {instructions && (
                <div className="relative flex justify-end" ref={viewInstructionButtonRef}>
                    <Button
                        type="ghost"
                        size="xs"
                        text="View Instructions"
                        onClick={() => setIsInstructionModalOpened(true)}
                    />
                    {isInstructionModalOpened && (
                        <InstructionsModal
                            instructions={instructions}
                            description="Here are the instructions for this step."
                            pageContainerRef={tabContainerRef}
                            viewInstructionButtonRef={viewInstructionButtonRef}
                            topValue={42}
                            onClose={(e) => {
                                e?.stopPropagation();
                                setIsInstructionModalOpened(false);
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default SettingStepForm;
