import React, { useEffect, useState } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Link, useLocation } from 'react-router-dom';
import { mergeNewDataIntoCurrent } from '../../../helpers/mergeNewDataIntoCurrent';

import ThreadCard from '../ThreadCard/ThreadCard';
import { Button } from '../../../design-system';
import Loading from '../../../components/Loading';

const ThreadsMobilePaginatedContainer = ({
    data,
    shouldSetNewData,
    setShouldSetNewData,
    canLoadMore,
    setPage,
    loading,
    total,
}) => {
    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 850;

    const location = useLocation();

    const [threads, setThreads] = useState([]);

    useEffect(() => {
        setThreads((prevResults) => {
            if (data === null && !shouldSetNewData) {
                return prevResults;
            }

            // clear threads when user submit search in SearchBar, or change sorting and new data is loading
            if (shouldSetNewData && data === null) {
                return [];
            }

            // when user submit search in SearchBar, or change sorting, we don't have to merge new Data into current, but we should replace them
            if (shouldSetNewData && data !== null) {
                setShouldSetNewData(false);
                return data;
            }

            return mergeNewDataIntoCurrent(prevResults || [], data || []); // when user click show more
        });
    }, [data]);

    if (!isMobile) {
        return <></>;
    }

    return (
        <div className="w-full flex flex-col gap-5">
            {!!threads.length && (
                <div className="w-full flex flex-col gap-2">
                    {threads.map((thread) => (
                        <Link
                            key={thread.id}
                            to={`/thread/${thread.id}`}
                            state={{ from: location }}
                            className="cursor-pointer"
                        >
                            <ThreadCard id={thread.id} thread={thread} />
                        </Link>
                    ))}
                </div>
            )}
            {canLoadMore && total !== threads?.length && (
                <Button
                    type="link"
                    size="xs"
                    text="Load More"
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                />
            )}
            {loading && (
                <div className="pt-3">
                    <Loading withText={!threads.length} />
                </div>
            )}
        </div>
    );
};

export default ThreadsMobilePaginatedContainer;
