import React from 'react';

const TaskCard = ({ task, isDragging }) => {
    return (
        <div
            className={`w-full p-[16px] bg-white rounded-2 ${
                isDragging ? 'opacity-30' : 'opacity-100'
            }`}
        >
            <p className="font-body text-body-bold-xs text-neutral-500 mb-[8px]">{task.name}</p>
            <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2 mb-[8px]">
                {task.description}
            </p>
        </div>
    );
};

export default TaskCard;

// 'border-2 border-purple-300'
