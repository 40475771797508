import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../services/report-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import { Button, Input, SvgIcon } from '../../../design-system';
import EscapeIcon from '../../../design-system/Icons/EscapeIcon';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { useParams } from 'react-router-dom';

const EditTemplateNameModal = ({ name, onClose, setTemplateDetails }) => {
    const { templateId } = useParams();

    const [editedName, setEditedName] = useState(name);
    const [isNameInputEmpty, setIsNameInputEmpty] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleNameInputChange = (e) => {
        setEditedName(e.target.value);
        if (isNameInputEmpty) {
            setIsNameInputEmpty(false);
        }
    };

    const handleSave = async () => {
        if (!editedName) {
            setIsNameInputEmpty(true);
            return;
        }

        try {
            setIsLoading(true);

            await client.patch(`${API.ROUTES.report.template}${templateId}/`, { name: editedName });
            setTemplateDetails((prevData) => ({ ...prevData, name: editedName }));

            setIsLoading(false);
            onClose();
        } catch (e) {
            setErrorAlert({ statusCode: e.response?.status });
            setIsLoading(false);
        }
    };

    return (
        <Modal onClose={onClose} size="medium">
            <div className="p-2 flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <p className="font-heading-bold text-heading-bold-m text-neutral-400">
                        Edit Template Name
                    </p>
                    <button onClick={onClose} type="button">
                        <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
                    </button>
                </div>
                <Input
                    size="md"
                    name="name"
                    value={editedName}
                    label="Report Name"
                    isRequired
                    state={isNameInputEmpty ? 'error' : 'default'}
                    errorMessage="Please fill in this field."
                    onChange={handleNameInputChange}
                />
                <div className="flex justify-center items-center gap-4">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Save Changes"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleSave}
                    />
                </div>
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default EditTemplateNameModal;
