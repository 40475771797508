export const parseThreadsSearchParams = (searchParams) => {
    const page = Number.parseInt(searchParams.get('page'));
    const sort = searchParams.get('sort');
    const process = Number(searchParams.get('process'));
    const search = searchParams.get('search');

    const result = { sort };

    if (page) {
        result.page = page;
    }
    if (search) {
        result.search = search;
    }
    if (process) {
        result.process = process;
    }

    return result;
};
