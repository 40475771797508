import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from '../../../hooks/useUser';

import { API } from 'constants';
import client from '../../../services/template-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import {
    checkIsFormDataValid,
    formatSettingsFormDataForRequestBody,
    getMappedSettingsFormErrors,
    getSettingsFormDataInitialState,
} from '../../../helpers/settingsFormUtils';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import ArrowLeftSLineIcon from '../../../design-system/Icons/ArrowLeftSLineIcon';
import SettingsForm from '../SettingsForm/SettingsForm';
import ErrorBanner from '../../../design-system/ErrorBanner/ErrorBanner';
import Alert from '../../../design-system/Alert/Alert';
import SubmissionLoader from '../../../design-system/SubmissionLoader/SubmissionLoader';

const CustomizeGoalSettingsBlock = ({ goalTemplateData, processId }) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [errorBannerMessage, setErrorBannerMessage] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const [settingsFormData, setSettingsFormData] = useState(
        getSettingsFormDataInitialState(goalTemplateData.settings)
    );

    const getRequestBody = () => {
        return {
            process: processId,
            organization: user?.organization?.id,
            settings: formatSettingsFormDataForRequestBody(settingsFormData),
        };
    };

    const handleAddGoal = async () => {
        setErrorBannerMessage(null);

        const isFormDataValid = checkIsFormDataValid(settingsFormData, setSettingsFormData);
        if (!isFormDataValid) {
            return;
        }

        try {
            setIsLoading(true);

            const requestBody = getRequestBody();
            const { data } = await client.post(
                `${API.ROUTES.template.goalDeploy}${goalTemplateData.id}/`,
                requestBody
            );

            navigate(`/configure/process/${data.process}/goal/${data.id}`);
        } catch (error) {
            console.log('error', error);
            setIsLoading(false);
            if (error.response?.data?.error && typeof error.response?.data?.error === 'string') {
                // handle general error
                setErrorBannerMessage(error.response.data.error);
            } else if (error.response?.data?.error?.settings) {
                // handle settings error
                const updatedFormData = getMappedSettingsFormErrors(error, settingsFormData);
                setSettingsFormData(updatedFormData);
            } else {
                setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.status });
            }
        }
    };

    return (
        <div className="py-8 px-4 flex justify-center">
            {!isLoading ? (
                <div className="bg-white p-4 sm:p-8 rounded-2xl w-full max-w-[800px] flex flex-col gap-7">
                    <div className="flex items-start justify-between max-[570px]:flex-wrap gap-x-5 gap-y-3.5">
                        <div>
                            <p className="font-heading-bold text-heading-bold-m text-black mb-2">
                                Customize Goal Settings
                            </p>
                            <p className="font-body text-body-regular-s text-neutral-400">
                                These settings will be used to customize this template to your
                                specific business needs.
                            </p>
                        </div>
                        {user?.is_staff && (
                            <button
                                type="button"
                                className="block font-body text-body-regular-s text-neutral-400 underline min-w-max"
                                onClick={() =>
                                    navigate(`/templates/goal/${goalTemplateData.id}`, {
                                        state: { from: location },
                                    })
                                }
                            >
                                View Template
                            </button>
                        )}
                    </div>

                    {errorBannerMessage && <ErrorBanner errorMessage={errorBannerMessage} />}

                    {!!settingsFormData?.length ? (
                        <SettingsForm
                            formData={settingsFormData}
                            setFormData={setSettingsFormData}
                        />
                    ) : (
                        <p className="font-body text-body-bold-s text-neutral-500">
                            No settings to customize. Click Add Goal to continue.
                        </p>
                    )}
                    <div className="py-4">
                        <div className="flex justify-between items-center pt-4 border-t-1 border-neutral-200">
                            <Button
                                type="link"
                                size="md"
                                text="Cancel"
                                leadingIcon={ArrowLeftSLineIcon}
                                onClick={() => navigate(`/configure/process/${processId}`)}
                            />
                            <Button
                                type="secondary"
                                size="md"
                                text="Add Goal"
                                onClick={handleAddGoal}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <SubmissionLoader text="We're building it!" />
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default CustomizeGoalSettingsBlock;
