import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { ButtonIcon } from '../../../design-system';
import FileTextIcon from '../../../design-system/Icons/FileTextIcon';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import EyeIcon from '../../../design-system/Icons/EyeIcon';

const GroupHeader = ({ group }) => {
    const { id, name, description, icon_color } = group;
    const { width: screenWidth } = useWindowSize();

    const navigate = useNavigate();
    const location = useLocation();

    const isUnsortedGroup = id === 0;

    const [isHeaderHovered, setIsHeaderHovered] = useState(false);
    const areActionButtonsShown = isHeaderHovered && !isUnsortedGroup && screenWidth >= 1024;

    const displayedDescription =
        isUnsortedGroup && screenWidth < 1024
            ? 'This group cannot be renamed. All unsorted processes are stored here.'
            : description;

    const handleNavToPlaybookPage = (mode) => {
        navigate(`/playbook/${id}?mode=${mode}`, { state: { from: location } });
    };

    return (
        <div
            className="flex-grow"
            onMouseEnter={() => setIsHeaderHovered(true)}
            onMouseLeave={() => setIsHeaderHovered(false)}
        >
            <div className="flex justify-between items-center gap-4 mb-1">
                <div className={`flex items-center gap-2 ${!isUnsortedGroup && 'min-h-[36px]'}`}>
                    {!isUnsortedGroup && <FileTextIcon color={icon_color} width={32} height={32} />}
                    <p className="font-heading-bold text-heading-bold-s text-black">{name}</p>
                </div>
                {areActionButtonsShown && (
                    <div className="flex items-center gap-0.5">
                        <ButtonIcon
                            type="grey"
                            size="xs"
                            icon={EditLineIcon}
                            onClick={() => handleNavToPlaybookPage('edit')}
                        />
                        <ButtonIcon
                            type="grey"
                            size="xs"
                            icon={EyeIcon}
                            onClick={() => handleNavToPlaybookPage('view')}
                        />
                    </div>
                )}
            </div>
            <p className="font-body text-body-regular-s text-neutral-400">{displayedDescription}</p>
        </div>
    );
};

export default GroupHeader;
