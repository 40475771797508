import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import classNames from 'classnames';
import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import CloseIcon from 'design-system/Icons/CloseIcon';
import CopyIcon from '../Icons/CopyIcon';
import Alert from '../Alert/Alert';
import { defaultErrorMessage } from '../../constants/errorMessages';
import CheckLineIcon from '../Icons/CheckLineIcon';
import { ErrorWarningLineIcon } from '../Icons';

Badge.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]).isRequired,
    leadingIcon: PropTypes.func,
    leadingIconColor: PropTypes.string,
    leadingEmoji: PropTypes.string,
    removeable: PropTypes.bool,
    handleRemove: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // is used for handleRemove
    color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red'])
        .isRequired,
    withBorder: PropTypes.bool,
    textToCopy: PropTypes.string, // if Badge includes a copy button, and you want to specify a different text to copy than the text prop value, provide the desired value for 'textToCopy'. If 'textToCopy' is empty, the text prop value will be used for copying.
    withCopyButton: PropTypes.bool,
};

function Badge(props) {
    const {
        text,
        leadingIcon,
        leadingEmoji,
        removeable,
        color,
        handleRemove,
        id,
        leadingIconColor,
        withBorder = false,
        textToCopy,
        withCopyButton = false,
    } = props;
    const [copyAlert, setCopyAlert] = useState(null);

    const badgeClass = classNames(
        'max-w-full inline-flex items-center gap-x-2 rounded-lg px-2 py-1 text-gray-600 ',
        {
            'bg-neutral-100': color === 'neutral',
            'bg-blue-100': color === 'blue',
            'bg-purple-100': color === 'purple',
            'bg-peach-100': color === 'peach',
            'bg-lime-100': color === 'lime',
            'bg-red-300': color === 'red',
            'bg-white': color === 'white',
            'border-1 border-neutral-200': withBorder,
        }
    );

    const trailingIcon = CloseIcon;

    // TODO: Fix hard coded font and match design system

    let leadingItem = null;
    if (leadingIcon) {
        leadingItem = (
            <SvgIcon icon={leadingIcon} size="medium" color={leadingIconColor || '#5E6470'} />
        );
    } else if (leadingEmoji) {
        leadingItem = (
            <Emoji unified={leadingEmoji} emojiStyle={EmojiStyle.APPLE} size={16} /> // TODO: 16 is same as medium for icon but how can we avoid hardcoding?
        );
    }

    const handleCopy = () => {
        navigator.clipboard
            .writeText(textToCopy || text)
            .then(() => {
                setCopyAlert({ status: 'positive', message: 'Copied to Clipboard!' });
            })
            .catch(() => {
                setCopyAlert({ status: 'critical', message: defaultErrorMessage });
            });
    };

    return (
        <>
            <div className={badgeClass}>
                {leadingItem}
                <span className="font-inter text-neutral-500 text-body-regular-xs truncate">
                    {text}
                </span>
                {withCopyButton && (
                    <button
                        type="button"
                        onClick={(e) => {
                            e?.stopPropagation();
                            handleCopy();
                        }}
                    >
                        <span className="sr-only">Copy</span>
                        <SvgIcon icon={CopyIcon} size="medium" color="#1F2125" />
                    </button>
                )}
                {removeable && (
                    <button
                        type="button"
                        className="group h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20 flex justify-center items-center"
                        onClick={(e) => {
                            e?.stopPropagation();
                            handleRemove(id);
                        }}
                    >
                        <span className="sr-only">Remove</span>
                        <SvgIcon icon={trailingIcon} size="medium" color="#5E6470" />
                    </button>
                )}
            </div>
            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.status === 'positive' ? CheckLineIcon : ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </>
    );
}

export default Badge;
