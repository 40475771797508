import React, { forwardRef } from 'react';

const ProcessDragAndDropTarget = forwardRef(function ProcessDragAndDropTarget(
    { isUnsortedGroup, isBorderHighlighted, groupIconColor },
    ref
) {
    const highlightedBorderColor = isUnsortedGroup ? '#b3b7b9' : groupIconColor;
    const borderColor = isBorderHighlighted ? highlightedBorderColor : '#CFD6E5';

    return (
        <div
            className="min-h-[123px] self-stretch px-4 rounded-2 border-2 border-dashed flex items-center justify-center font-body-old text-body-bold-s text-neutral-200 transition-colors"
            ref={ref}
            style={{ borderColor }}
        >
            Drag & drop processes here to {isUnsortedGroup ? 'unsort' : 'sort'}
        </div>
    );
});

export default ProcessDragAndDropTarget;
