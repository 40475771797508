import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import client from '../../../services/template-api';
import { TEMPLATE_TYPES, templateAPIRoutes } from '../../../constants/template';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

import Loading from '../../../components/Loading';
import LayersIcon from '../../../design-system/Icons/LayersIcon';
import ProcessDetailsBlock from './ProcessDetailsBlock/ProcessDetailsBlock';
import AvailableVersionsBlock from './AvailableVersionsBlock/AvailableVersionsBlock';
import { Button } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';

const PublishProcessTemplatePage = () => {
    const params = useParams();
    const processTemplateId = Number(params.processTemplateId);

    const navigate = useNavigate();
    const backLinkHref = `/templates/process/${processTemplateId}`;

    const [processTemplateData, setProcessTemplateData] = useState(null);

    useEffect(() => {
        const fetchTemplateData = async () => {
            try {
                const { data } = await client.get(
                    `${templateAPIRoutes[TEMPLATE_TYPES.process]}${processTemplateId}/`
                );
                setProcessTemplateData(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        fetchTemplateData();
    }, []);

    useDocumentTitle('Publish Settings');

    return (
        <div className="flex flex-col gap-5 h-full w-full sm:w-[calc(100%-68px)] p-4 sm:p-0">
            {processTemplateData ? (
                <>
                    <div className="flex flex-col gap-3 items-start">
                        <Button
                            type="link"
                            size="sm"
                            text={`Back to ${processTemplateData.name}`}
                            leadingIcon={ArrowGoBackLineIcon}
                            trailingIcon={LayersIcon}
                            onClick={() => navigate(backLinkHref)}
                        />
                        <h1 className="font-heading-bold text-heading-bold-l text-black">
                            Publish Settings
                        </h1>
                    </div>
                    <ProcessDetailsBlock
                        processTemplateData={processTemplateData}
                        setProcessTemplateData={setProcessTemplateData}
                    />
                    <AvailableVersionsBlock
                        processTemplateData={processTemplateData}
                        setProcessTemplateData={setProcessTemplateData}
                    />
                </>
            ) : (
                <div className="absolute top-1/2 left-1/2 sm:left-[calc(50%+68px)] transform translate-x-[-50%] translate-y-[-50%] w-max">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default PublishProcessTemplatePage;
