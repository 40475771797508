import React from 'react';
import NewJobForm from '../../../../components/NewJobForm/NewJobForm';
import Button from '../../../../design-system/Button/Button';
import PlayCircleFillIcon from '../../../../design-system/Icons/PlayCircleFillIcon';

const StartNewJobFormBlock = ({
    process,
    formData,
    setFormData,
    startNewJobState,
    handleStartNewJob,
}) => {
    return (
        <div className="p-5 sm:p-8">
            <div className="mb-5 flex flex-col gap-1">
                <h1 className="font-heading-bold text-heading-bold-l text-black">{process.name}</h1>
                {process.description && (
                    <p className="font-body text-body-regular-s text-neutral-400">
                        {process.description}
                    </p>
                )}
            </div>

            <div className="py-8 px-8 sm:px-[40px] w-full max-w-[800px] mx-auto rounded-2xl bg-white flex flex-col gap-7">
                <div>
                    <p className="font-heading-bold text-heading-bold-m text-black mb-2">
                        Start New Job
                    </p>
                    <p className="font-body text-body-regular-s text-neutral-400">
                        Fill out the required inputs to start this job
                    </p>
                </div>

                <NewJobForm formData={formData} setFormData={setFormData} containerGap={28} />

                <div className="py-4">
                    <div className="pt-4 border-t-1 border-neutral-200 flex justify-end">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Start Job"
                            state={startNewJobState === 'loading' ? 'loading' : 'default'}
                            trailingIcon={PlayCircleFillIcon}
                            onClick={handleStartNewJob}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartNewJobFormBlock;
