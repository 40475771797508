import React, { useState } from 'react';

import * as API from '../../../../constants/api';
import client from '../../../../services/library-api';

import { updateSpecificUserInputs } from '../../../../helpers/determineInputs';
import { removeRedundantFieldsFromInput } from '../../../../helpers/taskPlaygroundUtils';
import { TASK_TYPES } from '../../../../constants/library';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Alert from '../../../../design-system/Alert/Alert';
import Modal from '../../../../design-system/Modal/Modal';
import { Button } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const DeleteTaskInputConfirmModal = ({
    taskId,
    taskType,
    inputIdToDelete,
    userInput,
    setUpdatedUserInputs,
    setOutputs,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({
        show: false,
        statusCode: null,
    });
    const inputToDelete = userInput.inputs?.find((item) => item.id === inputIdToDelete);

    const handleTaskInputDelete = async () => {
        try {
            setIsLoading(true);
            setErrorAlert({
                show: false,
                statusCode: null,
            });
            const requestBody = {
                [userInput.key]: [
                    ...userInput.inputs.reduce((acc, item) => {
                        if (item.id === inputIdToDelete) {
                            return acc;
                        } else {
                            const input = removeRedundantFieldsFromInput(item);
                            return [
                                ...acc,
                                taskType === TASK_TYPES.assistant
                                    ? { ...input, value: '' }
                                    : { ...input },
                            ];
                        }
                    }, []),
                ],
            };
            const { data } = await client.patch(
                `${API.ROUTES.library.task}${taskId}/`,
                requestBody
            );
            const { func_def, inputs, outputs } = data;
            setUpdatedUserInputs(
                updateSpecificUserInputs(userInput.key, func_def, inputs, userInput.inputs)
            );
            setOutputs(outputs);

            setIsLoading(false);
            onClose();
        } catch (error) {
            setIsLoading(false);
            setErrorAlert({ show: true, statusCode: error.response.status });
        }
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="p-2">
                <h2 className="text-neutral-400 font-heading-bold text-heading-bold-m mb-[40px] text-center">
                    Are you sure you want to delete {inputToDelete?.label}?
                </h2>
                <div className="flex gap-8 justify-center">
                    <Button type="link" size="sm" text="No, Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        text="Yes, Delete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleTaskInputDelete}
                    />
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    icon={ErrorWarningLineIcon}
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </Modal>
    );
};

export default DeleteTaskInputConfirmModal;
