import {
    LIBRARY_FILTER_COLLECTION_QUERY_PARAM_NAME,
    LIBRARY_FILTER_TASK_TYPE_QUERY_PARAM_NAME,
    LIBRARY_PAGE_NUMBER_QUERY_PARAM_NAME,
    LIBRARY_SORT_QUERY_PARAM_NAME,
} from 'constants/library';

export function parseLibrarySearchParams(searchParams) {
    const page = Number.parseInt(searchParams.get(LIBRARY_PAGE_NUMBER_QUERY_PARAM_NAME));
    const sort = searchParams.get(LIBRARY_SORT_QUERY_PARAM_NAME);
    const collection = Number(searchParams.get(LIBRARY_FILTER_COLLECTION_QUERY_PARAM_NAME)) || null;
    const task_type = searchParams.get(LIBRARY_FILTER_TASK_TYPE_QUERY_PARAM_NAME);

    const result = { page, sort };
    if (collection) {
        result.collection = collection;
    }

    if (task_type) {
        result.task_type = task_type;
    }

    return result;
}
