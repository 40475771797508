import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';
import Alert from '../Alert/Alert';
import { Button } from '../index';
import { ErrorWarningLineIcon } from '../Icons';

ConfirmDeletionModalPattern.propTypes = {
    title: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    confirmationTextStart: PropTypes.string,
    confirmationTextEnd: PropTypes.string,
    entityName: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    errorAlert: PropTypes.shape({
        message: PropTypes.string.isRequired,
        statusCode: PropTypes.number,
    }),
    setErrorAlert: PropTypes.func,
    buttonsTheme: PropTypes.oneOf(['dark', 'colored']),
    customConfirmButtonText: PropTypes.string,
};

function ConfirmDeletionModalPattern({
    title,
    onClose,
    handleDelete,
    isLoading = false,
    confirmationTextStart = '',
    confirmationTextEnd = '',
    entityName = '',
    errorAlert,
    setErrorAlert,
    buttonsTheme = 'colored',
    customConfirmButtonText = '',
}) {
    const cancelButtonType = {
        dark: 'ghost',
        colored: 'neutral',
    };
    const deleteButtonType = {
        dark: 'primary',
        colored: 'secondary',
    };

    return (
        <Modal size="extraSmall" onClose={onClose}>
            <p className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">{title}</p>
            <p className="mb-8 font-body text-body-regular-m text-neutral-500">
                {confirmationTextStart}{' '}
                <span className="font-body-bold text-body-bold-m">{entityName}</span>{' '}
                {confirmationTextEnd}
            </p>
            <div className="flex items-center justify-between">
                <Button
                    type={cancelButtonType[buttonsTheme]}
                    size="sm"
                    text={buttonsTheme === 'dark' ? 'No, Cancel' : 'Cancel'}
                    onClick={onClose}
                />
                <Button
                    type={deleteButtonType[buttonsTheme]}
                    size="sm"
                    text={customConfirmButtonText || 'Yes, Delete'}
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleDelete}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
}

export default ConfirmDeletionModalPattern;
