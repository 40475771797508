import React from 'react';

import IntegrationCard from '../../../components/IntegrationCard/IntegrationCard';
import SetupStepCard from '../../../components/SetupStepCard/SetupStepCard';
import { Button } from '../../../design-system';

import { useNavigate } from 'react-router-dom';

const MakePromptSetupPage = () => {
    const navigate = useNavigate();
    const handleDoneClick = () => {
        navigate('/integrations');
    };

    return (
        <div className="w-full max-sm:p-4 flex flex-col gap-3 sm:gap-5">
            <IntegrationCard
                title="Connecting a Prompt in a Make Automation"
                description="Access your Promptfolio directly from your Make automations"
                centeredContent
            />
            <div className="w-full py-[30px] sm:py-[80px] flex flex-col gap-[40px] sm:gap-[60px]">
                <SetupStepCard
                    title="Step 1: Search for Promptfolio and get the ‘Run Prompt’ action"
                    image={'/images/make-screenshot-run-prompt-action.png'}
                    imageAlt="Screenshot Search for Promptfolio and get the ‘Run Prompt’ action"
                    description="Don’t see it?"
                    descriptionLink={{
                        text: 'Request an invite.',
                        href: '/integrations/make/setup-1',
                    }}
                />
                <SetupStepCard
                    title="Step 2: Select the right Connection and search your prompts"
                    image={'/images/make-screenshot-select-connection.png'}
                    imageAlt="Screenshot Select the right Connection and search your prompts"
                    description="Click refresh next to the prompt dropdown if you don’t see your prompt and it will repopulate."
                />
                <SetupStepCard
                    title="Step 3: Fill in the input variables and you’re good to go!"
                    image={'/images/make-screenshot-filled-inputs.png'}
                    imageAlt="Screenshot Filled Input Variables"
                />
                <div className="mx-auto">
                    <Button
                        type="secondary"
                        size="md"
                        text={'I’m Done!'}
                        onClick={handleDoneClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default MakePromptSetupPage;
