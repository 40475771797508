import React from 'react';
import { DateTime } from 'luxon';
import { Badge } from '../../../design-system';
import { lowerCaseFirstLetter } from '../../../services/strings';
import { PROPERTY_DATA_FORMAT } from '../../../constants/base';

const EntryCard = ({ entry, baseProperties, setEntryPanel, hasCreateEditAccess }) => {
    if (!baseProperties) {
        return <></>;
    }

    const { lastUpdateTimeUnix, properties: entryProperties } = entry;
    const formattedTime = DateTime.fromMillis(lastUpdateTimeUnix).toRelative();

    const hiddenPropertiesCount = baseProperties.length - 4;
    const visiblePropertiesCount = baseProperties.length < 4 ? baseProperties.length : 4;

    const shouldColumnBeNarrow = (dataFormat) =>
        dataFormat === PROPERTY_DATA_FORMAT.checkbox ||
        dataFormat === PROPERTY_DATA_FORMAT.integer ||
        dataFormat === PROPERTY_DATA_FORMAT.decimal;

    const shouldColumnBeRegularWidth = (dataFormat) =>
        dataFormat === PROPERTY_DATA_FORMAT.singleLineText ||
        dataFormat === PROPERTY_DATA_FORMAT.url ||
        dataFormat === PROPERTY_DATA_FORMAT.email;

    const getPropertyColumnWidth = (dataFormat) => {
        const narrowColumnsCount = baseProperties
            .slice(0, 4)
            ?.reduce((acc, item) => (shouldColumnBeNarrow(item.dataFormat) ? acc + 1 : acc), 0);

        const regularColumnsCount = baseProperties
            .slice(0, 4)
            ?.reduce(
                (acc, item) => (shouldColumnBeRegularWidth(item.dataFormat) ? acc + 1 : acc),
                0
            );

        const wideColumnsCount = visiblePropertiesCount - narrowColumnsCount - regularColumnsCount;

        const shouldCurrentColumnBeNarrow = shouldColumnBeNarrow(dataFormat);
        const shouldCurrentColumnBeRegular = shouldColumnBeRegularWidth(dataFormat);

        // if there is at least one wide column (property with dataFormat === 'Multi line text') than make some columns narrow (100px), some regular (180px) and 'Multi line text' will occupy the entire available width.
        // otherwise (if there are no wide columns) make all columns the same width
        const columnWidth = {
            true: shouldCurrentColumnBeNarrow
                ? '100px'
                : shouldCurrentColumnBeRegular
                ? '180px'
                : `calc((100% - ${
                      (visiblePropertiesCount - 1) * 20 +
                      narrowColumnsCount * 100 +
                      regularColumnsCount * 180
                  }px) / ${visiblePropertiesCount - narrowColumnsCount - regularColumnsCount})`,
            false: `calc((100% - ${
                (visiblePropertiesCount - 1) * 20
            }px) / ${visiblePropertiesCount})`,
        };

        return {
            width: columnWidth[!!wideColumnsCount],
        };
    };

    const openEditingPanel = () => {
        setEntryPanel({ opened: true, action: hasCreateEditAccess ? 'edit' : 'view', item: entry });
    };

    return (
        <div
            className="w-full max-w-full px-3 sm:px-4 py-3 rounded-2 bg-white flex items-center gap-5 border-1 border-neutral-100 cursor-pointer"
            onClick={openEditingPanel}
        >
            <div
                className={`${
                    hiddenPropertiesCount > 0 ? 'w-[calc(100%-293px)]' : 'w-[calc(100%-168px)]'
                } flex items-start gap-5`}
            >
                {baseProperties.slice(0, 4)?.map(({ name, dataType, dataFormat }) => (
                    <div
                        className="font-body"
                        key={name}
                        style={{ ...getPropertyColumnWidth(dataFormat) }}
                    >
                        <p className="text-body-bold-xs text-neutral-500 mb-1">{name}</p>
                        <p className="text-body-regular-xs text-neutral-300 line-clamp-2">
                            {dataType === 'boolean' ? (
                                <Badge
                                    text={
                                        entryProperties[lowerCaseFirstLetter(name)]
                                            ? 'True'
                                            : 'False'
                                    }
                                    color="neutral"
                                />
                            ) : (
                                entryProperties[lowerCaseFirstLetter(name)]
                            )}
                        </p>
                    </div>
                ))}
            </div>
            {hiddenPropertiesCount > 0 && (
                <div className="min-w-[105px] w-[105px]">
                    <div className="max-w-full w-fit truncate py-0.5 px-2 border-1 border-neutral-200 rounded-[999px] font-body text-body-regular-xs text-neutral-300">
                        +{hiddenPropertiesCount}{' '}
                        {hiddenPropertiesCount > 1 ? 'Properties' : 'Property'}
                    </div>
                </div>
            )}
            <p className="font-body text-body-regular-xs text-neutral-300 min-w-[148px] w-[148px]">
                Updated{' '}
                <span className="font-body text-body-bold-xs text-neutral-500">
                    {formattedTime}
                </span>
            </p>
        </div>
    );
};

export default EntryCard;
