import { Sparkling2FillIcon } from '../design-system/Icons';
import OrganizationTableActionBlock from '../pages/Profile/OrganizationsBlock/OrganizationTableActionBlock/OrganizationTableActionBlock';
import { DEFAULT_LIMIT } from '../hooks/useCustomPagination';
import { USER_ROLE } from '../constants/organization';

export const transformDataMembersTable = (data, page = null) => {
    const result = {
        columns: [
            {
                header: 'EMAIL',
                accessor: 'email',
                type: 'text',
            },
            {
                header: 'ORG ROLE',
                accessor: 'role',
                type: 'text',
                fixedWidth: 130,
            },
            {
                header: 'TEAMS',
                accessor: 'teams',
                type: 'text',
                fixedWidth: 330,
            },
        ],
        rows: [],
        removeRowActionButtonsForRows: [],
    };

    if (Array.isArray(data)) {
        data.map((item) => {
            if (item.role === USER_ROLE.owner) {
                result.removeRowActionButtonsForRows.push(item.id.toString());
            }

            const row = {
                id: item.id.toString(),
                email: item.user.email,
                teams: item.teams.join(', '),
                role: capitalizeStr(item.role),
            };
            result.rows.push(row);
        });
    }

    return result;
};

export const transformDataTeamsTable = (data, page = null) => {
    const result = {
        columns: [
            {
                header: 'TEAMS',
                accessor: 'name',
                type: 'text',
            },
        ],
        rows: [],
    };

    if (Array.isArray(data)) {
        data.map((item) => {
            result.rows.push({ id: item.id.toString(), name: item.name });
        });
    }
    return result;
};

export const capitalizeStr = (inputString) => {
    if (!inputString) {
        return '';
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};

const badgeColor = {
    plus: 'lime',
    pro: 'purple',
    agency: 'blue',
};

export const transformOrganizationDataForTable = (data, page) => {
    const result = {
        columns: [
            {
                header: 'ORGANIZATION',
                accessor: 'organization',
                type: 'text',
            },
            {
                header: 'ROLE',
                accessor: 'role',
                type: 'text',
                fixedWidth: 110,
            },
            {
                header: 'PLAN',
                accessor: 'plan',
                type: 'badge',
                badgeIcon: Sparkling2FillIcon,
                badgeIconColor: '#1F2125',
                fixedWidth: 168,
            },
            {
                header: '',
                accessor: 'action',
                type: 'any',
                fixedWidth: 70,
            },
        ],
        rows: [],
        pagination: {
            page: page,
            limit: DEFAULT_LIMIT,
            totalItems: data.count,
        },
    };

    data.results?.map((item) =>
        result.rows.push({
            id: item.id,
            organization: item.organization?.name,
            role: capitalizeStr(item.role),
            plan: `${item.organization?.plan?.toUpperCase()} PLAN`,
            badgeColor: badgeColor[item.organization?.plan],
            action: <OrganizationTableActionBlock id={item.organization?.id} role={item.role} />,
        })
    );

    return result;
};
