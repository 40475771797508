import React from 'react';
import { Badge } from '../../../design-system';

const GoalOutputsCard = ({ goal }) => {
    return (
        <div className="pt-4 border-t-1 border-neutral-200 font-body flex flex-col gap-4">
            <div className="flex justify-between gap-2 flex-wrap">
                <p className="font-body text-body-bold-s text-neutral-500 ml-[14px] relative before:content-[''] before:block before:w-[8px] before:h-[8px] before:absolute before:bg-[#1A1A1A] before:rounded-full before:left-[-14px] before:top-1/2 before:transform before:translate-y-[-50%]">
                    {goal.name}
                </p>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Goal ID <span className="text-body-bold-xs text-neutral-500">{goal.id}</span>
                </p>
            </div>
            <p className="text-body-regular-s text-neutral-500">{goal.description}</p>
            {!!goal.outputs?.length && (
                <ul className="flex gap-1 items-center flex-wrap">
                    {goal.outputs.map((output) => (
                        <Badge
                            key={output.id}
                            text={output.key}
                            textToCopy={`{{${output.key}}}`}
                            color="blue"
                            withCopyButton
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default GoalOutputsCard;
