import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import useCustomPagination from '../../../hooks/useCustomPagination';
import client from '../../../services/library-api';
import reportClient from '../../../services/report-api';
import { SORT_TYPES } from '../../../constants/sort';

import { Tabs } from '../../../design-system';
import OverviewTabSection from './OverviewTabSection/OverviewTabSection';
import Loading from '../../../components/Loading';
import Banner from '../../../design-system/Banner/Banner';
import PageTopNavBlock from './PageTopNavBlock/PageTopNavBlock';
import JobHistoryTabSection from './JobHistoryTabSection/JobHistoryTabSection';
import SettingsTabSection from './SettingsTabSection/SettingsTabSection';

const tabs = [{ name: 'Overview' }, { name: 'Job History' }, { name: 'Settings' }];
export const jobsLimit = 10;

const ClientProcessDetailPage = () => {
    const params = useParams();
    const processId = Number(params.processId);

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from ?? '/process';

    const [processDetails, setProcessDetails] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [jobsPage, setJobsPage] = useState(1); // current page in paginated request for jobs for Job History Tab

    const isUpgradeStatus = processDetails?.default_version?.upgrade_status?.is_upgrade;

    useEffect(() => {
        const fetchProcessData = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.library.clientProcess}${processId}/`
                );
                setProcessDetails(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        setProcessDetails(null);
        fetchProcessData();
    }, [processId]);

    const jobsUseCustomPaginationResponse = useCustomPagination({
        client: reportClient,
        route: API.ROUTES.report.job,
        pageIndex: jobsPage - 1,
        searchParams: { ordering: SORT_TYPES.created, process: processId },
        limit: jobsLimit,
        autoFetchNextPage: true,
    });

    const handleTabChanged = (tab, index) => {
        setActiveTabIndex(index);
    };

    const upgradeNowBannerText = (
        <>
            You are on outdated version ({processDetails?.default_version?.name}).{' '}
            <span className="underline">
                Click here to view the improvements and migrate to{' '}
                {processDetails?.default_version?.upgrade_status?.latest_version}.
            </span>
        </>
    );

    const onUpgradeNowBannerClick = () => {
        const latestVersionId = processDetails.default_version?.upgrade_status?.latest_version_id;
        navigate(`/process/${processId}/upgrade/${latestVersionId}`, { state: { from: location } });
    };

    return (
        <div className="page-position overflow-y-auto pb-8 hide-scrollbar">
            {processDetails ? (
                <>
                    <PageTopNavBlock processId={processId} backLinkHref={backLinkHref} />

                    <div className="w-full flex flex-col gap-5 p-5 xs:p-6 sm:p-8">
                        <h1 className="font-heading-bold text-heading-bold-l text-black">
                            {processDetails.name}
                        </h1>

                        <div className="max-w-max">
                            <Tabs
                                tabs={tabs}
                                tabIndex={activeTabIndex}
                                onTabChanged={handleTabChanged}
                            />
                        </div>

                        {isUpgradeStatus && (
                            <Banner
                                title="Upgrade Today!"
                                text={upgradeNowBannerText}
                                color="peach"
                                onBannerClick={onUpgradeNowBannerClick}
                            />
                        )}

                        <div>
                            {activeTabIndex === 0 && (
                                <OverviewTabSection processDetails={processDetails} />
                            )}
                            {activeTabIndex === 1 && (
                                <JobHistoryTabSection
                                    jobsUseCustomPaginationResponse={
                                        jobsUseCustomPaginationResponse
                                    }
                                    jobsPage={jobsPage}
                                    setJobsPage={setJobsPage}
                                />
                            )}
                            <div
                                className={`flex flex-col gap-5 ${
                                    activeTabIndex !== 2 && 'h-0 max-h-0 overflow-hidden'
                                }`}
                            >
                                <SettingsTabSection
                                    processDetails={processDetails}
                                    setProcessDetails={setProcessDetails}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ClientProcessDetailPage;
