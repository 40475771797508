import React from 'react';
import { DateTime } from 'luxon';

import { Badge } from '../../../design-system';

const RunCard = ({ item }) => {
    const formattedTime = DateTime.fromISO(item.created_at).toLocaleString();

    return (
        <li className="w-full max-w-full px-4 py-3 rounded-2 bg-white flex items-center gap-4">
            <p className="font-body text-body-regular-xs text-neutral-300 w-[43px] min-w-[43px] truncate">
                ID {item.id}
            </p>
            <div className="flex-grow w-[calc(100%-307px)] flex gap-4 items-center">
                <p className="w-[calc((100%-16px)/2)] flex-grow font-body text-body-bold-s text-black truncate">
                    {item.name}
                </p>
                <p className="w-[calc((100%-16px)/2)] flex-grow font-body text-body-regular-xs text-neutral-300 truncate">
                    {item.goal_name}
                </p>
            </div>

            <div className="w-[82px] min-w-[82px]">
                <Badge
                    text={`${item.step_count} ${item.step_count === 1 ? 'Prompt' : 'Prompts'}`}
                    color="blue"
                />
            </div>
            <p className="font-body text-body-regular-xs text-neutral-300 min-w-[136px] w-[136px]">
                Created At{' '}
                <span className="font-body text-body-bold-xs text-neutral-500">
                    {formattedTime}
                </span>
            </p>
        </li>
    );
};

export default RunCard;
