import React from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import Loading from '../../../components/Loading';
import EmptyIndexPage from '../EmptyIndexPage/EmptyIndexPage';
import PromptChainCard from '../PromptChainCard/PromptChainCard';
import RunCard from '../RunCard/RunCard';

const PromptChainIndexPage = ({ data, path, loading, pageIndex }) => {
    const location = useLocation();

    if (!data?.length && loading) {
        return (
            <div className="flex flex-col p-8 m-8">
                <Loading text="" />
            </div>
        );
    }

    if (!data.length && pageIndex === 0) {
        return <EmptyIndexPage path={path} />;
    }

    const listStyle = {
        runs: 'max-md:min-w-[700px] max-md:w-[700px]',
        chains: 'max-md:min-w-[620px] max-md:w-[620px]',
    };

    return (
        <div className="max-md:overflow-x-auto">
            <ul className={classNames('flex flex-col gap-2', listStyle[path])}>
                {data.map((item) => {
                    const linkHref =
                        path === 'runs'
                            ? `/run/${item.id}/overview`
                            : `/${path.slice(0, -1)}/${item.id}`;
                    return (
                        <Link
                            key={item.id}
                            to={linkHref}
                            state={{ from: location }}
                            className="cursor-pointer"
                        >
                            {path === 'runs' ? (
                                <RunCard item={item} key={item.id} />
                            ) : (
                                <PromptChainCard item={item} key={item.id} />
                            )}
                        </Link>
                    );
                })}
            </ul>
        </div>
    );
};

export default PromptChainIndexPage;
