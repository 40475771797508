import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../design-system';

const BlockHeader = ({ title, description, viewAllNavPath }) => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col xs:flex-row gap-y-2 gap-x-5 sm:gap-x-10 justify-between">
            <div>
                <p className="font-heading-bold text-heading-bold-s text-black mb-1">{title}</p>
                <p className="font-body text-body-regular-s text-neutral-400">{description}</p>
            </div>
            <div className="min-w-fit">
                <Button
                    type="ghost"
                    size="sm"
                    text="View all"
                    onClick={() => navigate(viewAllNavPath)}
                />
            </div>
        </div>
    );
};

export default BlockHeader;
