import React from 'react';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';

const InboxTaskInstructionsBlock = ({ instructions, setInstructions, setChangedTaskFields }) => {
    const { width: screenWidth } = useWindowSize();

    const handleInstructionsChange = (value) => {
        setInstructions(value);
        setChangedTaskFields((prevData) => addUniqueElementToArray('instructions', prevData));
    };

    return (
        <div className="flex-grow">
            <RichTextArea
                value={instructions}
                label="Inbox Instructions"
                tipText="Instructions will be shown to the user in any Inbox Message created by this task."
                setValue={handleInstructionsChange}
                withCopyButton
                autoExpand={screenWidth < 1024}
                minHeight={220}
            />
        </div>
    );
};

export default InboxTaskInstructionsBlock;
