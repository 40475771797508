import React from 'react';
import { DateTime } from 'luxon';

import { Badge } from '../../../design-system';

const PromptChainCard = ({ item }) => {
    const formattedTime = DateTime.fromISO(item.last_run_at).toRelative() || 'No runs yet';

    return (
        <li className="w-full max-w-full px-4 py-3 rounded-2 bg-white flex items-center gap-4">
            <p className="flex-grow max-ml:min-w-[180px] max-ml:w-[180px] font-body text-body-bold-s text-black truncate">
                {item.name}
            </p>
            <div>
                <Badge
                    text={`${item.step_count} ${item.step_count === 1 ? 'Prompt' : 'Prompts'}`}
                    color="blue"
                />
            </div>
            <p className="font-body text-body-regular-xs text-neutral-300 min-w-[142px] w-[142px] truncate">
                Last Run{' '}
                <span className="font-body text-body-bold-xs text-neutral-500">
                    {formattedTime}
                </span>
            </p>
        </li>
    );
};

export default PromptChainCard;
