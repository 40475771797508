import React from 'react';

const LogoIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 36 36">
            <path
                d="M24.075 3.55583C25.069 3.55583 25.875 2.75977 25.875 1.77791C25.875 0.796061 25.069 0 24.075 0H11.9249C10.9308 0 10.1249 0.796061 10.1249 1.77791C10.1249 2.75977 10.9308 3.55583 11.9249 3.55583H24.075Z"
                fill={props.color}
            />
            <path
                d="M6.07489 8.00057C6.07489 8.98242 6.88085 9.77849 7.8749 9.77849H28.1251C29.1191 9.77849 29.9251 8.98242 29.9251 8.00057C29.9251 7.01872 29.1191 6.22266 28.1251 6.22266H7.8749C6.88085 6.22266 6.07489 7.01872 6.07489 8.00057Z"
                fill={props.color}
            />
            <path
                d="M-0.00012207 20.0756C-0.00012207 22.9662 0.329011 25.8692 0.978096 28.7043L1.22641 29.7873C1.897 32.7157 4.56057 34.9593 7.85428 35.37C11.2201 35.7901 14.6101 36 18 36C21.39 36 24.78 35.7901 28.1458 35.37C31.4395 34.9594 34.103 32.7157 34.7736 29.7877L35.0219 28.7039C35.671 25.8692 36.0002 22.9663 36.0002 20.0756C36.0002 16.4981 33.0237 13.4755 29.0765 13.0447C21.7561 12.2455 14.244 12.2455 6.92354 13.0447C2.97634 13.4755 -0.00012207 16.4981 -0.00012207 20.0756ZM7.31901 16.5788C10.8483 16.1936 14.4422 15.9983 18 15.9983C21.5578 15.9983 25.1517 16.1936 28.681 16.5788C30.8014 16.8104 32.4001 18.3136 32.4001 20.0756C32.4001 22.7027 32.1009 25.3418 31.5107 27.9191L31.2623 29.003C30.9248 30.4772 29.4909 31.6182 27.6949 31.8424C21.2612 32.645 14.7379 32.645 8.30514 31.8424C6.50909 31.6182 5.07515 30.4772 4.73765 29.0025L4.48934 27.9195C3.89916 25.3419 3.59991 22.7028 3.59991 20.0756C3.59991 18.3136 5.19863 16.8104 7.31901 16.5788Z"
                fill={props.color}
            />
        </svg>
    );
};

export default LogoIcon;
