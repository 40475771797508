import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import client from '../../../services/knowledge-api';
import { API } from 'constants';
import useCustomPagination, { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';
import { Button } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import { KNOWLEDGE_BASE_SORT_TYPES } from '../../../constants/knowledgeBase';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';
import ContextIndexPage from '../ContextIndexPage/ContextIndexPage';
import { parseKnowledgeBaseSearchParams } from '../../../helpers/parseKnowledgeBaseSearchParams';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { capitalizeFirstLetter } from '../../../services/strings';
import { BASE_TYPES } from '../../../constants/base';
import KnowledgeBaseIndexPage from '../KnowledgeBaseIndexPage/KnowledgeBaseIndexPage';

const requestSearchParams = { sort: KNOWLEDGE_BASE_SORT_TYPES.created };

const BaseDetailView = () => {
    const { baseType, baseId } = useParams();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedSearchParams = parseKnowledgeBaseSearchParams(searchParams);

    const [requestKeysToMutate, setRequestKeysToMutate] = useState({
        context: [],
        'knowledge-base': [],
    });

    const pageIndex = parsedSearchParams.page - 1 || 0;

    const baseTypeRequestRoute = {
        [BASE_TYPES.context]: API.ROUTES.knowledge.context,
        [BASE_TYPES.knowledgeBase]: `${API.ROUTES.knowledge.base}${baseId}/entry/`,
    };

    const { count, total, loading, data, key } = useCustomPagination({
        searchParams: parsedSearchParams.search
            ? { ...requestSearchParams, search: parsedSearchParams.search }
            : requestSearchParams,
        pageIndex,
        client: client,
        route: baseTypeRequestRoute[baseType],
    });

    useDocumentTitle(capitalizeFirstLetter(baseType));

    useEffect(() => {
        const keyAlreadySaved =
            key && requestKeysToMutate[baseType]?.find((item) => item.key === key);

        if (key && !keyAlreadySaved) {
            setRequestKeysToMutate((prevKeys) => ({
                ...prevKeys,
                [baseType]: [
                    ...(prevKeys[baseType] || []),
                    {
                        key,
                        page: pageIndex + 1,
                        id: Number(baseId),
                        search: parsedSearchParams.search,
                    },
                ],
            }));
        }
    }, [key]);

    useEffect(() => {
        const pageNumber = Number.isNaN(parsedSearchParams.page) ? 1 : parsedSearchParams.page;

        if (Number.isNaN(parsedSearchParams.page)) {
            setSearchParams({ ...parsedSearchParams, page: pageNumber });
        }
    }, [parsedSearchParams, setSearchParams]);

    const start = pageIndex === 0 ? 1 : pageIndex * DEFAULT_LIMIT + 1;
    const end = total - start < count ? total : start + count - 1;
    const hasData = total > 0;

    const handleNextPressed = () => {
        setSearchParams({ ...parsedSearchParams, page: parsedSearchParams.page + 1 });
    };

    const handlePreviousPressed = () => {
        if (parsedSearchParams.page > 0) {
            setSearchParams({ ...parsedSearchParams, page: parsedSearchParams.page - 1 });
        }
    };

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white pt-5 flex flex-col overflow-y-auto">
            <div className="px-4 sm:px-6">
                <Button
                    type="link"
                    size="xs"
                    text="Back to Bases List"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate('/base')}
                />
            </div>
            {baseType === BASE_TYPES.context && (
                <ContextIndexPage
                    data={data}
                    loading={loading}
                    pageIndex={pageIndex}
                    requestKeysToMutate={requestKeysToMutate[baseType]}
                />
            )}
            {baseType === BASE_TYPES.knowledgeBase && (
                <KnowledgeBaseIndexPage
                    data={data}
                    loading={loading}
                    pageIndex={pageIndex}
                    requestKeysToMutate={requestKeysToMutate[baseType]}
                />
            )}

            {hasData && (
                <div className="flex flex-col flex-grow pt-5 pb-6 bg-neutral-50">
                    <PaginationFooter
                        start={start}
                        end={end}
                        total={total}
                        onNextPressed={handleNextPressed}
                        onPreviousPressed={handlePreviousPressed}
                    />
                </div>
            )}
        </div>
    );
};

export default BaseDetailView;
