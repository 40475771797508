import React, { useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/library-api';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import StartNewJobForm from '../StartNewJobForm/StartNewJobForm';

const StartNewJobPanel = ({ opened, onClose }) => {
    const { width: screenWidth } = useWindowSize();

    const [selectedProcessId, setSelectedProcessId] = useState(null);

    const [startNewJobState, setStartNewJobState] = useState('default'); // states are "default", "loading", "success"
    const [startNewJobFormData, setStartNewJobFormData] = useState(null);

    const setProcessOptions = (prevData, data) => {
        const newData = data.results?.map((item) => ({ id: item.id, name: item.name })) || [];
        return [...prevData, ...newData];
    };

    const {
        options: processOptions,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.library.process,
        callback: setProcessOptions,
    });

    const panelClassName = classNames('bg-white pt-5 flex flex-col gap-5', {
        'w-0 max-w-0 overflow-hidden': !opened,
        'w-[37%] min-w-[37%] pb-[40px] px-5  h-full overflow-y-auto border-l border-neutral-200':
            opened && screenWidth >= 970,
        'w-full mb-[90px] px-5  sm:mb-8 pb-4 rounded-2': opened && screenWidth < 970,
    });

    const handleProcessSelect = (id) => {
        setSelectedProcessId(id);
        setStartNewJobFormData(null);
    };

    return (
        <div className={panelClassName}>
            <div>
                <div
                    className={`flex items-center justify-between gap-3 mb-4 border-b ${
                        selectedProcessId || startNewJobState === 'success'
                            ? 'border-transparent'
                            : 'border-neutral-200'
                    }`}
                >
                    <p className="font-body-bold text-body-bold-l text-black">Start New Job</p>
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>
                {startNewJobState !== 'success' && (
                    <PaginatedSelect
                        size="sm"
                        name="process"
                        options={processOptions}
                        optionsLoading={optionsLoading}
                        value={selectedProcessId}
                        label="Select a Process"
                        isRequired
                        placeholder="Select a process to start"
                        onChange={handleProcessSelect}
                        state="default"
                        errorMessage="Please select an option"
                        fetchOptions={() => setPage((page) => page + 1)}
                        canLoadMore={canLoadMoreOptions}
                        includeClientSideFiltering
                        totalOptionsCount={totalOptions}
                    />
                )}
            </div>
            <StartNewJobForm
                processId={selectedProcessId}
                setSelectedProcessId={setSelectedProcessId}
                formData={startNewJobFormData}
                setFormData={setStartNewJobFormData}
                startNewJobState={startNewJobState}
                setStartNewJobState={setStartNewJobState}
            />
        </div>
    );
};

export default StartNewJobPanel;
