import React from 'react';

import { useDrop } from 'react-dnd';

import Tabs from '../../../design-system/Tabs/Tabs';
import DraggableSidebarCard from '../DraggableSidebarCard/DraggableSidebarCard';
import Loading from '../../../components/Loading';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';

const tabs = [{ name: 'Yours' }, { name: 'Shared' }, { name: 'Community' }];

const ChainSidebar = ({
    data,
    setData,
    tabIndex,
    setTabIndex,
    isLoading,
    setPage,
    canLoadMoreTasks,
    areMoreTasksLoading,
}) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'STEP_CARD',
        drop: (draggingItem) => {
            // setTabIndex(draggingItem.tabIndex || 0);
            setData((prevData) =>
                prevData.map((item) => {
                    if (item.id === draggingItem.id) {
                        const { position, step, ...rest } = item;
                        return { ...rest, position: 'sidebar' };
                    } else if (item.position === 'dashboard' && item.step > draggingItem.step) {
                        return { ...item, step: item.step - 1 };
                    } else {
                        return item;
                    }
                })
            );
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const onTabChanged = (tab, index) => {
        // setTabIndex(index);
    };

    const bgStyles = isOver ? 'bg-neutral-200' : 'bg-neutral-50';

    return (
        <div
            className={`h-auto w-[320px] max-w-[320px] lg:h-full lg:overflow-y-auto ${bgStyles} transition-all`}
            ref={drop}
        >
            <div className="bg-white px-[12px] pt-[16px] pb-[8px]">
                <p className="font-body text-body-bold-s text-black mb-[8px]">Prompts</p>
                <div style={{ width: 'fit-content' }}>
                    <Tabs onTabChanged={onTabChanged} tabIndex={tabIndex} tabs={tabs} />
                </div>
            </div>
            <div className="p-[12px]">
                <div className="flex flex-col gap-[8px] mb-[20px]">
                    <p className="font-body text-body-regular-xs text-neutral-300 uppercase">
                        All prompts
                    </p>
                    {isLoading && <Loading text="" />}
                    {!isLoading && data.length > 0 && (
                        <InfiniteScrollList
                            handleFetch={() => setPage((page) => page + 1)}
                            canLoadMore={canLoadMoreTasks}
                            loading={areMoreTasksLoading}
                            items={data}
                        >
                            {data.map((task) => (
                                <DraggableSidebarCard key={task.id} task={task} setData={setData} />
                            ))}
                        </InfiniteScrollList>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChainSidebar;
