import React from 'react';

import { jobStatusFilterBadges } from '../../../constants/jobs';
import { useWindowSize } from '../../../hooks/useWindowSize';

import ListOption from '../../../design-system/ListOption/ListOption';
import ListOptionGroup from '../../../design-system/ListOptionGroup/ListOptionGroup';

const FilterByStatusPopup = ({ selectedStatus, handleStatusSelect, onClose }) => {
    const { width: screenWidth } = useWindowSize();

    const listOptions = jobStatusFilterBadges.map((status, index) => (
        <ListOption
            key={status.value}
            badgeInfo={status}
            showTextAsBadge
            index={index}
            leadingSelectIcon
            selected={status.value === selectedStatus}
            onClick={() => handleStatusSelect(status.value)}
        />
    ));

    const defaultDropdownWidth = screenWidth > 850 ? 355 : 320;

    return (
        <div className="absolute top-[110%] left-0 z-20">
            <ListOptionGroup
                listOptions={listOptions}
                fixedWidth={defaultDropdownWidth}
                handleClose={onClose}
            />
        </div>
    );
};
export default FilterByStatusPopup;
