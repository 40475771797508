import { useEffect, useState } from 'react';
import { DEFAULT_LIMIT } from './useCustomPagination';

export const useFetchOptionsForPaginatedSelect = ({
    client,
    route,
    searchParams = {},
    callback,
    startRequest = true, // startRequest prop should be used just if you want to initiate a request after getting some data or any other event (not on page mount)
}) => {
    const [options, setOptions] = useState([]);
    const [optionsLoading, setOptionsLoading] = useState(false);
    const [totalOptions, setTotalOptions] = useState(0);

    const [page, setPage] = useState(1);
    const [canLoadMoreOptions, setCanLoadMoreOptions] = useState(false);

    const [isFirstRequestCompleted, setIsFirstRequestCompleted] = useState(false); // indicates if the first data is already received

    const [searchParamsId, setSearchParamsId] = useState(0); //the searchParams indicator (when searchParams change, searchParamsId increases by 1, triggering a refetch of options)
    const [controller, setController] = useState(new AbortController()); // state to manage the AbortController instance used for aborting requests

    const searchParamsQuery =
        Object.keys(searchParams)
            .map((key) => `${key}=${searchParams[key]}`)
            .join('&') || '';

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                controller.abort(); // cancel the previous request if it's still active

                // create and set the new controller as the active one
                const newController = new AbortController();
                setController(newController);

                setOptionsLoading(true);
                const { data: newData } = await client.get(
                    `${route}?limit=${DEFAULT_LIMIT}&offset=${(page - 1) * DEFAULT_LIMIT}${
                        searchParamsQuery && `&${searchParamsQuery}`
                    }`,
                    { signal: newController.signal }
                );
                setOptions((prevData) =>
                    page === 1 ? callback([], newData) : callback(prevData, newData)
                );
                setCanLoadMoreOptions(!!newData.next);
                setOptionsLoading(false);
                if (page === 1) {
                    setTotalOptions(newData.count);
                    setIsFirstRequestCompleted(true);
                }
            } catch (error) {
                if (error.message === 'canceled') {
                    return; // the next request is loading
                }
                setOptionsLoading(false);
            }
        };

        if (startRequest && searchParamsId) {
            fetchOptions();
        }
    }, [page, startRequest, searchParamsId]);

    useEffect(() => {
        // if searchParams changed reset current options data to fetch new options
        if (startRequest) {
            resetCurrentData();
        }
    }, [searchParamsQuery, startRequest]);

    const resetCurrentData = () => {
        setPage(1);
        setOptions([]);
        setTotalOptions(0);
        setSearchParamsId((prevData) => prevData + 1);
    };

    return {
        options,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions,
        isFirstRequestCompleted,
    };
};
