import React from 'react';

const ArrowDownIcon = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 26" fill="none">
            <path
                d="M9.00088 22.1716L14.3648 16.8076L15.779 18.2218L8.00088 26L0.222656 18.2218L1.63687 16.8076L7.00088 22.1716V0H9.00088V22.1716Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ArrowDownIcon;
