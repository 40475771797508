import React from 'react';
import { ButtonIcon } from '../../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../../design-system/Icons';

const SettingStepCollapsibleContainer = ({ stepDetails, isExpanded, setIsExpanded, children }) => {
    const { order, label, title, description } = stepDetails;

    const toggleExpand = () => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [order]: !prevState[order],
        }));
    };

    return (
        <div className="pb-4 border-b-1 border-neutral-200">
            <div className="flex items-start justify-between gap-3">
                <div>
                    <p className="font-body-bold text-body-bold-m text-black mb-2">{label}</p>
                    <p className="font-body-bold text-body-bold-s text-neutral-400">
                        {title}{' '}
                        {description && (
                            <span className="font-body text-body-regular-s">{description}</span>
                        )}
                    </p>
                </div>
                <ButtonIcon
                    type="neutral"
                    size="sm"
                    icon={isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                    onClick={toggleExpand}
                />
            </div>

            <div className={`${isExpanded ? 'pt-5 pb-2' : 'h-0 max-h-0 overflow-hidden'}`}>
                {children}
            </div>
        </div>
    );
};

export default SettingStepCollapsibleContainer;
