import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../design-system';
import { Link } from 'react-router-dom';

SetupStepCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    subtitleLink: PropTypes.shape({
        text: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
    }),
    image: PropTypes.node,
    imageAlt: PropTypes.string,
    description: PropTypes.string,
    descriptionLink: PropTypes.shape({
        text: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
    }),
    additionalDescription: PropTypes.string,
    actionButton: PropTypes.shape({
        text: PropTypes.string.isRequired,
        handleClick: PropTypes.func,
    }),
};

function SetupStepCard({
    title,
    subtitle,
    subtitleLink,
    image,
    imageAlt,
    description,
    descriptionLink,
    additionalDescription,
    actionButton,
}) {
    return (
        <div className="flex flex-col items-center gap-3 sm:gap-4">
            <p className="font-heading-bold text-heading-bold-m text-black text-center">{title}</p>
            {subtitle && (
                <p className="font-body text-body-regular-m text-black text-center max-w-[380px] px-auto">
                    {subtitle}{' '}
                    {subtitleLink && (
                        <Link to={subtitleLink.href} className="underline">
                            {subtitleLink.text}
                        </Link>
                    )}
                </p>
            )}
            {image && (
                <div className="p-2 bg-white rounded-2">
                    <img src={image} alt={imageAlt || title} />
                </div>
            )}
            {description && (
                <p className="font-body text-body-regular-m text-black text-center max-w-[380px] px-auto">
                    {description}{' '}
                    {descriptionLink && (
                        <Link to={descriptionLink.href} className="underline">
                            {descriptionLink.text}
                        </Link>
                    )}
                </p>
            )}
            {additionalDescription && (
                <p className="font-body text-body-regular-m text-black text-center max-w-[380px] px-auto">
                    {additionalDescription}
                </p>
            )}
            {actionButton && (
                <div>
                    <Button
                        type="primary"
                        size="md"
                        text={actionButton.text}
                        onClick={actionButton.handleClick}
                    />
                </div>
            )}
        </div>
    );
}

export default SetupStepCard;
