import { useEffect } from 'react';

function useDocumentTitle(title, prevailOnUnmount = false) {
    const pageTitle = title ? `${title} | Promptfolio` : 'Promptfolio';

    useEffect(() => {
        document.title = pageTitle;
    }, [title]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = 'Promptfolio';
            }
        },
        []
    );
}

export default useDocumentTitle;
