import { mutate } from 'swr';
import { SORT_TYPES } from '../constants/sort';

export const moveDroppedProcessInGroupsState = (prevGroups, draggableItem, targetGroupId) => {
    const draggableItemGroup = prevGroups?.find((group) => group.id === draggableItem.groupId);
    const draggableProcess = draggableItemGroup?.processes?.find(
        (process) => process.id === draggableItem.processId
    );
    if (draggableProcess) {
        let updatedGroups = prevGroups.map((group) => {
            // add draggable Process to the new Group
            if (group.id === targetGroupId) {
                return {
                    ...group,
                    processes: [...(group.processes || []), draggableProcess],
                };
            }
            // remove draggable Process from the previous group
            if (group.id === draggableItemGroup.id) {
                return {
                    ...group,
                    processes: group.processes?.filter(
                        (process) => process.id !== draggableProcess.id
                    ),
                };
            }
            return group;
        });

        // remove Unsorted Group if it has no more processes
        const isDraggableItemGroupUnsortedWithNoMoreProcesses =
            draggableItemGroup.id === 0 && draggableItemGroup.processes?.length === 1;

        if (isDraggableItemGroupUnsortedWithNoMoreProcesses) {
            updatedGroups = updatedGroups.filter((group) => group.id !== 0);
        }

        return updatedGroups;
    }
};

export const mutateRequestDataAfterDrop = (requestKeysToMutate, draggableItem, targetGroupId) => {
    requestKeysToMutate.map((key) => {
        mutate(
            key,
            (currentData) => {
                if (!currentData) {
                    return;
                }

                return moveDroppedProcessInGroupsState(currentData, draggableItem, targetGroupId);
            },
            { revalidate: false }
        );
    });
};

export const revalidateAllRequestsKey = (requestKeysToMutate) => {
    requestKeysToMutate.map((key) => {
        mutate(key, null, { revalidate: true });
    });
};

const getNotUnsortedGroups = (unsortedGroupExists, prevGroups) =>
    unsortedGroupExists ? [...prevGroups].slice(1) : [...prevGroups];

const sortGroupsAlphabetical = (groupsList, unsortedGroupExists, prevGroups) => {
    const sortedGroups = groupsList.sort((a, b) => a.name.localeCompare(b.name));
    return unsortedGroupExists ? [prevGroups[0], ...sortedGroups] : sortedGroups;
};

export const addNewGroupToGroupsList = (newGroup, prevGroups, currentSortType) => {
    const unsortedGroupExists = prevGroups?.[0]?.id === 0;
    if (currentSortType === SORT_TYPES.created) {
        // if isUnsortedGroupExist than put newGroup after UnsortedGroup, otherwise on the first place
        const indexNewGroupToInsert = unsortedGroupExists ? 1 : 0;

        const updatedGroups = [...prevGroups];
        updatedGroups.splice(indexNewGroupToInsert, 0, newGroup);

        return updatedGroups;
    }
    if (currentSortType === SORT_TYPES.alphabetical) {
        // add new group and result sort alphabetical
        const notUnsortedGroups = getNotUnsortedGroups(unsortedGroupExists, prevGroups);
        notUnsortedGroups.push(newGroup);

        return sortGroupsAlphabetical(notUnsortedGroups, unsortedGroupExists, prevGroups);
    }
    return prevGroups;
};

export const mutateRequestDataAfterCreatingNewGroup = (requestKeysToMutate, newGroup) => {
    requestKeysToMutate.map((key) => {
        const isKeyWithSearchQueryParams = !!key.includes('search=');

        if (isKeyWithSearchQueryParams) {
            mutate(key, null, { revalidate: true });
        }
        if (!isKeyWithSearchQueryParams) {
            mutate(
                key,
                (currentData) => {
                    if (!currentData) {
                        return;
                    }

                    const requestSortType = key.includes('ordering=-created_at')
                        ? SORT_TYPES.created
                        : SORT_TYPES.alphabetical;
                    return addNewGroupToGroupsList(newGroup, currentData, requestSortType);
                },
                { revalidate: false }
            );
        }
    });
};

export const updateEditedGroupInGroupsList = (
    groupIdToEdit,
    updatedGroup,
    prevGroups,
    sortAlphabetical
) => {
    if (sortAlphabetical) {
        const unsortedGroupExists = prevGroups?.[0]?.id === 0;
        const notUnsortedGroups = getNotUnsortedGroups(unsortedGroupExists, prevGroups);

        const editedGroups = notUnsortedGroups.map((group) =>
            group.id === groupIdToEdit ? { ...group, ...updatedGroup } : group
        );

        return sortGroupsAlphabetical(editedGroups, unsortedGroupExists, prevGroups);
    }

    return prevGroups.map((group) =>
        group.id === groupIdToEdit ? { ...group, ...updatedGroup } : group
    );
};

export const mutateRequestDataAfterEditGroup = (
    requestKeysToMutate,
    groupIdToEdit,
    updatedGroup
) => {
    requestKeysToMutate.map((key) => {
        mutate(
            key,
            (currentData) => {
                if (!currentData) {
                    return;
                }

                const isAlphabeticalSorting = !!key.includes('ordering=name');
                return updateEditedGroupInGroupsList(
                    groupIdToEdit,
                    updatedGroup,
                    currentData,
                    isAlphabeticalSorting
                );
            },
            { revalidate: true }
        );
    });
};

export const mutateRequestDataAfterDeletingGroup = async (
    requestKeysToMutate,
    currentKey,
    idToDelete,
    shouldReFetchGroupsData
) => {
    // shouldReFetchGroupsData means that the deleted Group has some processes so these processes should be replaced to Unsorted Group
    // that is why the data is reFetched
    if (shouldReFetchGroupsData) {
        await mutate(currentKey);
        requestKeysToMutate.map(
            (key) => key !== currentKey && mutate(key, null, { revalidate: true })
        );
    }

    const isSearchApplied = currentKey.includes('search=');

    // if the deleted Group does not have any processes (and there are no search value in search params so group has no processes at all!) than just remove the group from the list
    if (!shouldReFetchGroupsData && !isSearchApplied) {
        requestKeysToMutate.map((key) =>
            mutate(
                key,
                (currentData) => {
                    if (!currentData) {
                        return;
                    }
                    return currentData.filter((group) => group.id !== idToDelete);
                },
                { revalidate: false }
            )
        );
    }

    // if the deleted Group does not have any processes but has a search value in search params (means that on the index page (without ant request filtering) process can have processes)
    if (!shouldReFetchGroupsData && isSearchApplied) {
        requestKeysToMutate.map((key) => {
            if (key.includes('search=')) {
                mutate(
                    key,
                    (currentData) => {
                        if (!currentData) {
                            return;
                        }
                        return currentData.filter((group) => group.id !== idToDelete);
                    },
                    { revalidate: true }
                );
            } else {
                mutate(key, null, { revalidate: true });
            }
        });
    }
};
