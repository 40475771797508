const modelOptions = [
    { id: 1, model: 'gpt-3.5-turbo', name: 'OpenAI GPT-3.5', provider: 'openai', type: 'chat', token_limit: 4097 },
    { id: 2, model: 'gpt-3.5-turbo-16k', name: 'OpenAI GPT-3.5 16k', provider: 'openai', type: 'chat', token_limit: 16385 },
    { id: 3, model: 'gpt-3.5-turbo-instruct', name: 'OpenAI GPT-3.5 Instruct', provider: 'openai', type: 'llm', token_limit: 4097 },
    { id: 4, model: 'gpt-4', name: 'OpenAI GPT-4', provider: 'openai', type: 'chat', token_limit: 8192 },
    { id: 5, model: 'gpt-4-32k', name: 'OpenAI GPT-4 32k', provider: 'openai', type: 'chat', token_limit: 32768 },
    { id: 6, model: 'claude-instant-1', name: 'Anthropic Claude Instant 1', provider: 'anthropic', type: 'chat', token_limit: 100000 },
    { id: 7, model: 'claude-2', name: 'Anthropic Claude 2', provider: 'anthropic', type: 'chat', token_limit: 100000 },
    { id: 8, model: 'gpt-4-1106-preview', name: 'OpenAI GPT-4 Turbo Preview', provider: 'openai', type: 'chat', token_limit: 128000},
    { id: 9, model: 'claude-3-opus-20240229', name: 'Anthropic Claude 3 Opus', provider: 'anthropic', type: 'chat', token_limit: 200000 },
    { id: 10, model: 'claude-3-sonnet-20240229', name: 'Anthropic Claude 3 Sonnet', provider: 'anthropic', type: 'chat', token_limit: 200000 },
    { id: 11, model: 'gpt-4-turbo-preview', name: 'OpenAI GPT-4 Turbo Preview Updated', provider: 'openai', type: 'chat', token_limit: 128000},
    { id: 12, model: 'gemini-pro', name: 'Google Gemini Pro', provider: 'google', type: 'chat', token_limit: 32000 },
];

export default modelOptions;