import React, { useState } from 'react';
import { Button, ButtonIcon, SvgIcon, TextArea } from '../../../design-system';
import {
    ArrowDownSLineIcon,
    ArrowUpSLineIcon,
    FlashlightFillIcon,
} from '../../../design-system/Icons';
import IndicatorIcon from '../../../design-system/Icons/IndicatorIcon';
import { indicatorColors } from '../../../constants/runStatus';
import { useNavigate } from 'react-router-dom';

const CollapsibleStepCard = ({ step, isOutdated, workerId }) => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const {
        task_name: taskName,
        task_description: taskDescription,
        order,
        status,
        inputs,
        outputs,
    } = step;

    const cardShadowStyles = {
        true: 'shadow-inset-2 shadow-purple-300',
        false: 'shadow-inset-1 shadow-neutral-200',
    };

    return (
        <div
            className={`px-2.5 pt-2 pb-3 sm:p-4 flex flex-col gap-2 rounded-2 ${cardShadowStyles[isExpanded]}`}
        >
            <div className="flex items-center justify-between gap-3">
                <div className="flex items-center gap-1">
                    <SvgIcon
                        color={indicatorColors[status] || '#CFD6E5'}
                        icon={IndicatorIcon}
                        size="medium"
                    />
                    <p className="font-body text-body-bold-xs text-neutral-300 uppercase ">
                        Step {order}
                    </p>
                </div>
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                    onClick={() => setIsExpanded((prevState) => !prevState)}
                />
            </div>
            <p className="font-body text-body-bold-m text-neutral-500 truncate">{taskName}</p>
            <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2">
                {taskDescription}
            </p>
            {isExpanded && (
                <>
                    <div className="flex flex-col gap-3 border-b-1 border-neutral-200 pb-2">
                        {!!inputs?.length &&
                            inputs.map((input) => (
                                <div>
                                    <TextArea
                                        value={input.value}
                                        name={input.key}
                                        state="disabled"
                                        withCopyButton
                                        label={
                                            <>
                                                <strong>Input: </strong>
                                                {input.label}
                                            </>
                                        }
                                        displayLabelAsBadge={true}
                                        labelBadgeColor="peach"
                                        height="86px"
                                        lightBg
                                    />
                                </div>
                            ))}
                        {!!outputs?.length &&
                            outputs.map((output) => (
                                <div>
                                    <TextArea
                                        value={output.value}
                                        name={output.key}
                                        state="disabled"
                                        withCopyButton
                                        label={
                                            <>
                                                <strong>Output: </strong>
                                                {output.label}
                                            </>
                                        }
                                        displayLabelAsBadge={true}
                                        labelBadgeColor="blue"
                                        height="86px"
                                        lightBg
                                    />
                                </div>
                            ))}
                    </div>
                    <div className="ml-auto">
                        <Button
                            type="secondary"
                            size="xs"
                            text="Go To Step"
                            state={isOutdated ? 'disabled' : 'default'}
                            leadingIcon={FlashlightFillIcon}
                            onClick={() => navigate(`/run/${workerId}?step=${order}`)}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default CollapsibleStepCard;
