import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { parseProcessPageSearchParams } from '../../../helpers/parseProcessPageSearchParams';
import {
    handleAddNewFilterOption,
    handleClearSearchBar,
    handleRemoveFilterOption,
    handleSearch,
} from '../../../helpers/handleUpdateSearchParams';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import FilteringPopup from '../../../design-system/FilteringPopup/FilteringPopup';
import { Button } from '../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../design-system/Icons';
import LabelBadge from '../../../components/LabelBadge/LabelBadge';

const DiscoverPageTopControls = ({ labels, filteredLabels, areLabelsLoading }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseProcessPageSearchParams(searchParams), [searchParams]);

    const [searchValue, setSearchValue] = useState(parsedParams.search || '');
    const [isFilteringPopupOpened, setIsFilteringPopupOpened] = useState(false);

    const filterLabelsOptions = useMemo(() => {
        return (
            labels?.map((label) => ({
                id: label.id,
                name: label.name,
                bulletPointColor: label.icon_color,
            })) || []
        );
    }, [labels]);

    const totalFilteredProcesses = useMemo(
        () => filteredLabels?.reduce((acc, item) => acc + (item.processes?.length || 0), 0),
        [filteredLabels]
    );

    const handleSearchProcesses = () => {
        handleSearch({ searchParams, setSearchParams, searchValue, usePagination: false });
    };

    const handleClearSearchQuery = () => {
        handleClearSearchBar({
            searchParams,
            setSearchParams,
            setSearchValue,
            usePagination: false,
        });
    };

    const handleAddNewFilter = (label) => {
        handleAddNewFilterOption({
            searchParams,
            setSearchParams,
            currentFilters: parsedParams.filter,
            newFilter: label.id,
            usePagination: false,
        });
    };

    const handleRemoveFilter = (label) => {
        handleRemoveFilterOption({
            searchParams,
            setSearchParams,
            currentFilters: parsedParams.filter,
            filterToDelete: label.id,
            usePagination: false,
        });
    };

    function closeFilteringPopup(e) {
        e?.stopPropagation();
        setIsFilteringPopupOpened(false);
    }

    useEffect(() => {
        const currentLabelsFilter = parsedParams.filter;

        if (currentLabelsFilter && filteredLabels) {
            const areLabelsInFilterThatDoesNotExistInFilteredLabels =
                currentLabelsFilter.length > filteredLabels.length;

            // this situation can happen when a label is selected in filter, but when the user submits a search, the label is not present in the search results
            // in such cases, we remove this label from the filter to display the correct data.
            if (areLabelsInFilterThatDoesNotExistInFilteredLabels) {
                // filter without labels that are not present in the backend response
                const newLabelsFilter = filteredLabels.reduce((acc, label) => {
                    if (currentLabelsFilter.includes(label.id)) {
                        return [...acc, label.id];
                    }
                    return acc;
                }, []);

                const urlSearchParams = new URLSearchParams(searchParams);
                if (!!newLabelsFilter?.length) {
                    urlSearchParams.set('filter', newLabelsFilter.join(','));
                } else {
                    urlSearchParams.delete('filter');
                }
                setSearchParams(urlSearchParams);
            }
        }
    }, [parsedParams.filter, filteredLabels]);

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col xs:flex-row xs:items-center gap-x-5 gap-y-2 w-full relative max-w-[690px]">
                <SearchBar
                    size="sm"
                    value={searchValue}
                    withSubmitIcon
                    withClearIcon
                    placeholder="Search..."
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSubmit={handleSearchProcesses}
                    onClear={handleClearSearchQuery}
                />
                <div className="flex items-center justify-end gap-2 relative">
                    <Button
                        type="link"
                        size="xs"
                        text="Filter"
                        trailingIcon={
                            isFilteringPopupOpened ? ArrowUpSLineIcon : ArrowDownSLineIcon
                        }
                        onClick={() => setIsFilteringPopupOpened(true)}
                    />
                    {isFilteringPopupOpened && (
                        <FilteringPopup
                            label="All categories"
                            options={filterLabelsOptions}
                            selectedOptionsIds={parsedParams.filter || []}
                            areOptionsLoading={areLabelsLoading}
                            handleAddFilterOption={handleAddNewFilter}
                            handleRemoveOption={handleRemoveFilter}
                            includeClientSideFiltering
                            totalOptionsCount={filterLabelsOptions?.length}
                            onClose={closeFilteringPopup}
                            position="absolute top-[120%] right-0 min-[1180px]:right-auto min-[1180px]:left-0"
                            optionViewType="checkbox"
                            popupWidth={360}
                        />
                    )}
                </div>
            </div>
            {parsedParams.filter && filteredLabels && (
                <div className="flex justify-between gap-3">
                    <div className="flex items-center gap-2 flex-wrap">
                        {filteredLabels?.map((label) => (
                            <LabelBadge
                                key={label.id}
                                label={label}
                                removeable
                                handleRemove={() => handleRemoveFilter(label)}
                            />
                        ))}
                    </div>
                    <p className="font-body text-body-regular-s text-neutral-300 min-w-fit py-1">
                        {totalFilteredProcesses}{' '}
                        {totalFilteredProcesses === 1 ? 'process' : 'processes'}
                    </p>
                </div>
            )}
        </div>
    );
};

export default DiscoverPageTopControls;
