import React from 'react';
import Modal from '../../../design-system/Modal/Modal';
import { Button } from '../../../design-system';

const OutdatedAlertModal = ({ onClose, isSaveChangesLoading, handleSaveChanges }) => {
    const handleOverwrite = async () => {
        await handleSaveChanges({ overwrite: true });
        onClose();
    };

    return (
        <Modal size="medium" onClose={onClose}>
            <div className="sm:p-2">
                <p className="font-heading-bold text-heading-bold-m text-neutral-400 mb-6">
                    You are about to overwrite others’ recent edits. Are you sure?
                </p>
                <div className="flex justify-center items-center gap-4">
                    <Button type="neutral" size="sm" text="No, Go Back" onClick={onClose} />
                    <Button
                        type="primary"
                        size="sm"
                        state={isSaveChangesLoading ? 'loading' : 'default'}
                        text="Yes, Overwrite"
                        onClick={handleOverwrite}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default OutdatedAlertModal;
