import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import { DEFAULT_SECTION_NAME, PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { setSyncTimeout } from '../../../helpers/setSyncTimeout';
import { deleteSection, duplicateSection } from '../../../helpers/playbookBuilderUtils';

import { ButtonIcon } from '../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon, More2FillIcon } from '../../../design-system/Icons';
import PlaybookSectionMoreOptionPopup from '../PlaybookSectionMoreOptionPopup/PlaybookSectionMoreOptionPopup';
import TextFieldWithCustomTextStyles from '../../../design-system/TextFieldWithCustomTextStyles/TextFieldWithCustomTextStyles';
import PlaybookSectionExpandedView from '../PlaybookSectionExpandedView/PlaybookSectionExpandedView';

const PlaybookSection = ({
    sectionData,
    setPlaybookContentFormData,
    isTheOnlySection,
    mode,
    isDragAndDropEnabled,
    areSectionsExpandedState,
}) => {
    const { name, temporaryId, block_content } = sectionData;
    const [areSectionsExpanded, setAreSectionsExpanded] = areSectionsExpandedState;

    const [isMoreOptionPopupOpened, setIsMoreOptionPopupOpened] = useState(false);

    const isCurrentStateExpanded = areSectionsExpanded[temporaryId];
    const expandButtonIcon = isCurrentStateExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    // this useDrop hook is used just for tracking if user drag a block to a collapsed section
    const [{ isOver }, drop] = useDrop({
        accept: ['BLOCK', 'NEW_BLOCK'],
        drop: () => {},
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    useEffect(() => {
        // if user drag a block to a collapsed section (sections is hovered for 0.5s) expand it
        let stopTimeout;
        if (isOver && !isCurrentStateExpanded) {
            stopTimeout = setSyncTimeout(toggleExpand, 500);
        }

        return () => {
            if (stopTimeout) {
                stopTimeout();
            }
        };
    }, [isOver, isCurrentStateExpanded]);

    const setSectionData = (cb) =>
        setPlaybookContentFormData((prevData) =>
            prevData.map((section) =>
                section.temporaryId === temporaryId
                    ? typeof cb === 'function'
                        ? cb(section)
                        : cb
                    : section
            )
        );

    const handleSectionTitleChange = (e) => {
        setSectionData((prevData) => ({ ...prevData, name: e.target.value }));
    };

    const handleDuplicateSection = () => {
        duplicateSection({ sectionData, setPlaybookContentFormData, setAreSectionsExpanded });
    };

    const handleDeleteSection = () => {
        deleteSection({ temporaryId, setPlaybookContentFormData });
    };

    function toggleExpand() {
        setAreSectionsExpanded((prevData) => ({
            ...prevData,
            [temporaryId]: !prevData[temporaryId],
        }));
    }

    const sectionWrapperClassName = classNames(
        'rounded-2.5 border-1 border-neutral-200 px-6 py-4 flex flex-col bg-white w-full',
        {
            'max-w-[calc(100%-27px)]': isDragAndDropEnabled,
            'max-w-full': !isDragAndDropEnabled,
        }
    );
    const sectionHeaderClassName = classNames('flex items-start gap-5', {
        'w-[calc(100%-36px)]': mode === PLAYBOOK_BUILDER_MODE.edit,
        'w-full': mode === PLAYBOOK_BUILDER_MODE.view,
    });

    return (
        <div className={sectionWrapperClassName} ref={drop}>
            <div className="flex justify-between items-start">
                <div className={sectionHeaderClassName}>
                    <div className="max-w-[calc(100%-36px)] flex-grow">
                        <TextFieldWithCustomTextStyles
                            value={name}
                            isDisabled={mode === PLAYBOOK_BUILDER_MODE.view}
                            placeholder={DEFAULT_SECTION_NAME}
                            textStyle="font-heading-regular text-heading-regular-m text-black placeholder:neutral-300"
                            minHeight={26}
                            handleChange={handleSectionTitleChange}
                        />
                    </div>

                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={expandButtonIcon}
                        onClick={toggleExpand}
                    />
                </div>

                {mode === PLAYBOOK_BUILDER_MODE.edit && (
                    <div className="relative">
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={More2FillIcon}
                            onClick={() => setIsMoreOptionPopupOpened(true)}
                        />
                        {isMoreOptionPopupOpened && (
                            <PlaybookSectionMoreOptionPopup
                                isTheOnlySection={isTheOnlySection}
                                handleDuplicateSection={handleDuplicateSection}
                                handleDeleteSection={handleDeleteSection}
                                onClose={() => setIsMoreOptionPopupOpened(false)}
                            />
                        )}
                    </div>
                )}
            </div>

            {isCurrentStateExpanded && (
                <PlaybookSectionExpandedView
                    mode={mode}
                    blockContent={block_content}
                    sectionTemporaryId={temporaryId}
                    setPlaybookContentFormData={setPlaybookContentFormData}
                    setSectionData={setSectionData}
                />
            )}
        </div>
    );
};

export default PlaybookSection;
