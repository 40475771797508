import React from 'react';
import { USER_ROLE } from '../../../../constants/organization';
import { useNavigate } from 'react-router-dom';
import { ButtonIcon } from '../../../../design-system';
import EditLineIcon from '../../../../design-system/Icons/EditLineIcon';
import EyeIcon from '../../../../design-system/Icons/EyeIcon';

const OrganizationTableActionBlock = ({ id, role }) => {
    const navigate = useNavigate();

    return (
        <div>
            <ButtonIcon
                type="link"
                size="xs"
                icon={role === USER_ROLE.member ? EyeIcon : EditLineIcon}
                onClick={() => navigate(`/organization/${id}`)}
            />
        </div>
    );
};

export default OrganizationTableActionBlock;
