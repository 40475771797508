import React, { useState } from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import { getNewBlockObject, moveBlockBetweenSections } from '../../../helpers/playbookBuilderUtils';
import { SvgIcon } from '../../../design-system';
import { AddLineIcon } from '../../../design-system/Icons';
import BlockOptionsSelector from '../BlockOptionsSelector/BlockOptionsSelector';

const BlockDropAndSelectZone = ({
    targetSectionTemporaryId,
    setContentBlock,
    setPlaybookContentFormData,
}) => {
    const [isBlockOptionsSelectorOpened, setIsBlockOptionsSelectorOpened] = useState(false);

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ['NEW_BLOCK', 'BLOCK'],
        drop: handleDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item) => item.sectionTemporaryId !== targetSectionTemporaryId,
    });

    function handleDrop(item) {
        const { dndType, blockType, blockTemporaryId } = item;

        if (dndType === 'NEW_BLOCK') {
            addNewBlockAtTheBottom(blockType);
        }

        if (dndType === 'BLOCK') {
            const currentSectionTemporaryId = item.sectionTemporaryId;
            moveBlockBetweenSections({
                currentSectionTemporaryId,
                targetSectionTemporaryId,
                blockTemporaryId,
                setPlaybookContentFormData,
            });
        }
    }

    function addNewBlockAtTheBottom(blockType) {
        setContentBlock((prevData) => {
            const newOrder = prevData.length + 1;
            const newBlock = getNewBlockObject({ blockType, order: newOrder });

            return [...prevData, newBlock];
        });
    }

    const isDndActive = isOver && canDrop;
    const containerClassName = classNames('relative transition-all', {
        'text-purple-500 text-body-bold-m': isDndActive,
        'text-neutral-300 text-body-regular-m': !isDndActive,
    });

    return (
        <div className={containerClassName} ref={drop}>
            <button
                type="button"
                className="flex gap-2.5 items-center font-body"
                onClick={() => setIsBlockOptionsSelectorOpened(true)}
            >
                <SvgIcon icon={AddLineIcon} color="currentColor" size="large" />
                <p className="text-start">Drag and drop block contents here or click + to add</p>
            </button>

            {isBlockOptionsSelectorOpened && (
                <BlockOptionsSelector
                    position="top-[110%] left-[36px]"
                    isDraggableSelector={false}
                    addNewBlock={addNewBlockAtTheBottom}
                    onClose={() => setIsBlockOptionsSelectorOpened(false)}
                />
            )}
        </div>
    );
};

export default BlockDropAndSelectZone;
