import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRequest } from '../../../hooks/request';
import useUser from '../../../hooks/useUser';
import { API } from 'constants';
import client from '../../../services/knowledge-api';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../../constants/organization';

import SearchBar from '../../../design-system/SearchBar/SearchBar';
import { Button, ButtonIcon } from '../../../design-system';
import {
    AddCircleLineIcon,
    ErrorWarningLineIcon,
    More2FillIcon,
} from '../../../design-system/Icons';
import Alert from '../../../design-system/Alert/Alert';
import KnowledgeBaseEntryPanel from '../KnowledgeBaseEntryPanel/KnowledgeBaseEntryPanel';
import EntryCard from '../EntryCard/EntryCard';
import Loading from '../../../components/Loading';
import { EmptyIndexPage } from '../EmptyIndex/EmptyIndex';
import BaseCardMoreOptions from '../BaseCardMoreOptions/BaseCardMoreOptions';
import DeleteBaseModal from '../DeleteBaseModal/DeleteBaseModal';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const KnowledgeBaseIndexPage = ({ data: entries, loading, pageIndex, requestKeysToMutate }) => {
    const { baseId } = useParams();
    const { orgPlan, userRole } = useUser();

    const hasCreateEditAccess =
        orgPlan === ORGANIZATION_PLAN.agency &&
        (userRole === USER_ROLE.admin || userRole === USER_ROLE.owner);

    const [searchQuery, setSearchQuery] = useState('');
    const [baseData, setBaseData] = useState(null);
    const [entryPanel, setEntryPanel] = useState({ opened: false, action: null });

    const [baseMoreOptionsOpened, setBaseMoreOptionsOpened] = useState(false);
    const [deleteBaseModalOpened, setDeleteBaseModalOpened] = useState(false);

    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const baseDetail = useGetRequest({ url: `${API.ROUTES.knowledge.base}${baseId}/` }, client);

    useEffect(() => {
        const fetchBaseDetail = async () => {
            const { response, success, error } = await baseDetail.request();
            if (success) {
                setBaseData(response);
            }
            if (error) {
                setErrorAlert({ show: true, statusCode: error?.status });
            }
        };

        fetchBaseDetail();
    }, []);

    const createEntry = () => {
        if (baseData) {
            setEntryPanel({ opened: true, action: 'create' });
        }
    };

    return (
        <div className="">
            <div className="px-4 sm:px-6">
                <h1 className="font-heading-bold text-heading-bold-s text-black mb-2">
                    {baseData?.name}
                </h1>
                <div className="flex flex-col-reverse xs:items-center xs:justify-between gap-x-4 gap-y-2 xs:flex-row mb-5">
                    <div
                        className={`w-full max-w-[680px] ${
                            hasCreateEditAccess ? 'xs:w-[calc(100%-205px)]' : 'w-full'
                        }`}
                    >
                        <SearchBar
                            size="sm"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            state="disabled"
                            placeholder="Search..."
                            withSubmitIcon
                            withClearIcon
                        />
                    </div>
                    {hasCreateEditAccess && baseData && (
                        <div className="flex items-center gap-3 justify-end">
                            <Button
                                type="secondary"
                                size="xs"
                                text="New Entry"
                                leadingIcon={AddCircleLineIcon}
                                onClick={createEntry}
                            />
                            <div className="relative">
                                <ButtonIcon
                                    type="link"
                                    size="sm"
                                    icon={More2FillIcon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setBaseMoreOptionsOpened(true);
                                    }}
                                />
                                {baseMoreOptionsOpened && (
                                    <BaseCardMoreOptions
                                        baseId={Number(baseId)}
                                        handleClose={() => setBaseMoreOptionsOpened(false)}
                                        openDeleteModal={() => setDeleteBaseModalOpened(true)}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {!entries?.length && loading && (
                    <div className="flex flex-col p-8 m-8">
                        <Loading />
                    </div>
                )}
                {!entries?.length && pageIndex === 0 && !loading && (
                    <EmptyIndexPage onCreatePressed={createEntry} type="entry" />
                )}
            </div>

            {!!entries?.length && baseData && (
                <div className="max-[1100px]:overflow-x-auto bg-neutral-50 pt-4 px-4 sm:px-6">
                    <ul
                        className={
                            'flex flex-col gap-2 max-[1100px]:min-w-[1040px] max-[1100px]:w-[1040px]'
                        }
                    >
                        {entries.map((entry) => (
                            <div key={entry.id} className="cursor-pointer">
                                <EntryCard
                                    entry={entry}
                                    setEntryPanel={setEntryPanel}
                                    baseProperties={baseData.properties}
                                    hasCreateEditAccess={hasCreateEditAccess}
                                />
                            </div>
                        ))}
                    </ul>
                </div>
            )}
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
            {entryPanel.opened && (
                <KnowledgeBaseEntryPanel
                    base={baseData}
                    entry={entryPanel.item}
                    action={entryPanel.action}
                    page={pageIndex + 1}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={() => setEntryPanel({ opened: false })}
                />
            )}
            {deleteBaseModalOpened && (
                <DeleteBaseModal
                    baseId={baseData.id}
                    baseName={baseData.name}
                    navigateToBasePage
                    onClose={() => {
                        setDeleteBaseModalOpened(false);
                    }}
                />
            )}
        </div>
    );
};

export default KnowledgeBaseIndexPage;
