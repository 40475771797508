import React, { Fragment } from 'react';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';

import BlockDropAndSelectZone from '../BlockDropAndSelectZone/BlockDropAndSelectZone';
import DraggablePlaybookBlock from '../DraggablePlaybookBlock/DraggablePlaybookBlock';
import BlockSeparatorDropZone from '../BlockSeparatorDropZone/BlockSeparatorDropZone';

const PlaybookSectionExpandedView = ({
    mode,
    blockContent,
    sectionTemporaryId,
    setSectionData,
    setPlaybookContentFormData,
}) => {
    const isBlockContentEmpty = !blockContent.length;
    const isContentDisplayed = !isBlockContentEmpty || mode === PLAYBOOK_BUILDER_MODE.edit;

    const setContentBlock = (cb) => {
        setSectionData((prevData) => ({ ...prevData, block_content: cb(prevData.block_content) }));
    };

    return (
        <>
            {isContentDisplayed && (
                <div className={`flex flex-col gap-6 ${isBlockContentEmpty ? 'pt-6' : ''}`}>
                    {!!blockContent.length && (
                        <div>
                            {blockContent.map((blockData) => (
                                <Fragment key={blockData.temporaryId}>
                                    <BlockSeparatorDropZone
                                        setContentBlock={setContentBlock}
                                        setPlaybookContentFormData={setPlaybookContentFormData}
                                        targetOrder={blockData.order}
                                        targetSectionTemporaryId={sectionTemporaryId}
                                    />
                                    <DraggablePlaybookBlock
                                        key={blockData.temporaryId}
                                        mode={mode}
                                        blockData={blockData}
                                        setContentBlock={setContentBlock}
                                        sectionTemporaryId={sectionTemporaryId}
                                    />
                                </Fragment>
                            ))}
                        </div>
                    )}

                    {mode === PLAYBOOK_BUILDER_MODE.edit && (
                        <BlockDropAndSelectZone
                            targetSectionTemporaryId={sectionTemporaryId}
                            setContentBlock={setContentBlock}
                            setPlaybookContentFormData={setPlaybookContentFormData}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default PlaybookSectionExpandedView;
