import React, { forwardRef } from 'react';

const DragAndDropTarget = forwardRef(function DragAndDropTarget({ text, isOver }, ref) {
    return (
        <div
            ref={ref}
            className={`rounded-2 p-4 border-dashed border-1 border-purple-300 bg-purple-100 transition-all ${
                isOver ? 'bg-opacity-100`' : 'bg-opacity-30'
            }`}
        >
            <p className="font-body text-body-regular-m text-neutral-300">{text}</p>
        </div>
    );
});

export default DragAndDropTarget;
