import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';

Input.propTypes = {
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    size: PropTypes.oneOf(['md', 'sm', 'xs']).isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    icon: PropTypes.element,
    iconColor: PropTypes.string,
    onIconClick: PropTypes.func,
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'email', 'tel', 'url', 'password']),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
};

function Input(props) {
    const {
        state = 'default',
        size = 'sm',
        label,
        name,
        placeholder,
        isRequired = false,
        icon,
        iconColor = '#5E6470',
        onIconClick,
        errorMessage,
        onChange,
        value,
        type = 'text',
        tipText = '',
        onBlur = () => {},
        ...inputProps
    } = props;

    const sizes = {
        md: icon ? 'h-13 py-17 pl-11 pr-34' : 'h-13 py-17 px-11',
        sm: icon ? 'h-12 py-15 pl-11 pr-34' : 'h-12 py-15 px-11',
        xs: icon ? 'h-10 py-11 pl-11 pr-34' : 'h-10 py-11 px-11',
    };

    const inputSize = sizes[size];

    let inputClassName = classNames(
        'w-[100%] rounded-2 text-base text-neutral-500 font-normal disabled:text-neutral-300 leading-1.25 placeholder-neutral-300 focus:placeholder-transparent caret-neutral-500 border-1 focus:outline-0 focus:border-1 focus:shadow-l3 transition-all ease-in',
        inputSize,
        {
            'bg-white': state !== 'disabled',
            'bg-neutral-50': state === 'disabled',
            'border-neutral-300 focus:shadow-neutral-300': state !== 'error',
            'border-red-500 focus:shadow-red-500': state === 'error',
        }
    );

    return (
        <div className="flex flex-col w-full gap-1 font-inter">
            <label className="text-small font-bold leading-1.25">
                {label && (
                    <div className="mb-1">
                        {label}
                        {isRequired && <sup className="text-red-500 leading-1 ml-0.5">*</sup>}
                    </div>
                )}

                <div className="relative">
                    <input
                        className={inputClassName}
                        type={type}
                        value={value}
                        required={isRequired}
                        disabled={state === 'disabled'}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                        onBlur={() => onBlur(name)}
                        {...inputProps}
                    />
                    {icon && (
                        <span
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                            onClick={onIconClick}
                        >
                            <SvgIcon color={iconColor} icon={icon} size="medium" />
                        </span>
                    )}
                </div>
            </label>

            {state === 'error' && errorMessage && (
                <div className="flex items-center gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#E95B69" size="medium" />
                    <p className="text-extraSmall text-red-500 leading-1.25">{errorMessage}</p>
                </div>
            )}
            {tipText && (state !== 'error' || !errorMessage) && (
                <div className="flex items-center gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                    <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                </div>
            )}
        </div>
    );
}

export default Input;
