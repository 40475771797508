import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { parseJobsSearchParams } from '../../../helpers/parseJobsSearchParams';
import {
    handleAddNewFilterOption,
    handleClearSearchBar,
    handleRemoveFilterOption,
    handleSearch,
    handleSelectAndDeselectSearchParamValue,
    handleSortOptionSelect,
} from '../../../helpers/handleUpdateSearchParams';

import { jobsSortOptions } from '../../../constants/sort';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { Badge, Button } from '../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../design-system/Icons';
import SearchBar from '../../../design-system/SearchBar/SearchBar';
import SortDropdown from '../../../components/SortDropdown/SortDropdown';
import AddProcessFiltersPopup from '../../../components/AddProcessFiltersPopup/AddProcessFiltersPopup';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import FilterByStatusPopup from '../FilterByStatusPopup/FilterByStatusPopup';

const JobsPageTopControls = ({ isMobile, setPage, setShouldSetNewData, totalJobs }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseJobsSearchParams(searchParams), [searchParams]);

    const [searchValue, setSearchValue] = useState(parsedParams.search || '');
    const [actionDropdown, setActionDropdown] = useState({ isOpened: false, action: null }); // action can be "filterByProcess", "filterByStatus" or "sort"

    const { width: screenWidth } = useWindowSize();

    const [selectedProcessFiltersInfo, setSelectedProcessFiltersInfo] = useState([]);

    const resetPageState = () => {
        setPage(1);
        setShouldSetNewData(true);
    };

    const closeActionDropdown = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setActionDropdown({ isOpened: false, action: null });
    };

    const handleSearchJobs = () => {
        handleSearch({
            searchParams,
            setSearchParams,
            searchValue,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
        });
    };

    const handleClearSearchQuery = () => {
        handleClearSearchBar({
            searchParams,
            setSearchParams,
            setSearchValue,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
        });
    };

    const handleJobsSortOptionSelect = (sortOption) => {
        handleSortOptionSelect({
            sortOption,
            setSearchParams,
            searchParams,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
            onSearchDropdownClose: closeActionDropdown,
        });
    };

    const handleAddProcessFilterOption = (process) => {
        handleAddNewFilterOption({
            searchParams,
            setSearchParams,
            currentFilters: parsedParams.process,
            newFilter: process.id,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
            searchParamKey: 'process',
            shouldSortFilters: true,
        });
        setSelectedProcessFiltersInfo((prevFilters) => [...prevFilters, process]);
    };

    const handleRemoveProcessFilterOption = (process) => {
        handleRemoveFilterOption({
            searchParams,
            setSearchParams,
            currentFilters: parsedParams.process,
            filterToDelete: process.id,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
            searchParamKey: 'process',
        });
        setSelectedProcessFiltersInfo((prevFilters) =>
            prevFilters.filter((option) => option.id !== process.id)
        );
    };

    const handleStatusSelect = (selectedStatus) => {
        handleSelectAndDeselectSearchParamValue({
            searchParamKey: 'status',
            searchParams,
            setSearchParams,
            currentValue: parsedParams.status,
            newValue: selectedStatus,
            usePagination: !isMobile,
            usePageState: true,
            resetPageState,
            onDropdownClose: closeActionDropdown,
        });
    };

    const getButtonTrailingIcon = (action) =>
        actionDropdown.isOpened && actionDropdown.action === action
            ? ArrowUpSLineIcon
            : ArrowDownSLineIcon;

    const sortDropdownPosition = {
        top: '130%',
        left: screenWidth <= 640 ? 0 : screenWidth >= 1000 ? '70px' : 'auto',
        right: screenWidth > 640 && screenWidth < 1000 ? 0 : 'auto',
    };

    return (
        <>
            <div className="flex flex-col ql:flex-row ql:items-center gap-2 w-full relative max-w-[615px]">
                <SearchBar
                    size="sm"
                    value={searchValue}
                    withSubmitIcon
                    withClearIcon
                    placeholder="Search by keyword"
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSubmit={handleSearchJobs}
                    onClear={handleClearSearchQuery}
                />
                <div className="flex items-center gap-2 relative min-w-max">
                    <Button
                        type="link"
                        size="xs"
                        text="By Process"
                        trailingIcon={getButtonTrailingIcon('filterByProcess')}
                        onClick={() =>
                            setActionDropdown({ isOpened: true, action: 'filterByProcess' })
                        }
                    />
                    <div className="sm:relative">
                        <Button
                            type="link"
                            size="xs"
                            text="By Status"
                            trailingIcon={getButtonTrailingIcon('filterByStatus')}
                            onClick={() =>
                                setActionDropdown({ isOpened: true, action: 'filterByStatus' })
                            }
                        />
                        {actionDropdown.isOpened && actionDropdown.action === 'filterByStatus' && (
                            <FilterByStatusPopup
                                selectedStatus={parsedParams.status}
                                handleStatusSelect={handleStatusSelect}
                                onClose={closeActionDropdown}
                            />
                        )}
                    </div>
                    <Button
                        type="link"
                        size="xs"
                        text="Sort"
                        trailingIcon={getButtonTrailingIcon('sort')}
                        onClick={() => setActionDropdown({ isOpened: true, action: 'sort' })}
                    />
                    {actionDropdown.isOpened && actionDropdown.action === 'filterByProcess' && (
                        <AddProcessFiltersPopup
                            selectedOptions={parsedParams.process || []}
                            handleAddFilterOption={handleAddProcessFilterOption}
                            handleRemoveOption={handleRemoveProcessFilterOption}
                            popupPosition="top-[110%] left-0 z-20"
                            onClose={closeActionDropdown}
                        />
                    )}
                    {actionDropdown.isOpened && actionDropdown.action === 'sort' && (
                        <SortDropdown
                            options={jobsSortOptions}
                            selectedOption={parsedParams.sort}
                            handleOptionSelect={handleJobsSortOptionSelect}
                            onClose={closeActionDropdown}
                            dropdownPosition={sortDropdownPosition}
                        />
                    )}
                </div>
            </div>

            {parsedParams.process &&
                !!selectedProcessFiltersInfo?.length &&
                selectedProcessFiltersInfo?.length === parsedParams.process.length && (
                    <div className="flex items-center justify-between gap-2">
                        <div className="flex items-center gap-2 px-2 py-1 flex-wrap">
                            {selectedProcessFiltersInfo.map((process) => (
                                <Badge
                                    key={process.id}
                                    color="neutral"
                                    text={process.name}
                                    leadingIconColor={process.iconColor}
                                    leadingIcon={PlayCircleFillIcon}
                                    removeable
                                    handleRemove={() => handleRemoveProcessFilterOption(process)}
                                    withBorder
                                />
                            ))}
                        </div>
                        {typeof totalJobs === 'number' && (
                            <p className="font-body text-body-regular-s text-neutral-300">
                                {totalJobs} {totalJobs === 1 ? 'job' : `jobs`}
                            </p>
                        )}
                    </div>
                )}
        </>
    );
};

export default JobsPageTopControls;
