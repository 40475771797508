import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ButtonIcon from 'design-system/ButtonIcon/ButtonIcon';
import ExpandRightLineIcon from 'design-system/Icons/ExpandRightLineIcon';
import FullscreenLineIcon from 'design-system/Icons/FullscreenLineIcon';
import { useClickOutside } from 'hooks';
import { createPortal } from 'react-dom';

Panel.propTypes = {
    onClose: PropTypes.func,
    onNavigationButtonClick: PropTypes.func,
    showControls: PropTypes.bool,
    closeByClickOutside: PropTypes.func,
    resetPadding: PropTypes.bool,
};

const panelRoot = document.getElementById('panel-root');

function Panel({
    children,
    onClose,
    onNavigationButtonClick,
    showControls = true,
    closeByClickOutside = true,
    resetPadding = false,
}) {
    const panelRef = useRef(null);
    useClickOutside(panelRef, closeByClickOutside ? onClose : () => {});

    useEffect(() => {
        document.body.classList.add('no-scroll');

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const panelClassName = classNames(
        'fixed top-0 bottom-0 right-0 bg-white z-32 transition-all duration-300 w-full ml:w-1/2 ml:min-w-[800px] overflow-y-auto flex flex-col',
        { 'px-4 pt-4 pb-8 sm:px-8': !resetPadding }
    );

    return createPortal(
        <div className="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 z-31">
            <div className={panelClassName} ref={panelRef}>
                {showControls && (
                    <div className="flex mb-[12px]">
                        <ButtonIcon
                            size="sm"
                            type="link"
                            icon={ExpandRightLineIcon}
                            onClick={onClose}
                        />
                        <ButtonIcon
                            size="sm"
                            type="link"
                            icon={FullscreenLineIcon}
                            onClick={onNavigationButtonClick || (() => {})}
                        />
                    </div>
                )}
                {children}
            </div>
        </div>,
        panelRoot
    );
}

export default Panel;
