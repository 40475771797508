import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API } from 'constants';
import client from '../../../services/report-api';
import { JOB_STATUS } from '../../../constants/jobs';

import Loading from '../../../components/Loading';
import JobDetailPageHeader from './JobDetailPageHeader/JobDetailPageHeader';
import ViewSwitcher from './ViewSwitcher/ViewSwitcher';
import Switcher from '../../../design-system/Switcher/Switcher';
import JobTasksTabSection from './JobTasksTabSection/JobTasksTabSection';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import RenameJobModal from './RenameJobModal/RenameJobModal';
import ConfirmArchiveJobModal from './ConfirmArchiveJobModal/ConfirmArchiveJobModal';
import RestartJobSidePanel from './RestartJobSidePanel/RestartJobSidePanel';

const tabs = [{ name: 'Job Tasks' }];
const fetcher = (url) => client.get(url).then((res) => res.data);

const JobDetailPage = () => {
    const params = useParams();
    const jobId = Number(params.jobId);

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from ?? '/jobs';

    const [shouldRefresh, setShouldRefresh] = useState(true); // if job.status === 'started' auto polling is enabled

    const getJobDataRequestEndpoint = `${API.ROUTES.report.job}${jobId}/`;
    const {
        data: jobDetail,
        error,
        mutate,
    } = useSWR(getJobDataRequestEndpoint, fetcher, {
        refreshInterval: shouldRefresh ? 5000 : 0,
    });

    const [actionModal, setActionModal] = useState({ isOpened: false, action: null }); // actions are "rename", "archive", "restart"

    // column or row view
    const [view, setView] = useState('column');
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const [pageHeaderHeight, setPageHeaderHeight] = useState(0);

    useEffect(() => {
        if (jobDetail) {
            setShouldRefresh(jobDetail.status === JOB_STATUS.started);
        }
    }, [jobDetail]);

    if (error) {
        navigate(backLinkHref);
    }

    useDocumentTitle(jobDetail?.name);

    const closeActionModal = () => {
        setActionModal({ isOpened: false, action: null });
    };

    return (
        <div
            className={`page-position bg-white flex flex-col hide-scrollbar ${
                view === 'row' && 'overflow-y-auto'
            }`}
        >
            {jobDetail && (
                <>
                    <JobDetailPageHeader
                        jobDetail={jobDetail}
                        backLinkHref={backLinkHref}
                        setPageHeaderHeight={setPageHeaderHeight}
                        setActionModal={setActionModal}
                    />
                    <div
                        className="pt-2.5 px-4 xs:px-5 sm:px-8 pb-4 lg:pb-7 lg:px-[40px] xl:px-[60px] flex flex-col flex-grow bg-neutral-50/70"
                        style={
                            view === 'column'
                                ? { height: `calc(100% - ${pageHeaderHeight}px)` }
                                : {}
                        }
                    >
                        <div className="relative flex flex-col max-h-full flex-grow">
                            <div className="px-3 sm:px-4 border-b-1 border-neutral-200 mb-5 flex flex-col">
                                <div className="flex items-center justify-between">
                                    <Switcher
                                        tabs={tabs}
                                        tabIndex={currentTabIndex}
                                        onTabChanged={(_, index) => setCurrentTabIndex(index)}
                                    />
                                    <ViewSwitcher view={view} setView={setView} />
                                </div>
                            </div>
                            {currentTabIndex === 0 && (
                                <JobTasksTabSection
                                    milestones={jobDetail.milestones || []}
                                    view={view}
                                />
                            )}
                        </div>
                    </div>
                    {actionModal.isOpened && actionModal.action === 'rename' && (
                        <RenameJobModal
                            jobId={jobId}
                            jobName={jobDetail.name}
                            mutate={mutate}
                            onClose={closeActionModal}
                        />
                    )}
                    {actionModal.isOpened && actionModal.action === 'restart' && (
                        <RestartJobSidePanel
                            jobId={jobId}
                            mutate={mutate}
                            onClose={closeActionModal}
                        />
                    )}
                    {actionModal.isOpened && actionModal.action === 'archive' && (
                        <ConfirmArchiveJobModal
                            jobId={jobId}
                            jobName={jobDetail.name}
                            backLinkHref={backLinkHref}
                            onClose={closeActionModal}
                        />
                    )}
                </>
            )}
            {!jobDetail && (
                <div className="flex-grow flex justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default JobDetailPage;
