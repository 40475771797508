import React from 'react';
import { integerRegex } from '../../../constants/regex_patterns';

import { Input, Select, TextArea } from '../../../design-system';
import JsonTextareaBox from '../../../design-system/JsonTextareaBox/JsonTextareaBox';
import RichTextArea from '../../../design-system/RichTextArea/RichTextArea';
import OptionsInput from '../../../design-system/OptionsInput/OptionsInput';
import MultiSelect from '../../../design-system/MultiSelect/MultiSelect';
import Radio from '../../../design-system/Radio/Radio';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import Checkbox from '../../../design-system/Checkbox/Checkbox';
import Toggle from '../../../design-system/Toggle/Toggle';

const SettingsForm = ({ formData, setFormData, containerGap = 28 }) => {
    const handleInputChange = (name, value) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.name === name ? { ...item, value, state: 'default', errorMessage: null } : item
            )
        );
    };

    const handleIntegerInputChange = (name, event) => {
        const inputValue = event.target.value;

        if (integerRegex.test(inputValue)) {
            setFormData((prevData) =>
                prevData.map((item) =>
                    item.name === name ? { ...item, value: +inputValue, errorMessage: null } : item
                )
            );
        }
    };

    const handleAddNewOption = (name, newOption) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.name === name
                    ? {
                          ...item,
                          value: [...(item.value || []), newOption],
                          state: 'default',
                          errorMessage: null,
                      }
                    : item
            )
        );
    };

    const handleRemoveOption = (name, optionToRemove) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.name === name
                    ? {
                          ...item,
                          value: item.value?.filter((option) => option !== optionToRemove),
                          state: 'default',
                          errorMessage: null,
                      }
                    : item
            )
        );
    };

    const handleRadioButtonChange = (name, optionId) => {
        setFormData((prevData) =>
            prevData.map((item) =>
                item.name === name
                    ? { ...item, value: optionId, state: 'default', errorMessage: null }
                    : item
            )
        );
    };

    const handleMultiSelectChange = (name, optionId) => {
        setFormData((prevData) =>
            prevData.map((item) => {
                if (item.name === name) {
                    const newValue = item.value?.includes(optionId)
                        ? item.value.filter((el) => el !== optionId)
                        : [...(item.value || []), optionId];
                    return { ...item, value: newValue, state: 'default', errorMessage: null };
                } else {
                    return item;
                }
            })
        );
    };

    return (
        <>
            {!!formData?.length && (
                <div className="flex flex-col" style={{ gap: `${containerGap}px` }}>
                    {formData.map(
                        ({
                            name,
                            label,
                            description,
                            type,
                            action,
                            is_required,
                            options,
                            value,
                            state,
                            errorMessage,
                        }) => {
                            const formattedOptions =
                                options &&
                                Object.keys(options)?.map((item) => ({
                                    id: options[item],
                                    name: item,
                                }));

                            return (
                                <React.Fragment key={name}>
                                    {action === 'single-input' && type === 'string' && (
                                        <Input
                                            size="sm"
                                            name={name}
                                            value={value || ''}
                                            label={label}
                                            isRequired={is_required}
                                            placeholder="Fill this input"
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please fill in this field.'
                                            }
                                            tipText={description}
                                            type="text"
                                            onChange={(e) =>
                                                handleInputChange(name, e.target.value)
                                            }
                                        />
                                    )}
                                    {action === 'single-input' && type === 'integer' && (
                                        <Input
                                            size="sm"
                                            name={name}
                                            value={value}
                                            label={label}
                                            isRequired={is_required}
                                            placeholder="0"
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please fill in this field.'
                                            }
                                            tipText={description}
                                            onChange={(e) => handleIntegerInputChange(name, e)}
                                        />
                                    )}
                                    {action === 'single-input' && type === 'number' && (
                                        <Input
                                            size="sm"
                                            name={name}
                                            value={value}
                                            label={label}
                                            isRequired={is_required}
                                            placeholder="0.1"
                                            type="number"
                                            step="0.1"
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please fill in this field.'
                                            }
                                            tipText={description}
                                            onChange={(e) =>
                                                handleInputChange(name, e.target.value)
                                            }
                                        />
                                    )}
                                    {action === 'json-input' && (
                                        <div className="flex flex-col gap-4 min-h-[200px]">
                                            <JsonTextareaBox
                                                mode="code"
                                                value={value}
                                                label={label}
                                                isRequired={is_required}
                                                state={state}
                                                errorMessage={
                                                    errorMessage || 'Please fill in this field.'
                                                }
                                                tipText={description}
                                                parentGap={16}
                                                containerMinHeight={200}
                                                setValue={(value) => handleInputChange(name, value)}
                                            />
                                        </div>
                                    )}
                                    {action === 'textarea-input' && (
                                        <TextArea
                                            name={name}
                                            value={value || ''}
                                            label={label}
                                            isRequired={is_required}
                                            placeholder="Fill this input"
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please fill in this field.'
                                            }
                                            tipText={description}
                                            onChange={(e) =>
                                                handleInputChange(name, e.target.value)
                                            }
                                        />
                                    )}
                                    {action === 'richtext-input' && (
                                        <RichTextArea
                                            name={name}
                                            value={value || ''}
                                            label={label}
                                            isRequired={is_required}
                                            placeholder="Fill this input"
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please fill in this field.'
                                            }
                                            tipText={description}
                                            setValue={(value) => handleInputChange(name, value)}
                                            autoExpand
                                            minHeight={170}
                                            darkBorderColor
                                        />
                                    )}
                                    {action === 'multi-input' && (
                                        <OptionsInput
                                            name={name}
                                            options={value || []}
                                            label={label}
                                            isRequired={is_required}
                                            placeholder="Add your input and click + to add them"
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please add at least one input.'
                                            }
                                            tipText={description}
                                            handleAddNewOption={(option) =>
                                                handleAddNewOption(name, option)
                                            }
                                            handleRemoveOption={(option) =>
                                                handleRemoveOption(name, option)
                                            }
                                            containerGap={16}
                                            optionsBadgeColor="neutral"
                                            optionsBadgeFontStyle="text-body-regular-xs"
                                        />
                                    )}
                                    {action === 'single-select' && (
                                        <FormFieldWrapper
                                            label={label}
                                            isRequired={is_required}
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please select an option.'
                                            }
                                            tipText={description}
                                        >
                                            <ul className="flex flex-col gap-1">
                                                {formattedOptions.map((option) => (
                                                    <li key={option.id} className="py-1 px-2">
                                                        <Radio
                                                            isSelected={option.id === value}
                                                            name={option.id}
                                                            label={option.name}
                                                            state={state}
                                                            onChange={() =>
                                                                handleRadioButtonChange(
                                                                    name,
                                                                    option.id
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </FormFieldWrapper>
                                    )}
                                    {action === 'multi-select' && (
                                        <FormFieldWrapper
                                            label={label}
                                            isRequired={is_required}
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please select an option.'
                                            }
                                            tipText={description}
                                        >
                                            <ul className="flex flex-col gap-1">
                                                {formattedOptions.map((option) => (
                                                    <li key={option.id} className="py-1 px-2">
                                                        <Checkbox
                                                            name={option.id}
                                                            label={option.name}
                                                            isChecked={value?.includes(option.id)}
                                                            state={state}
                                                            onChange={() =>
                                                                handleMultiSelectChange(
                                                                    name,
                                                                    option.id
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </FormFieldWrapper>
                                    )}
                                    {action === 'single-dropdown' && (
                                        <Select
                                            size="sm"
                                            name={name}
                                            value={value}
                                            options={formattedOptions}
                                            label={label}
                                            isRequired={is_required}
                                            placeholder="Select an option"
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please select an option.'
                                            }
                                            tipText={description}
                                            onChange={(optionId) =>
                                                handleInputChange(name, optionId)
                                            }
                                            includeClientSideFiltering
                                        />
                                    )}
                                    {action === 'multi-dropdown' && (
                                        <MultiSelect
                                            name={name}
                                            addedOptions={value || []}
                                            optionsForSelect={formattedOptions}
                                            label={label}
                                            isRequired={is_required}
                                            placeholder="Select an option and click + to add them"
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please select at least one option.'
                                            }
                                            tipText={description}
                                            handleAddNewOption={(option) =>
                                                handleAddNewOption(name, option)
                                            }
                                            handleRemoveOption={(option) =>
                                                handleRemoveOption(name, option)
                                            }
                                            includeClientSideFiltering
                                        />
                                    )}
                                    {action === 'toggle' && (
                                        <FormFieldWrapper
                                            state={state}
                                            errorMessage={
                                                errorMessage || 'Please select an option.'
                                            }
                                            tipText={description}
                                        >
                                            <div className="flex items-center gap-x-7 gap-y-2 flex-wrap">
                                                <div className="font-body text-body-bold-s text-neutral-500">
                                                    {label}
                                                    {is_required && (
                                                        <sup className="text-red-500 leading-1 ml-0.5">
                                                            *
                                                        </sup>
                                                    )}
                                                </div>
                                                <Toggle
                                                    name={name}
                                                    isDisabled={state === 'disabled'}
                                                    isSelected={value === true}
                                                    size="sm"
                                                    onChange={(state) =>
                                                        handleInputChange(name, state)
                                                    }
                                                />
                                            </div>
                                        </FormFieldWrapper>
                                    )}
                                </React.Fragment>
                            );
                        }
                    )}
                </div>
            )}
        </>
    );
};

export default SettingsForm;
