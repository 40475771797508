import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../design-system/SvgIcon/SvgIcon';
import ErrorWarningLineIcon from '../../design-system/Icons/ErrorWarningLineIcon';
import { Badge } from '../../design-system';
import ToolTip from '../../design-system/ToolTip/ToolTip';
import LockIcon from '../../design-system/Icons/LockIcon';
import ButtonIcon from '../../design-system/ButtonIcon/ButtonIcon';
import {
    DeleteBin4LineIcon,
    FileCopyLineIcon,
    InformationLineIcon,
} from '../../design-system/Icons';
import Alert from '../../design-system/Alert/Alert';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';
import { defaultErrorMessage } from '../../constants/errorMessages';
import { useWindowSize } from '../../hooks/useWindowSize';

TaskInputHeader.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    errorMessage: PropTypes.string,
    withCopyButton: PropTypes.bool,
    value: PropTypes.string, // if you set withCopyButton = true then this value will be copied to clipboard
    withDeleteButton: PropTypes.bool,
    onDeleteButtonClick: PropTypes.func,
    trailingTags: PropTypes.shape({
        text: PropTypes.string.isRequired,
        color: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']).isRequired,
    }),
    protectedMode: PropTypes.bool,
    showProtectedModeTooltip: PropTypes.bool,
    setShowProtectedModeTooltip: PropTypes.func,
    info: PropTypes.shape({
        text: PropTypes.string,
        state: PropTypes.oneOf(['default', 'error']),
    }),
};
function TaskInputHeader({
    id,
    label,
    isRequired,
    state,
    errorMessage,
    trailingTags,
    withCopyButton = false,
    value = '',
    withDeleteButton,
    onDeleteButtonClick,
    protectedMode = false,
    info = {},
    children,
}) {
    const { width } = useWindowSize();
    const [showInfoTooltip, setShowInfoTooltip] = useState(false);

    const [showProtectedModeTooltip, setShowProtectedModeTooltip] = useState(false);
    const [copyStatusAlert, setCopyStatusAlert] = useState({ show: false, status: '' });

    const handleCopy = () => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                setCopyStatusAlert({ show: true, status: 'positive' });
            })
            .catch(() => {
                setCopyStatusAlert({ show: true, status: 'critical' });
            });
    };

    const getTooltipPosition = () => {
        if (info.state === 'default') {
            if (width < 420) {
                return 'bottom-right';
            } else if (width < 1300) {
                return 'bottom-center';
            } else return 'bottom-left';
        }
        if (width < 1100) {
            return 'top-center';
        } else return 'top-left';
    };

    return (
        <div>
            <div className="flex justify-between gap-3 flex-wrap mb-1.5">
                <div className="flex items-center gap-1 max-sm:flex-wrap max-sm:items-start">
                    <span className="font-body text-body-bold-s sm:text-body-bold-m">
                        {label}
                        {isRequired && (
                            <sup className="text-red-500 font-small leading-1 font-medium ml-0.5">
                                *
                            </sup>
                        )}
                    </span>
                    {info?.text && (
                        <ToolTip
                            text={info.text}
                            position={getTooltipPosition()}
                            isShown={showInfoTooltip}
                            shift="-8px"
                        >
                            <div
                                onMouseEnter={() => setShowInfoTooltip(true)}
                                onMouseLeave={() => setShowInfoTooltip(false)}
                            >
                                <SvgIcon
                                    icon={InformationLineIcon}
                                    size="medium"
                                    color={info.state === 'error' ? '#E95B69' : '#5E6470'}
                                />
                            </div>
                        </ToolTip>
                    )}
                </div>

                <div className="flex flex-row items-center gap-1">
                    {!!trailingTags?.length &&
                        trailingTags.map((item, idx) => (
                            <Badge key={idx} text={item.text} color={item.color} />
                        ))}
                    {protectedMode && (
                        <ToolTip
                            text="Used in Prompt Chain"
                            position="top-right"
                            isShown={showProtectedModeTooltip}
                            shift="10px"
                        >
                            <div
                                className="flex items-center gap-1 px-2.5 py-1"
                                onMouseEnter={() => setShowProtectedModeTooltip(true)}
                                onMouseLeave={() => setShowProtectedModeTooltip(false)}
                            >
                                <SvgIcon color="#000000" icon={LockIcon} size="medium" />
                                <span className="font-body text-body-regular-xs text-neutral-500">
                                    Protected
                                </span>
                            </div>
                        </ToolTip>
                    )}
                    {withCopyButton && (
                        <ButtonIcon
                            type="link"
                            size="xs"
                            state={!value ? 'disabled' : 'default'}
                            icon={FileCopyLineIcon}
                            onClick={handleCopy}
                        />
                    )}
                    {withDeleteButton && (
                        <ButtonIcon
                            type="link"
                            size="xs"
                            state={'default'}
                            icon={DeleteBin4LineIcon}
                            onClick={() => onDeleteButtonClick(id)}
                        />
                    )}
                </div>
            </div>
            {children}
            {state === 'error' && errorMessage && (
                <div className="flex items-center gap-1 mt-2">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#E95B69" size="medium" />
                    <p className="text-extraSmall text-red-500 leading-1.25">{errorMessage}</p>
                </div>
            )}
            {copyStatusAlert.show && (
                <Alert
                    status={copyStatusAlert.status}
                    message={
                        copyStatusAlert.status === 'positive'
                            ? `${label} copied to clipboard!`
                            : defaultErrorMessage
                    }
                    icon={
                        copyStatusAlert.status === 'positive' ? CheckLineIcon : ErrorWarningLineIcon
                    }
                    autoCloseInMS={3000}
                    handleClose={() => setCopyStatusAlert({ show: false, status: '' })}
                />
            )}
        </div>
    );
}

export default TaskInputHeader;
