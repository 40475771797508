import React from 'react';
import { SvgIcon } from '../../../../design-system';
import EscapeIcon from '../../../../design-system/Icons/EscapeIcon';
import { TABS } from '../SharePublishModal';
import { ORGANIZATION_PLAN } from '../../../../constants/organization';

const SharedPublishModalHeader = ({ activeTab, handleTabSelect, onModalClose, type, orgPlan }) => {
    const tabs = {
        prompt: orgPlan === ORGANIZATION_PLAN.plus ? [TABS.publish] : [TABS.share, TABS.publish],
        task: [TABS.share],
    };

    return (
        <div className="pt-3 px-4 xs:px-8 flex items-center gap-2 justify-between border-b-1 border-neutral-200">
            <div className="flex items-center gap-4">
                {tabs[type].map((tab, index) => (
                    <div
                        className={`
                                    capitalize cursor-pointer font-heading-bold text-[14px] font-semibold tracking-[0.7] leading-[100%] transition-colors pt-3 ${
                                        tab === activeTab
                                            ? 'text-purple-500 border-b-2 border-purple-500 pb-[8px]'
                                            : 'text-neutral-500 pb-[10px]'
                                    }
                                `}
                        onClick={() => handleTabSelect(tab)}
                        key={index}
                    >
                        {tab}
                    </div>
                ))}
            </div>
            <div onClick={onModalClose} className="cursor-pointer">
                <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
            </div>
        </div>
    );
};

export default SharedPublishModalHeader;
