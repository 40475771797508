import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useUser from '../../../hooks/useUser';
import { useViewType } from '../../../hooks/useViewType';
import { useNavigationRestrictionContext } from '../../../hooks/useNavigationRestrictionContext';

import { VIEW_TYPES } from '../../../constants/viewTypes';
import { navItemIconColor, navItemLabelColor } from '../../../constants/sideBar';
import { ACCESS_CONFIG, ORGANIZATION_PLAN, USER_ROLE } from '../../../constants/organization';

import SvgIcon from '../../../design-system/SvgIcon/SvgIcon';
import DashboardLineIcon from '../../../design-system/Icons/DashboardLineIcon';
import UserSettingsLineIcon from '../../../design-system/Icons/UserSettingsLineIcon';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import ConfirmNavigationModal from '../../ConfirmNavigationModal/ConfirmNavigationModal';

const ViewTypeSwitcher = ({ mobileVersion = false, handleMobMenuClose }) => {
    const { viewType, toggleViewType } = useViewType();
    const { orgPlan, userRole } = useUser();

    const location = useLocation();
    const isPromptViewPageOpened = location.pathname?.includes('/library/prompt/');
    const { isNavigationRestricted, setIsNavigationRestricted } = useNavigationRestrictionContext();
    const [isConfirmNavigationModalShown, setIsConfirmNavigationModalShown] = useState(false);

    const shouldBeDisplayed = orgPlan === ORGANIZATION_PLAN.agency && userRole !== USER_ROLE.member;
    const [showToolTip, setShowToolTip] = useState(false);

    useEffect(() => {
        const shouldToggleViewType =
            orgPlan && userRole && !ACCESS_CONFIG[viewType][orgPlan]?.includes(userRole);
        if (shouldToggleViewType) {
            toggleViewType();
        }
    }, [viewType, orgPlan, userRole]);

    const title = {
        [VIEW_TYPES.admin]: 'Client View',
        [VIEW_TYPES.client]: 'Admin View',
    };

    const icon = {
        [VIEW_TYPES.admin]: DashboardLineIcon,
        [VIEW_TYPES.client]: UserSettingsLineIcon,
    };

    if (!shouldBeDisplayed) {
        return <></>;
    }

    const handleConfirmNavigation = () => {
        setIsNavigationRestricted(false);
        toggleViewType();
        setIsConfirmNavigationModalShown(false);
    };

    const handleCancelNavigation = () => {
        setIsConfirmNavigationModalShown(false);
    };

    const handleToggleViewTypeClick = () => {
        if (isNavigationRestricted) {
            setIsConfirmNavigationModalShown(true);
        }

        if (!isNavigationRestricted) {
            toggleViewType();
        }
    };

    const confirmNavigationModalText = {
        true: `You have unsaved changes. If you change to ${title[viewType]}, all your changes will be lost.`,
        false: `Are you sure you want to change to ${title[viewType]}? We’ll keep loading your process in the background.`,
    };

    return (
        <>
            {mobileVersion ? (
                <li className="p-2" onClick={handleMobMenuClose}>
                    <div
                        className={`
                            flex items-center gap-[16px] font-body text-body-bold-m cursor-pointer ${navItemLabelColor[viewType]}
                        `}
                        onClick={handleToggleViewTypeClick}
                    >
                        <span className="flex items-center justify-center w-[36px] h-[36px] relative z-[-1]">
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                size="large"
                                icon={icon[viewType]}
                            />
                        </span>
                        {title[viewType]}
                    </div>
                </li>
            ) : (
                <li className={`list-none p-2`}>
                    <ToolTip
                        text={title[viewType]}
                        position="center-left"
                        shift={'55px'}
                        isShown={showToolTip}
                    >
                        <div
                            className="w-9 h-9 flex justify-center items-center cursor-pointer"
                            onClick={handleToggleViewTypeClick}
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        >
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                size="large"
                                icon={icon[viewType]}
                            />
                        </div>
                    </ToolTip>
                </li>
            )}
            {isConfirmNavigationModalShown && (
                <ConfirmNavigationModal
                    text={confirmNavigationModalText[isPromptViewPageOpened]}
                    confirmButtonText={
                        isPromptViewPageOpened
                            ? `Change to ${title[viewType]}`
                            : `Yes, change to ${title[viewType]}`
                    }
                    cancelButtonText={isPromptViewPageOpened ? 'Stay on Page' : 'No, Stay on Page'}
                    onCancel={handleCancelNavigation}
                    onConfirm={handleConfirmNavigation}
                    buttonsTheme={isPromptViewPageOpened ? 'colored' : 'dark'}
                />
            )}
        </>
    );
};

export default ViewTypeSwitcher;
