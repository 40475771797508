import React from 'react';
import Modal from 'design-system/Modal/Modal';
import { SvgIcon, Button } from 'design-system';
import EscapeIcon from 'design-system/Icons/EscapeIcon';

function ConfirmationModal({ onClose, onConfirm, confirmText, itemId }) {
    return (
        <Modal size="small" onClose={onClose}>
            <div className="flex flex-col gap-[16px]">
                <div className="flex justify-between items-center gap-2">
                    <h2 className="font-heading-bold text-heading-bold-m text-black">Delete</h2>
                    <div onClick={onClose} className="cursor-pointer">
                        <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle"></SvgIcon>
                    </div>
                </div>
                <p className="font-body text-body-bold-s neutral-500 mb-[8px]">
                    {confirmText ? confirmText : 'Are you sure to proceed?'}
                </p>
                <div className="flex justify-between">
                    <Button type="ghost" text="Cancel" size="sm" onClick={onClose} />
                    <Button
                        type="primary"
                        text={'Confirm'}
                        size="sm"
                        onClick={() => {
                            onConfirm(itemId);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmationModal;
