import React from 'react';
import JsonTextareaBox from '../../../../design-system/JsonTextareaBox/JsonTextareaBox';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

const TemplateFileBlock = ({
    content,
    editable = false,
    isLive = false,
    isMainBranch = false,
    errorMessage,
    setVersionChangeableData,
    setChangedVersionFields,
    setVersionInputsErrorMessages,
    shouldRefreshTemplateFile = false,
    setShouldRefreshTemplateFile,
}) => {
    const handleContentChange = (value) => {
        setVersionChangeableData((prevData) => ({ ...prevData, file_content: value }));
        setChangedVersionFields((prevFields) =>
            addUniqueElementToArray('file_content', prevFields)
        );
        setVersionInputsErrorMessages((prevData) => ({ ...prevData, file_content: null }));
    };

    return (
        <div className="flex-grow flex flex-col gap-4 min-h-[350px]">
            {isMainBranch && !isLive ? (
                <div className="w-full h-full flex flex-col gap-2">
                    <p className="font-body-bold text-body-bold-s text-neutral-500 uppercase">
                        Template file
                    </p>
                    <div className="flex-grow rounded-2 border-1 border-neutral-300 px-3 py-4 flex justify-center items-center">
                        <p className="font-body-bold text-body-bold-m text-neutral-300">
                            No file found at branch /main
                        </p>
                    </div>
                </div>
            ) : (
                <JsonTextareaBox
                    mode="code"
                    value={content}
                    label="TEMPLATE FILE"
                    state={!editable ? 'disabled' : errorMessage ? 'error' : 'default'}
                    setValue={handleContentChange}
                    errorMessage={errorMessage}
                    isRequired
                    withCopyButton
                    withDownloadButton
                    shouldRefreshValue={shouldRefreshTemplateFile}
                    setShouldRefreshValue={setShouldRefreshTemplateFile}
                    autoExpand
                />
            )}
        </div>
    );
};

export default TemplateFileBlock;
