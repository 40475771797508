import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import organizationClient from '../../../services/organization-api';

import { formatVersionDataForSelectOption } from '../../../helpers/formatVersionDataForSelectOption';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

import CollapsableContainer from '../../../components/CollapsableContainer/CollapsableContainer';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import Alert from '../../../design-system/Alert/Alert';
import { Select } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const BranchAndOrgTestSettingsBlock = ({
    isExpanded,
    toggleExpand,
    versions,
    blockFormData,
    setBlockFormData,
    fieldsErrorMessage,
    setFieldsErrorMessage,
    isOrganizationPreselectLoading,
    orgIdFromPreviousTestRun = null,
    stopPreselecting,
    blockTitle,
    versionSelectLabel,
    organizationSelectPlaceholder,
}) => {
    const [searchParams] = useSearchParams();
    const organizationFromUrl = useMemo(
        () => +searchParams.get('organization') || null,
        [searchParams]
    );
    const organizationIdToPreselect = orgIdFromPreviousTestRun || organizationFromUrl;

    const branchSelectOptions = useMemo(
        () => [
            ...(versions || [])
                .filter((version) => version.status !== 'canceled')
                .map(formatVersionDataForSelectOption),
        ],
        [versions]
    );

    const [errorAlert, setErrorAlert] = useState(null);

    const setOrganizationOptions = (prevData, data) => {
        const newData =
            data.results?.map((item) => ({
                id: item.organization.id,
                name: item.organization.name,
            })) || [];
        return [...prevData, ...newData];
    };

    const {
        options: organizationOptions,
        optionsLoading: organizationOptionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions: totalOrganizationOptions,
        isFirstRequestCompleted: areFirstPageOrganizationOptionsLoaded,
    } = useFetchOptionsForPaginatedSelect({
        client: organizationClient,
        route: API.ROUTES.organization.memberships,
        callback: setOrganizationOptions,
    });

    useEffect(() => {
        const shouldPreselectOrganization =
            isOrganizationPreselectLoading &&
            areFirstPageOrganizationOptionsLoaded &&
            !blockFormData.organization &&
            organizationIdToPreselect;

        if (shouldPreselectOrganization) {
            // find the appropriate organization among all organization (first pagination page) and set it in the organization select
            const organizationToPreselect = organizationOptions.find(
                (organization) => organization.id === organizationIdToPreselect
            )?.id;

            if (organizationToPreselect) {
                handleSelectChange('organization', organizationToPreselect);
            } else {
                // if such organization is not found => stop preselecting (means stop filling other fields and turn off the loader)
                const isPreviousTestRunDataPreselecting = !!orgIdFromPreviousTestRun;
                if (isPreviousTestRunDataPreselecting) {
                    setErrorAlert({
                        message:
                            "Oops, this Test Run's settings could not be loaded! Please try again.",
                    });
                }

                stopPreselecting();
            }
        }
    }, [
        organizationOptions,
        organizationIdToPreselect,
        isOrganizationPreselectLoading,
        areFirstPageOrganizationOptionsLoaded,
    ]);

    const handleSelectChange = (name, value) => {
        // return if user select already selected option
        if (blockFormData[name] === value) return;

        setBlockFormData((prevData) => ({ ...prevData, [name]: value }));
        if (fieldsErrorMessage[name]) {
            setFieldsErrorMessage((prevErrorMessages) => ({ ...prevErrorMessages, [name]: null }));
        }
    };

    return (
        <CollapsableContainer
            title={blockTitle}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
        >
            <div className="flex flex-col gap-4">
                <Select
                    size="sm"
                    name="version"
                    value={blockFormData.version}
                    options={branchSelectOptions}
                    optionsLoading={!versions}
                    label={versionSelectLabel}
                    isRequired
                    onChange={(value) => handleSelectChange('version', value)}
                    placeholder="Select a branch"
                    includeClientSideFiltering
                    dropdownHeight={280}
                    disableSorting
                    filterSearchBarPlaceholder="Search branches"
                />
                <PaginatedSelect
                    size="sm"
                    name="organization"
                    value={blockFormData.organization}
                    options={organizationOptions}
                    optionsLoading={organizationOptionsLoading}
                    label="Select an Organization"
                    isRequired
                    placeholder={organizationSelectPlaceholder}
                    onChange={(value) => handleSelectChange('organization', value)}
                    state={fieldsErrorMessage.organization ? 'error' : 'default'}
                    errorMessage={fieldsErrorMessage.organization}
                    fetchOptions={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMoreOptions}
                    includeClientSideFiltering
                    totalOptionsCount={totalOrganizationOptions}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </CollapsableContainer>
    );
};

export default BranchAndOrgTestSettingsBlock;
