import React from 'react';
import { useLocation } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/library-api';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { SORT_TYPES } from '../../constants/sort';
import PaginatedDropdown from '../../design-system/PaginatedDropdown/PaginatedDropdown';
import PlayCircleFillIcon from '../../design-system/Icons/PlayCircleFillIcon';

const FilterByProcessDropdown = ({
    selectedOption,
    onClose,
    handleOptionSelect,
    handleOptionRemove,
}) => {
    const { width: screenWidth } = useWindowSize();
    const location = useLocation();
    const pageLocation = location.pathname?.includes('threads')
        ? 'threads'
        : location.pathname?.includes('/process/runs')
        ? 'workRuns'
        : 'inbox';

    const formatResponseToOptions = (results) =>
        results?.map(({ id, name, icon_color }) => ({
            id,
            name,
            badgeLeadingIconColor: icon_color,
        }));

    const {
        options: processOptions,
        optionsLoading,
        totalOptions,
        setPage,
        canLoadMoreOptions,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.process,
        searchParams: { ordering: SORT_TYPES.alphabetical },
        formatResponseToOptions,
    });

    const dropdownWidth = {
        threads: screenWidth >= 712 ? 360 : 330,
        workRuns: screenWidth >= 800 ? 360 : 330,
        inbox: screenWidth >= 860 && screenWidth < 1180 ? 265 : 340,
    };

    const dropdownPosition = {
        threads: 'absolute top-[105%] sm:max-[950px]:right-0 max-sm:left-0 min-[950px]:left-0',
        workRuns: 'absolute top-[105%] sm:max-[800px]:right-0 max-sm:left-0 min-[800px]:left-0',
        inbox: 'absolute top-[110%] right-[16px]',
    };

    return (
        <div className={dropdownPosition[pageLocation]}>
            <PaginatedDropdown
                options={processOptions}
                fetchOptions={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                optionsLoading={optionsLoading}
                handleOptionSelect={handleOptionSelect}
                selectedOption={selectedOption}
                showOptionsAsRemovableBadge
                handleOptionRemove={handleOptionRemove}
                highlightSelectedOption={false}
                badgeColor="neutral"
                badgeLeadingIcon={PlayCircleFillIcon}
                dropdownMaxHeight={246}
                fixedWidth={dropdownWidth[pageLocation]}
                closeDropdown={onClose}
                includeClientSideFiltering
                totalOptionsCount={totalOptions}
            />
        </div>
    );
};

export default FilterByProcessDropdown;
