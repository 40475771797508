import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import { API } from 'constants';
import operateClient from '../../services/operate-api';
import client from '../../services/library-api';

import { updateOutputState } from '../../helpers/updateOutputState';
import { ArrowGoBackLineIcon, TerminalBoxLineIcon } from '../../design-system/Icons';
import ArchiveLineIcon from '../../design-system/Icons/ArchiveLineIcon';
import Button from '../../design-system/Button/Button';
import Loading from '../../components/Loading';
import HistoryLineIcon from '../../design-system/Icons/HistoryLineIcon';
import OutputRatingControls from '../../components/OutputRatingControls/OutputRatingControls';
import CollapsableContainer from '../../components/CollapsableContainer/CollapsableContainer';
import NewRunContainer from '../../components/NewRunContainer/NewRunContainer';
import { SIMPLIFIED_TASK_TYPES } from '../../constants/library';
import { taskTypeLabel } from '../TaskHistoryIndexPage/TaskHistoryIndexPage';
import PromptSpecificSection from './PromptSpecificSection/PromptSpecificSection';
import ViewHistoricalRunHeader from './ViewHistoricalRunHeader/ViewHistoricalRunHeader';

const ViewHistoricalRunPage = () => {
    const { promptId, taskId, resultId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '/library';
    const historyRunsPageNumber = location.state?.historyRunsPageNumber || null;

    // prompt or task
    const simplifiedTaskType = location.pathname?.includes('/prompt/') // here we use a name simplifiedTaskType instead of just taskType because on this page we need to distinguish all taskTypes (as webhook or assistant ect.)
        ? SIMPLIFIED_TASK_TYPES.prompt
        : SIMPLIFIED_TASK_TYPES.nonPrompt;

    const id =
        simplifiedTaskType === SIMPLIFIED_TASK_TYPES.prompt ? Number(promptId) : Number(taskId); // promptId or taskId dependent on what page user lands (PromptHistoricalRunPage or TaskHistoricalRunPage)

    const [taskData, setTaskData] = useState(null); // data for prompt or non-prompt task
    const [resultData, setResultData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState({
        prompt: false,
        promptSettings: false,
        inputs: true,
    });

    const inputs = resultData
        ? resultData.inputs?.map((input) => ({ ...input, state: 'disabled', isRequired: true }))
        : [];
    const context = resultData
        ? resultData?.context_json?.map((input) => ({
              ...input,
              state: 'disabled',
              isRequired: true,
          }))
        : [];
    const outputs = resultData ? updateOutputState(resultData.outputs) : [];

    const taskDataRequestEndpoint = {
        [SIMPLIFIED_TASK_TYPES.prompt]: `${API.ROUTES.library.prompt}${id}/`,
        [SIMPLIFIED_TASK_TYPES.nonPrompt]: `${API.ROUTES.library.task}${id}/`,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const [taskDataResponse, resultDataResponse] = await Promise.all([
                    client.get(taskDataRequestEndpoint[simplifiedTaskType]),
                    operateClient.get(`${API.ROUTES.operate.result}${resultId}/`),
                ]);

                const taskData = taskDataResponse.data;
                const resultData = resultDataResponse.data;

                setTaskData(taskData);
                setResultData(resultData);
            } catch (e) {
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const toggleCollapsed = (field) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handleBackPressed = () => {
        navigate(`/library/${taskTypeLabel[simplifiedTaskType]}/${id}/history?page=runs`, {
            state: { libraryLocation, historyRunsPageNumber },
        });
    };

    const copyInputsToPlayground = () => {
        navigate(`/library/${taskTypeLabel[simplifiedTaskType]}/${id}?fill=${resultId}`, {
            state: { libraryLocation },
        });
    };

    return (
        <div className="w-full sm:w-[calc(100%-68px)] h-full min-h-[calc(100vh-64px)] px-[10px] py-5 bg-white flex flex-col gap-4 sm:gap-8 items-start">
            {!isLoading && resultData && (
                <>
                    <div className="w-full max-w-[800px] mx-auto flex flex-col gap-5 items-start">
                        <Button
                            type="link"
                            size="sm"
                            text="Back to Run History"
                            onClick={handleBackPressed}
                            leadingIcon={ArrowGoBackLineIcon}
                            trailingIcon={HistoryLineIcon}
                        />
                        <div className="p-3 sm:p-5 flex flex-col gap-8 w-full">
                            <ViewHistoricalRunHeader
                                resultData={resultData}
                                taskData={taskData}
                                simplifiedTaskType={simplifiedTaskType}
                            />
                            <div className="p-4 rounded-2 bg-neutral-50 shadow-inset-1 shadow-neutral-200 flex justify-center items-center gap-8">
                                <p className="font-body text-body-bold-m text-netral-500 uppercase text-center">
                                    Rate your {taskTypeLabel[simplifiedTaskType]} run
                                </p>
                                <OutputRatingControls
                                    outputRating={resultData.rating}
                                    resultId={resultId}
                                    showSuccessAlert
                                />
                            </div>
                            <div className="p-4 rounded-2 shadow-inset-1 shadow-purple-300 flex flex-col gap-3">
                                {simplifiedTaskType === SIMPLIFIED_TASK_TYPES.prompt && (
                                    <PromptSpecificSection
                                        resultData={resultData}
                                        isExpanded={isExpanded}
                                        toggleCollapsed={toggleCollapsed}
                                    />
                                )}
                                <CollapsableContainer
                                    title="Inputs"
                                    isExpanded={isExpanded.inputs}
                                    toggleExpand={() => toggleCollapsed('inputs')}
                                >
                                    <div className="flex flex-col gap-6">
                                        <NewRunContainer
                                            textAreaData={inputs}
                                            useKeyAsALabel
                                            darkBg
                                            withCopyButton
                                        />
                                        {simplifiedTaskType === SIMPLIFIED_TASK_TYPES.prompt && (
                                            <NewRunContainer
                                                textAreaData={context}
                                                withCopyButton
                                                withDeleteButton={false}
                                                useDoubleBracketsKeyAsALabel
                                                leadingIcon={ArchiveLineIcon}
                                                darkBg
                                            />
                                        )}
                                    </div>
                                </CollapsableContainer>
                                <div className="flex-grow flex flex-col pb-[10px] pt-2 pb-5">
                                    <h3 className="font-body text-body-bold-m text-neutral-500 uppercase mb-4">
                                        Output
                                    </h3>
                                    <NewRunContainer
                                        textAreaData={outputs}
                                        withCopyButton
                                        placeholder="Output will be added here"
                                        darkBg
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed bottom-[12px] sm:bottom-[24px] right-[8px] sm:right-[36px]">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Copy Inputs to Playground"
                            trailingIcon={TerminalBoxLineIcon}
                            onClick={copyInputsToPlayground}
                        />
                    </div>
                </>
            )}
            {isLoading && (
                <div className="w-full h-full flex-grow flex justify-center items-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ViewHistoricalRunPage;
