import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ message }) => {
    return (
        <div className="error">
            <div className="error"></div>
            <p>Oops, we broke something. :/</p>
            <p>{message}</p>
        </div>
    );
};

Error.prototype = {
    message: PropTypes.string,
};

export default Error;
