import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API } from 'constants';
import operateClient from '../../../services/operate-api';

import {
    transformPromptRunDataForTable,
    transformTaskRunDataForTable,
} from '../../../helpers/formatHistoryDataForTable';
import { SIMPLIFIED_TASK_TYPES } from '../../../constants/library';

import Table from '../../../design-system/Table/Table';
import Loading from '../../../components/Loading';
import { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';
import { taskTypeLabel } from '../TaskHistoryIndexPage';

const TaskRunHistory = () => {
    const params = useParams();
    const id = Number(params.id);

    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '/library';
    const initialPageNumber = location.state?.historyRunsPageNumber || 1;

    // prompt or task
    const taskType = location.pathname?.includes('/prompt/')
        ? SIMPLIFIED_TASK_TYPES.prompt
        : SIMPLIFIED_TASK_TYPES.nonPrompt;

    const [historyData, setHistoryData] = useState([]);
    const [historyTableData, setHistoryTableData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(initialPageNumber);

    const requestEndpoint = {
        [SIMPLIFIED_TASK_TYPES.prompt]: `${API.ROUTES.operate.promptHistory}${id}/`,
        [SIMPLIFIED_TASK_TYPES.nonPrompt]: `${API.ROUTES.operate.taskHistory}${id}/`,
    };

    const transformDataForTableFunction = {
        [SIMPLIFIED_TASK_TYPES.prompt]: transformPromptRunDataForTable,
        [SIMPLIFIED_TASK_TYPES.nonPrompt]: transformTaskRunDataForTable,
    };

    useEffect(() => {
        const fetchPromptHistory = async () => {
            try {
                setIsLoading(true);
                const { data } = await operateClient.get(
                    `${requestEndpoint[taskType]}?ordering=-created_at&limit=12&offset=${
                        (page - 1) * DEFAULT_LIMIT
                    }`
                );
                setHistoryData(data.results);
                setHistoryTableData(transformDataForTableFunction[taskType](data, page));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log('error', error);
            }
        };

        fetchPromptHistory();
    }, [page]);

    const handleViewClick = (resultId) => {
        navigate(`/library/${taskTypeLabel[taskType]}/${id}/run/${resultId}`, {
            state: { libraryLocation, historyRunsPageNumber: page },
        });
    };

    return (
        <>
            {!isLoading && historyTableData && historyData.length > 0 && (
                <>
                    {taskType === SIMPLIFIED_TASK_TYPES.prompt && (
                        <div className="w-full relative">
                            <Table
                                data={historyTableData}
                                size="md"
                                rowActionButtons={[
                                    {
                                        action: 'view',
                                        handleAction: handleViewClick,
                                    },
                                ]}
                                columnsCount={2}
                                shift={720}
                                includePagination={true}
                                onPaginationPreviousClick={() =>
                                    setPage((prevPage) => prevPage - 1)
                                }
                                onPaginationNextClick={() => setPage((prevPage) => prevPage + 1)}
                            />
                        </div>
                    )}
                    {taskType === SIMPLIFIED_TASK_TYPES.nonPrompt && (
                        <div className="w-full relative">
                            <Table
                                data={historyTableData}
                                size="md"
                                rowActionButtons={[
                                    {
                                        action: 'view',
                                        handleAction: handleViewClick,
                                    },
                                ]}
                                columnsCount={2}
                                shift={705}
                                includePagination={true}
                                onPaginationPreviousClick={() =>
                                    setPage((prevPage) => prevPage - 1)
                                }
                                onPaginationNextClick={() => setPage((prevPage) => prevPage + 1)}
                            />
                        </div>
                    )}
                </>
            )}
            {!isLoading && historyData.length === 0 && (
                <p className="font-body text-body-regular-l text-neutral-500">
                    Your Run History is empty.{' '}
                </p>
            )}
            {isLoading && (
                <div className="w-full flex justify-center items-center pt-8">
                    <Loading text="" />
                </div>
            )}
        </>
    );
};

export default TaskRunHistory;
