import React, { useEffect, useState } from 'react';
import { API } from 'constants';
import Modal from 'design-system/Modal/Modal';
import { Button, Select, SvgIcon } from 'design-system';
import EscapeIcon from 'design-system/Icons/EscapeIcon';
import Input from 'design-system/Input/Input';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import Alert from 'design-system/Alert/Alert';
import client from 'services/organization-api';
import { usePatchRequest, usePostRequest } from 'hooks/request';
import Checkbox from 'design-system/Checkbox/Checkbox';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const EditMembershipModal = ({ onClose, data, mode, handleSave, organizationData, teamsData }) => {
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });
    const [errorMessage, setErrorMessage] = useState(null);

    const [member, setMember] = useState({});
    const [selectedTeams, setSelectedTeams] = useState([]);

    const membershipCreate = usePostRequest(
        { url: API.ROUTES.organization.organizationMembership },
        client
    );
    const membershipUpdate = usePatchRequest({}, client);
    const [errorFields, setErrorFields] = useState({
        email: false,
        role: false,
    });

    useEffect(() => {
        if (mode === 'edit' && data && teamsData) {
            setMember({
                email: data.user.email,
                role: data.org_role,
                organization: organizationData.id,
            });
            const initialSelectedTeams = teamsData
                .filter((team) => team.team_role)
                .map((team) => team.id);
            setSelectedTeams(initialSelectedTeams);
        } else {
            setMember({ email: '', organization: organizationData?.id, teams: [] });
        }
    }, [mode, data, teamsData]);

    const handleInputChange = (e) => {
        setMember((prevCredentials) => ({
            ...prevCredentials,
            [e.target.name]: e.target.value,
        }));
        if (errorFields[e.target.name]) {
            setErrorFields((prevState) => ({ ...prevState, [e.target.name]: false }));
        }
    };

    const handleRoleSelectChange = (role) => {
        setMember((prevCredentials) => ({
            ...prevCredentials,
            role,
        }));
        if (errorFields.role) {
            setErrorFields((prevState) => ({ ...prevState, role: false }));
        }
    };

    const handleTeamCheckboxChange = (teamId) => {
        // Toggle the selection of the team based on its ID
        setSelectedTeams((prevSelectedTeams) =>
            prevSelectedTeams.includes(teamId)
                ? prevSelectedTeams.filter((id) => id !== teamId)
                : [...prevSelectedTeams, teamId]
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!member.email || !member.role) {
            setErrorFields({
                email: !member.email,
                role: !member.role,
            });
            setErrorMessage('Please fill in this field.');
            return;
        }

        if (mode === 'edit') {
            const { success, error } = await membershipUpdate.request({
                data: {
                    teams: selectedTeams,
                    role: member.role,
                    organization: member.organization,
                },
                url: `${API.ROUTES.organization.organizationMembership}${data.id}/`,
            });
            if (success) {
                onClose();
                handleSave();
            }
        } else {
            const requestBody = { ...member, teams: selectedTeams };
            const { success, error } = await membershipCreate.request({
                data: requestBody,
            });
            if (success) {
                onClose();
                handleSave();
            }
            if (error) {
                if (error.data && error.data.email) {
                    setErrorMessage(error.data.email[0]);
                    setErrorFields({ email: true });
                }
            }
        }
    };

    return (
        <Modal size="large" onClose={onClose}>
            <div className="flex flex-col gap-[16px]">
                <div className="flex justify-between items-center gap-2">
                    <h2 className="font-heading-bold text-heading-bold-m text-black">
                        {mode === 'create'
                            ? `Add member to ${organizationData?.name} Organization`
                            : 'Editing member access'}
                    </h2>
                    <div onClick={onClose} className="cursor-pointer">
                        <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle"></SvgIcon>
                    </div>
                </div>
                <Input
                    label={'Email Address'}
                    value={member.email}
                    placeholder="example@mail.com"
                    name="email"
                    size="xs"
                    onChange={handleInputChange}
                    isRequired
                    state={errorFields.email ? 'error' : mode === 'edit' ? 'disabled' : 'default'}
                    errorMessage={errorMessage}
                    tipText={
                        mode === 'create'
                            ? 'Tip: this email address must already have an account.'
                            : ''
                    }
                />
                <Select
                    size="md"
                    name="role"
                    label="Member type"
                    value={member.role}
                    isRequired
                    options={[
                        { id: 'member', name: 'Member' },
                        { id: 'admin', name: 'Admin' },
                    ]}
                    state={errorFields.role ? 'error' : 'default'}
                    errorMessage="Please select a Member type"
                    onChange={handleRoleSelectChange}
                />
                <p className="font-body text-body-bold-s neutral-500 mb-[8px]">
                    Add member to teams
                </p>
                {teamsData &&
                    teamsData.map((team) => (
                        <Checkbox
                            key={team.id}
                            isChecked={selectedTeams.includes(team.id)}
                            onChange={() => handleTeamCheckboxChange(team.id)}
                            label={team.name}
                            name={`team_${team.id}`}
                            state={errorFields.name ? 'error' : 'default'}
                        />
                    ))}
                <div className="flex justify-between">
                    <Button type="ghost" text="Cancel" size="sm" onClick={onClose} />
                    <Button type="primary" text={'Confirm'} size="sm" onClick={handleSubmit} />
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorMessage || defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </Modal>
    );
};

export default EditMembershipModal;
