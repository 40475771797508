import { VIEW_TYPES } from './viewTypes';

export const ORGANIZATION_PLAN = {
    plus: 'plus',
    pro: 'pro',
    agency: 'agency',
};

export const USER_ROLE = {
    owner: 'owner',
    admin: 'admin',
    member: 'member',
};

//ACCESS_CONFIG object indicates who has access to the admin and client viewTypes
export const ACCESS_CONFIG = {
    [VIEW_TYPES.admin]: {
        [ORGANIZATION_PLAN.plus]: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
        [ORGANIZATION_PLAN.pro]: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
        [ORGANIZATION_PLAN.agency]: [USER_ROLE.owner, USER_ROLE.admin],
    },
    [VIEW_TYPES.client]: {
        [ORGANIZATION_PLAN.plus]: [],
        [ORGANIZATION_PLAN.pro]: [],
        [ORGANIZATION_PLAN.agency]: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
    },
};

export const ORGANIZATION_ID_LS_KEY = 'organization_id';
