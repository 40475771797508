import { useEffect, useRef } from 'react';
import { useWindowSize } from './useWindowSize';

export const useTextareaAutoResize = (text, minHeight = 0) => {
    const textareaRef = useRef(null);
    const textareaContainerRef = useRef(null);

    const { width: screenWidth } = useWindowSize();

    useEffect(() => {
        if (textareaRef.current && textareaContainerRef.current) {
            textareaRef.current.style.height = 'auto';
            const scrollHeight = textareaRef.current.scrollHeight;

            const heightResult = Math.max(scrollHeight, minHeight);
            textareaRef.current.style.height = heightResult + 'px';
            textareaContainerRef.current.style.height = heightResult + 'px';
        }
    }, [textareaRef, textareaContainerRef, text, screenWidth]);

    return { textareaRef, textareaContainerRef };
};
