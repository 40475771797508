import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { API } from 'constants';
import operateClient from '../../services/operate-api';
import { useWindowSize } from '../../hooks/useWindowSize';

import Loading from '../Loading';
import SvgIcon from '../../design-system/SvgIcon/SvgIcon';
import ThumbsUpIcon from '../../design-system/Icons/ThumbsUpIcon';
import ThumbsDownIcon from '../../design-system/Icons/ThumbsDownIcon';
import { ErrorWarningLineIcon } from '../../design-system/Icons';
import Alert from '../../design-system/Alert/Alert';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';
import { ButtonIcon } from '../../design-system';
import { defaultErrorMessage } from '../../constants/errorMessages';

const OutputRatingControls = ({
    resultId,
    outputRating,
    updateOutputRating,
    useExternalState = false,
    showSuccessAlert = false,
    onSuccessRatingUpdate = () => {},
    view = 'icon',
    size = 'sm', // size for buttonIcons
}) => {
    const [rating, setRating] = useState(outputRating);
    const liked = useExternalState ? outputRating === 1 : rating === 1;
    const disliked = useExternalState ? outputRating === -1 : rating === -1;

    const { width: screenWidth } = useWindowSize();

    const [isUpdateRatingLoading, setIsUpdateRatingLoading] = useState({
        like: false,
        dislike: false,
    });
    const [statusAlert, setStatusAlert] = useState(null);

    const updateRating = async (ratingType) => {
        try {
            setIsUpdateRatingLoading((prevState) => ({ ...prevState, [ratingType]: true }));
            const currentRating = useExternalState ? outputRating : rating;
            const updatedRating =
                ratingType === 'like'
                    ? currentRating === 1
                        ? 0
                        : 1
                    : ratingType === 'dislike'
                    ? currentRating === -1
                        ? 0
                        : -1
                    : 0;
            const { data } = await operateClient.patch(`${API.ROUTES.operate.result}${resultId}/`, {
                rating: updatedRating,
            });
            if (useExternalState) {
                updateOutputRating(data.rating);
            } else {
                setRating(data.rating);
                if (updateOutputRating) {
                    updateOutputRating(data.rating);
                }
            }
            await onSuccessRatingUpdate(data);
            if (showSuccessAlert) {
                setStatusAlert({
                    status: 'positive',
                    message: 'Prompt run rating successfully updated!',
                });
            }
        } catch (e) {
            setStatusAlert({
                status: 'critical',
                statusCode: e.response.status,
                message: defaultErrorMessage,
                icon: ErrorWarningLineIcon,
            });
        } finally {
            setIsUpdateRatingLoading((prevState) => ({ ...prevState, [ratingType]: false }));
        }
    };

    return (
        <>
            {view === 'icon' && (
                <div className="flex items-center gap-1.5">
                    <div
                        className="w-[28px] h-[28px] flex items-center justify-center cursor-pointer"
                        onClick={isUpdateRatingLoading.like ? () => {} : () => updateRating('like')}
                    >
                        {isUpdateRatingLoading.like && <Loading withText={false} />}
                        {!isUpdateRatingLoading.like && (
                            <SvgIcon
                                color={liked ? '#0E9F6E' : disliked ? '#CFD6E5' : '#1F2125'}
                                icon={ThumbsUpIcon}
                                size="medium"
                            />
                        )}
                    </div>

                    <div
                        className="w-[28px] h-[28px] flex items-center justify-center cursor-pointer"
                        onClick={
                            isUpdateRatingLoading.dislike ? () => {} : () => updateRating('dislike')
                        }
                    >
                        {isUpdateRatingLoading.dislike && <Loading withText={false} />}
                        {!isUpdateRatingLoading.dislike && (
                            <SvgIcon
                                color={disliked ? '#E95B69' : liked ? '#CFD6E5' : '#1F2125'}
                                icon={ThumbsDownIcon}
                                size="medium"
                            />
                        )}
                    </div>
                </div>
            )}
            {view === 'buttonIcon' && (
                <div className={`flex items-center ${size === 'xs' ? 'gap-2' : 'gap-3'} sm:gap-2`}>
                    <ButtonIcon
                        type={liked ? 'secondary' : 'neutral'}
                        size={screenWidth >= 640 ? size : 'xs'}
                        state={isUpdateRatingLoading.like ? 'loading' : 'default'}
                        icon={ThumbsUpIcon}
                        onClick={() => updateRating('like')}
                        showSpinnerOnLoading
                    />
                    <ButtonIcon
                        type={disliked ? 'secondary' : 'neutral'}
                        size={screenWidth >= 640 ? size : 'xs'}
                        state={isUpdateRatingLoading.dislike ? 'loading' : 'default'}
                        icon={ThumbsDownIcon}
                        onClick={() => updateRating('dislike')}
                        showSpinnerOnLoading
                    />
                </div>
            )}
            {statusAlert && (
                <Alert
                    status={statusAlert.status}
                    message={statusAlert.message}
                    statusCode={statusAlert.statusCode}
                    icon={statusAlert.icon || CheckLineIcon}
                    handleClose={() => setStatusAlert(null)}
                />
            )}
        </>
    );
};

OutputRatingControls.propTypes = {
    resultId: PropTypes.number.isRequired,
    outputRating: PropTypes.number.isRequired,
    updateOutputRating: PropTypes.func,
    useExternalState: PropTypes.bool,
    showSuccessAlert: PropTypes.bool,
    view: PropTypes.oneOf(['icon', 'buttonIcon']),
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
};

export default OutputRatingControls;
