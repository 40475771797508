import React, { useState } from 'react';
import { API } from 'constants';
import clientObject from '../../../../services/community-api';
import NewTextAreaBox from '../../../../design-system/NewTextAreaBox/NewTextAreaBox';
import { Button } from '../../../../design-system';
import { formatStringToSlug } from '../../../../helpers/formatStringToSlug';
import Alert from '../../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

const CreatePublishedPromptBlock = ({
    promptId,
    setPrompt,
    taskName,
    defaultVersion,
    updateTaskPublishId,
    allowMakeAnyChanges,
}) => {
    const { clientWithToken } = clientObject;

    const [publishNotes, setPublishNotes] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const publishPrompt = async () => {
        try {
            setIsLoading(true);
            const requestBody = {
                prompt: promptId,
                prompt_version: defaultVersion.id,
                slug: formatStringToSlug(taskName),
                info: {
                    publish_note: publishNotes,
                    example_inputs: [],
                    example_outputs: [],
                },
            };
            const { data } = await clientWithToken.post(
                API.ROUTES.community.publishPrompt,
                requestBody
            );
            const publish_id = data.id;
            if (setPrompt) {
                setPrompt((prevPrompt) => ({ ...prevPrompt, publish_id }));
            }
            if (updateTaskPublishId) {
                updateTaskPublishId(publish_id);
            }
            setIsLoading(false);
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response.status });
            setIsLoading(false);
        }
    };

    return (
        <>
            <h3 className="font-heading-bold text-heading-bold-m text-neutral-500">
                Prompt Publishing
            </h3>
            <p className="font-body text-body-regular-m text-neutral-400">
                Publish your prompt live so that other users can see and duplicate to their library.
            </p>
            <div className="flex flex-col">
                <NewTextAreaBox
                    name="notes"
                    value={publishNotes}
                    onChange={(e) => {
                        setPublishNotes(e.target?.value);
                    }}
                    label="Publish Notes"
                    placeholder="Add a publish note. You can use this to promote your work and/or explain how to use this task"
                    errorMessage="Please fill in this field."
                    state={allowMakeAnyChanges ? 'default' : 'disabled'}
                    withCopyButton
                    fullHeight
                />
            </div>
            <div className="mx-auto">
                <Button
                    type="primary"
                    size="sm"
                    text="Publish Prompt"
                    state={!allowMakeAnyChanges ? 'disabled' : isLoading ? 'loading' : 'default'}
                    onClick={publishPrompt}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default CreatePublishedPromptBlock;
