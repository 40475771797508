import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import libraryClient from '../../services/library-api';
import operateClient from '../../services/operate-api';
import { API } from 'constants';

import {
    PROMPT_CHAINS_SORT_TYPES,
    PROMPT_CHAINS_TYPES_LABELS,
    promptChainSortItems,
} from '../../constants/promptChains';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { usePagination } from '../../hooks';

import { Button, Dropdown, Tabs } from '../../design-system';
import { AddCircleLineIcon, FlashlightFillIcon } from '../../design-system/Icons';
import PromptChainPaginatedContainer from './PromptChainPaginatedContainer/PromptChainPaginatedContainer';
import { parsePromptChainsSearchParams } from '../../helpers/parsePromptChainsSearchParams';

const PROMPT_CHAIN_SORT_INDEX_LOOKUP = [
    PROMPT_CHAINS_SORT_TYPES.alphabetical,
    PROMPT_CHAINS_SORT_TYPES.created,
];

const tabs = [{ name: 'Runs' }, { name: 'Prompt Chains' }];

const PromptChainsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const path = location.pathname?.slice(1);
    const tabIndex = path === 'chains' ? 1 : 0;

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parsePromptChainsSearchParams(searchParams);
    const [showSortDropdown, setShowSortDropdown] = useState(false);
    const sortDropdownIndex = promptChainSortItems.findIndex(
        (item) => item.value === parsedParams.sort
    );

    const { total } = usePagination({
        sortType: parsedParams.sortType || PROMPT_CHAINS_SORT_TYPES.created,
        pageIndex: 0,
        client: path === 'chains' ? libraryClient : operateClient,
        route: path === 'chains' ? API.ROUTES.library.workflow : API.ROUTES.operate.worker,
    });

    useDocumentTitle('Prompt Chains');

    useEffect(() => {
        let pageNumber = Number.isNaN(parsedParams.page) ? 1 : parsedParams.page;
        let sort = Object.values(PROMPT_CHAINS_SORT_TYPES).includes(parsedParams.sort)
            ? parsedParams.sort
            : PROMPT_CHAINS_SORT_TYPES.created;

        if (Number.isNaN(parsedParams.page) || parsedParams.sort !== sort) {
            setSearchParams({
                ...parsedParams,
                page: pageNumber,
                sort: sort,
            });
        }
    }, [parsedParams, setSearchParams]);

    const onTabChanged = () => {
        navigate(path === 'chains' ? '/runs' : '/chains');
    };

    const handleNewPromptChainClick = () => {
        navigate('/chain/new', { state: { from: location } });
    };

    const handleNewRunClick = () => {
        navigate('/run/new', {
            state: { chainsSearchParams: location.search },
        });
    };

    function handleNextPressed() {
        setSearchParams({ ...parsedParams, page: parsedParams.page + 1 });
    }

    function handlePreviousPressed() {
        if (parsedParams.page > 0) {
            setSearchParams({ ...parsedParams, page: parsedParams.page - 1 });
        }
    }

    function onSortItemSelect(index) {
        const sort = PROMPT_CHAIN_SORT_INDEX_LOOKUP[index] || PROMPT_CHAINS_SORT_TYPES.created;
        if (parsedParams.sort !== sort) {
            setSearchParams({ ...parsedParams, sort });
        }
        setShowSortDropdown(false);
    }

    return (
        <div className="w-full sm:w-[calc(100%-68px)] max-sm:px-[12px] max-sm:py-[16px] flex flex-col gap-5">
            <div className="flex items-start flex-col">
                <p className="font-heading-bold text-heading-bold-l">Prompt Chains</p>
                <p className="font-body text-body-regular-xs text-neutral-300 pt-1">
                    Prompts combined to run sequentially to complete a task{' '}
                </p>
            </div>
            <div className="flex flex-col md:flex-row gap-2 justify-between ">
                <div className="flex items-center gap-4 max-sm:relative">
                    <Tabs onTabChanged={onTabChanged} tabIndex={tabIndex} tabs={tabs} />
                    <div className="sm:relative cursor-pointer">
                        <span
                            className="text-black font-body-bold text-body-bold-s underline"
                            onClick={() => setShowSortDropdown((show) => !show)}
                        >
                            Sort
                        </span>
                        <div className="absolute max-sm:left-0 max-sm:bottom-0">
                            <Dropdown
                                showDropdown={showSortDropdown}
                                onItemSelect={onSortItemSelect}
                                items={promptChainSortItems}
                                initiallySelectedIndex={
                                    sortDropdownIndex === -1 ? 1 : sortDropdownIndex
                                }
                            />
                        </div>
                    </div>{' '}
                </div>

                <div className="flex gap-[9px] items-center">
                    <Button
                        type="secondary"
                        size="xs"
                        text="New Prompt Chain"
                        leadingIcon={AddCircleLineIcon}
                        onClick={handleNewPromptChainClick}
                    />
                    <Button
                        type="primary"
                        size="xs"
                        text="New Run"
                        leadingIcon={FlashlightFillIcon}
                        state={total === 0 ? 'disabled' : 'default'}
                        onClick={handleNewRunClick}
                    />
                </div>
            </div>
            <PromptChainPaginatedContainer
                path={path}
                sortType={parsedParams.sort || PROMPT_CHAINS_SORT_TYPES.created}
                pageIndex={parsedParams.page - 1 || 0}
                onPreviousPressed={handlePreviousPressed}
                onNextPressed={handleNextPressed}
            />
        </div>
    );
};

export default PromptChainsPage;
