import React, { useEffect, useState } from 'react';
import { mutate } from 'swr';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import useUser from '../../../hooks/useUser';
import { PROPERTY_DATA_FORMAT } from '../../../constants/base';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { lowerCaseFirstLetter } from '../../../services/strings';

import { Badge, Button, ButtonIcon } from '../../../design-system';
import { CloseLineIcon, ErrorWarningLineIcon } from '../../../design-system/Icons';
import Alert from '../../../design-system/Alert/Alert';
import Panel from '../../../components/Panel';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import KnowledgeBaseEntryForm from '../KnowledgeBaseEntryForm/KnowledgeBaseEntryForm';

const KnowledgeBaseEntryPanel = ({
    action = 'create',
    base,
    entry,
    onClose,
    requestKeysToMutate,
    page,
}) => {
    const { user } = useUser();
    const { id: baseId, name, properties } = base;

    const [inputsData, setInputsData] = useState([]);
    const [dataFieldsError, setDataFieldsError] = useState({});

    const [errorAlert, setErrorAlert] = useState({ show: false, message: '', statusCode: null });
    const [isLoading, setIsLoading] = useState(false);

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    useEffect(() => {
        setInputsData(
            properties.map(({ name, dataFormat }) => ({
                name,
                dataFormat,
                value: entry
                    ? entry.properties[lowerCaseFirstLetter(name)]
                    : dataFormat === PROPERTY_DATA_FORMAT.checkbox
                    ? true
                    : '',
            }))
        );
        setDataFieldsError(properties.reduce((acc, { name }) => ({ ...acc, [name]: false }), {}));
    }, []);

    const closePanel = () => {
        if (!showConfirmDeleteModal) {
            onClose();
        }
    };

    const title = {
        create: `Create New ${name} Entry`,
        edit: 'Edit Entry',
        view: 'View Entry',
    };

    const createEntry = async (inputsResult) => {
        const requestBody = {
            properties: {
                ...inputsResult,
                isDeleted: false,
                createdBy: user.id,
                updatedBy: user.id,
            },
        };

        const { data: newEntry } = await client.post(
            `${API.ROUTES.knowledge.base}${baseId}/entry/`,
            requestBody
        );

        requestKeysToMutate?.map((request) =>
            mutate(
                request.key,
                request.page === 1 && request.id === baseId
                    ? (currentData) => {
                          if (currentData) {
                              const updatedResults = [newEntry, ...(currentData.results || [])];
                              return {
                                  ...currentData,
                                  count: currentData.count + 1,
                                  results: updatedResults,
                              };
                          }
                      }
                    : null,
                {
                    revalidate: true,
                }
            )
        );
    };

    const updateEntry = async (inputsResult) => {
        const requestBody = {
            properties: {
                ...inputsResult,
                isDeleted: false,
                createdBy: entry.properties.createdBy,
                updatedBy: user.id,
            },
        };

        const { data } = await client.patch(
            `${API.ROUTES.knowledge.base}${baseId}/entry/${entry.id}/`,
            requestBody
        );

        const correspondedRequestKey = requestKeysToMutate?.find(
            (item) => item.page === page && item.id === baseId
        );
        if (correspondedRequestKey) {
            mutate(
                correspondedRequestKey.key,
                (currentData) => {
                    if (currentData) {
                        const updatedResults = currentData.results.map((item) =>
                            item.id === entry.id ? data : item
                        );
                        return {
                            ...currentData,
                            results: updatedResults,
                        };
                    }
                },
                {
                    revalidate: false,
                }
            );
        }
    };

    const actionHandler = {
        create: createEntry,
        edit: updateEntry,
    };

    const handleAction = async () => {
        const areEmptyFields = inputsData.some(
            (item) => item.value === null || item.value === undefined || item.value === ''
        );

        if (areEmptyFields && action === 'create') {
            setDataFieldsError(
                inputsData.reduce(
                    (acc, item) =>
                        item.value === null || item.value === undefined || item.value === ''
                            ? { ...acc, [item.name]: true }
                            : acc,
                    {}
                )
            );
            return;
        }

        const inputsResult = inputsData.reduce(
            (acc, item) => ({
                ...acc,
                [item.name]:
                    item.dataFormat === PROPERTY_DATA_FORMAT.decimal && item.value
                        ? parseFloat(item.value)
                        : item.dataFormat === PROPERTY_DATA_FORMAT.integer && item.value
                        ? parseInt(item.value)
                        : item.value,
            }),
            {}
        );

        try {
            setIsLoading(true);
            await actionHandler[action](inputsResult);
            setIsLoading(false);
            onClose();
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.data?.non_field_errors) {
                setErrorAlert({ show: true, message: error.response.data.non_field_errors[0] });
            } else if (error.response && error.response.data?.error) {
                setErrorAlert({ show: true, message: error.response.data.error });
            } else {
                setErrorAlert({
                    show: true,
                    message: defaultErrorMessage,
                    statusCode: error.response.status,
                });
            }
        }
    };

    const getDeleteEntryRequestBody = () => {
        const inputsResult = inputsData.reduce(
            (acc, item) => ({
                ...acc,
                [item.name]: entry.properties[lowerCaseFirstLetter(item.name)],
            }),
            {}
        );

        return {
            properties: {
                ...inputsResult,
                createdBy: entry.properties.createdBy,
                updatedBy: user.id,
                isDeleted: true,
            },
        };
    };

    return (
        <Panel onClose={closePanel} showControls={false}>
            <div className="flex flex-col pb-4 justify-between gap-5 min-h-full">
                <div>
                    <div className="flex items-center justify-between gap-4 mb-3">
                        <p className="font-body-bold text-body-bold-l text-black w-[calc(100%-56px)]">
                            {title[action]}
                        </p>
                        <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                    </div>
                    {action !== 'view' && (
                        <KnowledgeBaseEntryForm
                            inputsData={inputsData}
                            setInputsData={setInputsData}
                            dataFieldsError={dataFieldsError}
                            setDataFieldsError={setDataFieldsError}
                        />
                    )}
                    {action === 'view' && (
                        <div className="flex flex-col gap-5">
                            {inputsData.map(({ value, name, dataFormat }, index) => {
                                if (value !== undefined && value !== null) {
                                    return (
                                        <div key={index}>
                                            <p className="font-body-bold text-body-bold-s text-neutral-500 mb-1.5">
                                                {name}
                                            </p>
                                            <p className="font-body text-body-regular-m text-neutral-500">
                                                {dataFormat === PROPERTY_DATA_FORMAT.checkbox ? (
                                                    <Badge
                                                        text={value === true ? 'True' : 'False'}
                                                        color="neutral"
                                                    />
                                                ) : (
                                                    value
                                                )}
                                            </p>
                                        </div>
                                    );
                                } else return <></>;
                            })}
                        </div>
                    )}
                </div>
                {action !== 'view' && (
                    <div
                        className={`pt-3 border-t-1 border-neutral-200 flex items-center ${
                            action === 'edit' ? 'justify-between' : 'justify-end'
                        }`}
                    >
                        {action === 'edit' && (
                            <button
                                className="h-[48px] font-body text-body-bold-m tracking-[0.8px] text-red-500"
                                onClick={() => setShowConfirmDeleteModal(true)}
                            >
                                Delete entry
                            </button>
                        )}
                        <Button
                            type="primary"
                            size="md"
                            state={isLoading ? 'loading' : 'default'}
                            text={action === 'edit' ? 'Save changes' : 'Create entry'}
                            onClick={handleAction}
                        />
                    </div>
                )}
            </div>

            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={5000}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                />
            )}
            {showConfirmDeleteModal && (
                <ConfirmDeleteModal
                    itemId={entry.id}
                    baseId={baseId}
                    label={`${name} entry`}
                    baseName="Entry"
                    requestBody={getDeleteEntryRequestBody()}
                    requestKeysToMutate={requestKeysToMutate}
                    handleCloseModal={() => {
                        setShowConfirmDeleteModal(false);
                    }}
                    handleClosePanel={onClose}
                />
            )}
        </Panel>
    );
};

export default KnowledgeBaseEntryPanel;
