import React from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';

import NewTextAreaBox from '../../../../design-system/NewTextAreaBox/NewTextAreaBox';
import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';

const RetrievalResponseFormatBlock = ({
    retrievalResponseFormat,
    setRetrievalResponseFormat,
    setChangedRetrievalFields,
}) => {
    const { width: screenWidth } = useWindowSize();

    const handleChange = (e) => {
        setRetrievalResponseFormat(e.target.value);
        setChangedRetrievalFields((prevFields) =>
            addUniqueElementToArray('response_format', prevFields)
        );
    };

    return (
        <div className="flex-grow w-full flex flex-col max-lg:min-h-[240px]">
            <NewTextAreaBox
                name="response_format"
                value={retrievalResponseFormat}
                onChange={handleChange}
                label="Response Format"
                placeholder="Write here"
                state={'default'}
                withCopyButton
                fullHeight
                scrollInsideTextArea={screenWidth >= 1024}
            />
        </div>
    );
};

export default RetrievalResponseFormatBlock;
