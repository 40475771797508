export const parseJobsSearchParams = (searchParams) => {
    const page = Number.parseInt(searchParams.get('page'));
    const sort = searchParams.get('sort');
    const process = searchParams.get('process');
    const search = searchParams.get('search');
    const status = searchParams.get('status');

    const result = { sort };

    if (page) {
        result.page = page;
    }
    if (search) {
        result.search = search;
    }
    if (process) {
        const parsedProcesses = process.split(',')?.map((id) => +id) || [];
        result.process = parsedProcesses;
    }
    if (status) {
        result.status = status;
    }

    return result;
};
