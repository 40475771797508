import React from 'react';
import PropTypes from 'prop-types';

// prop size can be added
UserBadge.propTypes = {
    name: PropTypes.string.isRequired,
};

function UserBadge({ name }) {
    return (
        <div className="flex items-center gap-1">
            <div className="w-5 h-5 min-w-[20px] rounded-full bg-purple-500 flex items-center justify-center uppercase font-body text-body-regular-xs text-white">
                {name?.[0]}
            </div>
            <p className="font-body-bold text-body-bold-xs text-neutral-500 truncate">{name}</p>
        </div>
    );
}

export default UserBadge;
