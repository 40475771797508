import React from 'react';
import { DateTime } from 'luxon';
import { Badge } from '../../../design-system';
import { Settings6LineIcon } from '../../../design-system/Icons';

const IntegrationTaskCard = ({ item }) => {
    const formattedTime = DateTime.fromISO(item.last_run_at).toRelative();

    const model = item.default_prompt?.default_prompt_version?.settings.model;

    return (
        <li className="w-full max-w-full px-4 py-3 rounded-2 bg-white flex items-center gap-4">
            <div className="flex-grow w-[calc(100%-307px)] flex gap-4 items-center">
                <p className="w-[calc((100%-16px)/2)] flex-grow font-body text-body-bold-s text-black truncate">
                    {item.name}
                </p>
                <p className="w-[calc((100%-16px)/2)] flex-grow font-body text-body-regular-xs text-neutral-300 truncate">
                    {item.description}
                </p>
            </div>

            <div className="w-[120px] min-w-[120px]">
                <Badge leadingIcon={Settings6LineIcon} text={model} color="neutral" />
            </div>
            <p className="font-body text-body-regular-xs text-neutral-300 min-w-[140px] w-[140px]">
                Last Run{' '}
                <span className="font-body text-body-bold-xs text-neutral-500">
                    {formattedTime}
                </span>
            </p>
        </li>
    );
};

export default IntegrationTaskCard;
