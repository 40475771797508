import React, { useEffect, useState } from 'react';
import { usePostRequest } from '../../../hooks/request';
import * as API from '../../../constants/api';
import externalClient from '../../../services/external-api';
import { useNavigate } from 'react-router-dom';
import IntegrationCard from '../../../components/IntegrationCard/IntegrationCard';
import SetupStepCard from '../../../components/SetupStepCard/SetupStepCard';
import EditApiKeyModal from '../../../pages/OrganizationPage/EditApiKeyModal/EditApiKeyModal';
import { Button } from '../../../design-system';

const ZapierSetup2Page = () => {
    const [editApiKeyModal, setEditApiKeyModal] = useState({
        opened: false,
        data: null,
        mode: 'create',
    });

    const apiKeyCreate = usePostRequest({ url: API.ROUTES.apikey.apikey }, externalClient);

    const navigate = useNavigate();
    const handleDoneClick = () => {
        navigate('/integrations/zapier/prompt-setup');
    };

    const openCreateAPIKeyModal = () => {
        setEditApiKeyModal({ opened: true });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="w-full max-sm:p-4 flex flex-col gap-3 sm:gap-5">
            <IntegrationCard
                title="Use your Promptfolio in a Zapier Automation"
                description="Access your Promptfolio directly from your Zapier automations"
                centeredContent
            />
            <div className="w-full py-[30px] sm:py-[80px] flex flex-col gap-[40px] sm:gap-[60px]">
                <SetupStepCard
                    title="Step 1: Search Promptfolio in your apps"
                    subtitle="Don’t see it?"
                    subtitleLink={{
                        text: 'Request an invite.',
                        href: '/integrations/zapier/setup-1',
                    }}
                    image={'/images/zapier-screenshot-promtfolio-in-apps.png'}
                    imageAlt="Screenshot Search Promptfolio in your apps"
                />
                <SetupStepCard
                    title="Step 2: Search for Promptfolio and get the ‘Run a Prompt’ action"
                    image={'/images/zapier-screenshot-run-prompt-action.png'}
                    imageAlt="Screenshot Run Prompt Action"
                    description="Don’t see it?"
                    descriptionLink={{
                        text: 'Request an invite.',
                        href: '/integrations/zapier/setup-1',
                    }}
                />
                <SetupStepCard
                    title="Step 3: Select or create a connection"
                    subtitle="Click ‘Sign in’."
                    image={'/images/zapier-screenshot-connection.png'}
                    imageAlt="Screenshot select or create a connection"
                    description="Create an API Key below and input it in Zapier connection window to set up the connection. "
                    additionalDescription="Don’t worry, you can manage these in your settings later."
                    actionButton={{
                        text: 'Create a Promptfolio API Key',
                        handleClick: openCreateAPIKeyModal,
                    }}
                />
                <div className="mx-auto">
                    <Button
                        type="secondary"
                        size="md"
                        text={'I’m Done!'}
                        onClick={handleDoneClick}
                    />
                </div>
            </div>
            {editApiKeyModal.opened && (
                <EditApiKeyModal
                    data={editApiKeyModal.data}
                    onClose={() =>
                        setEditApiKeyModal({
                            opened: false,
                            data: null,
                            mode: 'create',
                        })
                    }
                    apiKeyCreate={apiKeyCreate}
                    handleSave={() => {}}
                    mode={editApiKeyModal.mode}
                />
            )}
        </div>
    );
};

export default ZapierSetup2Page;
