import React from 'react';
import { DateTime } from 'luxon';

import { Badge } from '../../../design-system';

const ContextCard = ({ item, setContextPanel }) => {
    const formattedTime = DateTime.fromISO(item.updated_at).toRelative();

    const openEditContextPanel = () => {
        setContextPanel({ opened: true, item, action: 'edit' });
    };

    return (
        <>
            <li
                className="w-full max-w-full px-3 sm:px-4 py-3 rounded-2 bg-white flex items-center gap-5 border-1 border-neutral-100"
                onClick={openEditContextPanel}
            >
                <div className="w-[calc(100%-270px)] flex gap-5">
                    <div className="w-[250px] font-body">
                        <p className="text-body-bold-xs text-neutral-500 mb-1">Variable Name</p>
                        <p className="text-body-regular-xs text-neutral-300 line-clamp-2">
                            {item.label}
                        </p>
                    </div>

                    <div className="w-[calc(100%-270px)] flex-grow font-body">
                        <p className="text-body-bold-xs text-neutral-500 mb-1">Variable Context</p>
                        <p className="text-body-regular-xs text-neutral-300 line-clamp-2">
                            {item.default_version?.value}
                        </p>
                    </div>
                </div>

                <div className="w-[77px] min-w-[77px]">
                    <Badge
                        text={item.task_count > 0 ? 'In Use' : 'Not In Use'}
                        color={item.task_count > 0 ? 'lime' : 'peach'}
                    />
                </div>
                <p className="font-body text-body-regular-xs text-neutral-300 min-w-[150px] w-[150px]">
                    Updated{' '}
                    <span className="font-body text-body-bold-xs text-neutral-500">
                        {formattedTime}
                    </span>
                </p>
            </li>
        </>
    );
};

export default ContextCard;
