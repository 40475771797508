import React from 'react';
import CollapsibleStepCard from '../CollapsibleStepCard/CollapsibleStepCard';

const PromptChainStepsBlock = ({ steps, workerId, isOutdated }) => {
    return (
        <>
            {!!steps?.length && (
                <div className="flex flex-col gap-3 w-full">
                    {steps
                        .sort((a, b) => a.order - b.order)
                        .map((step) => (
                            <CollapsibleStepCard
                                key={step.step_id}
                                step={step}
                                workerId={workerId}
                                isOutdated={isOutdated}
                            />
                        ))}
                </div>
            )}
        </>
    );
};

export default PromptChainStepsBlock;
