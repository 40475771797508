import React, { useRef } from 'react';
import classNames from 'classnames';

import { TASK_TYPES_ICON } from '../../../../constants/library';
import { getGoalTemplateSidePanelMoreOptionPopupTopPosition } from '../../../../helpers/templatesUtils';

import { ButtonIcon } from '../../../../design-system';
import { More2FillIcon } from '../../../../design-system/Icons';
import Tag from '../../../../design-system/Tag/Tag';

const GoalTemplateSidePanelCard = ({
    goalTemplate,
    isUsedInProcessTemplate,
    panelRef,
    setMoreOptionPopup,
}) => {
    const { id, name, description, current_version, tasks } = goalTemplate;
    const buttonRef = useRef(null);

    const openMoreOptionPopup = () => {
        const { topPosition } = getGoalTemplateSidePanelMoreOptionPopupTopPosition({
            buttonRef,
            panelRef,
        });
        setMoreOptionPopup({ isOpened: true, goalTemplateData: goalTemplate, topPosition });
    };

    const cardContainerStyles = classNames('p-4 rounded-2', {
        'bg-white shadow-inset-1 shadow-neutral-200': !isUsedInProcessTemplate,
        'bg-purple-100 shadow-inset-2 shadow-purple-300': isUsedInProcessTemplate,
    });

    return (
        <div className={cardContainerStyles}>
            <div className="flex items-center justify-between gap-3 mb-1">
                <p className="font-body text-body-bold-xs text-neutral-500 truncate">{name}</p>
                <div ref={buttonRef}>
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={openMoreOptionPopup}
                    />
                </div>
            </div>
            <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2 mb-2">
                {description}
            </p>
            <div className="flex items-center justify-between gap-3">
                <div className="flex items-center gap-2">
                    <Tag text={current_version} />
                    <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap">
                        ID{' '}
                        <span className="font-body-bold text-body-bold-xs text-neutral-500">
                            {id}
                        </span>
                    </p>
                </div>
                <div className="flex items-center gap-2 flex-wrap">
                    {tasks?.map((task, index) => {
                        const Icon = TASK_TYPES_ICON[task.type];
                        return Icon ? (
                            <Icon key={index} width={14} height={14} color="#754DCF" />
                        ) : (
                            <></>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default GoalTemplateSidePanelCard;
