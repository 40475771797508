import React from 'react';
import { usePagination } from '../../../hooks';

import { API } from 'constants';
import Loading from '../../../components/Loading';
import { DEFAUlT_LIMIT } from '../../../hooks/usePagination';
import client from '../../../services/library-api';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';
import IntegrationsEmptyState from '../IntegrationsEmptyState/IntegrationsEmptyState';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import IntegrationTaskCard from '../IntegrationTaskCard/IntegrationTaskCard';

const IntegrationsPaginatedContainer = ({
    sortType,
    pageIndex,
    onPreviousPressed,
    onNextPressed,
}) => {
    const { count, total, loading, sortedData } = usePagination({
        sortType,
        pageIndex,
        client,
        route: API.ROUTES.library.integrationTasks,
    });
    const location = useLocation();

    if (loading) {
        return <Loading text="" />;
    }

    const start = pageIndex === 0 ? 1 : pageIndex * DEFAUlT_LIMIT + 1;
    const end = total - start < count ? total : start + count - 1;
    const hasData = total > 0;

    return (
        <div>
            <p className="font-body text-body-bold-m text-neutral-500 mb-6">
                Prompts Used in Integrations
            </p>
            {!hasData && <IntegrationsEmptyState />}
            {hasData && (
                <div className="max-md:overflow-x-auto">
                    <ul
                        className={classNames(
                            'flex flex-col gap-2 max-md:min-w-[700px] max-md:w-[700px]'
                        )}
                    >
                        {sortedData.map((item) => (
                            <Link
                                key={item.id}
                                to={`/library/prompt/${item?.default_prompt.id}`}
                                state={{ from: location }}
                                className="cursor-pointer"
                            >
                                <IntegrationTaskCard item={item} key={item.id} />
                            </Link>
                        ))}
                    </ul>
                </div>
            )}

            {hasData && (
                <div className="flex flex-col mt-[20px]">
                    <PaginationFooter
                        start={start}
                        end={end}
                        total={total}
                        onPreviousPressed={onPreviousPressed}
                        onNextPressed={onNextPressed}
                    />
                </div>
            )}
        </div>
    );
};

export default IntegrationsPaginatedContainer;
