import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { isFileSizeValid } from '../../helpers/fileUtils';
import { ErrorWarningLineIcon } from '../Icons';
import Alert from '../Alert/Alert';

FileInput.propTypes = {
    file: PropTypes.instanceOf(File),
    handleFile: PropTypes.func.isRequired,
    maxSizeInBytes: PropTypes.number,
    maxSizeLabel: PropTypes.string,
    formatsArray: PropTypes.arrayOf(PropTypes.string).isRequired,
    placeholder: PropTypes.string,
    limitationsLabel: PropTypes.string,
    view: PropTypes.oneOf(['simple', 'extended']),
    isDisabled: PropTypes.bool,
};

function FileInput({
    file,
    handleFile,
    maxSizeInBytes,
    maxSizeLabel = '1 MB',
    formatsArray,
    placeholder = 'Drag & drop or click here to upload photo',
    limitationsLabel = '',
    view = 'simple',
    isDisabled = false,
}) {
    const [errorMessage, setErrorMessage] = useState(null);

    const [{ isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: handleDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const handleFileWrongSize = () => {
        setErrorMessage(`File size exceeds ${maxSizeLabel} and cannot be uploaded.`);
    };

    function handleDrop(item, monitor) {
        if (isDisabled) {
            return;
        }

        const droppedFiles = monitor.getItem().files;
        if (droppedFiles && droppedFiles[0]) {
            const file = droppedFiles[0];
            const isWrongFormat = !formatsArray.some((format) =>
                file.name.toLowerCase().endsWith(format.toLowerCase())
            );
            if (isWrongFormat) {
                setErrorMessage(
                    `Only following file formats are acceptable: ${formatsArray.join(', ')}`
                );
                return;
            }
            const isWrongSize = !isFileSizeValid(file, maxSizeInBytes, handleFileWrongSize);
            if (isWrongSize) {
                return;
            }

            handleFile(file);
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        setErrorMessage(null);
        const file = e.target.files && e.target.files[0];

        if (file) {
            const isWrongSize = !isFileSizeValid(file, maxSizeInBytes, handleFileWrongSize);
            if (isWrongSize) {
                return;
            }
            handleFile(file);
        }
    };

    const labelClassName = classNames(
        'block p-4 bg-purple-100 border-1 border-dashed transition-all',
        {
            'rounded-2': view === 'simple',
            'rounded-[10px]': view === 'extended',
            'border-purple-300': view === 'simple' && !isDisabled,
            'border-purple-500': view === 'extended' && !isDisabled,
            'border-neutral-300': isDisabled,
            'cursor-pointer': !isDisabled,
            'bg-opacity-80': isOver && !isDisabled,
            'bg-opacity-30': !isOver || isDisabled,
        }
    );

    return (
        <>
            {view === 'simple' && (
                <label ref={drop} className={labelClassName}>
                    <p className="font-body text-body-regular-m text-neutral-300">
                        {file ? file.name : placeholder}
                    </p>
                    <input
                        type="file"
                        className="hidden"
                        accept={formatsArray?.join(', ')}
                        onChange={handleChange}
                        disabled={isDisabled}
                    />
                </label>
            )}

            {view === 'extended' && (
                <label ref={drop} className={labelClassName}>
                    <input
                        type="file"
                        className="hidden"
                        accept={formatsArray?.join(', ')}
                        onChange={handleChange}
                        disabled={isDisabled}
                    />
                    <div className="flex flex-col gap-2.5 items-center p-2.5">
                        <p
                            className={`font-body text-body-regular-m text-center ${
                                isDisabled ? 'text-neutral-300' : 'text-purple-500'
                            }`}
                        >
                            <span className="font-body-bold text-body-bold-m underline">
                                Click to upload
                            </span>{' '}
                            or drag and drop here
                        </p>
                        <p className="font-body text-body-regular-s text-neutral-300 text-center">
                            {limitationsLabel}
                        </p>
                    </div>
                </label>
            )}

            {errorMessage && (
                <Alert
                    status="critical"
                    message={errorMessage}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorMessage(null)}
                    autoCloseInMS={3000}
                />
            )}
        </>
    );
}

export default FileInput;
