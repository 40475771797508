import React from 'react';
import { DateTime } from 'luxon';
import { jobsStatusBadge } from '../../../constants/jobs';

import { Badge } from '../../../design-system';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';

const JobCard = ({ job, pageName = 'workRuns' }) => {
    const { name, process, status, updated_at } = job;

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');
    const statusBadge = jobsStatusBadge[status];

    const containerStyles = {
        workRuns: 'py-3 rounded-2',
        dashboard: 'py-3.5 border-b-1 border-neutral-200',
        processDetail: 'py-3.5 border-b-1 border-neutral-200',
    };

    const lastUpdatedContainerStyles = {
        workRuns: 'pt-2 border-t-1',
        dashboard: 'pt-1',
        processDetail: 'pt-1',
    };

    const nameWidth = {
        workRuns: 'lg:w-[calc((100%-20px)*0.55)] xl:w-[calc((100%-32px)*0.55)]',
        dashboard: 'lg:w-[calc((100%-20px)*0.7)] xl:w-[calc((100%-32px)*0.7)]',
        processDetail: 'w-full',
    };

    const processBadgeWidth = {
        workRuns: 'lg:w-[calc((100%-20px)*0.45)] xl:w-[calc((100%-32px)*0.45)]',
        dashboard: 'lg:w-[calc((100%-20px)*0.3)] xl:w-[calc((100%-32px)*0.3)]',
    };

    return (
        <div
            className={`w-full bg-white px-4 py-3 flex flex-col min-[815px]:flex-row min-[815px]:items-center gap-x-5 gap-y-2 xl:gap-x-8 justify-between ${containerStyles[pageName]}`}
        >
            <div className="flex flex-col min-[550px]:flex-row min-[550px]:items-center gap-x-5 gap-y-2 xl:gap-x-8 justify-between w-full min-[815px]:w-[calc(100%-242px)] xl:w-[calc(100%-254px)]">
                <div className="flex flex-col-reverse lg:flex-row lg:items-center gap-y-1 gap-x-5 xl:gap-x-8 w-full min-[550px]:w-[calc(100%-100px)] xl:w-[calc(100%-112px)]">
                    <p
                        className={`font-body-bold text-body-bold-s text-black truncate w-full ${nameWidth[pageName]}`}
                    >
                        {name}
                    </p>
                    {pageName !== 'processDetail' && (
                        <div className={`w-full ${processBadgeWidth[pageName]}`}>
                            <Badge
                                text={process.name}
                                color="neutral"
                                leadingIcon={PlayCircleFillIcon}
                                leadingIconColor={process.icon_color}
                            />
                        </div>
                    )}
                </div>
                <div className="w-[80px] min-w-[80px] flex min-[550px]:justify-end">
                    <Badge text={statusBadge.text} color={statusBadge.color} />
                </div>
            </div>
            <p
                className={`font-body text-body-regular-xs text-neutral-300 truncate w-full max-w-full min-[815px]:w-[222px] min-w-[222px] border-neutral-200 min-[815px]:pt-0 min-[815px]:border-t-0 ${lastUpdatedContainerStyles[pageName]}`}
            >
                Last Updated{' '}
                <span className="font-body-bold text-body-bold-xs text-neutral-500">
                    {formattedTime}
                </span>
            </p>
        </div>
    );
};

export default JobCard;
