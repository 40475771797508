import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/organization-api';

import { urlRegex } from '../../../constants/regex_patterns';
import { PROVIDERS, PROVIDERS_LABEL } from '../../../constants/organizationConnections';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import Input from '../../../design-system/Input/Input';
import Alert from '../../../design-system/Alert/Alert';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const OrgConnectionActionModal = ({
    onClose,
    action = 'create',
    data,
    provider,
    setOrgConnections,
    setSuccessAlertMessage = () => {},
}) => {
    const { id } = useParams();
    const orgId = Number(id);

    const [inputsData, setInputsData] = useState({
        name: '',
        api_key: '',
        weaviate_url: '',
    });
    const [editedFields, setEditedFields] = useState([]);
    const [inputFieldsErrorMessages, setInputFieldsErrorMessages] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorAlert, setErrorAlert] = useState({ show: false, message: null, statusCode: null });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if ( action && (action === 'edit' || action === 'view')) {
            setInputsData({
                name: data.name,
                weaviate_url: data.detail?.weaviate_url,
                api_key: '',
            });
        }
    }, [data, action]);

    const handleInputChange = (e) => {
        setInputsData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
        if (inputFieldsErrorMessages[e.target.name]) {
            setInputFieldsErrorMessages((prevState) => ({ ...prevState, [e.target.name]: null }));
        }
        if (!editedFields.includes(e.target.name)) {
            setEditedFields((prevState) => [...prevState, e.target.name]);
        }
    };

    const createConnection = async () => {
        const requestBody = {
            name: inputsData.name,
            organization: orgId,
            provider,
            api_key: inputsData.api_key,
        };
        if (provider === PROVIDERS.weaviate) {
            requestBody.detail = {
                weaviate_url: inputsData.weaviate_url.trim(),
            };
        }

        const { data: response } = await client.post(
            API.ROUTES.organization.connection,
            requestBody
        );
        setOrgConnections((prevData) => [...prevData, response]);
        setSuccessAlertMessage(`Successfully connected to ${PROVIDERS_LABEL[provider]}`);
    };

    const editConnection = async () => {
        const requestBody = editedFields.reduce((acc, field) => {
            if (field === 'weaviate_url') {
                return {
                    ...acc,
                    detail: {
                        weaviate_url: inputsData.weaviate_url,
                    },
                };
            } else if (field === 'api_key' && !inputsData.api_key) {
                return acc;
            } else {
                return { ...acc, [field]: inputsData[field] };
            }
        }, {});

        const { data: response } = await client.patch(
            `${API.ROUTES.organization.connection}${data.id}/`,
            requestBody
        );
        setOrgConnections((prevData) =>
            prevData.map((item) => (item.id === response.id ? response : item))
        );
    };

    const handleAction = {
        create: createConnection,
        edit: editConnection,
    };

    const handleConfirm = async (e) => {
        e.preventDefault();
        if (errorMessage) {
            setErrorMessage(null);
        }

        const isUrlValid = urlRegex.test(inputsData.weaviate_url?.trim());
        const areEmptyFields =
            !inputsData.name ||
            (provider === PROVIDERS.weaviate ? !inputsData.weaviate_url : false) ||
            (action === 'create' ? !inputsData.api_key : false);

        if (areEmptyFields || (provider === PROVIDERS.weaviate ? !isUrlValid : false)) {
            setInputFieldsErrorMessages({
                name: !inputsData.name ? 'This field may not be blank.' : null,
                api_key:
                    !inputsData.api_key && action === 'create'
                        ? 'This field may not be blank.'
                        : null,
                weaviate_url:
                    provider === PROVIDERS.weaviate
                        ? !inputsData.weaviate_url
                            ? 'This field may not be blank.'
                            : !isUrlValid
                            ? 'Please check the entered URL as it seems to be invalid.'
                            : null
                        : null,
            });
            return;
        }

        try {
            setIsLoading(true);
            await handleAction[action]();
            setIsLoading(false);
            onClose();
        } catch (error) {
            setIsLoading(false);
            if (
                error.response &&
                (error.response.data?.name ||
                    error.response.data?.api_key ||
                    error.response.data?.weaviate_url)
            ) {
                setInputFieldsErrorMessages({
                    name: error.response.data?.name ? error.response.data?.name[0] : null,
                    api_key: error.response.data?.api_key ? error.response.data?.api_key[0] : null,
                    weaviate_url: error.response.data?.weaviate_url
                        ? error.response.data?.weaviate_url[0]
                        : null,
                });
            } else if (error.response && error.response.data?.non_field_errors) {
                setErrorMessage(error.response.data.non_field_errors[0]);
            } else {
                setErrorAlert({
                    show: true,
                    message: defaultErrorMessage,
                    statusCode: error.response?.status,
                });
            }
        }
    };

    return (
        <Modal onClose={onClose} size="medium">
            <div className="flex flex-col gap-4">
                <h2 className="font-heading-bold text-heading-bold-m text-black">
                    {action === 'edit'
                        ? `Update your ${PROVIDERS_LABEL[provider]} Connection`
                        : `Connect your ${PROVIDERS_LABEL[provider]} Account`}
                </h2>
                <Input
                    label="Name"
                    value={inputsData.name}
                    placeholder={`My ${PROVIDERS_LABEL[provider]} Key`}
                    name="name"
                    size="md"
                    onChange={handleInputChange}
                    isRequired
                    state={inputFieldsErrorMessages.name ? 'error' : 'default'}
                    errorMessage={inputFieldsErrorMessages.name}
                />
                {provider === PROVIDERS.weaviate && (
                    <Input
                        label={`${PROVIDERS_LABEL[provider]} URL`}
                        value={inputsData.weaviate_url}
                        placeholder={`https://test-xxxxxx.${provider}.network`}
                        name="weaviate_url"
                        size="md"
                        onChange={handleInputChange}
                        isRequired
                        state={inputFieldsErrorMessages.weaviate_url ? 'error' : 'default'}
                        errorMessage={inputFieldsErrorMessages.weaviate_url}
                    />
                )}
                <Input
                    label={`${PROVIDERS_LABEL[provider]} API key`}
                    value={inputsData.api_key}
                    placeholder={action === 'create' ? 'xxxxxxxx' : '********************'}
                    name="api_key"
                    size="md"
                    isRequired
                    state={inputFieldsErrorMessages.api_key ? 'error' : 'default'}
                    errorMessage={inputFieldsErrorMessages.api_key}
                    onChange={handleInputChange}
                />
                {errorMessage && (
                    <p className="font-body text-body-bold-xs text-red-500">{errorMessage}</p>
                )}
                <div className="flex items-center gap-2 justify-between pt-2">
                    <Button type="neutral" size="xs" text="Cancel" onClick={onClose} />
                    <Button
                        type="primary"
                        text={'Confirm'}
                        state={action === 'view' ? 'disabled' : isLoading ? 'loading' : 'default'}
                        size="xs"
                        onClick={handleConfirm}
                    />
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: null, statusCode: null })
                    }
                />
            )}
        </Modal>
    );
};

export default OrgConnectionActionModal;
