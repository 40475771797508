import React from 'react';
import PropTypes from 'prop-types';
import ArrowDownIcon from '../../design-system/Icons/ArrowDownIcon';

StepCardContainer.propTypes = {
    step: PropTypes.number.isRequired,
};
function StepCardContainer({ step, children }) {
    return (
        <div className="w-full">
            <p className="font-body text-body-bold-xs text-neutral-300 uppercase text-center mb-[8px]">
                Step {step}
            </p>
            {children}
            <div className="mt-[16px] p-[4px] flex justify-center">
                <ArrowDownIcon width={16} height={26} color="#CFD6E5" />
            </div>
        </div>
    );
}

export default StepCardContainer;
