import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button } from '../../../../design-system';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';

SuccessStartNewJobState.propTypes = {
    processName: PropTypes.string.isRequired,
    viewMode: PropTypes.oneOf(['fullScreen', 'partialScreen']),
    onStartFromSameProcess: PropTypes.func.isRequired,
    onStartFromNewProcess: PropTypes.func.isRequired,
};

function SuccessStartNewJobState({
    jobId,
    processName,
    viewMode = 'fullScreen',
    onStartFromSameProcess,
    onStartFromNewProcess,
}) {
    const navigate = useNavigate();

    const openJobDetailsPage = () => {
        navigate(`/job/${jobId}`);
    };

    return (
        <div
            className={`px-3 py-[80px] w-full ${
                viewMode === 'fullScreen' && 'sm:w-[calc(100%-68px)] sm:px-8 sm:py-[140px]'
            } `}
        >
            <div className="py-5 max-w-[460px] mx-auto flex flex-col gap-[40px]">
                <div className="flex flex-col items-center gap-4 pb-[40px] border-b-1 border-neutral-200">
                    <div className="w-[80px] h-[80px] min-w-[80px] bg-lime-500 rounded-full flex items-center justify-center">
                        <CheckLineIcon width={32} height={32} color="#FFFFFF" />
                    </div>
                    <p className="font-body text-body-regular-xs text-neutral-300 uppercase">
                        Job Successfully started
                    </p>
                    <p className="font-heading-bold text-heading-bold-m text-black text-center">
                        {processName} has been started!
                    </p>
                    <p className="font-body text-body-regular-s text-neutral-400 text-center">
                        To follow progress of this job, go to the job details page.
                    </p>
                    <Button
                        type="link"
                        size="sm"
                        text="View Job Details"
                        onClick={openJobDetailsPage}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <p className="font-body text-body-regular-s text-neutral-400 text-center">
                        Start a <span className="bont-body-bold text-body-bold-s">New Job</span>{' '}
                        from:
                    </p>
                    <div className="flex gap-2 items-center">
                        <div className="min-w-[calc((100%-8px)/2)] flex flex-col">
                            <Button
                                type="ghost"
                                size="sm"
                                text="Same Process"
                                onClick={onStartFromSameProcess}
                            />
                        </div>
                        <div className="min-w-[calc((100%-8px)/2)] flex flex-col">
                            <Button
                                type="secondary"
                                size="sm"
                                text="New Process"
                                onClick={onStartFromNewProcess}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessStartNewJobState;
