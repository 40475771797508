import AlignLeftIcon from '../design-system/Icons/AlignLeftIcon';
import ImageAddLineIcon from '../design-system/Icons/ImageAddLineIcon';
import VideoAddLineIcon from '../design-system/Icons/VideoAddLineIcon';
import TextBlockIcon from '../design-system/Icons/TextBlockIcon';

export const PLAYBOOK_BUILDER_MODE = {
    edit: 'edit',
    view: 'view',
};

export const BLOCK_TYPE = {
    callout: 'callout',
    richtext: 'richtext',
    image: 'image',
    video: 'video',
};

export const BLOCK_TYPE_LABEL = {
    callout: 'Callout',
    richtext: 'Text',
    image: 'Image',
    video: 'Video',
};

export const BLOCK_TYPE_ICON = {
    callout: TextBlockIcon,
    richtext: AlignLeftIcon,
    image: ImageAddLineIcon,
    video: VideoAddLineIcon,
};

export const DEFAULT_SECTION_NAME = 'Add a section title';

export const EMPTY_PLAYBOOK_SECTION = {
    id: null,
    order: 1,
    name: '',
    description: '',
    version_id: null,
    block_content: [],
};

export const EMPTY_CALLOUT_BLOCK_PROPERTIES = {
    properties: {
        title: '',
        description: '',
        callout_color: '#5E6470',
    },
};

export const EMPTY_RICHTEXT_BLOCK_PROPERTIES = {
    properties: {
        content: '',
    },
};

export const EMPTY_IMAGE_BLOCK_PROPERTIES = {
    properties: {
        image_url: '',
    },
};

export const EMPTY_VIDEO_BLOCK_PROPERTIES = {
    properties: {
        video_url: '',
    },
};

export const EMPTY_BLOCK_PROPERTIES = {
    [BLOCK_TYPE.callout]: EMPTY_CALLOUT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.richtext]: EMPTY_RICHTEXT_BLOCK_PROPERTIES,
    [BLOCK_TYPE.image]: EMPTY_IMAGE_BLOCK_PROPERTIES,
    [BLOCK_TYPE.video]: EMPTY_VIDEO_BLOCK_PROPERTIES,
};
