import React from 'react';
import Loading from '../Loading';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

const InfiniteScrollList = ({ children, loading, canLoadMore, handleFetch, items }) => {
    const { guardRef } = useIntersectionObserver({
        handleFetch,
        options: items,
        optionsLoading: loading,
        canLoadMore,
    });

    return (
        <div className="flex flex-col gap-2">
            {children}
            <div ref={guardRef}></div>
            {loading && <Loading withText={false} />}
        </div>
    );
};

export default InfiniteScrollList;
