import React, { useRef, useState } from 'react';
import { SvgIcon } from '../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../design-system/Icons';
import IndicatorIcon from '../../../design-system/Icons/IndicatorIcon';
import ChainStepCard from '../ChainStepCard/ChainStepCard';
import { useClickOutside } from '../../../hooks';
import Loading from '../../../components/Loading';
import { indicatorColors } from '../../../constants/runStatus';

const ChainStepSwitcher = ({ workerDetails, currentStepData, handleStepSelect }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);

    const dropdownRef = useRef(null);
    useClickOutside(dropdownRef, () => {
        setShowDropdown(false);
    });

    const onStepSelect = async (id) => {
        setLoading(true);
        await handleStepSelect(id);
        setShowDropdown(false);
        setLoading(false);
    };

    return (
        <div className="relative">
            <div className="w-[90vw] min-[350px]:w-[310px] px-4 py-[6px] flex items-center justify-between gap-2 shadow-inset-1 shadow-neutral-200 rounded-2">
                {!loading && (
                    <div className="w-[calc(100%-32px)]">
                        <p className="font-body text-body-bold-s text-neutral-500 w-full truncate mb-[2px]">
                            {workerDetails.goal_name}
                        </p>
                        <div className="flex items-center gap-1">
                            <SvgIcon
                                color={indicatorColors[currentStepData?.status] || '#CFD6E5'}
                                icon={IndicatorIcon}
                                size="medium"
                            />
                            <p className="font-body text-body-bold-xs text-neutral-300">
                                Step {currentStepData.step}
                            </p>
                        </div>
                    </div>
                )}
                {loading && (
                    <div className="mx-auto">
                        <Loading withText={false} />
                    </div>
                )}
                <div className="cursor-pointer" onClick={() => setShowDropdown((prev) => !prev)}>
                    <SvgIcon
                        color="#754DCF"
                        icon={showDropdown ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                    />
                </div>
            </div>
            {showDropdown && (
                <div
                    className="w-[90vw] min-[350px]:w-[310px] max-h-[600px] overflow-auto w-[310px] p-4 bg-white shadow-inset-1 shadow-neutral-200 shadow-l1 rounded-2 absolute bottom-[calc(100%+8px)] flex flex-col gap-2"
                    ref={dropdownRef}
                >
                    {workerDetails.config.map((item) => (
                        <ChainStepCard
                            key={item.order}
                            stepId={item.step_id}
                            step={item.order}
                            name={item.task_name}
                            status={item.status}
                            // changed this to step id instead of task id because two steps can have the same task id
                            selected={item.step_id === currentStepData.stepId}
                            handleStepSelect={onStepSelect}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ChainStepSwitcher;
