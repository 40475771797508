import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../design-system';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import Banner from '../../../design-system/Banner/Banner';

const SubmissionSuccessState = ({ pageAction, processTemplateName, processId }) => {
    const navigate = useNavigate();

    const title = {
        setup: 'Your new process is ready!',
        upgrade: 'Your process has been upgraded!',
    };

    const descriptionPart = {
        setup: 'You’ve successfully set up your new',
        upgrade: 'You’ve successfully upgraded your',
    };

    return (
        <div className="flex-grow px-2 py-3 sm:px-0 lg:py-5 flex flex-col gap-4">
            {pageAction === 'upgrade' && (
                <Banner
                    title="Important Note"
                    text="Your Make.com and Zapier scenarios will require a reload to continue to work with this upgraded process!"
                    color="blue"
                />
            )}
            <div className="max-w-[460px] mx-auto flex flex-col gap-[40px]">
                <div className="flex flex-col items-center gap-4 pb-4 border-b-1 border-neutral-200">
                    <div className="w-[80px] h-[80px] min-w-[80px] bg-lime-500 rounded-full flex items-center justify-center">
                        <CheckLineIcon width={32} height={32} color="#FFFFFF" />
                    </div>
                    <p className="font-body text-body-regular-xs text-neutral-300 uppercase">
                        Successfully {pageAction === 'upgrade' ? 'Upgraded' : 'Created'}
                    </p>
                    <p className="font-heading-bold text-heading-bold-m text-black text-center">
                        {title[pageAction]}
                    </p>
                    <p className="font-body text-body-regular-s text-neutral-400 text-center">
                        {descriptionPart[pageAction]} {processTemplateName} process! Visit Process
                        Details to view and edit your settings at any time.
                    </p>
                    <Button
                        type="link"
                        size="sm"
                        text="View Process Details"
                        onClick={() => navigate(`/process/${processId}`)}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <p className="font-body text-body-regular-s text-neutral-400 text-center">
                        View your current processes or discover more:
                    </p>
                    <div className="flex gap-2 items-center">
                        <div className="min-w-[calc((100%-8px)/2)] flex flex-col">
                            <Button
                                type="ghost"
                                size="sm"
                                text="My Processes"
                                onClick={() => navigate('/process')}
                            />
                        </div>
                        <div className="min-w-[calc((100%-8px)/2)] flex flex-col">
                            <Button
                                type="secondary"
                                size="sm"
                                text="Discover More"
                                onClick={() => navigate('/discover')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmissionSuccessState;
