import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { API } from 'constants';
import operateClient from '../../services/operate-api';

import useUser from '../../hooks/useUser';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useWindowSize } from '../../hooks/useWindowSize';

import Loading from '../../components/Loading';
import ThreadDetailPageHeader from './ThreadDetailPageHeader/ThreadDetailPageHeader';
import GoalCard from './GoalCard/GoalCard';
import RestartSidePanel from './RestartSidePanel/RestartSidePanel';
import CancelConfirmationModal from './CancelConfirmationModal/CancelConfirmationModal';
import FeedbackPanel from './FeedbackPanel/FeedbackPanel';
import ShareAiFeedbackModal from '../../components/ShareAIFeedbackModal/ShareAIFeedbackModal';
import Alert from '../../design-system/Alert/Alert';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';
import ConfirmReloadReportModal from './ConfirmReloadReportModal/ConfirmReloadReportModal';

const fetcher = (url) => operateClient.get(url).then((res) => res.data);

const ThreadDetailPage = () => {
    const params = useParams();
    const threadId = Number(params.threadId);

    const { user } = useUser();

    const [searchParams] = useSearchParams();
    const withArchivedWorkers = Boolean(searchParams.get('archived'));

    const navigate = useNavigate();
    const location = useLocation();
    const backlinkHref = location.state?.from ?? '/threads';
    const { width: screenWidth } = useWindowSize();

    const [shouldRefresh, setShouldRefresh] = useState(true); // thread.status === 'started'
    const requestEndpoint = withArchivedWorkers
        ? `${API.ROUTES.operate.thread}${threadId}/?archived=true`
        : `${API.ROUTES.operate.thread}${threadId}/`;

    const { data, error, isLoading, mutate } = useSWR(requestEndpoint, fetcher, {
        refreshInterval: shouldRefresh ? 5000 : 0,
    });

    const [thread, setThread] = useState(null);

    const [feedbackPanelOpened, setFeedbackPanelOpened] = useState(false);
    const [restartSidePanel, setRestartSidePanel] = useState({ opened: false });
    const [cancelConfirmationModal, setCancelConfirmationModal] = useState({ opened: false });
    const [isReloadReportModalOpened, setIsReloadReportModalOpened] = useState(false);

    const [shareThreadFeedbackModal, setShareThreadFeedbackModal] = useState({
        opened: false,
        state: null,
        initialRating: 0,
    });
    const [successAlert, setSuccessAlert] = useState(null);

    const [pageHeaderHeight, setPageHeaderHeight] = useState(0);
    const pageContentHeight =
        feedbackPanelOpened && screenWidth >= 970 ? `calc(100% - ${pageHeaderHeight}px)` : 'auto'; // height of the page without header

    useDocumentTitle(thread && thread.name);

    useEffect(() => {
        if (data) {
            setShouldRefresh(data.status === 'started');
            setThread(data); // We use the 'thread' state instead of immediately showing data from useSWR. This helps display the loader only in the section with workers, not the whole page, when toggling the display of archived workers. The header remains unchanged from the previous request.
        }
    }, [data]);

    if (error) {
        navigate(backlinkHref);
    }

    const onSuccessThreadFeedbackShare = async (newFeedback) => {
        // add new feedback to current feedbacks
        setThread((prevData) => ({
            ...prevData,
            feedback: [
                ...(prevData.feedback || []),
                { ...newFeedback, created_by_user: user.email },
            ],
        }));
    };

    return (
        <div
            className={`fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-neutral-50 flex flex-col ${
                (!feedbackPanelOpened || screenWidth < 970) && 'overflow-y-auto'
            }`}
        >
            {thread ? (
                <>
                    <ThreadDetailPageHeader
                        thread={thread}
                        setThread={setThread}
                        setRestartSidePanel={setRestartSidePanel}
                        setCancelConfirmationModal={setCancelConfirmationModal}
                        setFeedbackPanelOpened={setFeedbackPanelOpened}
                        setIsReloadReportModalOpened={setIsReloadReportModalOpened}
                        feedbackCount={thread.feedback?.length}
                        setPageHeaderHeight={setPageHeaderHeight}
                    />
                    <div
                        className="flex flex-grow"
                        style={{
                            height: pageContentHeight,
                        }}
                    >
                        {(!feedbackPanelOpened || screenWidth >= 970) && (
                            <div
                                className={`px-5 pt-4 pb-[90px] sm:px-8 sm:pt-8 sm:pb-[40px] flex flex-col gap-2.5 flex-grow h-full ${
                                    feedbackPanelOpened && 'overflow-y-auto'
                                }`}
                            >
                                {isLoading && !data ? (
                                    <div className="flex justify-center items-center pt-4">
                                        <Loading />
                                    </div>
                                ) : (
                                    <>
                                        {thread.workers?.map((worker) => (
                                            <GoalCard
                                                key={worker.id}
                                                worker={worker}
                                                setThread={setThread}
                                                processId={thread.process?.id}
                                                setRestartSidePanel={setRestartSidePanel}
                                                setCancelConfirmationModal={
                                                    setCancelConfirmationModal
                                                }
                                                smallerWidth={feedbackPanelOpened}
                                                setShareThreadFeedbackModal={
                                                    setShareThreadFeedbackModal
                                                }
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        )}
                        {feedbackPanelOpened && (
                            <FeedbackPanel
                                feedback={thread.feedback}
                                setShareThreadFeedbackModal={setShareThreadFeedbackModal}
                                onClose={() => setFeedbackPanelOpened(false)}
                            />
                        )}
                    </div>
                </>
            ) : (
                <div className="flex-grow h-full flex justify-center items-center">
                    <Loading />
                </div>
            )}
            {restartSidePanel.opened && (
                <RestartSidePanel
                    restartTarget={restartSidePanel.restartTarget}
                    workerId={restartSidePanel.workerId}
                    mutate={mutate}
                    onClose={() => setRestartSidePanel({ opened: false })}
                />
            )}
            {cancelConfirmationModal.opened && (
                <CancelConfirmationModal
                    cancelTarget={cancelConfirmationModal.cancelTarget}
                    workerId={cancelConfirmationModal.workerId}
                    goalName={cancelConfirmationModal.goalName}
                    threadName={thread?.name}
                    setThread={setThread}
                    mutate={mutate}
                    onClose={() => setCancelConfirmationModal({ opened: false })}
                />
            )}
            {shareThreadFeedbackModal.opened && (
                <ShareAiFeedbackModal
                    target="thread"
                    state={shareThreadFeedbackModal.state || 'empty'}
                    initialRating={shareThreadFeedbackModal.initialRating}
                    threadId={threadId}
                    setAlert={setSuccessAlert}
                    onSuccessFeedbackShare={onSuccessThreadFeedbackShare}
                    onClose={() => setShareThreadFeedbackModal({ opened: false })}
                />
            )}
            {isReloadReportModalOpened && (
                <ConfirmReloadReportModal
                    threadId={threadId}
                    threadName={thread?.name}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => setIsReloadReportModalOpened(false)}
                />
            )}
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </div>
    );
};

export default ThreadDetailPage;
