import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { Button } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';

const PageHeader = ({ pageAction, pageTitle, backLinkHref, setPageHeaderHeight }) => {
    const headerRef = useRef(null);
    const navigate = useNavigate();
    const { width: screenWidth } = useWindowSize();

    const caption = {
        setup: 'Discover / Process Setup',
        upgrade: 'Process / Process Overview / Upgrade',
    };

    useEffect(() => {
        if (headerRef.current && headerRef.current?.offsetHeight) {
            setPageHeaderHeight(headerRef.current.offsetHeight);
        }
    }, [headerRef.current, screenWidth]);

    return (
        <div ref={headerRef} className="bg-white py-3 px-4 sm:px-6 border-b-1 border-neutral-200">
            <div className="flex justify-between sm:flex-col items-center sm:items-start gap-x-5">
                <div className="flex items-center gap-5">
                    <Button
                        type="link"
                        size="xs"
                        text="Back"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(backLinkHref)}
                    />
                    <p className="hidden sm:block font-body text-body-regular-s text-neutral-300">
                        {caption[pageAction]}
                    </p>
                </div>
                <p className="font-body text-body-regular-s text-neutral-300 sm:font-body-bold sm:text-body-bold-l sm:text-black text-right sm:text-start">
                    {pageTitle[pageAction]}
                </p>
            </div>
        </div>
    );
};

export default PageHeader;
