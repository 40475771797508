import React from 'react';
import { useDrag } from 'react-dnd';

import { useWindowSize } from '../../../hooks/useWindowSize';
import ProcessCard from '../../DashboardPage/ProcessCard/ProcessCard';

const DraggableProcessCard = ({ process, groupId }) => {
    const { width: screenWidth } = useWindowSize();

    const [{ opacity }, drag] = useDrag(
        () => ({
            type: `PROCESS_CARD`,
            item: { processId: process.id, groupId },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        []
    );

    return (
        <div
            ref={screenWidth >= 1024 ? drag : null}
            className="self-stretch rounded-2"
            style={{ opacity }}
        >
            <ProcessCard process={process} />
        </div>
    );
};

export default DraggableProcessCard;
