import { Button, Form } from 'design-system';
import { useState } from 'react';
import apiClient from 'services/user-api';
import Loading from 'components/Loading';
import { API } from 'constants';
import Input from 'design-system/Input/Input';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';

const initialOptions = {
    method: 'POST',
    url: API.ROUTES.user.passwordReset,
    headers: { 'Content-Type': 'application/json' },
};

export default function ResetPassword() {
    const [formData, setFormData] = useState({ email: '' });
    const [apiResponse, setApiResponse] = useState({
        loading: false,
        success: false,
        error: null,
    });
    const { user, isValidating } = useUser();
    const isNotLoggedIn = !isValidating && !user;

    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        setApiResponse({
            loading: true,
            success: false,
            error: null,
        });
        e.preventDefault(); //

        const options = {
            ...initialOptions,
            data: {
                email: formData.email,
            },
        };
        const response = await apiClient(options);
        setApiResponse({
            loading: false,
            success: response.status === 200,
            error: null,
        });
    };

    const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    let formBody;
    if (apiResponse.loading) {
        formBody = <Loading text="Generating a password reset email." />;
    } else if (apiResponse.success) {
        formBody = (
            <h2 className="font-heading-bold text-heading-bold-m text-center">
                A link to reset your password has been sent to your email.
            </h2>
        );
    } else {
        formBody = (
            <div className="flex flex-col gap-[16px]">
                <Input
                    size="xs"
                    label="Email"
                    value={formData.email}
                    name="email"
                    isRequired={true}
                    type="email"
                    onChange={handleInputChange}
                />
                <div className="mx-auto">
                    <Button type="primary" text="Send me an email" size="sm" isSubmit={true} />
                </div>
            </div>
        );
    }

    return (
        <div className="mx-auto py-[80px] w-[300px] flex flex-col gap-[32px]">
            {apiResponse.success && !apiResponse.loading && formBody}
            {!apiResponse.success && (
                <Form
                    title="Log in to Promptfolio"
                    onSubmit={handleSubmit}
                    description="Enter your email and we’ll send you instructions to reset your password"
                >
                    {formBody}
                </Form>
            )}
            {!apiResponse.loading && !apiResponse.success && isNotLoggedIn && (
                <div className="flex gap-[16px] justify-center">
                    <Button
                        type="link"
                        size="sm"
                        text="Login"
                        onClick={() => {
                            navigate('/login');
                        }}
                    />
                    <Button
                        type="link"
                        size="sm"
                        text="Create Account"
                        onClick={() => {
                            navigate('/register');
                        }}
                    />
                </div>
            )}
        </div>
    );
}
