import React, { useRef, useState } from 'react';
import CheckboxLineIcon from '../../../design-system/Icons/CheckboxLineIcon';
import UserSharedLineIcon from '../../../design-system/Icons/UserSharedLineIcon';
import { useClickOutside } from '../../../hooks';
import { Button } from '../../../design-system';
import ReassignModal from '../ReassignModal/ReassignModal';
import SquareIcon from '../../../design-system/Icons/SquareIcon';
import MessageCancelationConfirmModal from '../MessageCancelationConfirmModal/MessageCancelationConfirmModal';
import ActionOption from '../../../design-system/ActionOption/ActionOption';

const MultiActionOptions = ({ onClose, onApprove, onReassign, onCancel, messageSubject }) => {
    const listRef = useRef(null);

    const handleClose = (e) => {
        if (!reassignModalOpened && !cancelationConfirmModalOpened) {
            onClose(e);
        }
    };

    useClickOutside(listRef, handleClose);

    const [reassignModalOpened, setReassignModalOpened] = useState(false);
    const [cancelationConfirmModalOpened, setCancelationConfirmModalOpened] = useState(false);

    return (
        <>
            <div className="max-xs:fixed max-xs:top-0 max-xs:left-0 max-xs:right-0 max-xs:bottom-0 max-xs:bg-black/50 xs:hidden"></div>
            <div
                className="px-5 pt-6 pb-8 sm:p-4 flex flex-col gap-1 bg-white fixed xs:absolute bottom-0 xs:bottom-[calc(100%+4px)] max-xs:left-0 right-0 rounded-2 shadow-l1 border-1 border-neutral-200 w-full xs:w-[297px]"
                ref={listRef}
            >
                <div className="max-xs:mb-8">
                    <ActionOption
                        label="Approve"
                        description="This will be moved to Done area of your inbox"
                        icon={CheckboxLineIcon}
                        handleAction={onApprove}
                        onClose={handleClose}
                    />
                    <ActionOption
                        label="Cancel"
                        description="This will mark task as Failed and remove from your inbox"
                        icon={SquareIcon}
                        onClose={() => {}}
                        handleAction={() => setCancelationConfirmModalOpened(true)}
                    />
                    <ActionOption
                        label="Reassign"
                        description="Reassign this to another team member"
                        icon={UserSharedLineIcon}
                        onClose={() => {}}
                        handleAction={() => setReassignModalOpened(true)}
                    />
                </div>

                <div className="xs:hidden flex flex-col">
                    <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                </div>
                {reassignModalOpened && (
                    <ReassignModal
                        onReassign={onReassign}
                        onClose={() => setReassignModalOpened(false)}
                        onMultiActionOptionsPanelClose={onClose}
                    />
                )}
                {cancelationConfirmModalOpened && (
                    <MessageCancelationConfirmModal
                        messageSubject={messageSubject}
                        onCancelMessage={onCancel}
                        onClose={() => setCancelationConfirmModalOpened(false)}
                        onMultiActionOptionsPanelClose={onClose}
                    />
                )}
            </div>
        </>
    );
};

export default MultiActionOptions;
