import React from 'react';
import { DEFAULT_SECTION_NAME } from '../../../constants/playbookBuilder';
import { checkIfViewModeWithEmptyPlaybookContent } from '../../../helpers/playbookBuilderUtils';

const NavigationGuide = ({ mode, playbookContent, areSectionsExpandedState }) => {
    const [_, setAreSectionsExpanded] = areSectionsExpandedState;

    const isViewModeWithEmptyPlaybookContent = checkIfViewModeWithEmptyPlaybookContent(
        mode,
        playbookContent
    );

    const expandSection = (temporaryId) => {
        setAreSectionsExpanded((prevData) => ({ ...prevData, [temporaryId]: true }));
    };

    const expandAndScrollToTargetSection = (temporaryId) => {
        const targetSection = document.getElementById(temporaryId);
        if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
            expandSection(temporaryId);
        }
    };

    return (
        <div className="bg-neutral-400 rounded-2.5 p-5 md:p-6 flex flex-col gap-4">
            <p className="font-body text-body-regular-xs uppercase text-neutral-200">
                Navigation Guide
            </p>

            {!!playbookContent.length && !isViewModeWithEmptyPlaybookContent && (
                <ul className="flex flex-col gap-4">
                    {playbookContent.map((section) => (
                        <li
                            key={section.temporaryId}
                            className="font-body text-body-regular-s text-white underline cursor-pointer"
                            onClick={() => expandAndScrollToTargetSection(section.temporaryId)}
                        >
                            {section.name || DEFAULT_SECTION_NAME}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default NavigationGuide;
