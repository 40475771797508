import React from 'react';
import { Input, TextArea } from 'design-system';
import PromptSettingsContainer from 'components/PromptSettingsContainer/PromptSettingsContainer';

function CrudPromptContainer({
    promptName,
    handlePromptNameChange,
    promptText,
    handlePromptTextChange,
    promptSettings,
    setPromptSettings,
}) {
    return (
        <div className="w-full space-y-4">
            <div>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-start">
                        <span class="pr-4 bg-white font-body-bold text-body-bold-l leading-10 text-gray-900">
                            Prompt
                        </span>
                    </div>
                </div>
                <div className="font-body-regular text-body-regular-m">
                    Write your prompt. Use [input] to reference your input variables above.
                </div>
            </div>
            <Input
                state="default"
                size="md"
                label="Give your prompt a name"
                name="promptTitle"
                value={promptName || ''}
                placeholder="Enter prompt title"
                onChange={handlePromptNameChange}
            />
            <TextArea
                state="default"
                label="Write your prompt"
                name="promptText"
                value={promptText || ''}
                placeholder="Act as a..."
                onChange={handlePromptTextChange}
            />
            <PromptSettingsContainer
                promptSettings={promptSettings}
                setPromptSettings={setPromptSettings}
            />
        </div>
    );
}

export default CrudPromptContainer;
