import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/library-api';
import useSWRRequest, { getKey } from '../../hooks/useSWRRequest';

import { SORT_TYPES } from '../../constants/sort';
import { parseProcessPageSearchParams } from '../../helpers/parseProcessPageSearchParams';
import { getGroupRequestQueryParams } from '../../helpers/getGroupRequestQueryParams';
import { addUniqueElementToArray } from '../../helpers/addUniqueElementToArray';
import { useWindowSize } from '../../hooks/useWindowSize';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import GroupDetails from './GroupDetails/GroupDetails';
import Loading from '../../components/Loading';
import ProcessPageTopControls from './ProcessPageTopControls/ProcessPageTopControls';
import PlaybookDetailsModal from '../PlaybookBuilderPage/PlaybookDetailsModal/PlaybookDetailsModal';
import ProcessPageTitleBlock from './ProcessPageTitleBlock/ProcessPageTitleBlock';

const startNewJobModalRequestSWRKey = getKey({
    route: API.ROUTES.library.playbook,
    searchParams: { ordering: SORT_TYPES.alphabetical },
});

const ProcessPage = () => {
    // playbook is just renamed group
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseProcessPageSearchParams(searchParams), [searchParams]);

    const { width: screenWidth } = useWindowSize();

    const [requestKeysToMutate, setRequestKeysToMutate] = useState([startNewJobModalRequestSWRKey]);
    const [isCreatePlaybookModalOpened, setIsCreatePlaybookModalOpened] = useState(false);

    const requestSearchParams = useMemo(
        () => getGroupRequestQueryParams(parsedParams),
        [parsedParams]
    );

    const {
        data: groups,
        loading,
        key,
    } = useSWRRequest({
        client,
        route: API.ROUTES.library.playbook,
        searchParams: requestSearchParams,
    });

    // if filter is not empty show just filtered groups
    const filteredGroups = useMemo(() => {
        const groupsFilter = parsedParams.filter;
        if (groupsFilter) {
            return groups?.filter((group) => groupsFilter.includes(group.id));
        }
        return groups;
    }, [groups, parsedParams.filter]);

    useEffect(() => {
        // add sort search params if it does not exist
        if (!parsedParams.sort) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('sort', SORT_TYPES.alphabetical);
            setSearchParams(urlSearchParams, { replace: true });
        }
    }, [parsedParams]);

    useEffect(() => {
        if (key) {
            setRequestKeysToMutate((prevKeys) => addUniqueElementToArray(key, prevKeys));
        }
    }, [key]);

    useDocumentTitle('Your Processes');

    useEffect(() => {
        if (screenWidth < 1024 && isCreatePlaybookModalOpened) {
            setIsCreatePlaybookModalOpened(false);
        }
    }, [screenWidth, isCreatePlaybookModalOpened]);

    const openCreatePlaybookModal = () => setIsCreatePlaybookModalOpened(true);

    return (
        <div className="page-position bg-white overflow-y-auto flex flex-col gap-5 px-5 pt-4 pb-8 sm:px-8 sm:pt-5 sm:pb-[40px]">
            <ProcessPageTitleBlock openCreatePlaybookModal={openCreatePlaybookModal} />
            <ProcessPageTopControls
                groups={groups}
                filteredGroups={filteredGroups}
                areGroupsLoading={loading}
            />
            {groups && (
                <>
                    {!!filteredGroups?.length && (
                        <div className="flex flex-col gap-5">
                            {filteredGroups.map((group) => (
                                <GroupDetails
                                    key={group.id}
                                    group={group}
                                    requestKeysToMutate={requestKeysToMutate}
                                />
                            ))}
                        </div>
                    )}
                    {!filteredGroups?.length && parsedParams.search && (
                        <p className="font-body text-body-bold-s text-neutral-300 max-w-[600px]">
                            We couldn't find any groups or processes matching your search for{' '}
                            {parsedParams.search}. Please try using a different keyword.
                        </p>
                    )}
                    {isCreatePlaybookModalOpened && (
                        <PlaybookDetailsModal
                            mode="create"
                            requestKeysToMutate={requestKeysToMutate}
                            onClose={() => setIsCreatePlaybookModalOpened(false)}
                        />
                    )}
                </>
            )}
            {!groups && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ProcessPage;
