import { SORT_TYPES } from '../constants/sort';
import { JOB_STATUS } from '../constants/jobs';

export const getJobsRequestQueryParams = ({ parsedSearchParams }) => {
    const { sort, search, process, status } = parsedSearchParams;

    const queryParams = { ordering: sort || SORT_TYPES.created };
    if (search) {
        queryParams.search = search;
    }
    if (!!process?.length) {
        queryParams.process__in = process.join(',');
    }
    if (status) {
        const isStatusValid = Object.values(JOB_STATUS).includes(status);
        if (isStatusValid) {
            queryParams.status = status;
        }
    }
    return queryParams;
};
