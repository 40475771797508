import React, { useState } from 'react';

import { ButtonIcon } from '../../../../design-system';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import EditLineIcon from '../../../../design-system/Icons/EditLineIcon';
import PaginationBar from '../../../../design-system/PaginationBar/PaginationBar';
import EditBaseRecordModal from '../EditBaseRecordModal/EditBaseRecordModal';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import Alert from '../../../../design-system/Alert/Alert';

const KnowledgeBaseContentPiece = ({ content, setShouldClosePanelOnOutsideClick }) => {
    const { data, is_filled, label } = content;

    const [page, setPage] = useState(1);
    const [editRecordModal, setEditRecordModal] = useState({ isOpened: false, objectUuid: null });

    // baseDetail are used in EditBaseRecordModal
    // this state is stored here to prevent redundant requests (if EditBaseRecordModal has been opened previously and baseDetail is not empty, it can be reused for subsequent openings of EditBaseRecordModal)
    const [baseDetail, setBaseDetail] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    if (is_filled && !data.records?.length) {
        return (
            <FormFieldWrapper label={label} gap={6}>
                <div className="px-4 py-8 rounded-2 border-1 border-neutral-300 flex justify-center font-body text-body-regular-m text-neutral-300 text-center">
                    No records found
                </div>
            </FormFieldWrapper>
        );
    }

    const { records, is_editable, limit, base_id } = data;
    const recordsTotal = records.length;

    const recordsValueToCopy = records
        .map((record) => `Title: ${record.title}\nContent: ${record.content}`)
        .join('\n\n');

    const firstDisplayedRecordIndex = (page - 1) * limit;
    const displayedRecords = records.slice(
        firstDisplayedRecordIndex,
        firstDisplayedRecordIndex + limit
    );

    return (
        <FormFieldWrapper label={label} gap={6} withCopyButton value={recordsValueToCopy}>
            <ul className="flex flex-col gap-2">
                {displayedRecords.map((record, index) => {
                    const { title, content, object_uuid } = record;
                    return (
                        <div
                            key={index}
                            className="px-3 sm:px-5 py-3 border-1 flex items-start justify-between gap-3 sm:gap-5 rounded-2"
                        >
                            <div
                                className={
                                    is_editable
                                        ? 'max-w-[calc(100%-40px)] sm:max-w-[calc(100%-48px)]'
                                        : 'max-w-full'
                                }
                            >
                                <p className="font-body-bold text-body-bold-s text-neutral-500 truncate mb-1">
                                    {title}
                                </p>
                                <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2">
                                    {content}
                                </p>
                            </div>
                            {is_editable && (
                                <ButtonIcon
                                    type="neutral"
                                    size="xs"
                                    icon={EditLineIcon}
                                    onClick={() => {
                                        setShouldClosePanelOnOutsideClick(false);
                                        setEditRecordModal({
                                            isOpened: true,
                                            objectUuid: object_uuid,
                                        });
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </ul>
            {recordsTotal > limit && (
                <PaginationBar
                    page={page}
                    limit={limit}
                    total={recordsTotal}
                    handlePreviousClick={() => setPage((page) => page - 1)}
                    handleNextClick={() => setPage((page) => page + 1)}
                />
            )}
            {editRecordModal.isOpened && (
                <EditBaseRecordModal
                    objectUuid={editRecordModal.objectUuid}
                    baseId={base_id}
                    baseDetail={baseDetail}
                    setBaseDetail={setBaseDetail}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => {
                        setShouldClosePanelOnOutsideClick(true);
                        setEditRecordModal({ isOpened: false, objectUuid: null });
                    }}
                />
            )}
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    autoCloseInMS={4000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </FormFieldWrapper>
    );
};

export default KnowledgeBaseContentPiece;
