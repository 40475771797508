import React, { useState } from 'react';
import InboxRecordItem from '../InboxRecordItem/InboxRecordItem';
import ViewInboxMessageModal from '../ViewInboxMessageModal/ViewInboxMessageModal';
import Alert from '../../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const InboxContentPiece = ({ content, setShouldClosePanelOnOutsideClick }) => {
    const { label, data } = content;

    const inboxRecordItems = Array.isArray(data) ? data : [data];

    const [viewInboxMessageModal, setViewInboxMessageModal] = useState({
        isOpened: false,
        messageId: null,
    });
    const [errorAlert, setErrorAlert] = useState(null);

    const openViewInboxMessageModal = (messageId) => {
        setShouldClosePanelOnOutsideClick(false);
        setViewInboxMessageModal({
            isOpened: true,
            messageId,
        });
    };

    return (
        <div>
            <p className="font-body-bold text-body-bold-s text-neutral-500 mb-1.5">{label}</p>
            <ul className="flex flex-col gap-1">
                {inboxRecordItems.map((item, index) => {
                    return (
                        <InboxRecordItem
                            key={index}
                            item={item}
                            openViewInboxMessageModal={openViewInboxMessageModal}
                        />
                    );
                })}
            </ul>
            {viewInboxMessageModal.isOpened && (
                <ViewInboxMessageModal
                    messageId={viewInboxMessageModal.messageId}
                    setErrorAlert={setErrorAlert}
                    onClose={() => {
                        setShouldClosePanelOnOutsideClick(true);
                        setViewInboxMessageModal({ isOpened: false, messageId: null });
                    }}
                />
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={4000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default InboxContentPiece;
