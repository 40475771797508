import React, { useState } from 'react';
import classNames from 'classnames';

import { capitalizeFirstLetter } from '../../../../services/strings';

import JobTaskCard from '../JobTaskCard/JobTaskCard';
import { ButtonIcon, SvgIcon } from '../../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../../design-system/Icons';
import { JOB_TASK_BUCKET_STATUS_ICON } from '../../../../constants/jobs';

const StatusBuckets = ({ bucketStatus, milestones, view }) => {
    const [isExpanded, setIsExpanded] = useState(!!milestones?.length);
    const isCollapsible = view === 'row';

    const expandButtonIcon = isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    const toggleExpand = (e) => {
        e.stopPropagation();
        setIsExpanded((prevState) => !prevState);
    };

    const bucketClassName = classNames('flex flex-col gap-5 hide-scrollbar h-full', {
        'min-w-[280px] sm:min-w-[320px] lg:min-w-[280px] w-[calc((100%-40px)/3)]':
            view === 'column',
    });

    return (
        <div className={bucketClassName}>
            <div
                className={`flex items-center gap-2.5 ${isCollapsible && 'cursor-pointer'}`}
                onClick={isCollapsible ? toggleExpand : () => {}}
            >
                {isCollapsible && (
                    <ButtonIcon
                        icon={expandButtonIcon}
                        type="link"
                        size="xs"
                        onClick={toggleExpand}
                    />
                )}
                <SvgIcon
                    size="large"
                    color="#1F2125"
                    icon={JOB_TASK_BUCKET_STATUS_ICON[bucketStatus]}
                />
                <p className="font-heading-regular text-heading-regular-s">
                    {capitalizeFirstLetter(bucketStatus)}
                </p>
            </div>
            <div
                className={`flex flex-col gap-2.5 flex-grow hide-scrollbar h-[calc(100%-44px)] overflow-y-auto ${
                    isCollapsible && !isExpanded ? 'hidden' : 'flex'
                }`}
            >
                {!!milestones.length &&
                    milestones.map((milestone) => (
                        <JobTaskCard
                            key={milestone.id}
                            milestone={milestone}
                            bucketStatus={bucketStatus}
                            view={view}
                        />
                    ))}
                {!milestones.length && (
                    <div className="px-4 py-8 border-1 border-dashed border-neutral-200 rounded-2 flex items-center justify-center font-body text-body-regular-m text-neutral-300">
                        No job tasks {bucketStatus}
                    </div>
                )}
            </div>
        </div>
    );
};

export default StatusBuckets;
