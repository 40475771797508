import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '../index';
import { InformationLineIcon } from '../Icons';

ErrorBanner.propTypes = {
    errorMessage: PropTypes.string.isRequired,
};
function ErrorBanner({ errorMessage }) {
    return (
        <div className="w-full p-2.5 xs:p-3 sm:p-4 rounded-2 bg-[#FCE4E680] border-1 border-red-300 flex gap-2.5">
            <div className="w-8 h-8 min-w-[32px] rounded-2 bg-white flex items-center justify-center">
                <SvgIcon color="#F8CACE" icon={InformationLineIcon} size="large" />
            </div>
            <div>
                <p className="font-body-bold text-body-bold-s text-neutral-500 mb-1">
                    Oops! Something went wrong
                </p>
                <p className="font-body text-body-regular-s text-neutral-500">{errorMessage}</p>
            </div>
        </div>
    );
}

export default ErrorBanner;
