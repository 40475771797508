import React, { useMemo, useRef, useState } from 'react';
import useUser from '../../../../hooks/useUser';

import { API } from 'constants';
import templateClient from '../../../../services/template-api';
import libraryClient from '../../../../services/library-api';
import {
    checkIfEveryStepFormDataValid,
    formatStepFormDataForRequestBody,
    getSetStepFormDataFunction,
    getSettingsFormDataInitialState,
    handleStepsFormErrors,
} from '../../../../helpers/settingsFormUtils';
import { useWebSocketListeningLoadingState } from '../../../../hooks/useWebSocketListeningLoadingState';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import SettingStepCollapsibleContainer from '../SettingStepCollapsibleContainer/SettingStepCollapsibleContainer';
import SettingStepForm from '../SettingStepForm/SettingStepForm';
import ErrorBanner from '../../../../design-system/ErrorBanner/ErrorBanner';
import Alert from '../../../../design-system/Alert/Alert';
import errorWarningLineIcon from '../../../../design-system/Icons/ErrorWarningLineIcon';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import NavigationGuard from '../../../../components/NavigationGuard/NavigationGuard';
import { Button } from '../../../../design-system';

const SettingsTabSection = ({ processDetails, setProcessDetails }) => {
    const { id: processId, default_version } = processDetails;
    const { settings, process_template_version: processTemplateVersionId } = default_version;
    const { user } = useUser();

    const tabContainerRef = useRef(null);

    // keeps form data in format {[step.order]: [stepFormData]}
    const [formData, setFormData] = useState(
        settings.reduce(
            (acc, step) => ({
                ...acc,
                [step.order]: getSettingsFormDataInitialState(step.settings),
            }),
            {}
        )
    );
    // keeps data in format {[step.order]: {Boolean (isCurrentStepRequiredFieldsFilled)}}
    // no need to set the initial state because in every SettingStepForm on Component mount useEffect is called and set the correct value
    // this state is used specifically to avoid checking all form fields when a field of a particular step changes.
    // validation is performed locally at the level of each step only if a field of that step is changed.
    const [areStepsRequiredFieldsFilled, setAreStepsRequiredFieldsFilled] = useState({});

    const allRequiredFieldsAreFilled = useMemo(
        () => Object.values(areStepsRequiredFieldsFilled).every(Boolean),
        [areStepsRequiredFieldsFilled]
    );

    const [isExpanded, setIsExpanded] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [stepsErrorData, setStepsErrorData] = useState({}); // keeps data in format {[step.order]: {isError: true, errorMessage: "error Message"}}
    const [errorBannerMessage, setErrorBannerMessage] = useState(null); // used for general (not step-specific) errors
    const [errorAlert, setErrorAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    const isProcessCustom = !processTemplateVersionId;

    const { setRequestUuid, tryReconnectIfWebsocketClosed } = useWebSocketListeningLoadingState({
        messageType: 'update_process',
        onSuccess: onWebsocketSuccess,
        onError: onWebsocketError,
        shouldConnectWebsocket: !isProcessCustom, // if process is custom websockets should not be connected
    });

    function onWebsocketSuccess(message) {
        setIsLoading(false);
        setSuccessAlert({ message: 'Process settings successfully updated!' });
        setProcessDetails((prevData) => ({ ...prevData, name: message.data?.name }));
    }

    function onWebsocketError(message) {
        setErrorBannerMessage(message.error);
        setErrorAlert({ message: defaultErrorMessage });
        setIsLoading(false);
    }

    const getRequestBody = () => {
        const settings = Object.values(formData).reduce((acc, stepFormData, index) => {
            if (index === 0) {
                return acc;
            }
            return [...acc, ...formatStepFormDataForRequestBody(stepFormData)];
        }, []);

        return {
            name: formData['0'][0]?.value, // value from the "Process Name" step's input (first step)
            organization: user.organization.id,
            process: processId,
            settings,
        };
    };

    const expandAllErrorsSteps = (stepsErrorData) => {
        const expandedSteps = {};

        Object.entries(stepsErrorData).map(([order, stepErrorData]) => {
            if (stepErrorData?.isError) {
                expandedSteps[order] = true;
            }
        });

        setIsExpanded(expandedSteps);
    };

    const handleUpdateCustomProcessSettings = async () => {
        const name = formData['0'][0]?.value;
        await libraryClient.patch(`${API.ROUTES.library.process}${processId}/`, { name });
        setProcessDetails((prevData) => ({ ...prevData, name }));
        setSuccessAlert({ message: 'Process settings successfully updated!' });
        setIsLoading(false);
    };

    const handleUpdateDeployedFromTemplateProcessSettings = async () => {
        const requestBody = getRequestBody();
        const { data } = await templateClient.patch(
            `${API.ROUTES.template.processTemplateVersion}${processTemplateVersionId}/deploy/`,
            requestBody
        );
        if (data.status === 'started' && data.request_uuid) {
            setRequestUuid(data.request_uuid);
        }
        tryReconnectIfWebsocketClosed();
    };

    const updateSettings = async () => {
        // remove all step errors before submitting
        setStepsErrorData({});
        setErrorBannerMessage(null);

        try {
            // check if all required fields are filled and if all inputted data is valid (json-input has a valid json value)
            const { isValid, updatedStepsErrorData } = checkIfEveryStepFormDataValid(
                formData,
                setFormData
            );

            if (!isValid) {
                setStepsErrorData(updatedStepsErrorData);
                expandAllErrorsSteps(updatedStepsErrorData);
                setErrorAlert({ message: 'All required fields should be filled and valid.' });
                return;
            }

            setIsLoading(true);

            if (isProcessCustom) {
                await handleUpdateCustomProcessSettings();
            }
            if (!isProcessCustom) {
                await handleUpdateDeployedFromTemplateProcessSettings();
            }
        } catch (e) {
            setIsLoading(false);
            setRequestUuid(null);

            handleStepsFormErrors({
                e,
                setErrorBannerMessage,
                setStepsErrorData,
                setErrorAlert,
                formData,
                setFormData,
                settings,
                expandAllErrorsSteps,
            });
        }
    };

    const sortedSettings = useMemo(() => settings?.sort((a, b) => a.order - b.order), [settings]);

    return (
        <>
            {errorBannerMessage && <ErrorBanner errorMessage={errorBannerMessage} />}

            <div
                className="w-full p-5 sm:p-6 bg-white rounded-2xl flex flex-col gap-5"
                ref={tabContainerRef}
            >
                <p className="font-heading-bold text-heading-bold-s text-black">Your Settings</p>

                <div className="flex flex-col gap-5">
                    {sortedSettings.map((stepDetails) => (
                        <SettingStepCollapsibleContainer
                            key={stepDetails.order}
                            stepDetails={stepDetails}
                            isExpanded={isExpanded[stepDetails.order]}
                            setIsExpanded={setIsExpanded}
                        >
                            <SettingStepForm
                                stepFormData={formData[stepDetails.order]}
                                setStepFormData={getSetStepFormDataFunction(
                                    stepDetails.order,
                                    setFormData
                                )}
                                stepOrder={stepDetails.order}
                                setAreStepsRequiredFieldsFilled={setAreStepsRequiredFieldsFilled}
                                currentStepErrorData={stepsErrorData[stepDetails.order]}
                                instructions={stepDetails.instructions}
                                tabContainerRef={tabContainerRef}
                            />
                        </SettingStepCollapsibleContainer>
                    ))}
                </div>

                <div className="flex justify-end">
                    <Button
                        type="secondary"
                        size="xs"
                        text="Update Settings"
                        state={
                            isLoading
                                ? 'loading'
                                : allRequiredFieldsAreFilled
                                ? 'default'
                                : 'disabled'
                        }
                        onClick={updateSettings}
                    />
                </div>

                <NavigationGuard when={isLoading} />
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={errorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </>
    );
};

export default SettingsTabSection;
