import React from 'react';
import { Badge } from '../../../../design-system';
import LayersIcon from '../../../../design-system/Icons/LayersIcon';

const GoalTemplateUsedInBlock = ({ processTemplates }) => {
    const openProcessTemplateDetail = (processTemplateId) => {
        window.open(`/templates/process/${processTemplateId}`, '_blank');
    };

    if (!processTemplates?.length) {
        return <></>;
    }

    return (
        <div className="flex flex-col gap-1">
            <p className="font-body-bold text-body-bold-xs text-neutral-500">Used In</p>
            <ul className="flex items-center gap-x-2 gap-y-1 flex-wrap">
                {processTemplates.map((processTemplate) => {
                    const badgeText = (
                        <div>
                            <span className="font-body-bold text-body-bold-xs">
                                {processTemplate.name}
                            </span>{' '}
                            ID {processTemplate.id}
                        </div>
                    );
                    return (
                        <li
                            key={processTemplate.id}
                            className="cursor-pointer"
                            onClick={() => openProcessTemplateDetail(processTemplate.id)}
                        >
                            <Badge
                                text={badgeText}
                                color="neutral"
                                leadingIcon={LayersIcon}
                                leadingIconColor="#000000"
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default GoalTemplateUsedInBlock;
