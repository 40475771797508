import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from '../../hooks/useWindowSize';
import classNames from 'classnames';

DetailContainer.propTypes = {
    label: PropTypes.string.isRequired,
    desktopStartPixel: PropTypes.number,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // can be passed in pixels or in percentage (150 or "20%")
};

function DetailContainer({ label, maxWidth, desktopStartPixel = 1024, children }) {
    const { width: screenWidth } = useWindowSize();
    const isDesktop = screenWidth >= desktopStartPixel;

    const formattedMaxWidth = typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth;
    const maxWidthStyle = maxWidth && isDesktop ? formattedMaxWidth : '100%';

    const containerClassName = classNames('flex', {
        'flex-col gap-1': isDesktop,
        'flex-row items-center gap-1.5': !isDesktop,
    });

    return (
        <div className={containerClassName} style={{ maxWidth: maxWidthStyle }}>
            <p className="font-body text-body-regular-xs text-neutral-300 whitespace-nowrap">
                {label}
            </p>
            <div className="font-body-bold text-body-bold-xs text-neutral-500 truncate">
                {children}
            </div>
        </div>
    );
}

export default DetailContainer;
