import React from 'react';
import { Tabs, Button, Input, Select, Badge } from 'design-system';

const tabs = [{ name: 'Use Existing Task' }, { name: 'Create New Task' }];

function CrudTaskContainer({
    handleNewTaskTab,
    taskTabIndex,
    showNewTask,
    newTask,
    handleNewTaskChange,
    tasks,
    chosenTaskId,
    handleTaskChoice,
    taskInputKeys,
    handleInputChange,
    taskOutputKeys,
    handleOutputChange,
    inputBadges,
}) {
    return (
        <div className="w-full space-y-4">
            <div>
                <div class="relative">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-start">
                        <span class="pr-4 bg-white font-body-bold text-body-bold-l leading-10 text-gray-900">
                            Task
                        </span>
                    </div>
                </div>
                <div className="font-body-regular text-body-regular-m">
                    A task is a single step in a workflow. You can create a new task or use an
                    existing one.
                </div>
            </div>
            <div className="flex flex-row space-x-2">
                <Tabs
                    onTabChanged={(tab, index) => handleNewTaskTab(index === 0)}
                    tabIndex={taskTabIndex}
                    tabs={tabs}
                />
            </div>
            {showNewTask ? (
                <div className="w-full space-y-4">
                    <Input
                        state="default"
                        size="md"
                        label="Give your task a name"
                        name="taskTitle"
                        value={newTask || ''}
                        placeholder="Enter task title"
                        onChange={handleNewTaskChange}
                    />
                    <Input
                        state="default"
                        size="md"
                        label="Add the inputs"
                        name="taskInputs"
                        value={taskInputKeys}
                        placeholder="Enter task inputs"
                        onChange={handleInputChange}
                    />
                    <div className="space-x-2">
                        {taskInputKeys.length > 0 &&
                            inputBadges.map((badge, index) => (
                                <span key={index} className="badge">
                                    <Badge text={badge} color="peach" />
                                </span>
                            ))}
                    </div>
                    <Input
                        state="default"
                        size="md"
                        label="Add the output"
                        name="taskOutput"
                        value={taskOutputKeys}
                        placeholder="Enter task output"
                        onChange={handleOutputChange}
                    />
                    <div className="space-x-2">
                        {taskOutputKeys.length > 0 && <Badge text={taskOutputKeys} color="peach" />}
                    </div>
                </div>
            ) : (
                <div className="w-full space-y-4">
                    <Select
                        label="Select a task"
                        name="task"
                        options={tasks}
                        value={chosenTaskId}
                        onChange={handleTaskChoice}
                        size="md"
                        state="default"
                    />
                    <Input
                        state="disabled"
                        size="md"
                        label="Add the inputs"
                        name="taskInputs"
                        value={taskInputKeys}
                        placeholder="Enter task inputs"
                        onChange={handleInputChange}
                    />
                    <div className="space-x-2">
                        {taskInputKeys.length > 0 &&
                            inputBadges.map((badge, index) => (
                                <span key={index} className="badge">
                                    <Badge text={badge} color="peach" />
                                </span>
                            ))}
                    </div>
                    <Input
                        state="disabled"
                        size="md"
                        label="Add the output"
                        name="taskOutput"
                        value={taskOutputKeys}
                        placeholder="Enter task output"
                        onChange={handleOutputChange}
                    />
                    <div className="space-x-2">
                        {taskOutputKeys.length > 0 && <Badge text={taskOutputKeys} color="peach" />}
                    </div>
                </div>
            )}
        </div>
    );
}

export default CrudTaskContainer;
