import React from 'react';

import { useDrag } from 'react-dnd';

import TaskCard from '../../../components/TaskCard/TaskCard';

const DraggableSidebarCard = ({ task, setData }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'SIDEBAR_CARD',
        item: { id: task.id, position: task.position },
        end: (draggingItem, monitor) => {
            const dropResult = monitor.getDropResult();
            if (dropResult && dropResult.position === 'sidebar') {
                setData((prevData) => {
                    const stepCardsCount = prevData.filter(
                        (item) => item.position === 'dashboard'
                    ).length;
                    return prevData.map((item) => {
                        if (item.id === draggingItem.id) {
                            return { ...item, position: 'dashboard', step: stepCardsCount + 1 };
                        }
                        return item;
                    });
                });
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div className="w-full" ref={drag}>
            <TaskCard task={task} isDragging={isDragging} />
        </div>
    );
};

export default DraggableSidebarCard;
