import React from 'react';
import PropTypes from 'prop-types';

Tag.propTypes = {
    text: PropTypes.string.isRequired,
    uppercase: PropTypes.bool,
};

function Tag({ text, uppercase = false }) {
    return (
        <p
            className={`px-2 py-[3px] border-1 border-neutral-200 font-body text-body-regular-xs text-neutral-300 rounded-full w-fit ${
                uppercase && 'uppercase'
            }`}
        >
            {text}
        </p>
    );
}

export default Tag;
