import { SOCKET_URL } from 'constants/baseUrl';
import operateClient from './operate-api';

class WebSocketService {
    static instance = null;
    callbacks = {};

    static typesForListeningLoadingState = [
        'integration_test_result',
        'deploy_process',
        'update_process',
    ];

    static getInstance() {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }

    getSingleton = () => {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    };

    constructor() {
        this.socketRef = null;
        this.currentPageGenId = null;
        this.currentRequestUuid = null;
        this.maxReconnectAttempts = 3;
        this.reconnectAttempts = 0;
    }

    setCurrentPageGenId(genId) {
        this.currentPageGenId = genId;
    }

    setCurrentRequestUuid(requestUuid) {
        this.currentRequestUuid = requestUuid;
    }

    // get options and authToken from local storage
    getOptions = () => {
        // TODO: if auth token isn't available
        const authToken = localStorage.getItem('authKey');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${authToken}`,
            },
        };
        return options;
    };

    getReadyState = () => {
        if (!this.socketRef) {
            return WebSocket.CLOSED;
        }
        return this.socketRef.readyState;
    };

    isOpen = () => {
        return this.socketRef && this.socketRef.readyState === WebSocket.OPEN;
    };

    connect() {
        // If socketRef is not null and its state is OPEN, return early.
        if (this.socketRef && this.socketRef.readyState === WebSocket.OPEN) {
            console.log('WebSocket is already connected');
            return;
        }
        const options = this.getOptions();
        operateClient
            .post('get-websocket-ticket/', options)
            .then((res) => {
                const ticket = res.data.ticket;
                const userId = res.data.id;
                const path = `${SOCKET_URL}${userId}/?ticket=${ticket}`;
                this.socketRef = new WebSocket(path);
                this.socketRef.onopen = () => {
                    console.log('WebSocket open');
                };
                this.socketRef.onmessage = (e) => {
                    if (JSON.parse(e.data).type === 'pong') {
                    } else {
                        this.socketNewMessage(e.data);
                    }
                };
                this.socketRef.onerror = (e) => {
                    console.log(e.message);
                };
                this.socketRef.onclose = () => {
                    console.log('WebSocket closed');
                    this.socketClosed();
                };
            })
            .catch((error) => {
                console.log('Getting WebSocket ticket error');
            });
    }

    disconnect() {
        if (this.socketRef) {
            // remove trying to reconnect on websocket close event
            this.socketRef.onclose = () => {
                console.log('WebSocket closed');
            };
            this.socketRef.close();
            this.socketRef = null;
            this.reconnectAttempts = 0; // Reset reconnection attempts on explicit disconnect
        }
    }

    socketNewMessage(data) {
        const parsedData = JSON.parse(data);
        const type = parsedData.message.type;

        const isRequestUuidCorrect = parsedData.message.request_uuid === this.currentRequestUuid;
        const isGenIdCorrect = parsedData.message.gen_id === this.currentPageGenId;

        const isCurrentTypeListeningLoadingState =
            WebSocketService.typesForListeningLoadingState.includes(type);

        // check if request_uuid match currentRequestUuid, then return early
        if (isCurrentTypeListeningLoadingState && !isRequestUuidCorrect) {
            return;
        }

        // if type stream_start, stream_token, stream_end, stream_error
        // Check if gen_id doesn't match currentPageGenId, then return early
        if (!isCurrentTypeListeningLoadingState && !isGenIdCorrect) {
            return;
        }

        // make sure we actually have a callback to run
        if (Object.keys(this.callbacks).length === 0) {
            return;
        }
        if (type === 'stream_start') {
            console.log('stream_start');
            this.callbacks[type](parsedData);
        }
        if (type === 'stream_token') {
            this.callbacks[type](parsedData);
        }
        if (type === 'stream_end') {
            console.log('stream_end');
            this.callbacks[type](parsedData);
        }
        if (type === 'stream_error') {
            console.log('stream_error');
            this.callbacks[type](parsedData);
        }
        if (isCurrentTypeListeningLoadingState) {
            this.callbacks[type](parsedData);
        }
    }

    addCallbacks(
        onStreamStartCallback,
        onStreamTokenCallback,
        onStreamEndCallback,
        onStreamErrorCallback
    ) {
        this.callbacks['stream_start'] = onStreamStartCallback;
        this.callbacks['stream_token'] = onStreamTokenCallback;
        this.callbacks['stream_end'] = onStreamEndCallback;
        this.callbacks['stream_error'] = onStreamErrorCallback;
    }

    addCallbackForSpecificType(type, callback) {
        this.callbacks[type] = callback;
    }

    sendMessage(data) {
        try {
            this.socketRef.send(JSON.stringify({ ...data }));
        } catch (err) {
            console.log(err.message);
        }
    }

    waitForSocketConnection(callback) {
        // ensures this method is continuously called until the socket is connected
        const socket = this.socketRef;
        const recursion = this.waitForSocketConnection;
        setTimeout(function () {
            if (socket && socket.readyState === 1) {
                console.log('Connection is made');
                if (callback != null) {
                    callback();
                }
                return;
            } else {
                console.log('wait for connection...');
                recursion(callback);
            }
        }, 300);
    }

    socketClosed() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            const delay = Math.pow(2, this.reconnectAttempts) * 1000; // Exponential backoff
            setTimeout(() => {
                this.reconnect();
                this.reconnectAttempts++;
            }, delay);
        } else {
            console.log('Exceeded maximum reconnection attempts. Stopping reconnect attempts.');
        }
    }

    state() {
        return this.socketRef ? this.socketRef.readyState : -1;
    }

    reconnect() {
        this.disconnect();
        this.connect();
    }

    attempt_reconnect() {
        try {
            console.log('Attempting to reconnect...');
            this.reconnect();
            return true;
        } catch (err) {
            console.log('Unable to establish websocket connection');
            console.log(err);
        }
        return false;
    }
}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;
