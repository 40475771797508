import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../../services/library-api';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

import Alert from '../../../../design-system/Alert/Alert';
import { ListOption, ListOptionGroup } from '../../../../design-system';
import { ErrorWarningLineIcon } from '../../../../design-system/Icons';

const GoalMoreOptionsPopup = ({
    goal,
    processDetail,
    setProcessDetail,
    isPositionedUp = false,
    onClose,
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { id: goalId, is_first_goal: isFirstGoal } = goal;
    const { id: processId, name: processName } = processDetail;

    const [isLoading, setIsLoading] = useState({
        duplicate: false,
        delete: false,
        makeFirstGoal: false,
    });
    const [errorAlert, setErrorAlert] = useState(null);

    const setGoals = (cb) => {
        setProcessDetail((prevProcessData) => ({
            ...prevProcessData,
            goals: cb(prevProcessData.goals),
        }));
    };

    const setInactiveGoals = (cb) => {
        setProcessDetail((prevProcessData) => ({
            ...prevProcessData,
            inactive_goals: cb(prevProcessData.inactive_goals),
        }));
    };

    const handleEdit = () => {
        navigate(`/configure/process/${processId}/goal/${goalId}`, {
            state: { processName, ...(location.state || {}) },
        });
    };

    const handleAction = async ({ actionType, executeAction, errorSnippet }) => {
        try {
            setIsLoading((prevState) => ({ ...prevState, [actionType]: true }));
            await executeAction();
            setIsLoading((prevState) => ({ ...prevState, [actionType]: false }));
            onClose();
        } catch (e) {
            setIsLoading((prevState) => ({ ...prevState, [actionType]: false }));
            setErrorAlert({
                message: `Oops! Something went wrong ${errorSnippet}. Please try again.`,
                statusCode: e.response.status,
            });
        }
    };

    const handleDuplicateGoal = async () => {
        const executeAction = async () => {
            const { data } = await client.post(`${API.ROUTES.library.duplicateGoal}${goalId}/`);
            const { id, name, description, outputs, process, process_version } = data;
            const duplicatedGoal = { id, name, description, outputs, process, process_version };

            // add duplicated goal in active goals list right after the goal that is duplicated
            setGoals((prevGoals) =>
                prevGoals.reduce((acc, goal) => {
                    if (goalId !== goal.id) {
                        return [...acc, goal];
                    }
                    return [...acc, goal, duplicatedGoal];
                }, [])
            );
        };

        await handleAction({
            actionType: 'duplicate',
            executeAction,
            errorSnippet: 'while duplicating your goal',
        });
    };

    const handleDeleteGoal = async () => {
        const executeAction = async () => {
            await client.delete(`${API.ROUTES.library.workflow}${goalId}/`);
            // add current goal to inactive_goals list
            setInactiveGoals((prevGoals) => [goal, ...prevGoals]);
        };

        await handleAction({
            actionType: 'delete',
            executeAction,
            errorSnippet: 'while deleting your goal.',
        });
    };

    const handleMakeFirstGoal = async () => {
        const executeAction = async () => {
            const { data } = await client.patch(`${API.ROUTES.library.process}${processId}/`, {
                first_goal_id: goalId,
            });

            setProcessDetail((prevData) => ({ ...prevData, ...data }));
        };

        await handleAction({
            actionType: 'makeFirstGoal',
            executeAction,
            errorSnippet: 'while making starting your goal',
        });
    };

    const popupContainerStyles = classNames(
        'absolute z-55 left-1/2 transform translate-x-[-50%] sm:translate-x-0 sm:left-auto sm:right-[0px]',
        {
            'bottom-[20px]': isPositionedUp,
            'top-[30px]': !isPositionedUp,
        }
    );

    return (
        <div className={popupContainerStyles}>
            <ListOptionGroup
                fixedWidth={280}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Edit"
                            onClick={handleEdit}
                        />

                        {!isFirstGoal && (
                            <ListOption
                                leadingIconName="arrowUpIcon"
                                text="Make First Goal"
                                isLoading={isLoading.makeFirstGoal}
                                onClick={handleMakeFirstGoal}
                            />
                        )}
                        <ListOption
                            leadingIconName="addToLibrary"
                            text="Duplicate"
                            isLoading={isLoading.duplicate}
                            onClick={handleDuplicateGoal}
                        />
                        <ListOption
                            leadingIconName="deleteBin4LineIcon"
                            text="Delete"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            isLoading={isLoading.delete}
                            onClick={handleDeleteGoal}
                        />
                    </>
                }
                handleClose={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onClose(false);
                }}
            />
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default GoalMoreOptionsPopup;
