import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

Toggle.propTypes = {
    size: PropTypes.oneOf(['sm', 'md']),
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    logAs: PropTypes.string,
};

function Toggle(props) {
    const {
        isSelected = false,
        isDisabled = false,
        name,
        onChange,
        logAs,
        size = 'md',
        label,
        isRequired = false,
    } = props;

    const colors = {
        true: 'bg-neutral-200 after:bg-neutral-300 peer-checked:bg-neutral-200 peer-checked:after:bg-neutral-300 peer-focus:ring-transparent',
        false: 'bg-neutral-200 after:bg-purple-500 peer-checked:bg-purple-500 peer-checked:after:bg-white peer-focus:ring-purple-100',
    };

    const sizes = {
        sm: 'w-9.5 h-5 after:h-4 after:w-4 peer-checked:after:translate-x-[18px]',
        md: 'w-11.5 h-6 after:h-5 after:w-5 peer-checked:after:translate-x-[22px]',
    };

    const transitionStyles = {
        transitionProperty: 'background-color, border-color, color',
    };

    const toggleColors = colors[isDisabled];
    const toggleSize = sizes[size];

    let className = classNames(
        "peer peer-focus:outline-0 peer-focus:ring-2 rounded-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:rounded-full  after:transition-all duration-500",
        toggleSize,
        toggleColors,
        transitionStyles
    );

    return (
        <div className="flex items-center gap-x-4 gap-y-2 flex-wrap">
            {label && (
                <div className="font-body text-body-bold-s text-neutral-500">
                    {label}
                    {isRequired && <sup className="text-red-500 leading-1 ml-0.5">*</sup>}
                </div>
            )}
            <label className="relative cursor-pointer">
                <input
                    type="checkbox"
                    className="sr-only peer"
                    name={name}
                    checked={isSelected}
                    disabled={isDisabled}
                    onChange={() => onChange(!isSelected)}
                    {...(logAs ? { 'data-dd-action-name': logAs } : {})}
                />
                <div className={className}></div>
            </label>
        </div>
    );
}

export default Toggle;
