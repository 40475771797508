import React, { Fragment } from 'react';
import { BLOCK_TYPE, PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { updateOrderKeys } from '../../../helpers/insertItemAtSpecificPositionWithOrderUpdate';

import CalloutBlock from '../../../design-system/PlaybookBuilder/CalloutBlock/CalloutBlock';
import RichTextBlock from '../../../design-system/PlaybookBuilder/RichTextBlock/RichTextBlock';
import ImageBlock from '../../../design-system/PlaybookBuilder/ImageBlock/ImageBlock';
import VideoBlock from '../../../design-system/PlaybookBuilder/VideoBlock/VideoBlock';

const PlaybookContentBlock = ({ mode, blockData, setContentBlock }) => {
    const { temporaryId } = blockData;

    const handleDeleteBlock = () => {
        setContentBlock((prevData) => {
            const filteredList = prevData.filter((block) => block.temporaryId !== temporaryId);
            return updateOrderKeys(filteredList);
        });
    };

    const setBlockProperties = (cb) => {
        setContentBlock((prevData) =>
            prevData.map((block) =>
                block.temporaryId === temporaryId
                    ? {
                          ...block,
                          properties: typeof cb === 'function' ? cb(block.properties) : cb,
                      }
                    : block
            )
        );
    };

    const handleBlockPropertyChange = (name, value) => {
        setBlockProperties((prevData) => ({ ...prevData, [name]: value }));
    };

    const blockComponent = {
        [BLOCK_TYPE.callout]: CalloutBlock,
        [BLOCK_TYPE.richtext]: RichTextBlock,
        [BLOCK_TYPE.image]: ImageBlock,
        [BLOCK_TYPE.video]: VideoBlock,
    };

    const Component = blockComponent[blockData.block_type];

    if (Component) {
        return (
            <Component
                key={blockData.temporaryId}
                blockData={blockData}
                editMode={mode === PLAYBOOK_BUILDER_MODE.edit}
                handleDeleteBlock={handleDeleteBlock}
                handleBlockPropertyChange={handleBlockPropertyChange}
            />
        );
    }

    return <Fragment key={blockData.temporaryId}></Fragment>;
};

export default PlaybookContentBlock;
