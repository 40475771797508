import React, { useEffect, useState } from 'react';
import { API } from 'constants';
import Modal from 'design-system/Modal/Modal';
import { Button, SvgIcon } from 'design-system';
import EscapeIcon from 'design-system/Icons/EscapeIcon';
import Input from 'design-system/Input/Input';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import Alert from 'design-system/Alert/Alert';
import client from 'services/organization-api';
import { usePatchRequest, usePostRequest } from 'hooks/request';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const EditTeamModal = ({ onClose, data, mode, handleSave, organizationId }) => {
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });
    const [errorMessage, setErrorMessage] = useState(null);

    const [team, setTeam] = useState({});

    const teamCreate = usePostRequest({ url: API.ROUTES.organization.team }, client);
    const teamUpdate = usePatchRequest({}, client);
    const [errorFields, setErrorFields] = useState({
        name: false,
    });

    useEffect(() => {
        if (mode === 'edit' && data) {
            setTeam({
                name: data.name,
                organization: organizationId,
            });
        } else {
            setTeam({ name: '', organization: organizationId });
        }
    }, [mode, data]);

    const handleInputChange = (e) => {
        setTeam((prevCredentials) => ({
            ...prevCredentials,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!team.name) {
            setErrorFields({
                name: true,
            });
            setErrorMessage('Please fill in this field.');
            return;
        }
        if (mode === 'edit') {
            const { success, error } = await teamUpdate.request({
                data: team,
                url: `${API.ROUTES.organization.team}${data.id}/`,
            });
            if (success) {
                onClose();
                handleSave();
            }
            if (error) {
                if (error.data && error.data.non_field_errors) {
                    setErrorMessage(error.data.non_field_errors);
                    setErrorFields({ name: true });
                }
            }
        } else {
            const { success, error } = await teamCreate.request({
                data: team,
            });
            if (success) {
                onClose();
                handleSave();
            }
            if (error) {
                if (error.data && error.data.non_field_errors) {
                    setErrorMessage(error.data.non_field_errors);
                    setErrorFields({ name: true });
                }
            }
        }
    };

    return (
        <Modal size="small" onClose={onClose}>
            <div className="flex flex-col gap-[16px]">
                <div className="flex justify-between items-center gap-2">
                    <h2 className="font-heading-bold text-heading-bold-m text-black">
                        {mode === 'edit' ? 'Edit Your Team' : 'Create your team'}
                    </h2>
                    <div onClick={onClose} className="cursor-pointer">
                        <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle"></SvgIcon>
                    </div>
                </div>
                <Input
                    label={'Team Name'}
                    value={team.name}
                    placeholder="Name"
                    name="name"
                    size="xs"
                    onChange={handleInputChange}
                    isRequired
                    state={errorFields.name ? 'error' : 'default'}
                    errorMessage={errorMessage}
                />
                <div className="flex justify-between">
                    <Button type="ghost" text="Cancel" size="sm" onClick={onClose} />
                    <Button type="primary" text={'Confirm'} size="sm" onClick={handleSubmit} />
                </div>
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorMessage || defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </Modal>
    );
};

export default EditTeamModal;
