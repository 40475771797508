import React, { useMemo, useState } from 'react';

import { API } from 'constants';
import client from '../../services/library-api';
import useSWRRequest from '../../hooks/useSWRRequest';

import { SORT_TYPES } from '../../constants/sort';

import Modal from '../../design-system/Modal/Modal';
import GroupContainer from './GroupContainer/GroupContainer';
import SearchBar from '../../design-system/SearchBar/SearchBar';
import Loading from '../Loading';
import ModalHeader from '../../design-system/ModalHeader/ModalHeader';

const StartNewJobModal = ({ handleClose }) => {
    const { data: groups, loading } = useSWRRequest({
        client,
        route: API.ROUTES.library.playbook,
        searchParams: { ordering: SORT_TYPES.alphabetical },
    });

    const [searchValue, setSearchValue] = useState('');
    const loweredSearchValue = searchValue.toLowerCase();

    // groups with processes that match search
    const filteredProcesses = useMemo(() => {
        if (!loweredSearchValue) {
            return groups;
        }

        return groups?.map((group) => ({
            ...group,
            processes: group.processes?.filter((process) =>
                process.name?.toLowerCase()?.includes(loweredSearchValue)
            ),
        }));
    }, [groups, loweredSearchValue]);

    // hide groups with no processes
    const groupsWithProcesses = filteredProcesses?.filter((group) => !!group.processes?.length);

    return (
        <Modal onClose={handleClose} size="midSize">
            <div className="max-h-[452px] flex flex-col gap-4">
                <ModalHeader title="Start New Job" onClose={handleClose} size="xs" />

                <SearchBar
                    size="md"
                    value={searchValue}
                    state={!groups ? 'disabled' : 'default'}
                    placeholder="Search all processes ..."
                    onChange={(e) => setSearchValue(e.target.value)}
                    tipText="Use command + J for quick start"
                    withClearIcon
                    showClearIconIfValueIsNotEmpty
                    onClear={() => setSearchValue('')}
                    onSubmit={() => {}}
                    autoFocus
                />

                {groupsWithProcesses && (
                    <>
                        {!!groupsWithProcesses.length && (
                            <div className="flex flex-col gap-4 overflow-y-auto">
                                {groupsWithProcesses.map((group) => (
                                    <GroupContainer
                                        key={group.id}
                                        group={group}
                                        handleModalClose={handleClose}
                                    />
                                ))}
                            </div>
                        )}

                        {!groupsWithProcesses.length && searchValue && (
                            <p className="font-body-bold text-body-bold-s text-[#25324B] px-3 py-2 text-center">
                                No matching results found
                            </p>
                        )}

                        {!groupsWithProcesses.length && !searchValue && (
                            <p className="font-body-bold text-body-bold-s text-[#25324B] px-3 py-2 text-center">
                                No processes found
                            </p>
                        )}
                    </>
                )}

                {!groups && loading && (
                    <div className="flex items-center justify-center h-[60px]">
                        <Loading withText={false} />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default StartNewJobModal;
