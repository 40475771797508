import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { API } from 'constants';
import operateClient from '../../services/operate-api';
import { parseThreadsSearchParams } from '../../helpers/parseThreadsSearchParams';
import { sortOptions, THREADS_SORT_TYPES } from '../../constants/threads';
import { useWindowSize } from '../../hooks/useWindowSize';
import { getThreadsRequestQueryParams } from '../../helpers/getThreadsRequestQueryParams';

import useCustomPagination from '../../hooks/useCustomPagination';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import ThreadsDesktopPaginatedContainer from './ThreadsDesktopPaginatedContainer/ThreadsDesktopPaginatedContainer';
import ThreadsMobilePaginatedContainer from './ThreadsMobilePaginatedContainer/ThreadsMobilePaginatedContainer';
import SearchSortAndFilterByProcessBar from '../../components/SearchSortAndFilterByProcessBar/SearchSortAndFilterByProcessBar';

const ThreadsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parseThreadsSearchParams(searchParams);

    const [page, setPage] = useState(1);
    const [shouldSetNewData, setShouldSetNewData] = useState(false); // this state is for mobile version (true when we should not merge new data but replace existing data)

    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 850;

    useDocumentTitle('Threads');

    const { data, total, count, loading, hasNextPage } = useCustomPagination({
        pageIndex: page - 1,
        searchParams: getThreadsRequestQueryParams({ parsedSearchParams: parsedParams }),
        client: operateClient,
        route: API.ROUTES.operate.thread,
    });

    useEffect(() => {
        const sort = Object.values(THREADS_SORT_TYPES).includes(parsedParams.sort)
            ? parsedParams.sort
            : THREADS_SORT_TYPES.created;

        if (parsedParams.sort !== sort) {
            setSearchParams({
                ...parsedParams,
                sort,
            });
        }
    }, [parsedParams, setSearchParams]);

    useEffect(() => {
        if (!parsedParams.sort) {
            return;
        }

        // update page state after user used pagination on Desktop Version or after page reload on Desktop Version
        if (parsedParams.page && page !== parsedParams.page) {
            setPage(parsedParams.page);
        }

        // if it is Mobile version remove page from search params
        if (isMobile && parsedParams.page) {
            const { page, ...restSearchParams } = parsedParams;
            setSearchParams({ ...restSearchParams });
        }

        // if it is Desktop version and there is no page in search params
        if (!isMobile && !parsedParams.page) {
            setSearchParams({ ...parsedParams, page: page || 1 });
        }
    }, [isMobile, parsedParams.page, parsedParams.sort]);

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-neutral-50 px-4 xs:px-5 pt-4 sm:px-8 sm:pt-8 pb-[40px] overflow-y-auto">
            <h1 className="font-heading-bold text-heading-bold-l text-black">Threads</h1>
            <p className="font-body text-body-regular-xs text-neutral-300 mb-4 sm:mb-5">
                Prompts and tasks combined to complete goals in a process.
            </p>
            <SearchSortAndFilterByProcessBar
                parsedParams={parsedParams}
                sortOptions={sortOptions}
                setPage={setPage}
                setShouldSetNewData={setShouldSetNewData}
                isMobile={isMobile}
                searchBarPlaceholder="Search by thread name"
                loading={loading}
                total={total}
                label="Thread"
                maxWidth={460}
            />
            {total === 0 && parsedParams.search && (
                <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[360px]">
                    We couldn't find any threads matching your search for {parsedParams.search}.
                    Please try a different keyword.
                </p>
            )}
            {total === 0 && !parsedParams.search && parsedParams.process && (
                <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[360px]">
                    We couldn't find any threads matching your selected process. Please try to
                    select a different process.
                </p>
            )}
            {total === 0 && !parsedParams.search && !parsedParams.process && !loading && (
                <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[360px]">
                    It looks like you have no threads yet. Run a process to create one!
                </p>
            )}
            {!isMobile && (
                <ThreadsDesktopPaginatedContainer
                    threads={data}
                    total={total}
                    count={count}
                    pageIndex={page - 1}
                    loading={loading}
                />
            )}

            <ThreadsMobilePaginatedContainer
                data={data}
                shouldSetNewData={shouldSetNewData}
                setShouldSetNewData={setShouldSetNewData}
                canLoadMore={hasNextPage}
                setPage={setPage}
                loading={loading}
                total={total}
            />
        </div>
    );
};

export default ThreadsPage;
