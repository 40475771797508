import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { API, ModelOptions, TemperatureOptions } from 'constants';
import operateClient from '../../services/operate-api';

import Loading from '../../components/Loading';
import client from '../../services/library-api';
import ChainActionBar from './ChainActionBar/ChainActionBar';
import NewTextAreaBox from '../../design-system/NewTextAreaBox/NewTextAreaBox';
import CollapsableContainer from '../../components/CollapsableContainer/CollapsableContainer';
import PromptSettingsBox from '../../components/PromptSettingsBox/PromptSettingsBox';
import { getPromptSettings } from '../../helpers/getPromptSettings';
import NewRunContainer from '../../components/NewRunContainer/NewRunContainer';
import { ErrorWarningLineIcon } from '../../design-system/Icons';
import WebSocketInstance from '../../services/websocket';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { datadogRum } from '@datadog/browser-rum';
import Alert from '../../design-system/Alert/Alert';
import OutputContainer from '../../components/OutputContainer/OutputContainer';
import OutputFocusModeModal from '../../components/OutputFocusModeModal/OutputFocusModeModal';
import EditableTextArea from '../../components/EditableTextArea/EditableTextArea';
import { useWindowSize } from '../../hooks/useWindowSize';
import OutdatedWorkerBanner from '../../components/OutdatedWorkerBanner/OutdatedWorkerBanner';
import { defaultErrorMessage } from '../../constants/errorMessages';

const PromptChainViewPage = () => {
    const { id: workerId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedSearchParams = useMemo(() => {
        const params = Object.fromEntries([...searchParams]);
        params.step = Number(params.step) || null;
        return params;
    }, [searchParams]);

    const [task, setTask] = useState(null);
    const [workerDetails, setWorkerDetails] = useState(null);
    const currentStepOrderFromDB = workerDetails?.config.find(
        (item) => item.step_id === workerDetails.current_step
    )?.order;
    const [prompt, setPrompt] = useState({ value: '', state: 'disabled' });
    const [promptInputs, setPromptInputs] = useState([]);
    const [promptOutputs, setPromptOutputs] = useState([]);
    const [promptSettings, setPromptSettings] = useState({
        model: ModelOptions[0],
        maxTokens: 1000,
        temperature: TemperatureOptions[0],
    });
    const [currentStepData, setCurrentStepData] = useState(null);
    const [arePromptChanges, setArePromptChanges] = useState(false);
    const [editPromptData, setEditPromptData] = useState({});
    const [editedOutputData, setEditedOutputData] = useState(null);
    const inputRef = useRef(null);
    const [runPromptState, setRunPromptState] = useState({
        isLoading: false,
        errorMessage: null,
    });
    const [isLoading, setIsLoading] = useState({
        page: false,
        changeStepId: null,
        nextStep: false,
        savePrompt: false,
    });
    const [stepState, setStepState] = useState({
        showOutput: false,
        isPromptRun: false,
        lastStep: false,
    });

    const [isOutputFocusMode, setIsOutputFocusMode] = useState(false);
    const [isExpanded, setIsExpanded] = useState({
        promptSettings: false,
        inputs: true,
    });
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    let websocket = WebSocketInstance.getSingleton();
    const [generationId, setGenerationId] = useState(null);
    const [streamingId, setStreamingId] = useState(null);
    const [isRunning, setIsRunning] = useState(false);
    const { width: screenWidth } = useWindowSize();

    const promptId = task?.default_prompt?.id || null;
    const isOutdated = workerDetails && workerDetails.is_outdated;

    useDocumentTitle(
        (currentStepData && `Step ${currentStepData.step}: ${currentStepData.taskName} `) || ''
    );

    useEffect(() => {
        WebSocketInstance.connect();
        waitForSocketConnection(() => {
            WebSocketInstance.addCallbacks(
                onStreamStart,
                onMessageStream,
                onStreamEnd,
                onStreamError
            );
        });
    }, []);

    useEffect(() => {
        WebSocketInstance.setCurrentPageGenId(streamingId);
    }, [streamingId]);

    const waitForSocketConnection = (callback) => {
        const checkConnection = () => {
            if (WebSocketInstance.state() === 1) {
                console.log('connection is secure');
                callback();
            } else {
                setTimeout(checkConnection, 300);
            }
        };

        checkConnection();
    };

    const onMessageStream = (data) => {
        if (isExpanded.inputs === true) {
            setIsExpanded({ inputs: false, promptSettings: false });
        }
        setPromptOutputs((prevPromptOutputs) => {
            const updatedPromptOutputs = prevPromptOutputs.map((output) => {
                const currentValue = output.value || '';
                return { ...output, value: currentValue + data.message.text };
            });
            return updatedPromptOutputs;
        });
        clearTimeout(llmStartTimeout);
    };

    const onStreamEnd = (data) => {
        setRunPromptState({ isLoading: false, errorMessage: null });
        setStepState((prevState) => ({ ...prevState, isPromptRun: true }));
        datadogRum.addAction('PromptStreamEnd', {
            streamingId,
            promptSettings,
            promptInputs,
        });
        setIsRunning(false);
        setPromptOutputs((prevOutput) => updateOutputState(prevOutput, 'default'));
    };

    const onStreamError = () => {
        setRunPromptState({
            isLoading: false,
            errorMessage: 'Oops! Something went wrong while running your prompt, please try again.',
        });
        setStepState((prevState) => ({ ...prevState, isPromptRun: true }));
        datadogRum.addAction('PromptStreamError', {
            streamingId,
            promptSettings,
            promptInputs,
        });
        setIsRunning(false);
    };

    let llmStartTimeout;

    const onStreamStart = () => {
        setIsExpanded({ inputs: false, promptSettings: false });
        setStepState((prevState) => ({ ...prevState, showOutput: true }));
        setIsRunning(true);
        llmStartTimeout = setTimeout(() => {
            console.log('llm timeout');
            onStreamError();
        }, 5000);
        datadogRum.addAction('PromptStreamStart', {
            streamingId,
            promptSettings,
            promptInputs,
        });
        setEditedOutputData(null);
        setPromptOutputs((prevOutput) => updateOutputState(prevOutput, 'disabled'));
    };

    const cancelStream = () => {
        if (websocket) {
            // reset prompt state
            setRunPromptState({ isLoading: false, errorMessage: null });
            setIsRunning(false);
            setStreamingId(null);
        }
    };

    const handleSavePrompt = async () => {
        try {
            setIsLoading((prevState) => ({ ...prevState, savePrompt: true }));
            await client.patch(`${API.ROUTES.library.prompt}${promptId}/`, editPromptData);
            setIsLoading((prevState) => ({ ...prevState, savePrompt: false }));
            setArePromptChanges(false);
            setEditPromptData({});
        } catch (e) {
            console.log('error', e);
            setErrorAlert({ show: true, statusCode: e.response.status });
            setIsLoading((prevState) => ({ ...prevState, savePrompt: false }));
        }
    };

    const runPrompt = () => {
        setRunPromptState({ isLoading: true, errorMessage: null });

        // Add the patch call if arePromptChanges isn't null
        if (arePromptChanges) {
            client
                .patch(`${API.ROUTES.library.prompt}${promptId}/`, editPromptData)
                .then(() => {
                    executePrompt();
                    setArePromptChanges(false);
                    setEditPromptData({});
                })
                .catch((patchError) => {
                    setRunPromptState({
                        isLoading: false,
                        errorMessage: 'You have unsaved prompt edits. Please try again.',
                    });
                });
        } else {
            executePrompt();
        }
    };

    const executePrompt = () => {
        setRunPromptState({ isLoading: true, errorMessage: null });
        // check if each promptInput has a value
        const promptInputsValid = promptInputs.every((input) => input.value);
        if (promptInputsValid) {
            // refresh PromptOutputs
            resetPromptOutputs('');
            // update prompt inputs to remove isRequired, errorMessage and state keys
            const updatedPromptInputs = promptInputs.map((input) => {
                const { isRequired, state, errorMessage, mappedOutput, ...rest } = input;
                return rest;
            });
            let run_data = {
                sync: false,
                worker_id: workerDetails.id,
                step_id: currentStepData.stepId,
                inputs: updatedPromptInputs,
            };

            if (!websocket.isOpen()) {
                if (!websocket.attempt_reconnect()) {
                    console.log('websocket reconnect failed / using sync mode');
                    run_data = { sync: true, ...run_data };
                    setIsExpanded({ inputs: false, promptSettings: false });
                }
            }

            // execute task
            operateClient
                .post(`execute/${task.id}/`, run_data)
                .then((res) => {
                    if (!websocket.isOpen()) {
                        console.log('websocket not open / using sync response mode');
                        resetPromptOutputs(res.data.final_output);
                        setGenerationId(res.data.id);
                        setIsRunning(false);
                        setRunPromptState({ isLoading: false, errorMessage: null });
                    } else {
                        console.log('streaming response');
                        setIsRunning(true);
                        setRunPromptState({ isLoading: false, errorMessage: null });
                        const generationId = res.data.id;
                        setStreamingId(res.data.run);
                        setGenerationId(generationId);
                    }
                })
                .catch((error) => {
                    setIsRunning(false);
                    if (error.response.status === 400) {
                        if (
                            error.response.data?.error &&
                            error.response.data.error.includes("You don't have API credentials")
                        ) {
                            setRunPromptState({
                                isLoading: false,
                                errorMessage: 'Add a valid API key to be able to run this prompt!',
                            });
                        } else {
                            setRunPromptState({
                                isLoading: false,
                                errorMessage:
                                    'Oops! Something went wrong while running your prompt, please try again.',
                            });
                        }
                    } else {
                        setRunPromptState({
                            isLoading: false,
                            errorMessage:
                                'Oops! Something went wrong while running your prompt, please try again.',
                        });
                    }
                });
        } else {
            // if promptInputs are not valid, set state to error
            setIsExpanded({ inputs: true, promptSettings: false });
            handleScrollToInputs();
            const updatedPromptInputs = promptInputs.map((input) => {
                if (!input.value) {
                    return {
                        ...input,
                        state: 'error',
                        errorMessage: 'Please fill in this field.',
                    };
                } else {
                    return input;
                }
            });
            setPromptInputs(updatedPromptInputs);
            setRunPromptState({ isLoading: false, errorMessage: null });
        }
    };

    const updateCurrentTask = async ({ data, updateSearchParams = false }) => {
        // use this function inside try {} catch(e) {} structure
        let currentStepData;
        if (!parsedSearchParams.step || updateSearchParams) {
            currentStepData = data.config.find((item) => item.step_id === data.current_step);
        } else {
            currentStepData = data.config.find((item) => item.order === parsedSearchParams.step);
        }

        const currentTaskId = currentStepData?.task_id;

        const { data: taskData } = await client.get(`${API.ROUTES.library.task}${currentTaskId}`);
        setWorkerDetails(data);
        setTask(taskData);
        setPrompt((prevState) => ({
            ...prevState,
            value: taskData?.default_prompt.default_prompt_version.messages[0]?.content || '',
            state: 'disabled',
        }));
        setCurrentStepData({
            step: currentStepData.order,
            stepId: currentStepData.step_id,
            taskId: currentTaskId,
            taskName: currentStepData.task_name,
            run_id: currentStepData.run_id,
            status: currentStepData.status,
        });
        setPromptSettings(
            getPromptSettings(taskData?.default_prompt.default_prompt_version.settings)
        );
        setPromptInputs(updateInputState(currentStepData.inputs, currentStepData.mapping, data));
        setPromptOutputs(updateOutputState(currentStepData.outputs, 'default'));
        setStepState({
            showOutput: Boolean(currentStepData.outputs[0]?.value),
            isPromptRun: Boolean(currentStepData.outputs[0]?.value),
            lastStep: Boolean(data.step_count === currentStepData.order),
        });
        setEditPromptData({});
        setEditedOutputData(null);
        setArePromptChanges(false);
        if (!parsedSearchParams.step || updateSearchParams) {
            setSearchParams({ ...parsedSearchParams, step: currentStepData.order });
        }
    };

    const handleNextPromptClick = async () => {
        try {
            setIsLoading((prevState) => ({ ...prevState, nextStep: true }));
            if (editedOutputData) {
                let receivedGenerationId = null;
                if (!generationId) {
                    const { data: runData } = await operateClient.get(
                        `${API.ROUTES.operate.run}${currentStepData.run_id}/`
                    );
                    setGenerationId(runData.results[0]?.id);
                    receivedGenerationId = runData.results[0]?.id;
                }
                await operateClient.patch(
                    `${API.ROUTES.operate.result}${generationId || receivedGenerationId}/`,
                    {
                        final_output: editedOutputData,
                    }
                );
                setEditedOutputData(null);
            }

            if (stepState.lastStep) {
                return;
            }

            const nextStepData = workerDetails.config?.find(
                (item) => item.order === currentStepData.step + 1
            );

            const nextStepId = nextStepData.step_id;

            if (currentStepOrderFromDB > nextStepData.order || nextStepData.outputs[0]?.value) {
                //change step without changing worker in db
                const newWorkerData = await refreshWorker();
                setWorkerDetails(newWorkerData);
                await changeStep({ stepId: nextStepId, workerDetails: newWorkerData });
                setIsLoading((prevState) => ({ ...prevState, nextStep: false }));
                return;
            }

            //change step with changing worker in db
            const { data } = await operateClient.patch(
                `${API.ROUTES.operate.worker}${workerDetails.id}/`,
                {
                    current_step: nextStepId,
                }
            );
            await updateCurrentTask({ data, updateSearchParams: true });
            setIsExpanded({ inputs: true, promptSettings: false });
            setIsLoading((prevState) => ({ ...prevState, nextStep: false }));
        } catch (e) {
            setErrorAlert({ show: true, statusCode: e.response.status });
            setIsLoading((prevState) => ({ ...prevState, nextStep: false }));
            console.log(e);
        }
    };

    const changeStep = async ({ stepId, options = {}, workerDetails }) => {
        try {
            const currentStepData = workerDetails.config.find((item) => item.step_id === stepId);
            const currentTaskId = currentStepData?.task_id;

            if (
                currentStepOrderFromDB > currentStepData.order ||
                currentStepData.outputs[0]?.value
            ) {
                const { data: runData } = await operateClient.get(
                    `${API.ROUTES.operate.run}${currentStepData.run_id}/`
                );
                setGenerationId(runData.results[0]?.id);
            }

            const { data: taskData } = await client.get(
                `${API.ROUTES.library.task}${currentTaskId}`
            );
            setTask(taskData);
            setPromptSettings(
                getPromptSettings(taskData?.default_prompt.default_prompt_version.settings)
            );
            setPrompt((prevState) => ({
                ...prevState,
                value: taskData?.default_prompt.default_prompt_version.messages[0]?.content || '',
                state: 'disabled',
            }));

            setCurrentStepData({
                step: currentStepData.order,
                stepId: currentStepData.step_id,
                taskId: currentTaskId,
                taskName: currentStepData.task_name,
                run_id: currentStepData?.run_id,
                status: currentStepData.status,
            });

            setPromptInputs(
                updateInputState(
                    currentStepData.inputs,
                    currentStepData.mapping,
                    workerDetails,
                    currentStepData.order > currentStepOrderFromDB ? 'disabled' : 'default'
                )
            );
            setPromptOutputs(updateOutputState(currentStepData.outputs, 'default'));
            setStepState({
                showOutput: Boolean(currentStepData.outputs[0]?.value),
                isPromptRun: Boolean(currentStepData.outputs[0]?.value),
                lastStep: Boolean(workerDetails.step_count === currentStepData.order),
            });
            setEditPromptData({});
            setEditedOutputData(null);
            setArePromptChanges(false);

            setIsExpanded({ inputs: true, promptSettings: false, ...options });
            setSearchParams({ ...parsedSearchParams, step: currentStepData.order });
        } catch (e) {
            setErrorAlert({ show: true, statusCode: e.response.status });
            console.log('error', e);
        }
    };

    useEffect(() => {
        const getCorrectStepInfo = async () => {
            try {
                setIsLoading((prevState) => ({ ...prevState, page: true }));
                await updateCurrentTask({ data: workerDetails });
            } catch (e) {
                console.log('error', e);
            } finally {
                setIsLoading((prevState) => ({ ...prevState, page: false }));
            }
        };

        const mismatchedParamsAndAndDisplayedSteps =
            parsedSearchParams?.step &&
            currentStepData?.step &&
            parsedSearchParams?.step !== currentStepData?.step;

        if (mismatchedParamsAndAndDisplayedSteps) {
            console.log('The step from search params differs from the displayed on-screen step.');
            getCorrectStepInfo();
        }
    }, [searchParams]);

    const handleStepSelect = async (stepId) => {
        const currentStepData = workerDetails.config.find((item) => item.step_id === stepId);
        // adding this to update the workerDetails if user is navigating through step select
        if (currentStepOrderFromDB < currentStepData.order) {
            const newWorkerData = await refreshWorker();
            setWorkerDetails(newWorkerData);
            await changeStep({ stepId: stepId, workerDetails: newWorkerData });
        } else {
            await changeStep({ stepId, workerDetails });
        }
    };

    const handleEditButtonClick = async (inputId) => {
        setIsLoading((prevState) => ({ ...prevState, changeStepId: inputId }));
        const mappedOutputStep = promptInputs.find((input) => input.id === inputId)?.mappedOutput;
        const mappedOutputStepId = mappedOutputStep?.stepId;

        await changeStep({
            stepId: mappedOutputStepId,
            options: { inputs: mappedOutputStep.type === 'input', promptSettings: false },
            workerDetails,
        });
        setIsLoading((prevState) => ({ ...prevState, changeStepId: null }));
    };

    const handleScrollToInputs = () => {
        if (inputRef.current) {
            inputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const updateInputState = (inputs, mapping = {}, workerDetails = {}, state = 'default') => {
        return inputs && inputs.length
            ? inputs.map((input) => {
                  if (mapping[input.id]) {
                      let mappedValue = null;
                      let mappedLabel = null;
                      let mappedStep = null;
                      let mappedStepId = null;
                      let mappingType = null;

                      for (const step of workerDetails?.config || []) {
                          const mappedId = mapping[input.id];

                          // Check if the mappedId is an output of this step
                          const targetOutput = step?.outputs?.find(
                              (output) => output.id === mappedId
                          );

                          // Check if the mappedId is an input of this step
                          const targetInput = step?.inputs?.find((input) => input.id === mappedId);

                          if (targetOutput) {
                              mappedLabel = targetOutput.label;
                              mappedValue = targetOutput.value;
                              mappedStep = step.order;
                              mappedStepId = step.step_id;
                              mappingType = 'output';
                              break;
                          } else if (targetInput) {
                              mappedLabel = targetInput.label;
                              mappedValue = targetInput.value;
                              mappedStep = step.order;
                              mappedStepId = step.step_id;
                              mappingType = 'input';
                              break;
                          }
                      }

                      return {
                          ...input,
                          value: mappedValue,
                          mappedOutput: {
                              type: mappingType, // indicating the type of mapping
                              id: mapping[input.id],
                              label: mappedLabel,
                              stepId: mappedStepId,
                              order: mappedStep,
                          },
                          state: 'disabled',
                          isRequired: true,
                      };
                  }
                  return {
                      ...input,
                      state: isOutdated ? 'disabled' : state || 'default',
                      isRequired: true,
                  };
              })
            : [];
    };

    const updatePromptTextData = (newValue) => {
        setPrompt((prevPrompt) => ({ ...prevPrompt, value: newValue }));
        setEditPromptData((prevData) => ({
            ...prevData,
            messages: [
                {
                    role: 'user',
                    content: newValue,
                },
            ],
        }));
        if (!arePromptChanges) {
            setArePromptChanges(true);
        }
    };

    const updatePromptOutputs = (newValue, index) => {
        const updatedPromptOutputs = promptOutputs.map((output, i) => {
            if (i === index) {
                return { ...output, value: newValue };
            } else {
                return output;
            }
        });
        setPromptOutputs(updatedPromptOutputs);
        setEditedOutputData(newValue);
    };

    const resetPromptOutputs = (newValue) => {
        setPromptOutputs((prevPromptOutputs) => {
            const updatedPromptOutputs = prevPromptOutputs.map((output) => {
                return { ...output, value: newValue };
            });
            return updatedPromptOutputs;
        });
    };

    const updateOutputState = (outputs, state = 'disabled') => {
        return outputs && outputs.length
            ? outputs.map((output) => ({ ...output, state: isOutdated ? 'disabled' : state }))
            : [];
    };

    const updatePromptInputs = (newValue, index) => {
        const updatedPromptInputs = promptInputs.map((input, i) => {
            if (i === index) {
                return { ...input, value: newValue, state: 'default' };
            } else {
                return input;
            }
        });
        setPromptInputs(updatedPromptInputs);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading((prevState) => ({ ...prevState, page: true }));
                const { data } = await operateClient.get(`${API.ROUTES.operate.worker}${workerId}`);
                await updateCurrentTask({ data });
                setIsLoading((prevState) => ({ ...prevState, page: false }));
            } catch (error) {
                console.log('error', error);
                setErrorAlert({ show: true, statusCode: error.response.status });
                setIsLoading((prevState) => ({ ...prevState, page: false }));
            }
        };

        if (task) {
            resetChainRunData();
        }
        fetchData();
    }, [workerId]);

    const resetChainRunData = () => {
        setPrompt(null);
        setTask(null);
        setWorkerDetails(null);
        setPromptSettings({
            model: ModelOptions[0],
            maxTokens: 1000,
            temperature: TemperatureOptions[0],
        });
        setCurrentStepData(null);
        setPromptInputs([]);
        setPromptOutputs([]);
    };

    const refreshWorker = async () => {
        // use in try {} catch () {} structures
        const { data } = await operateClient.get(`${API.ROUTES.operate.worker}${workerId}`);
        return data;
    };

    const handleTurnOffOutputFocusMode = () => {
        setIsOutputFocusMode(false);
        setIsExpanded({ inputs: false, promptSettings: false });
    };

    const toggleCollapsed = (field) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handleChainNameChange = async (value) => {
        try {
            await operateClient.patch(`${API.ROUTES.operate.worker}${workerDetails.id}/`, {
                name: value,
            });
        } catch (e) {
            console.log('error', e);
        }
    };

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white overflow-auto lg:overflow-hidden flex flex-col lg:flex-row pb-[110px] md:pb-[64px] ">
            {task && (
                <>
                    <div className="h-auto lg:h-full lg:min-w-[340px] bg-white relative w-full lg:w-1/2">
                        <div className="h-full flex flex-col px-[20px] sm:px-[24px] pt-[20px] pb-[10px] overflow-y-auto">
                            <div className="py-[20px] sm:px-[16px] flex flex-col gap-5">
                                {isOutdated && (
                                    <OutdatedWorkerBanner workerDetails={workerDetails} />
                                )}
                                <EditableTextArea
                                    initialText={workerDetails.name}
                                    textStyle="font-body-bold text-body-bold-xl"
                                    onSave={(value) => handleChainNameChange(value)}
                                />
                            </div>

                            <div className="flex flex-col sm:px-[16px] flex-grow">
                                <NewTextAreaBox
                                    name="prompt"
                                    value={prompt.value}
                                    label="Prompt"
                                    onChange={(e) => updatePromptTextData(e.target?.value)}
                                    placeholder="Write your prompt here"
                                    state={prompt.state}
                                    withEditButton={prompt.state === 'disabled' && !isOutdated}
                                    withCopyButton
                                    fullHeight
                                    onEditButtonClick={() =>
                                        setPrompt((prevState) => ({
                                            ...prevState,
                                            state: 'default',
                                        }))
                                    }
                                    darkBg={prompt.state === 'disabled'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="h-auto lg:h-full lg:min-w-[340px] bg-neutral-50 max-lg:flex-grow relative w-full lg:w-1/2">
                        <div className="h-full w-full flex flex-col gap-[12px] pt-[20px] px-[20px] sm:px-[32px] overflow-y-auto">
                            <CollapsableContainer
                                title="Prompt Settings"
                                isExpanded={isExpanded.promptSettings}
                                toggleExpand={() => toggleCollapsed('promptSettings')}
                            >
                                <PromptSettingsBox
                                    promptSettings={promptSettings}
                                    setPromptSettings={setPromptSettings}
                                    setEditPromptData={setEditPromptData}
                                    setArePromptChanges={setArePromptChanges}
                                />
                            </CollapsableContainer>
                            <CollapsableContainer
                                title="Inputs"
                                isExpanded={isExpanded.inputs}
                                toggleExpand={() => toggleCollapsed('inputs')}
                            >
                                <NewRunContainer
                                    textAreaData={promptInputs}
                                    updateTextAreaData={updatePromptInputs}
                                    ref={inputRef}
                                    withCopyButton
                                    useKeyAsALabel
                                    isLoadingId={isLoading.changeStepId}
                                    onEditButtonClick={handleEditButtonClick}
                                    infoMessage="To edit this output you will be taken back to the related step."
                                />
                            </CollapsableContainer>
                            {stepState.showOutput && (
                                <OutputContainer
                                    promptOutputs={promptOutputs}
                                    updatePromptOutputs={updatePromptOutputs}
                                    withFullScreenIcon={true}
                                    onFullScreenIconClick={() => {
                                        setIsOutputFocusMode(true);
                                    }}
                                    isRunning={isRunning}
                                    scrollInsideTextArea={screenWidth >= 1024}
                                />
                            )}
                        </div>
                    </div>
                    {runPromptState.errorMessage && (
                        <Alert
                            status="critical"
                            message={runPromptState.errorMessage || defaultErrorMessage}
                            icon={ErrorWarningLineIcon}
                            handleClose={() =>
                                setRunPromptState({ isLoading: false, errorMessage: null })
                            }
                        />
                    )}
                    {errorAlert.show && (
                        <Alert
                            status="critical"
                            message={defaultErrorMessage}
                            statusCode={errorAlert.statusCode}
                            icon={ErrorWarningLineIcon}
                            handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                        />
                    )}
                    {isOutputFocusMode && (
                        <OutputFocusModeModal
                            promptOutputs={promptOutputs}
                            updatePromptOutputs={updatePromptOutputs}
                            handleClose={handleTurnOffOutputFocusMode}
                            isRunning={isRunning}
                        />
                    )}
                </>
            )}
            {isLoading.page && (
                <div className="fixed inset-0 flex justify-center items-center">
                    <div className="z-6 bg-white p-4 rounded-lg">
                        <Loading />
                    </div>
                </div>
            )}

            {workerDetails && (
                <ChainActionBar
                    workerDetails={workerDetails}
                    stepState={stepState}
                    onRunPrompt={runPrompt}
                    onCancelStream={cancelStream}
                    handleNextPromptClick={handleNextPromptClick}
                    nextPromptLoading={isLoading.nextStep}
                    isLoading={isLoading}
                    handleStepSelect={handleStepSelect}
                    arePromptChanges={arePromptChanges}
                    isRunLoading={runPromptState.isLoading}
                    isRunning={isRunning}
                    currentStepData={currentStepData}
                    currentStepOrderFromDB={currentStepOrderFromDB}
                    handleSavePrompt={handleSavePrompt}
                    isOutdated={isOutdated}
                />
            )}
        </div>
    );
};

export default PromptChainViewPage;
