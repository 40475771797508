import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import OrganizationBlock from './OrganizationBlock/OrganizationBlock';


const OrganizationPage = () => {
    const { id } = useParams();

    if (!id) {
        return <Navigate to="/profile" />;
    }

    return (
        <div className="w-full sm:w-[calc(100%-68px)] max-sm:p-5 pb-[50px]">
            <OrganizationBlock />
        </div>
    );
};

export default OrganizationPage;
