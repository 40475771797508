import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { API } from 'constants';
import operateClient from '../../../services/operate-api';

import Modal from '../../../design-system/Modal/Modal';
import { Button } from '../../../design-system';
import Alert from '../../../design-system/Alert/Alert';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const CancelConfirmationModal = ({
    cancelTarget,
    goalName,
    workerId,
    onClose,
    threadName,
    setThread,
    mutate,
}) => {
    const params = useParams();
    const threadId = Number(params.threadId);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const requestEndpoint = {
        thread: `${API.ROUTES.operate.thread}${threadId}/`,
        worker: `${API.ROUTES.operate.worker}${workerId}/`,
    };

    const handleCancel = async () => {
        try {
            setIsLoading(true);
            const { data } = await operateClient.patch(requestEndpoint[cancelTarget], {
                status: 'canceled',
            });
            if (cancelTarget === 'thread') {
                setThread(data);
            }
            if (cancelTarget === 'worker') {
                await mutate();
            }
            setIsLoading(false);
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ statusCode: e.response?.status });
        }
    };

    const cancelTargetName = {
        thread: threadName,
        worker: goalName,
    };

    return (
        <Modal onClose={onClose} size="extraSmall">
            <p className="font-heading-bold text-heading-bold-m text-black py-2 pb-4">
                Confirm cancelation
            </p>
            <p className="font-body text-body-regular-m text-neutral-500 mb-8">
                Are you sure you want to cancel <strong>{cancelTargetName[cancelTarget]}</strong>{' '}
                {cancelTarget}?
            </p>
            <div className="flex items-center justify-between">
                <Button type="ghost" size="sm" text="No, Exit" onClick={onClose} />
                <Button
                    type="primary"
                    size="sm"
                    text="Yes, Cancel"
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleCancel}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

CancelConfirmationModal.propTypes = {
    cancelTarget: PropTypes.oneOf(['thread', 'worker']).isRequired,
    goalName: PropTypes.string,
    workerId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    threadName: PropTypes.string,
    setThread: PropTypes.func,
};

export default CancelConfirmationModal;
