import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '../../../design-system';
import JobCard from '../JobCard/JobCard';
import Loading from '../../../components/Loading';

const JobsMobilePaginatedContainer = ({ jobsHookResponse, jobs, setPage, isMobile }) => {
    const { total, loading, hasNextPage: canLoadMore } = jobsHookResponse;

    const location = useLocation();

    if (!isMobile) {
        return <></>;
    }

    return (
        <div className="w-full flex flex-col gap-5">
            {!!jobs.length && (
                <ul className="w-full flex flex-col gap-2">
                    {jobs.map((job) => (
                        <li key={job.id}>
                            <Link to={`/jobs/${job.id}`} state={{ from: location }}>
                                <JobCard job={job} />
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
            {canLoadMore && total !== jobs?.length && (
                <Button
                    type="link"
                    size="xs"
                    text="Load More"
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                />
            )}
            {loading && (
                <div className="pt-3">
                    <Loading withText={!jobs.length} />
                </div>
            )}
        </div>
    );
};

export default JobsMobilePaginatedContainer;
