export const KNOWLEDGE_BASE_SORT_TYPES = {
    alphabetical: 'label',
    created: '-created_at', // most recent first
};

export const KNOWLEDGE_BASE_TYPES_LABELS = {
    alphabetical: 'Alphabetical',
    created: 'Most Recently Created',
};

export const knowledgeBaseSortItems = [
    {
        text: KNOWLEDGE_BASE_TYPES_LABELS.created,
        value: KNOWLEDGE_BASE_SORT_TYPES.created,
        leadingSelectIcon: true,
    },
    {
        text: KNOWLEDGE_BASE_TYPES_LABELS.alphabetical,
        value: KNOWLEDGE_BASE_SORT_TYPES.alphabetical,
        leadingSelectIcon: true,
    },
];
