import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import TableRow from 'design-system/TableRow/TableRow';
import Button from 'design-system/Button/Button';
import TablePagination from 'design-system/TablePagination/TablePagination';

//in Table.stories.js you can find an example of data object
Table.propTypes = {
    size: PropTypes.oneOf(['md', 'sm']),
    data: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                header: PropTypes.string.isRequired,
                accessor: PropTypes.string.isRequired,
                type: PropTypes.oneOf(['text', 'badge', 'any']),
                badgeColor: PropTypes.oneOf([
                    'neutral',
                    'blue',
                    'purple',
                    'peach',
                    'lime',
                    'white',
                    'red',
                ]),
                badgeIcon: PropTypes.func,
                badgeIconColor: PropTypes.string,
                width: PropTypes.oneOf(['full']),
                fixedWidth: PropTypes.number, //in pixels
            }).isRequired
        ),
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                badgeColor: PropTypes.oneOf([
                    'neutral',
                    'blue',
                    'purple',
                    'peach',
                    'lime',
                    'white',
                    'red',
                ]), // if there is only one badge used in the row, this prop should be used
                badgesColorsConfigObj: PropTypes.shape({
                    // if there are multiple badges used in the row, this prop has to be used
                    //[accessor]: color,
                }),
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                //[accessor]: PropTypes.string
            }).isRequired
        ),
        pagination: PropTypes.shape({
            page: PropTypes.number.isRequired,
            limit: PropTypes.number.isRequired,
            totalItems: PropTypes.number.isRequired,
        }), // if you don't need pagination don't add pagination property to data
        removeRowActionButtonsForRows: PropTypes.arrayOf(PropTypes.string), // pass an array of row IDs for which you do not want to display rowActionButtons
    }).isRequired,
    rowActionButtons: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.oneOf([
                'edit',
                'delete',
                'done',
                'view',
                'visit',
                'moreOptions',
                'show',
            ]).isRequired,
            handleAction: PropTypes.func, // is not required if action === moreOptions
        })
    ), //will create a new column with these buttons in every row. If you don't need such column - just don't pass rowActionButtons prop
    useReactivateButton: PropTypes.bool,
    handleReactivateClick: PropTypes.func, //you need this prop if you have rowActionButtons and "Reactivate" button
    tableActionButton: PropTypes.shape({
        buttonText: PropTypes.string.isRequired,
        buttonSize: PropTypes.string,
        onClick: PropTypes.func.isRequired,
    }), //if you need an action button in table bottom pass button text and click handler. Otherwise, skip tableActionButton prop
    includePagination: PropTypes.bool,
    onPaginationPreviousClick: PropTypes.func,
    onPaginationNextClick: PropTypes.func,
    columnsCount: PropTypes.number, //number of columns that should have the same width (not fixed)
    shift: PropTypes.number, // width of columns that have fixed width including actions buttons
    moreOptionPopupComponent: PropTypes.func,
};

function Table(props) {
    const {
        size = 'sm',
        rowActionButtons = [],
        data,
        handleReactivateClick = () => {},
        useReactivateButton = false,
        tableActionButton,
        includePagination = false,
        onPaginationPreviousClick = () => {},
        onPaginationNextClick = () => {},
        columnsCount = 1,
        shift = 0,
        moreOptionPopupComponent: MoreOptionPopupComponent = () => {},
        ...moreOptionPopupComponentProps
    } = props;

    const [tableWidth, setTableWidth] = useState(0);
    const [tableTopPosition, setTopPosition] = useState(0);
    const tableRef = useRef(null);

    const [moreOptionsPopup, setMoreOptionsPopup] = useState({
        opened: false,
        data: null,
        topPosition: 0,
    });

    useEffect(() => {
        if (tableRef.current) {
            setTimeout(() => {
                setTableWidth(tableRef.current?.offsetWidth);
            }, 100);
            const rect = tableRef.current.getBoundingClientRect();
            setTopPosition(rect.top);
        }
    }, [tableRef]);

    const columnWidth = (tableWidth - shift) / columnsCount - 40;

    const cellClassName = classNames(
        'font-body text-body-bold-m text-neutral-500 px-[20px] border-b border-neutral-200',
        {
            'h-15': size === 'md',
            'h-12': size === 'sm',
        }
    );

    return (
        <div className="flex flex-col gap-[12px] w-full relative">
            <div className="overflow-auto">
                <div className="block overflow-auto">
                    <div
                        ref={tableRef}
                        className={`overflow-auto border border-neutral-200 rounded-2 opacity-1 transition-all duration-100 ${
                            !tableWidth && 'opacity-0'
                        }`}
                    >
                        <table className="bg-white text-left overflow-auto w-full max-w-full">
                            {!!data.columns.length && (
                                <thead>
                                    <tr>
                                        {data.columns.map((item, index) => (
                                            <th
                                                className={`${cellClassName} ${
                                                    item['width'] === 'full' ? 'w-full' : ''
                                                }`}
                                                key={index}
                                            >
                                                {item.header}
                                            </th>
                                        ))}
                                        {!!rowActionButtons.length && (
                                            <th className={cellClassName}></th>
                                        )}
                                    </tr>
                                </thead>
                            )}
                            <tbody>
                                {!!data.rows.length &&
                                    data.rows.map((item) => (
                                        <TableRow
                                            key={item.id}
                                            size={size}
                                            item={item}
                                            columns={data.columns}
                                            actionButtons={rowActionButtons}
                                            useReactivateButton={useReactivateButton}
                                            hideActionButtons={
                                                data.removeRowActionButtonsForRows?.includes(
                                                    item.id
                                                ) || false
                                            }
                                            handleReactivateClick={handleReactivateClick}
                                            columnWidth={columnWidth}
                                            setMoreOptionsPopup={setMoreOptionsPopup}
                                        />
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {(tableActionButton || includePagination) && (
                <div className={`flex items-center gap-4 ${!tableRef.current && 'opacity-0'}`}>
                    {tableActionButton && (
                        <Button
                            size={
                                tableActionButton.buttonSize ? tableActionButton.buttonSize : 'md'
                            }
                            text={tableActionButton.buttonText}
                            type="primary"
                            onClick={tableActionButton.onClick}
                        />
                    )}
                    {includePagination && (
                        <div className="ml-auto">
                            <TablePagination
                                data={data.pagination}
                                onPreviousClick={onPaginationPreviousClick}
                                onNextClick={onPaginationNextClick}
                            />
                        </div>
                    )}
                </div>
            )}
            {moreOptionsPopup.opened && (
                <MoreOptionPopupComponent
                    data={moreOptionsPopup.data}
                    topPosition={`${moreOptionsPopup.topPosition - tableTopPosition + 28}px`}
                    onClose={() => setMoreOptionsPopup({ opened: false })}
                    {...moreOptionPopupComponentProps}
                />
            )}
        </div>
    );
}

export default Table;
