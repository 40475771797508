import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/report-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import { Button } from '../../../design-system';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const status = {
    true: 'Draft',
    false: 'Live',
};

const ToggleTemplateStatusModal = ({ name, isLive, setTemplateDetails, onClose }) => {
    const { templateId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const onConfirm = async () => {
        try {
            setIsLoading(true);

            await client.patch(`${API.ROUTES.report.template}${templateId}/`, { is_live: !isLive });
            setTemplateDetails((prevData) => ({ ...prevData, is_live: !isLive }));

            onClose();
            setIsLoading(false);
        } catch (e) {
            setErrorAlert({ statusCode: e.response?.status });
            setIsLoading(false);
        }
    };

    return (
        <Modal onClose={onClose} size="extraSmall">
            <p className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">
                Confirm Mark as {status[isLive]}
            </p>
            <p className="font-body text-body-regular-m text-neutral-500 mb-8">
                {isLive ? (
                    <>
                        Are you sure you want to mark{' '}
                        <span className="text-body-bold-m">{name}</span> as draft? No new Work
                        Reports will be generated using this template.
                    </>
                ) : (
                    <>
                        Are you sure you want to mark{' '}
                        <span className="text-body-bold-m">{name}</span> as live? Threads started or restarted
                        for this process will generate a Work Report using this template.
                    </>
                )}
            </p>
            <div className="flex items-center justify-between">
                <Button type="ghost" size="sm" text="No, Cancel" onClick={onClose} />
                <Button
                    type="primary"
                    size="sm"
                    text={`Yes, Mark as ${status[isLive]}`}
                    state={isLoading ? 'loading' : 'default'}
                    onClick={onConfirm}
                />
            </div>

            {errorAlert && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </Modal>
    );
};

export default ToggleTemplateStatusModal;
