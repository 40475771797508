import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import clientObject from '../../services/community-api';
import { API } from 'constants';
import PublishedPromptPageLoggedInUserView from './PublishedPromptPageLoggedInUserView/PublishedPromptPageLoggedInUserView';
import PublishedPromptPageNotLoggedInUserView from './PublishedPromptPageNotLoggedInUserView/PublishedPromptPageNotLoggedInUserView';
import useUser from '../../hooks/useUser';
import Loading from '../../components/Loading';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

const PublishedPromptPage = () => {
    const { publishedId } = useParams();
    const { client } = clientObject;

    const useUserResult = useUser();
    const { user, isValidating } = useUserResult;
    const userIsNotLoggedIn = !isValidating && !user;

    const [publishedPromptData, setPublishedPromptData] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsDataLoading(true);
                const { data } = await client.get(
                    `${API.ROUTES.community.publishedPrompt}${publishedId}/`
                );
                setPublishedPromptData(data);
                setIsDataLoading(false);
            } catch (e) {
                setIsDataLoading(false);
                console.log('error', e);
            }
        };

        fetchData();
    }, []);

    if (!user && isValidating) {
        return <Loading />;
    }

    if (userIsNotLoggedIn) {
        return <PublishedPromptPageNotLoggedInUserView />;
    }

    return (
        <>
            {isDataLoading ? (
                <Loading />
            ) : publishedPromptData ? (
                <PublishedPromptPageLoggedInUserView publishedPromptData={publishedPromptData} />
            ) : (
                <NotFoundPage />
            )}
        </>
    );
};

export default PublishedPromptPage;
