import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DateTime } from 'luxon';
import { API } from 'constants';
import operateClient from '../../services/operate-api';

import { SvgIcon } from '../../design-system';
import { ErrorWarningLineIcon, InformationLineIcon } from '../../design-system/Icons';
import Loading from '../Loading';
import Alert from '../../design-system/Alert/Alert';
import { defaultErrorMessage } from '../../constants/errorMessages';

const OutdatedWorkerBanner = ({ workerDetails }) => {
    const navigate = useNavigate();
    const [startNewRunLoading, setStartNewRunLoading] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState(null);

    const handleStartNewRun = async () => {
        try {
            setStartNewRunLoading(true);
            const currentTime = DateTime.local().toFormat('LLL dd, yyyy h:mm a');
            const requestBody = {
                goal: workerDetails.goal,
                name: `${workerDetails.name} @ ${currentTime}`,
            };
            const { data } = await operateClient.post(`${API.ROUTES.operate.worker}`, requestBody);
            navigate(`/run/${data.id}`);
        } catch (e) {
            setErrorStatusCode(e.response.status);
        } finally {
            setStartNewRunLoading(false);
        }
    };

    return (
        <>
            <div
                className={`bg-blue-100 w-full flex items-start gap-[12px] rounded-2 relative py-2 pl-2 ${
                    startNewRunLoading ? 'pr-[48px]' : 'pr-2'
                }`}
            >
                <div className="w-9 h-9 flex justify-center items-center bg-white rounded-2">
                    <SvgIcon color="#17B4E5" icon={InformationLineIcon} size="large" />
                </div>
                <div className="flex flex-col">
                    <p className="font-body text-body-bold-s">New Chain version available</p>
                    <p className="font-body text-body-regular-s">
                        The current Chain Run is outdated. Generate a new Run with the updates.{' '}
                        <span
                            className="text-body-bold-s underline cursor-pointer"
                            onClick={handleStartNewRun}
                        >
                            Start New Run
                        </span>
                    </p>
                    {startNewRunLoading && (
                        <div className="absolute top-1/2 transform translate-y-[-50%] right-[8px]">
                            <Loading withText={false} />
                        </div>
                    )}
                </div>
            </div>
            {errorStatusCode && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorStatusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorStatusCode(null)}
                />
            )}
        </>
    );
};

export default OutdatedWorkerBanner;
