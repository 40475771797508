export const ROUTES = {
    library: {
        workflow: '/goal/',
        prompt: '/prompt/',
        task: '/task/',
        createTask: '/create-task/',
        createStep: '/create-step/',
        search: '/task/?search=',
        promptVersion: '/promptversion/',
        duplicateTask: '/duplicate-task/',
        duplicateGoal: '/duplicate-goal/',
        sharedTaskDetails: '/shared-task-details/',
        sharedTask: '/shared-task/',
        sharedTasks: '/shared-tasks/',
        integrationTasks: '/integration-tasks/',
        collection: '/collection/',
        process: '/process/',
        createProcess: '/create-process/',
        playbook: '/playbook/',
        clientProcess: '/client/process/',
    },
    user: {
        register: '/register/',
        login: '/login/',
        logout: '/logout/',
        userDetails: '/view/',
        apiCredentials: '/api-credentials/',
        verifyEmail: '/register/verify-email/',
        resendEmail: '/register/resend-email/',
        accountConfirmEmail: '/account-confirm-email/',
        passwordReset: '/password/reset/',
        resetPasswordConfirm: '/password/reset/confirm/',
        uploadProfileImage: '/upload-profile-image/',
        verifyInvite: '/verify-invite/',
        uploadImage: '/upload-image/',
    },
    operate: {
        worker: '/worker/',
        run: '/run/',
        result: '/result/',
        queuePromptRun: '/queue-prompt-run/',
        promptHistory: '/prompt-history/',
        taskHistory: '/task-history/',
        promptVersionHistory: '/prompt-version-history/',
        complete: '/complete/',
        cancel: '/cancel/',
        thread: '/thread/',
        restartThread: '/restart-thread/',
        restartWorker: '/restart-worker/',
    },
    organization: {
        organizationData: '/organization-data/',
        organization: '/organization/',
        team: '/team/',
        organizationMembership: '/organization-membership/',
        teamsUserList: '/teams-users-list/',
        memberships: '/memberships/',
        connection: '/connection/',
    },
    apikey: {
        apikey: '/external-api-key/',
    },
    community: {
        publishedPrompt: '/p/',
        publishPrompt: '/publish-prompt/',
    },
    knowledge: {
        base: '/base/',
        context: '/context/',
    },
    payment: {
        createCheckoutSession: '/create-checkout-session/',
    },
    inbox: {
        message: '/message/',
        feedback: '/feedback/',
    },
    report: {
        template: '/template/',
        workReport: '/work-report/',
        job: '/job/',
    },
    template: {
        goalTemplate: '/goal-template/',
        goalTemplateVersion: '/goal-template-version/',
        goalDeploy: '/goal/deploy/',
        processTemplate: '/process-template/',
        processTemplateVersion: '/process-template-version/',
        label: '/label/',
        processDiscover: '/process/discover/',
    },
    execute: {
        dataset: '/dataset/',
        startUnitTest: '/start-unit-test/',
        testRun: '/testrun/',
    },
};
