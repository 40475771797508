import React from 'react';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';

const HowItWorksTabForm = ({ formStateHookResponse }) => {
    const { formData, fieldErrorMessages, handleInputChange } = formStateHookResponse;

    return (
        <RichTextArea
            value={formData.instructions}
            label="How it works"
            isRequired
            setValue={(value) => handleInputChange('instructions', value)}
            state={fieldErrorMessages.instructions ? 'error' : 'default'}
            errorMessage={fieldErrorMessages.instructions}
            minHeight={270}
            autoExpand
        />
    );
};

export default HowItWorksTabForm;
