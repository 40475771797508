import React from 'react';
import { Button } from 'design-system';
import { ArrowGoBackLineIcon } from 'design-system/Icons';
import OutputContainer from 'components/OutputContainer/OutputContainer';
import { useWindowSize } from '../../hooks/useWindowSize';

const OutputFocusModeModal = ({
    promptOutputs,
    updatePromptOutputs,
    handleClose,
    isRunning,
    outputRating,
    updateOutputRating,
    resultId,
}) => {
    const { width: screenWidth } = useWindowSize();

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white z-29 pt-[40px] px-[12px] sm:px-[20px]">
            <div className="w-full max-w-[800px] mx-auto h-full max-h-full flex flex-col gap-[10px] pb-[12px]">
                <div>
                    <Button
                        type="link"
                        size="sm"
                        text="Back to Prompt"
                        onClick={handleClose}
                        leadingIcon={ArrowGoBackLineIcon}
                    />
                </div>
                <OutputContainer
                    promptOutputs={promptOutputs}
                    updatePromptOutputs={updatePromptOutputs}
                    isRunning={isRunning}
                    scrollInsideTextArea={screenWidth >= 1024}
                    fixedMinHeightOfTextArea={200}
                    outputRating={outputRating}
                    updateOutputRating={updateOutputRating}
                    resultId={resultId}
                />
            </div>
        </div>
    );
};

export default OutputFocusModeModal;
