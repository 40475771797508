import React, { useState } from 'react';
import { mutate } from 'swr';

import client from '../../../services/knowledge-api';
import { API } from 'constants';

import Modal from '../../../design-system/Modal/Modal';
import Alert from '../../../design-system/Alert/Alert';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const ConfirmDeleteModal = ({
    handleCloseModal,
    handleClosePanel,
    baseName,
    baseId,
    requestBody,
    itemId,
    label,
    requestKeysToMutate = [],
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const handleDeleteContext = async () => {
        await client.delete(`${API.ROUTES.knowledge.context}${itemId}/`);
    };
    const handleDeleteEntry = async () => {
        await client.patch(`${API.ROUTES.knowledge.base}${baseId}/entry/${itemId}/`, requestBody);
    };

    const deleteBaseFunction = {
        Context: handleDeleteContext,
        Entry: handleDeleteEntry,
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            await deleteBaseFunction[baseName]();
            requestKeysToMutate?.map((request) =>
                mutate(
                    request.key,
                    (currentData) => {
                        if (currentData) {
                            const updatedResults = currentData.results?.filter(
                                (item) => item.id !== itemId
                            );
                            return {
                                ...currentData,
                                count: currentData.count - 1,
                                results: updatedResults,
                            };
                        }
                    },
                    { revalidate: true }
                )
            );
            setIsLoading(false);
            handleCloseModal();
            handleClosePanel();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ show: true, statusCode: e.response.status });
        }
    };

    return (
        <>
            <Modal onClose={handleCloseModal} size="extraSmall">
                <p className="font-heading-bold text-heading-bold-m text-black mb-4">
                    Delete {baseName}
                </p>
                <p className="font-body text-body-regular-m text-neutral-500 mb-8">
                    Are you sure you want to delete {label}?
                </p>
                <div className="flex items-center justify-center gap-4">
                    <Button type="neutral" size="sm" text="No, Cancel" onClick={handleCloseModal} />
                    <Button
                        type="secondary"
                        size="sm"
                        text="Yes, Delete"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleDelete}
                    />
                </div>
            </Modal>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </>
    );
};

export default ConfirmDeleteModal;
