import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clientObject from '../../../../services/community-api';
import { API } from 'constants';
import Loading from '../../../../components/Loading';
import { Button, Input } from '../../../../design-system';
import useUser from '../../../../hooks/useUser';
import { ErrorWarningLineIcon, FileCopyLineIcon } from '../../../../design-system/Icons';
import NewTextAreaBox from '../../../../design-system/NewTextAreaBox/NewTextAreaBox';
import ArrowRightUpLineIcon from '../../../../design-system/Icons/ArrowRightUpLineIcon';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import Alert from '../../../../design-system/Alert/Alert';
import { defaultErrorMessage } from '../../../../constants/errorMessages';

const UpdatePublishedPromptBlock = ({
    publishId,
    defaultPromptVersionId,
    setPrompt,
    updateTaskPublishId,
    allowMakeAnyChanges,
}) => {
    const useUserResult = useUser();
    const { user } = useUserResult;

    const { clientWithToken } = clientObject;

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [publishedData, setPublishedData] = useState(null);
    const [publishNotes, setPublishNotes] = useState('');
    const [alert, setAlert] = useState({ show: false, statusCode: null });
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [isUnpublishLoading, setIsUnpublishLoading] = useState(false);

    const publishedPromptLinkPath = `${user.username}/p/${publishId}/${publishedData?.slug}`;
    const hostUrl = `${window.location.protocol}//${window.location.host}`;
    const publishedPromptLink = `${hostUrl}/${publishedPromptLinkPath}`;

    const arePromptAndDefaultVersionsDifferent =
        publishedData && publishedData.prompt_version !== defaultPromptVersionId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsDataLoading(true);
                const { data } = await clientWithToken.get(
                    `${API.ROUTES.community.publishPrompt}${publishId}/`
                );
                setPublishedData(data);
                setPublishNotes(data.info?.publish_note);
                setIsDataLoading(false);
            } catch (e) {
                setIsDataLoading(false);
            }
        };

        fetchData();
    }, []);

    const updatePublishedData = async () => {
        try {
            setIsUpdateLoading(true);
            const requestBody = {
                info: {
                    publish_note: publishNotes,
                    example_inputs: [],
                    example_outputs: [],
                },
            };
            if (arePromptAndDefaultVersionsDifferent) {
                requestBody.prompt_version = defaultPromptVersionId;
            }
            const { data } = await clientWithToken.patch(
                `${API.ROUTES.community.publishPrompt}${publishId}/`,
                requestBody
            );
            setPublishedData(data);
            setPublishNotes(data.info?.publish_note);
            setIsUpdateLoading(false);
            setAlert({
                show: true,
                status: 'positive',
                icon: CheckLineIcon,
                message: 'Published Prompt is updated!',
            });
        } catch (e) {
            setIsUpdateLoading(false);
            setAlert({ show: true, statusCode: e.response.status });
        }
    };

    const handleUnpublish = async () => {
        try {
            setIsUnpublishLoading(true);
            await clientWithToken.delete(`${API.ROUTES.community.publishPrompt}${publishId}/`);
            if (setPrompt) {
                setPrompt((prevPrompt) => ({ ...prevPrompt, publish_id: null }));
            }
            if (updateTaskPublishId) {
                updateTaskPublishId(null);
            }
            setIsUnpublishLoading(false);
        } catch (e) {
            setIsUpdateLoading(false);
            setAlert({ show: false, statusCode: e.response.status });
        }
    };

    const handleCopyLinkClick = () => {
        navigator.clipboard
            .writeText(publishedPromptLink)
            .then(() => {
                setAlert({
                    show: true,
                    status: 'positive',
                    icon: CheckLineIcon,
                    message: 'Link is copied to clipboard!',
                });
            })
            .catch(() => {
                setAlert({ show: true });
            });
    };

    return (
        <>
            <h3 className="font-heading-bold text-heading-bold-m text-neutral-500">
                Prompt Publishing
            </h3>
            {isDataLoading ? (
                <Loading />
            ) : (
                <>
                    {publishedData ? (
                        <div className="flex flex-col gap-6">
                            <div>
                                {arePromptAndDefaultVersionsDifferent ? (
                                    <p className="font-body text-body-regular-m text-neutral-400 mb-3">
                                        <span className="text-body-bold-m text-red-500">
                                            A different version of this prompt is live.{' '}
                                        </span>
                                        Click <span className="text-body-bold-m">update</span> below
                                        to publish the current version.
                                    </p>
                                ) : (
                                    <p className="font-body text-body-regular-m text-neutral-400 mb-3">
                                        This version of this prompt is live!
                                    </p>
                                )}
                                <div className="flex gap-x-2 gap-y-1 items-center flex-wrap">
                                    <div className="flex-grow">
                                        <Input
                                            size="xs"
                                            value={publishedPromptLink}
                                            name="live-published-link"
                                            state="disabled"
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            type="link"
                                            size="sm"
                                            text="Copy Link"
                                            leadingIcon={FileCopyLineIcon}
                                            onClick={handleCopyLinkClick}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <NewTextAreaBox
                                    name="notes"
                                    value={publishNotes}
                                    onChange={(e) => {
                                        setPublishNotes(e.target?.value);
                                    }}
                                    label="Publish Notes"
                                    placeholder="Add a publish note. You can use this to promote your work and/or explain how to use this task"
                                    errorMessage="Please fill in this field."
                                    state={allowMakeAnyChanges ? 'default' : 'disabled'}
                                    withCopyButton
                                    fullHeight
                                />
                            </div>
                            <div className="flex items-center gap-2 justify-between flex-wrap">
                                <Button
                                    type="neutral"
                                    size="sm"
                                    text="Unpublish"
                                    state={
                                        !allowMakeAnyChanges
                                            ? 'disabled'
                                            : isUnpublishLoading
                                            ? 'loading'
                                            : 'default'
                                    }
                                    onClick={handleUnpublish}
                                />
                                <div className="flex items-center gap-2">
                                    <Button
                                        type="link"
                                        size="sm"
                                        text="View Page"
                                        trailingIcon={ArrowRightUpLineIcon}
                                        onClick={() => {
                                            window.open(`/${publishedPromptLinkPath}`, '_blank');
                                        }}
                                    />
                                    <Button
                                        type="primary"
                                        size="sm"
                                        text="Update"
                                        state={
                                            !allowMakeAnyChanges
                                                ? 'disabled'
                                                : isUpdateLoading
                                                ? 'loading'
                                                : 'default'
                                        }
                                        onClick={updatePublishedData}
                                    />
                                </div>
                            </div>
                            {alert.show && (
                                <Alert
                                    status={alert.status || 'critical'}
                                    message={alert.message || defaultErrorMessage}
                                    icon={ErrorWarningLineIcon}
                                    statusCode={alert.statusCode}
                                    handleClose={() => setAlert({ show: false, statusCode: null })}
                                    autoCloseInMS={3000}
                                />
                            )}
                        </div>
                    ) : (
                        <div>Something went wrong.</div>
                    )}
                </>
            )}
        </>
    );
};

export default UpdatePublishedPromptBlock;
