import React, { useEffect, useState } from 'react';
import { API } from 'constants';
import client from '../../../services/knowledge-api';
import { mutate } from 'swr';

import Panel from '../../../components/Panel';
import { Button, ButtonIcon, Input } from '../../../design-system';
import { CloseLineIcon, ErrorWarningLineIcon } from '../../../design-system/Icons';
import TextArea from '../../../design-system/TextArea/TextArea';
import { formatToKey } from '../../../helpers/formatLabelToKey';
import Alert from '../../../design-system/Alert/Alert';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import { useSearchParams } from 'react-router-dom';
import { parseKnowledgeBaseSearchParams } from '../../../helpers/parseKnowledgeBaseSearchParams';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const ContextVariablePanel = ({
    context,
    action = 'create',
    page,
    requestKeysToMutate,
    onClose,
    handleSearchClear,
}) => {
    const [searchParams] = useSearchParams();
    const parsedSearchParams = parseKnowledgeBaseSearchParams(searchParams);

    const [inputsData, setInputsData] = useState({ label: '', value: '' , key: ''});
    const [dataFieldsError, setDataFieldsError] = useState({});
    const [errorAlert, setErrorAlert] = useState({ show: false, message: '', statusCode: null });
    const [isLoading, setIsLoading] = useState(false);

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    useEffect(() => {
        if (action === 'edit' && context) {
            setInputsData({ label: context.label, value: context.default_version?.value, key: context.key });
        }
    }, [context]);

    const handleChange = (e) => {
        setInputsData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
        if (dataFieldsError[e.target.name]) {
            setDataFieldsError((prevState) => ({ ...prevState, [e.target.name]: false }));
        }
    };

    const createContext = async () => {
        const requestBody = {
            ...inputsData,
            type: 'string',
            key: formatToKey(inputsData.label),
        };
        const { data: newContext } = await client.post(
            `${API.ROUTES.knowledge.context}`,
            requestBody
        );

        requestKeysToMutate?.map((request) =>
            mutate(
                request.key,
                request.page === 1 && !request.search
                    ? (currentData) => {
                          if (currentData) {
                              const updatedResults = [newContext, ...(currentData.results || [])];
                              return {
                                  ...currentData,
                                  count: currentData.count + 1,
                                  results: updatedResults,
                              };
                          }
                      }
                    : null,
                {
                    revalidate: true,
                }
            )
        );
        handleSearchClear();
    };

    const updateContext = async () => {
        const requestBody = {
            value: inputsData.value,
        };
        const { data } = await client.patch(
            `${API.ROUTES.knowledge.context}${context.id}/`,
            requestBody
        );

        const correspondedRequestKey = requestKeysToMutate?.find(
            (item) =>
                item.page === page &&
                (parsedSearchParams.search ? item.search === parsedSearchParams.search : true)
        );
        if (correspondedRequestKey) {
            mutate(
                correspondedRequestKey.key,
                (currentData) => {
                    if (currentData) {
                        const updatedResults = currentData.results.map((item) =>
                            item.id === context.id ? data : item
                        );
                        return {
                            ...currentData,
                            results: updatedResults,
                        };
                    }
                },
                {
                    revalidate: false,
                }
            );
        }
    };

    const actionHandler = {
        create: createContext,
        edit: updateContext,
    };

    const handleAction = async () => {
        if (!inputsData.label || !inputsData.value) {
            setDataFieldsError({ label: !inputsData.label, value: !inputsData.value });
            return;
        }
        try {
            setIsLoading(true);
            await actionHandler[action]();
            setIsLoading(false);
            onClose();
        } catch (e) {
            setErrorAlert({
                show: true,
                message: defaultErrorMessage,
                statusCode: e.response.status,
            });
            setIsLoading(false);
        }
    };

    const closePanel = () => {
        if (!showConfirmDeleteModal) {
            onClose();
        }
    };

    return (
        <Panel onClose={closePanel} showControls={false}>
            <div className="flex flex-col pb-4 justify-between gap-5 min-h-full">
                <div>
                    <div className="flex items-center justify-between gap-4 mb-3">
                        <p className="font-body text-body-bold-l text-black w-[calc(100%-56px)]">
                            {action === 'create' ? 'Create New' : 'Edit'} Context Variable
                        </p>
                        <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                    </div>
                    <div className="flex flex-col gap-5">
                        <Input
                            size="sm"
                            name="label"
                            value={inputsData.label}
                            label="Give your context a name"
                            isRequired
                            placeholder="Company Mission"
                            onChange={handleChange}
                            state={
                                action === 'edit'
                                    ? 'disabled'
                                    : dataFieldsError.label
                                    ? 'error'
                                    : 'default'
                            }
                            errorMessage={'Please fill in this field'}
                            // add the key into the tip text only if action is edit
                            tipText={action === 'edit' ? `[[${inputsData.key}]]` : ''}
                        />
                        <TextArea
                            name="value"
                            value={inputsData.value}
                            label="Add the context"
                            isRequired
                            placeholder="Give an overview of your company mission so that you can use it in multiple prompts"
                            onChange={handleChange}
                            state={dataFieldsError.value ? 'error' : 'default'}
                            errorMessage={'Please fill in this field'}
                            height="210px"
                        />
                    </div>
                </div>
                <div
                    className={`pt-3 border-t-1 border-neutral-200 flex items-center ${
                        action === 'edit' ? 'justify-between' : 'justify-end'
                    }`}
                >
                    {action === 'edit' && (
                        <button
                            className="h-[48px] font-body text-body-bold-m tracking-[0.8px] text-red-500"
                            onClick={() => setShowConfirmDeleteModal(true)}
                        >
                            Delete context
                        </button>
                    )}
                    <Button
                        type="primary"
                        size="md"
                        state={isLoading ? 'loading' : 'default'}
                        text={action === 'edit' ? 'Save changes' : 'Create Context'}
                        onClick={handleAction}
                    />
                </div>
            </div>

            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                />
            )}
            {showConfirmDeleteModal && (
                <ConfirmDeleteModal
                    itemId={context.id}
                    label={context.label}
                    baseName="Context"
                    requestKeysToMutate={requestKeysToMutate}
                    handleCloseModal={() => {
                        setShowConfirmDeleteModal(false);
                    }}
                    handleClosePanel={onClose}
                />
            )}
        </Panel>
    );
};

export default ContextVariablePanel;
