import React from 'react';
import { useNavigate } from 'react-router-dom';
import IntegrationCard from '../../../components/IntegrationCard/IntegrationCard';
import SetupStepCard from '../../../components/SetupStepCard/SetupStepCard';
import { Button } from '../../../design-system';

const ZapierSetup1Page = () => {
    const navigate = useNavigate();

    const handleAcceptInvite = () => {
        window.open(
            'https://zapier.com/developer/public-invite/193919/ed15bcf4447e2e2950dd1820dbf5a817/',
            '_blank'
        );
    };
    const handleDoneClick = () => {
        navigate('/integrations/zapier/setup-2');
    };

    return (
        <div className="w-full max-sm:p-4 flex flex-col gap-3 sm:gap-5">
            <IntegrationCard
                title="Setting up Zapier Integration"
                description="Access your Promptfolio directly from your Zapier automations"
                centeredContent
            />
            <div className="w-full py-[30px] sm:py-[80px] flex flex-col gap-[40px] sm:gap-[60px]">
                <SetupStepCard
                    title="Step 1: Accept  the Promptfolio App Install Invite"
                    description="Sign in or create an account in Zapier if you don’t already have one."
                    actionButton={{ text: 'Accept Invite', handleClick: handleAcceptInvite }}
                />
                <SetupStepCard
                    title="Step 2: Click Accept Invite"
                    subtitle="This will add Promptfolio to your available Apps in your Zapier editor"
                    image={'/images/zapier-screenshot-install.png'}
                    imageAlt="Install Promptfolio screenshot"
                />
                <div className="mx-auto">
                    <Button
                        type="secondary"
                        size="md"
                        text={'I’m Done!'}
                        onClick={handleDoneClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default ZapierSetup1Page;
