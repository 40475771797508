import React, { useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { SvgIcon } from '../../../../design-system';
import CheckLineIcon from '../../../../design-system/Icons/CheckLineIcon';
import JobTaskPanel from '../JobTaskPanel/JobTaskPanel';
import { JOB_TASK_BUCKET_STATUS } from '../../../../constants/jobs';

const JobTaskCard = ({ milestone, bucketStatus, view }) => {
    const { name, created_at, updated_at, completed_at, participants } = milestone;

    const [isPanelOpened, setIsPanelOpened] = useState(false);

    const { width: screenWidth } = useWindowSize();

    const isDesktop = view === 'row' ? screenWidth >= 1024 : screenWidth > 1280;

    const timeToDisplay = { pending: created_at, processing: updated_at, completed: completed_at };
    const timeToDisplayLabel = {
        pending: 'Created At',
        processing: 'Updated At',
        completed: 'Completed At',
    };

    const formattedTime = DateTime.fromISO(timeToDisplay[bucketStatus]).toFormat(
        'MM/dd/yyyy - hh:mm a'
    );

    const statusCircleClassName = classNames('mx-auto my-auto w-[18px] h-[18px] rounded-full', {
        'border-2 border-dashed border-neutral-300':
            bucketStatus === JOB_TASK_BUCKET_STATUS.pending,
        'border-[3px] border-purple-300': bucketStatus === JOB_TASK_BUCKET_STATUS.processing,
        'bg-purple-500 flex justify-center items-center':
            bucketStatus === JOB_TASK_BUCKET_STATUS.completed,
    });

    const participantClassName =
        'w-5 h-5 min-w-[20px] rounded-full bg-purple-500 flex items-center justify-center uppercase font-body text-body-regular-xs text-white';

    const dividingLineClassName = `relative pl-[7px] before:content-[''] before:w-[1px] before:h-[15px] before:bg-neutral-300 before:absolute before:top-1/2 before:transform before:translate-y-[-50%] before:left-0`;
    const containerClassName = classNames('flex', {
        'flex-col-reverse gap-x-1.5 gap-y-2': !isDesktop,
        'flex-row items-center gap-x-1.5 gap-y-2': isDesktop,
    });

    const participantsToDisplay = participants?.slice(0, 3);
    const participantRest = participants?.length - 3;

    return (
        <>
            <div
                className={`p-4 rounded-2 flex flex-col gap-4 bg-white cursor-pointer ${
                    bucketStatus === JOB_TASK_BUCKET_STATUS.completed && 'opacity-60'
                }`}
                onClick={() => setIsPanelOpened(true)}
            >
                <div className="flex gap-2">
                    <div className="w-6 h-6 min-w-[24px]">
                        <div className={statusCircleClassName}>
                            {bucketStatus === JOB_TASK_BUCKET_STATUS.completed && (
                                <SvgIcon color="#FFFFFF" icon={CheckLineIcon} size="medium" />
                            )}
                        </div>
                    </div>
                    <p className="font-body text-body-regular-m text-neutral-500 line-clamp-2">
                        {name}
                    </p>
                </div>
                <div className={containerClassName}>
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        <span className="font-body-bold text-body-bold-xs">
                            {timeToDisplayLabel[bucketStatus]}:
                        </span>{' '}
                        {formattedTime}
                    </p>
                    {!!participants?.length && (
                        <div
                            className={`flex items-center gap-1 ${
                                isDesktop && dividingLineClassName
                            }`}
                        >
                            {participantsToDisplay.map(({ id, first_name, email }) => {
                                const name = first_name || email;
                                return (
                                    <div key={id} className={participantClassName}>
                                        {name?.[0]}
                                    </div>
                                );
                            })}

                            {participantRest > 0 && (
                                <p className="font-body text-body-regular-s text-neutral-300 whitespace-nowrap">
                                    + {participantRest}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {isPanelOpened && (
                <JobTaskPanel
                    milestone={milestone}
                    bucketStatus={bucketStatus}
                    onClose={() => setIsPanelOpened(false)}
                />
            )}
        </>
    );
};

export default JobTaskCard;
