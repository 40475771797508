import React from 'react';

const JobsEmptyState = ({ jobsHookResponse, parsedParams }) => {
    const { total, loading } = jobsHookResponse;

    const getEmptyStateMessage = () => {
        if (total === 0 && parsedParams.search) {
            return `We couldn't find any jobs matching your search for ${parsedParams.search}. Please
                    try a different keyword.`;
        }
        if (total === 0 && parsedParams.process && parsedParams.status) {
            return "We couldn't find any jobs matching your selected processes and status. Please try to select a different processes or status.";
        }
        if (total === 0 && parsedParams.process) {
            return "We couldn't find any jobs matching your selected processes. Please try to select a different processes.";
        }
        if (total === 0 && parsedParams.status) {
            return "We couldn't find any jobs matching your selected status. Please try to select a different status.";
        }
        if (total === 0 && !loading) {
            return 'It looks like you have no jobs yet.';
        }
        return null;
    };

    const emptyStateMessage = getEmptyStateMessage();

    return (
        <>
            {emptyStateMessage && (
                <p className="font-body text-body-bold-s text-neutral-300 px-1 max-w-[600px]">
                    {emptyStateMessage}
                </p>
            )}
        </>
    );
};

export default JobsEmptyState;
