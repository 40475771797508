import { API } from 'constants';
import operateClient from '../services/operate-api';

import { defaultErrorMessage, emptyFieldErrorMessage } from '../constants/errorMessages';
import { checkIfInputtedJsonIsValid, getMappedFormErrors } from './settingsFormUtils';

const actionsWithArrayValue = ['multi-input', 'multi-select', 'multi-dropdown'];

export const getStartNewJobFormDataInitialState = (inputs) => {
    return (
        inputs?.map((input) => {
            if (input.action === 'json-input' && !input.value) {
                return { ...input, value: {}, state: 'default' }; // set initial value for "json-input"
            }

            if (actionsWithArrayValue.includes(input.action) && !input.value) {
                return { ...input, value: [], state: 'default' }; // set initial value for 'multi-input', 'multi-select', 'multi-dropdown'
            }

            if (input.action === 'toggle' && !input.value) {
                return { ...input, value: false, state: 'default' }; // set initial value for 'toggle'
            }

            return { ...input, state: 'default' };
        }) || []
    );
};

export const checkIsRequiredFieldFilled = (item) => {
    const isFieldNotFilled =
        (actionsWithArrayValue.includes(item.action) && !item.value?.length) ||
        (!actionsWithArrayValue.includes(item.action) &&
            !item.value &&
            item.value !== 0 &&
            item.value !== false);

    return item.is_required ? !isFieldNotFilled : true;
};

const getDefaultEmptyFormFieldErrorMessage = (action) => {
    switch (action) {
        case 'multi-input':
            return 'Please add at least one input.';
        case 'multi-dropdown':
            return 'Please select at least one option.';
        case 'single-select':
            return 'Please select an option.';
        case 'multi-select':
            return 'Please select an option.';
        case 'single-dropdown':
            return 'Please select an option.';
        default:
            return emptyFieldErrorMessage;
    }
};

export const checkIsFormDataValid = (formData, setFormData) => {
    const { areErrorFields, updatedFormData } = formData.reduce(
        (acc, item) => {
            // check if inputted Json is valid
            const invalidJsonError = checkIfInputtedJsonIsValid(acc, item);
            if (invalidJsonError) {
                return invalidJsonError;
            }

            // check if all required fields are filled
            const isRequiredFieldFilled = checkIsRequiredFieldFilled(item);
            if (isRequiredFieldFilled) {
                return { ...acc, updatedFormData: [...acc.updatedFormData, item] };
            } else {
                return {
                    areErrorFields: true,
                    updatedFormData: [
                        ...acc.updatedFormData,
                        {
                            ...item,
                            state: 'error',
                            errorMessage: getDefaultEmptyFormFieldErrorMessage(item.action),
                        },
                    ],
                };
            }
        },
        { areErrorFields: false, updatedFormData: [] }
    );

    if (areErrorFields) {
        setFormData(updatedFormData);
        return false; // means that there is a form data error
    }

    return true; // means that there are no form data errors
};

export const getFormattedFormValue = (input) => {
    let formattedValue = input.value;

    // make value type "number" for action === 'single-input' && type === 'number' (note: we do not have to do this for item.type === 'integer' as that value is already parsedInt)
    if (input.action === 'single-input' && input.type === 'number' && input.value) {
        formattedValue = parseFloat(input.value);
    }

    // parse Json value
    if (input.action === 'json-input' && input.value && typeof input.value === 'string') {
        formattedValue = JSON.parse(input.value);
    }

    return formattedValue;
};

export const formatInputsToFormData = (formData) =>
    formData.map(({ state, errorMessage, ...input }) => ({
        ...input,
        value: getFormattedFormValue(input),
    }));

export const handleRequestErrors = ({ error, setErrorAlert, formData, setFormData }) => {
    const inputsErrors = error.response?.data?.inputs;

    if (inputsErrors) {
        // handle inputs error
        const updatedFormData = getMappedFormErrors(inputsErrors, formData, 'key');
        setFormData(updatedFormData);
    } else {
        setErrorAlert({ message: defaultErrorMessage, statusCode: error.response?.statusCode });
    }
};

export const startNewJob = async ({
    formData,
    setFormData,
    processId,
    setNewJobId = () => {},
    setStartNewJobState,
    setErrorAlert,
}) => {
    const isFormDataValid = checkIsFormDataValid(formData, setFormData);
    if (!isFormDataValid) {
        return;
    }

    try {
        setStartNewJobState('loading');
        setNewJobId(null);

        const inputs = formatInputsToFormData(formData);
        const requestBody = { process: processId, auto: true, inputs };

        const { data } = await operateClient.post(API.ROUTES.operate.thread, requestBody);
        setNewJobId(data.id);

        setStartNewJobState('success');
    } catch (error) {
        setStartNewJobState('default');
        handleRequestErrors({ error, setErrorAlert, formData, setFormData });
    }
};
