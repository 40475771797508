import React from 'react';
import ActiveGoalsBlock from '../ActiveGoalsBlock/ActiveGoalsBlock';
import ProcessVariablesBlock from '../ProcessVariablesBlock/ProcessVariablesBlock';

const ConfigureTabSection = ({ processDetail, setProcessDetail, isEditable }) => {
    const { outputs } = processDetail;

    const setOutputs = (outputs) => {
        setProcessDetail((prevProcessData) => ({
            ...prevProcessData,
            outputs,
        }));
    };

    return (
        <div className="flex flex-col l:flex-row l:flex-start gap-5">
            <div className="w-full l:w-[calc((100%-20px)*0.57)] 3xl:w-[calc((100%-20px)*0.62)]">
                <ActiveGoalsBlock
                    processDetail={processDetail}
                    setProcessDetail={setProcessDetail}
                    isEditable={isEditable}
                />
            </div>
            <div className="w-full l:w-[calc((100%-20px)*0.43)] 3xl:w-[calc((100%-20px)*0.38)]">
                <ProcessVariablesBlock
                    processId={processDetail.id}
                    outputs={outputs}
                    setOutputs={setOutputs}
                    isEditable={isEditable}
                />
            </div>
        </div>
    );
};

export default ConfigureTabSection;
