import React, { useState } from 'react';
import { Button } from '../../../design-system';
import ProcessTemplateDetailsModal from '../../TemplatesLibraryPage/ProcessTemplateDetailsModal/ProcessTemplateDetailsModal';

const LabelGroup = ({ label }) => {
    const { name, description, icon_color, processes } = label;
    const [processDetailsModal, setProcessDetailsModal] = useState({
        isOpened: false,
        process: null,
    });

    return (
        <div>
            <div className="mb-5">
                <p className="font-heading-bold text-heading-bold-s text-black mb-1">{name}</p>
                <p className="font-body text-body-regular-s text-neutral-400">{description}</p>
            </div>
            {!!processes.length && (
                <div className="grid grid-cols-1 min-[600px]:grid-cols-2 gap-x-3 gap-y-4 min-[950px]:grid-cols-3 l:gap-x-4 l:gap-y-5 min-[1340px]:grid-cols-4 min-[1930px]:grid-cols-5">
                    {processes.map((process) => (
                        <div
                            key={process.id}
                            className={`self-stretch rounded-2 p-4 bg-neutral-50 border-2`}
                            style={{ borderColor: icon_color }}
                        >
                            <p className="font-body-bold text-body-bold-m text-black mb-1">
                                {process.name}
                            </p>
                            <p className="font-body text-body-regular-xs text-neutral-500 line-clamp-1 mb-4">
                                {process.description}
                            </p>
                            <div className="flex justify-end">
                                <Button
                                    type="primary"
                                    size="xs"
                                    text="View Details"
                                    onClick={() =>
                                        setProcessDetailsModal({
                                            isOpened: true,
                                            process,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {processDetailsModal.isOpened && (
                <ProcessTemplateDetailsModal
                    processTemplateData={processDetailsModal.process}
                    withContactUsButton
                    onClose={() => setProcessDetailsModal({ isOpened: false })}
                />
            )}
        </div>
    );
};

export default LabelGroup;
