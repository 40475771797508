import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/template-api';
import useCustomPagination, { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';
import { useSwrPageRequestKeysManagingWithContextSaving } from '../../../hooks/useSwrPageRequestKeysManagingWithContextSaving';

import { TEMPLATES_LIBRARY_TABS } from '../../../constants/template';
import { getTemplatesLibraryRequestQueryParams } from '../../../helpers/getTemplatesLibraryRequestQueryParams';

import Loading from '../../../components/Loading';
import TemplatesLibraryIndexPage from '../TemplatesLibraryIndexPage/TemplatesLibraryIndexPage';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';

const getDataRequestRoute = {
    [TEMPLATES_LIBRARY_TABS.goals]: API.ROUTES.template.goalTemplate,
    [TEMPLATES_LIBRARY_TABS.processes]: API.ROUTES.template.processTemplate,
};

const TemplatesLibraryPaginatedContainer = ({ parsedParams, activeTab }) => {
    const [_, setSearchParams] = useSearchParams();

    const { data, total, count, loading, key, nextPageKey } = useCustomPagination({
        pageIndex: parsedParams.page - 1 || 0,
        searchParams: getTemplatesLibraryRequestQueryParams(parsedParams),
        client: client,
        route: getDataRequestRoute[activeTab],
        autoFetchNextPage: true,
    });

    const { requestKeysToMutate } = useSwrPageRequestKeysManagingWithContextSaving({
        pageAccessor: 'templatesLibrary',
        key,
        nextPageKey,
    });

    if (loading) {
        return <Loading />;
    }

    const page = Number(parsedParams.page) || 1;
    const start = page === 1 ? 1 : (page - 1) * DEFAULT_LIMIT + 1;
    const end = total - start < count ? total : start + count - 1;

    const onNextPressed = () => {
        setSearchParams({ ...parsedParams, page: parsedParams.page + 1 });
    };

    const onPreviousPressed = () => {
        if (parsedParams.page > 0) {
            setSearchParams({ ...parsedParams, page: parsedParams.page - 1 });
        }
    };

    return (
        <>
            <TemplatesLibraryIndexPage
                data={data}
                activeTab={activeTab}
                loading={loading}
                page={parsedParams.page}
                parsedParams={parsedParams}
                requestKeysToMutate={requestKeysToMutate}
            />
            {total > 0 && (
                <div className="flex flex-col">
                    <PaginationFooter
                        start={start}
                        end={end}
                        total={total}
                        onNextPressed={onNextPressed}
                        onPreviousPressed={onPreviousPressed}
                    />
                </div>
            )}
        </>
    );
};

export default TemplatesLibraryPaginatedContainer;
