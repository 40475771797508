import React, { useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mutate } from 'swr';

import { API } from 'constants';
import client from 'services/library-api';
import useUser from '../../../hooks/useUser';
import useCustomPagination from '../../../hooks/useCustomPagination';

import { LIBRARY_SORT_TYPES } from 'constants/library';
import { USER_ROLE } from '../../../constants/organization';
import { parseLibrarySearchParams } from '../../../services/library-utils';
import { getLibraryRequestQueryParams } from '../../../helpers/getLibraryRequestQueryParams';

import Loading from 'components/Loading';
import { EmptyIndexPage } from 'pages/LibraryPage/EmptyIndex/EmptyIndex';
import TaskCard from 'pages/LibraryPage/TaskCard/TaskCard';

function TaskIndexPage({ pageIndex, libraryType, libraryView, collections, setCollections }) {
    const [searchParams] = useSearchParams();
    const parsedSearchParams = parseLibrarySearchParams(searchParams);
    const filteredCollectionId = parsedSearchParams.collection;

    const { userRole } = useUser();
    const viewOnlyMode = userRole === USER_ROLE.member;

    const navigate = useNavigate();
    const location = useLocation();

    const { data, loading, key } = useCustomPagination({
        pageIndex,
        client,
        searchParams: getLibraryRequestQueryParams(parsedSearchParams),
        route: filteredCollectionId
            ? `${API.ROUTES.library.collection}${filteredCollectionId}/tasks/`
            : API.ROUTES.library.task,
    });

    useEffect(() => {
        const deletedTaskId = Number(localStorage.getItem('deletedTaskId'));

        if (deletedTaskId && data?.length) {
            const newData = data.filter((task) => task.id !== deletedTaskId);
            mutate(
                key,
                (currentData) => {
                    return { ...currentData, count: currentData.count - 1, results: newData };
                },
                {
                    revalidate: false,
                }
            );
            localStorage.removeItem('deletedTaskId');
        }
    }, [data]);

    if (!data?.length && loading) {
        // data will prefetch so display it if available
        return (
            <div className="flex flex-col p-8 m-8">
                <Loading text="Fetching your Tasks." />
            </div>
        );
    }

    if (!data.length && pageIndex === 0) {
        return (
            <EmptyIndexPage
                libraryType={libraryType}
                onCreatePressed={() =>
                    navigate('/library/prompt/new', {
                        state: { libraryLocation: location },
                    })
                }
                hasCreateAccess={!viewOnlyMode}
                areFiltersApplied={Boolean(
                    parsedSearchParams.collection || parsedSearchParams.task_type
                )}
            />
        );
    }

    const cardsContainerClassName = {
        list: 'flex flex-col gap-2',
        grid: 'grid grid-cols-1 gap-x-5 gap-y-5 md:grid-cols-2 min-[1360px]:grid-cols-3 min-[1730px]:grid-cols-4',
    };

    return (
        <ul className={cardsContainerClassName[libraryView]}>
            {data.map((task, index) => (
                <TaskCard
                    key={index}
                    task={task}
                    swrKey={key}
                    libraryView={libraryView}
                    collections={collections}
                    setCollections={setCollections}
                />
            ))}
        </ul>
    );
}

TaskIndexPage.propTypes = {
    pageIndex: PropTypes.number.isRequired,
    sortType: PropTypes.oneOf(Object.values(LIBRARY_SORT_TYPES)).isRequired,
};

export default TaskIndexPage;
