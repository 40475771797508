import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import executeClient from '../../../services/execute-api';
import templateClient from '../../../services/template-api';
import useCustomPagination from '../../../hooks/useCustomPagination';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

import { datasetSortOptions, SORT_TYPES, testRunsSortOptions } from '../../../constants/sort';
import { parseSearchParams } from '../../../helpers/parseSearchSortPageSearchParams';
import {
    AddCircleLineIcon,
    ArrowDownSLineIcon,
    ArrowGoBackLineIcon,
    ArrowUpSLineIcon,
} from '../../../design-system/Icons';
import { Tabs } from '../../../design-system';
import Button from '../../../design-system/Button/Button';
import Loading from '../../../components/Loading';
import SearchBar from '../../../design-system/SearchBar/SearchBar';
import SortDropdown from '../../../components/SortDropdown/SortDropdown';
import {
    handleClearSearchBar,
    handleSearch,
    handleSortOptionSelect,
} from '../../../helpers/handleUpdateSearchParams';
import { getRequestQueryParams } from '../../../helpers/getDatasetAndTestRunsRequestQueryParams';
import PagePaginatedContainer from './PagePaginatedContainer/PagePaginatedContainer';

const tabs = [{ name: 'Datasets' }, { name: 'Test Runs' }];
const sortOptions = {
    datasets: datasetSortOptions,
    tests: testRunsSortOptions,
};
export const defaultSortType = { datasets: SORT_TYPES.alphabetical, tests: SORT_TYPES.created };
const searchbarPlaceholder = {
    datasets: 'Search',
    tests: 'Search test runs by name',
};

const GoalTemplateDatasetsAndTestRunsPage = () => {
    const params = useParams();
    const goalTemplateId = Number(params.goalTemplateId);

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseSearchParams(searchParams), [searchParams]);

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname?.includes('/datasets') ? 'datasets' : 'tests';
    const tabIndex = path === 'datasets' ? 0 : 1;

    const [goalTemplateData, setGoalTemplateData] = useState(null);
    const [sortDropdownOpened, setSortDropdownOpened] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const getDataRoutes = {
        datasets: API.ROUTES.execute.dataset,
        tests: API.ROUTES.execute.testRun,
    };

    const { data, total, key } = useCustomPagination({
        client: executeClient,
        route: getDataRoutes[path],
        searchParams: getRequestQueryParams(parsedParams, goalTemplateId, path),
        pageIndex: parsedParams.page - 1 || 0,
    });

    useDocumentTitle(path === 'datasets' ? 'Datasets' : 'Test Runs');

    useEffect(() => {
        const page = Number.isNaN(parsedParams.page) ? 1 : parsedParams.page;
        const sort = Object.values(SORT_TYPES).includes(parsedParams.sort)
            ? parsedParams.sort
            : defaultSortType[path];

        if (Number.isNaN(parsedParams.page) || parsedParams.sort !== sort) {
            setSearchParams(
                {
                    ...parsedParams,
                    page,
                    sort,
                },
                { replace: true }
            );
        }
    }, [parsedParams, setSearchParams]);

    useEffect(() => {
        // fill search bar after page reload
        if (parsedParams.search && !searchValue) {
            setSearchValue(parsedParams.search);
        }
    }, []);

    useEffect(() => {
        const fetchGoalTemplateData = async () => {
            try {
                const { data } = await templateClient.get(
                    `${API.ROUTES.template.goalTemplate}${goalTemplateId}/`
                );
                setGoalTemplateData(data);
            } catch (e) {
                navigate(`/templates/goal/${goalTemplateId}`);
            }
        };

        fetchGoalTemplateData();
    }, []);

    const onTabChanged = () => {
        navigate(
            path === 'datasets'
                ? `/templates/goal/${goalTemplateId}/tests`
                : `/templates/goal/${goalTemplateId}/datasets`
        );
    };

    return (
        <div className="w-full sm:w-[calc(100%-68px)] h-full min-h-[calc(100vh-64px)] px-4 sm:px-8 pt-4 pb-8 bg-white flex flex-col gap-4 sm:gap-8 items-start">
            {goalTemplateData ? (
                <>
                    <Button
                        type="link"
                        size="sm"
                        text="Back to Goal Template"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(`/templates/goal/${goalTemplateId}`)}
                    />
                    <p className="font-body text-body-bold-xl text-black">
                        {path === 'datasets' ? 'Datasets' : 'Test Runs'}:{' '}
                        <span className="font-body text-body-regular-xl">
                            {goalTemplateData.name}
                        </span>
                    </p>
                    <div className="flex min-[910px]:items-center flex-row gap-x-4 gap-y-2 justify-between flex-wrap self-stretch">
                        <div className="flex min-[910px]:items-center gap-2 flex-grow flex-wrap">
                            <div className="w-fit">
                                <Tabs onTabChanged={onTabChanged} tabs={tabs} tabIndex={tabIndex} />
                            </div>
                            <div className="flex items-center gap-2 flex-grow max-sm:relative">
                                <div className="flex-grow xs:min-w-[220px] max-w-[300px]">
                                    <SearchBar
                                        size="xs"
                                        value={searchValue}
                                        placeholder={searchbarPlaceholder[path]}
                                        withSubmitIcon
                                        withClearIcon
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        onSubmit={() =>
                                            handleSearch({
                                                searchParams,
                                                setSearchParams,
                                                searchValue,
                                            })
                                        }
                                        onClear={() =>
                                            handleClearSearchBar({
                                                searchParams,
                                                setSearchParams,
                                                setSearchValue,
                                            })
                                        }
                                    />
                                </div>

                                <div className="flex items-center gap-2 justify-end sm:relative">
                                    <Button
                                        type="link"
                                        size="xs"
                                        text="Sort"
                                        trailingIcon={
                                            sortDropdownOpened
                                                ? ArrowUpSLineIcon
                                                : ArrowDownSLineIcon
                                        }
                                        onClick={() => setSortDropdownOpened((prev) => !prev)}
                                    />

                                    {sortDropdownOpened && (
                                        <SortDropdown
                                            options={sortOptions[path]}
                                            selectedOption={parsedParams.sort}
                                            handleOptionSelect={(sortOption) =>
                                                handleSortOptionSelect({
                                                    searchParams,
                                                    setSearchParams,
                                                    sortOption,
                                                    onSearchDropdownClose: () =>
                                                        setSortDropdownOpened(false),
                                                })
                                            }
                                            onClose={() => setSortDropdownOpened(false)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {path === 'datasets' ? (
                            <Button
                                type="secondary"
                                size="xs"
                                text="New Dataset"
                                leadingIcon={AddCircleLineIcon}
                                onClick={() =>
                                    navigate(`/templates/goal/${goalTemplateId}/dataset/new`)
                                }
                            />
                        ) : (
                            <Button
                                type="secondary"
                                size="xs"
                                text="New Test Run"
                                leadingIcon={AddCircleLineIcon}
                                onClick={() => navigate(`/templates/goal/${goalTemplateId}/test`)}
                            />
                        )}
                    </div>

                    <PagePaginatedContainer
                        path={path}
                        data={data}
                        total={total}
                        parsedParams={parsedParams}
                        swrKey={key}
                    />
                </>
            ) : (
                <div className="flex flex-grow justify-center self-stretch">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default GoalTemplateDatasetsAndTestRunsPage;
