import React from 'react';
import PropTypes from 'prop-types';

IntegrationCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    buttonControls: PropTypes.node,
    centeredContent: PropTypes.bool,
};

function IntegrationCard({ title, description, buttonControls, centeredContent = false }) {
    return (
        <div
            className={`w-full px-[24px] sm:px-[80px] py-[20px] sm:py-[40px] flex flex-col gap-[10px] bg-purple-100 rounded-2 ${
                centeredContent && 'justify-center'
            }`}
        >
            <h2
                className={`font-heading-bold text-heading-bold-l text-neutral-500 ${
                    centeredContent && 'text-center'
                }`}
            >
                {title}
            </h2>
            {description && (
                <p
                    className={`font-body text-body-regular-xs text-neutral-500 ${
                        centeredContent && 'text-center'
                    }`}
                >
                    {description}
                </p>
            )}
            {buttonControls && <div>{buttonControls}</div>}
        </div>
    );
}

export default IntegrationCard;
