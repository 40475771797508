import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigationRestrictionContext } from '../../hooks/useNavigationRestrictionContext';

import ConfirmNavigationModal from '../ConfirmNavigationModal/ConfirmNavigationModal';
import ReactRouterPrompt from 'react-router-prompt';

const NavigationGuard = ({ when }) => {
    // this data is used to restrict navigation if user try to change View Type or Organization
    const { isNavigationRestricted, setIsNavigationRestricted } = useNavigationRestrictionContext();

    useEffect(() => {
        // update Navigation Restriction state in Context to show Confirm Navigation Modal while user try to change View Type or Organization
        if (when !== isNavigationRestricted) {
            setIsNavigationRestricted(when);
        }
    }, [when]);

    useEffect(() => {
        // clear context after page unmount
        return () => {
            setIsNavigationRestricted(false);
        };
    }, []);

    return (
        <ReactRouterPrompt when={isNavigationRestricted}>
            {({ onConfirm, onCancel }) => (
                <ConfirmNavigationModal onCancel={onCancel} onConfirm={onConfirm} />
            )}
        </ReactRouterPrompt>
    );
};

NavigationGuard.propTypes = {
    when: PropTypes.bool.isRequired,
};

export default NavigationGuard;
