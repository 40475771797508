import operateClient from '../services/operate-api';
import { API } from 'constants';
import { mutate } from 'swr';
import client from '../services/inbox-api';

export const mutateToDoData = (currentData, messageId) => {
    return {
        ...currentData,
        count: currentData.count - 1,
        results: currentData.results.filter((item) => item.id !== messageId),
    };
};

// use this function inside try {} catch(e) {} structure
export const handleReassignMessage = async ({
    selectedMember,
    message,
    requestKeysToMutate,
    setData,
    setNextMessageAfterMessageApproval,
}) => {
    const requestBody = {
        assigned_to: selectedMember,
    };
    await client.patch(`${API.ROUTES.inbox.message}${message.id}/`, requestBody);
    setNextMessageAfterMessageApproval();
    setData((prevData) => prevData.filter((item) => item.id !== message.id));
    // mutate all requests from To Do tab (remove approved message if it exists in request cash and set total count = count - 1)
    requestKeysToMutate.map((key) =>
        mutate(key, (currentData) => mutateToDoData(currentData, message.id), {
            revalidate: false,
        })
    );
};

// use this function inside try {} catch(e) {} structure
export const handleCancelMessage = async ({
    message,
    requestKeysToMutate,
    setData,
    setNextMessageAfterMessageApproval,
}) => {
    const requestBody = {
        message_id: message.id,
    };
    await operateClient.post(`${API.ROUTES.operate.cancel}${message.run}/`, requestBody);
    setNextMessageAfterMessageApproval();
    setData((prevData) => prevData.filter((item) => item.id !== message.id));
    // mutate all requests from To Do tab (remove approved message if it exists in request cash and set total count = count - 1)
    requestKeysToMutate.map((key) =>
        mutate(key, (currentData) => mutateToDoData(currentData, message.id), {
            revalidate: false,
        })
    );
};

export const mutateDataAfterIsReadChange = (key, currentData, currentMessage, isRead) => {
    if (!currentData) {
        return;
    }

    const messageId = currentMessage.id;

    let updatedCount = currentData.count;
    let updatedResults = currentData.results;

    const isKeyWithUnreadStatus = key.includes('is_read=false');

    const currentMessageIsInData = !!currentData.results.find((item) => item.id === messageId);

    if (isKeyWithUnreadStatus) {
        // delete just read message from the list of unread messages
        if (currentMessageIsInData && isRead === true) {
            updatedCount = updatedCount - 1;
            updatedResults = updatedResults.filter((item) => item.id !== messageId);
        }

        // add marked as unread message to the list of unread messages
        if (!currentMessageIsInData && isRead === false) {
            updatedCount = updatedCount + 1;
            updatedResults = [...updatedResults, currentMessage];
        }
    }

    if (!isKeyWithUnreadStatus && currentMessageIsInData) {
        // change is_read status of the current message
        updatedResults = updatedResults.map((item) =>
            item.id === messageId ? { ...item, is_read: isRead } : item
        );
    }

    return {
        ...currentData,
        count: updatedCount,
        results: updatedResults,
    };
};

// use this function inside try {} catch(e) {} structure
export const handleChangeMessageIsReadStatus = async ({
    is_read,
    message,
    setData,
    requestKeysToMutate,
}) => {
    const { data } = await client.patch(`${API.ROUTES.inbox.message}${message.id}/`, { is_read });

    setData((prevData) =>
        prevData.map((item) => (item.id === message.id ? { ...item, is_read } : item))
    );

    requestKeysToMutate.map((key) =>
        mutate(key, (currentData) => mutateDataAfterIsReadChange(key, currentData, data, is_read), {
            revalidate: true,
        })
    );
};
