const hideKey = (key) => {
    if (key.length) {
        return '•'.repeat(key.length);
    }
    return '•••';
};

export const transformDataApiKeysTable = (data) => {
    const result = {
        columns: [
            {
                header: 'KEY NAME',
                accessor: 'name',
                type: 'text',
            },
            {
                header: 'KEY',
                accessor: 'key',
                type: 'text',
            },
            {
                header: 'CREATED DATE',
                accessor: 'created_at',
                type: 'text',
            },
        ],
        rows: [],
    };

    if (Array.isArray(data)) {
        data.map((item) => {
            const row = {
                id: item.key,
                name: item.name,
                key: hideKey(item.key),
                created_at: new Date(item.created_at).toLocaleDateString(),
            };
            result.rows.push(row);
        });
    }

    return result;
};
