import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { API, TemperatureOptions } from 'constants';
import client from '../../services/library-api';
import operateClient from '../../services/operate-api';
import WebSocketInstance from '../../services/websocket';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import useUser from '../../hooks/useUser';
import { useFetchCollections } from '../../hooks/useFetchCollections';
import { useNavigationRestrictionContext } from '../../hooks/useNavigationRestrictionContext';

import { filterInputsWithFillQuerystring, updateInputState } from '../../helpers/updateInputState';
import { updateOutputState } from '../../helpers/updateOutputState';
import { getModelOptions } from '../../helpers/getModelOptions';
import { getPromptSettings } from '../../helpers/getPromptSettings';
import { updateQueryParameters } from '../../helpers/updateQueryParameters';
import { updateContextState } from '../../helpers/updateContextState';

import Loading from '../../components/Loading';
import {
    ArrowGoBackLineIcon,
    CloseCircleLineIcon,
    ErrorWarningLineIcon,
    FlashlightFillIcon,
    More2FillIcon,
    TerminalBoxLineIcon,
} from '../../design-system/Icons';
import { Button, ButtonIcon } from '../../design-system';
import NewRunContainer from '../../components/NewRunContainer/NewRunContainer';
import PromptViewPageHeader from '../../components/PromptViewPageHeader/PromptViewPageHeader';
import CollapsableContainer from '../../components/CollapsableContainer/CollapsableContainer';
import OutputContainer from '../../components/OutputContainer/OutputContainer';
import TaskOptions from '../../components/TaskOptions/TaskOptions';
import OutputFocusModeModal from '../../components/OutputFocusModeModal/OutputFocusModeModal';
import Alert from '../../design-system/Alert/Alert';
import BlockingNavigationModal from '../LibraryPage/BlockingNavigationModal/BlockingNavigationModal';
import ReactRouterPrompt from 'react-router-prompt';
import ArchiveLineIcon from '../../design-system/Icons/ArchiveLineIcon';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';

const ViewOnlyPromptPage = () => {
    const { id } = useParams();
    const promptId = parseInt(id, 10);
    const urlParams = new URLSearchParams(window.location.search);
    const [resultId, setResultId] = useState(urlParams.get('result') || null);
    const fillId = urlParams.get('fill') || null;

    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '/library';

    const inputRef = useRef(null);

    const { user } = useUser();
    const modelOptions = getModelOptions(user);
    const [promptSettings, setPromptSettings] = useState({
        model: modelOptions[0],
        maxTokens: 1000,
        temperature: TemperatureOptions[0],
    });

    const [prompt, setPrompt] = useState(null);
    const [temporaryPromptInputs, setTemporaryPromptInputs] = useState([]);
    const [promptInputs, setPromptInputs] = useState([]);
    const [promptOutputs, setPromptOutputs] = useState([]);
    const [context, setContext] = useState([]);
    const [collections, setCollections] = useState([]);
    const [areCollectionsLoading, setAreCollectionsLoading] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [runPromptState, setRunPromptState] = useState({
        isLoading: false,
        errorMessage: null,
        errorStatusCode: null,
    });
    const [generationId, setGenerationId] = useState(null);
    const [editPromptData, setEditPromptData] = useState({});
    const [isExpanded, setIsExpanded] = useState({ inputs: true });
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [isOutputFocusMode, setIsOutputFocusMode] = useState(false);
    const [statusAlert, setStatusAlert] = useState(null);

    let websocket = WebSocketInstance.getSingleton();

    const taskId = prompt?.task?.id || null;
    const accessLevel = prompt?.access_level || null;

    const [arePromptChanges, setArePromptChanges] = useState(false);
    const [areInputEdits, setAreInputEdits] = useState(false);
    const { isNavigationRestricted, setIsNavigationRestricted } = useNavigationRestrictionContext();

    useFetchCollections(setCollections, setAreCollectionsLoading);
    useDocumentTitle((prompt && prompt?.task?.name) || '');

    useEffect(() => {
        const areChanges = arePromptChanges || areInputEdits;

        // save should Navigation be restricted or not in context
        if (areChanges !== isNavigationRestricted) {
            setIsNavigationRestricted(areChanges);
        }
    }, [arePromptChanges, areInputEdits, isNavigationRestricted]);

    useEffect(() => {
        // clear context after page unmount
        return () => {
            setIsNavigationRestricted(false);
        };
    }, []);

    useEffect(() => {
        WebSocketInstance.connect();
        waitForSocketConnection(() => {
            WebSocketInstance.addCallbacks(
                onStreamStart,
                onMessageStream,
                onStreamEnd,
                onStreamError
            );
        });
    }, []);

    useEffect(() => {
        WebSocketInstance.setCurrentPageGenId(generationId);
    }, [generationId]);

    const waitForSocketConnection = (callback) => {
        const checkConnection = () => {
            if (WebSocketInstance.state() === 1) {
                console.log('connection is secure');
                callback();
            } else {
                setTimeout(checkConnection, 300);
            }
        };

        checkConnection();
    };

    const onMessageStream = (data) => {
        if (isExpanded.inputs === true) {
            setIsExpanded({ inputs: false, promptSettings: false });
        }
        setPromptOutputs((prevPromptOutputs) => {
            const updatedPromptOutputs = prevPromptOutputs.map((output) => {
                const currentValue = output.value || '';
                return { ...output, value: currentValue + data.message.text };
            });
            return updatedPromptOutputs;
        });
        clearTimeout(llmStartTimeout);
    };

    const onStreamEnd = (data) => {
        setRunPromptState({ isLoading: false, errorMessage: null, errorStatusCode: null });
        datadogRum.addAction('PromptStreamEnd', {
            generationId,
            promptSettings,
            promptInputs,
        });
        setIsRunning(false);
        updateQueryParameters({ resultId: data.message.gen_id });
    };

    const onStreamError = () => {
        setRunPromptState({
            isLoading: false,
            errorMessage: 'Oops! Something went wrong while running your prompt, please try again.',
            errorStatusCode: null,
        });
        datadogRum.addAction('PromptStreamError', {
            generationId,
            promptSettings,
            promptInputs,
        });
        setIsRunning(false);
    };

    let llmStartTimeout;

    const onStreamStart = () => {
        setIsExpanded({ inputs: false, promptSettings: false });
        setIsRunning(true);
        llmStartTimeout = setTimeout(() => {
            console.log('llm timeout');
            onStreamError();
        }, 5000);
        datadogRum.addAction('PromptStreamStart', {
            generationId,
            promptSettings,
            promptInputs,
        });
    };

    useEffect(() => {
        if (prompt) {
            resetPromptState();
        }
        fetchPromptData();

        if (resultId || fillId) {
            fetchResultData();
        }
    }, [promptId]);

    const fetchPromptData = async () => {
        try {
            const { data } = await client.get(`${API.ROUTES.library.prompt}${promptId}/`);
            setPrompt(data);

            const defaultVersion = data.default_prompt_version || null;

            if (defaultVersion) {
                if (!resultId) {
                    const { settings } = defaultVersion;
                    const { inputs, outputs } = data.task;
                    setPromptOutputs(updateOutputState(outputs));
                    setPromptSettings(getPromptSettings(settings));
                    setContext(updateContextState(data.task?.context));

                    if (fillId) {
                        setTemporaryPromptInputs((prevState) => [...prevState, ...inputs]);
                    } else {
                        setPromptInputs(updateInputState(inputs));
                    }

                    if (!settings.model) {
                        setEditPromptData((prevData) => ({
                            ...prevData,
                            settings: {
                                provider: promptSettings?.model.provider,
                                temperature: promptSettings?.temperature.value || 0.0,
                                max_tokens: promptSettings?.maxTokens,
                                type: promptSettings?.model.type,
                                model: promptSettings?.model.model,
                            },
                        }));
                        setArePromptChanges(true);
                    }
                }
            } else {
                resetPromptState();
            }
        } catch (error) {
            resetPromptState();
            if (error.response.status === 404 || error.response.status === 403) {
                navigate(libraryLocation);
            }
        }
    };

    const fetchResultData = async () => {
        try {
            const requestId = resultId || fillId;
            const { data } = await operateClient.get(`${API.ROUTES.operate.result}${requestId}/`);
            const {
                inputs,
                outputs,
                prompt_version: { settings },
                context_json,
            } = data;
            if (fillId) {
                setTemporaryPromptInputs((prevState) => [
                    ...prevState,
                    ...inputs.map((item) => ({ ...item, fromResultData: true })),
                ]);
            }
            if (resultId) {
                setPromptInputs(updateInputState(inputs));
                setPromptOutputs(updateOutputState(outputs));
                setPromptSettings(getPromptSettings(settings));
                setContext(updateContextState(context_json));
                if (!settings.model) {
                    setArePromptChanges(true);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const inputs = filterInputsWithFillQuerystring(temporaryPromptInputs);
        setPromptInputs(updateInputState(inputs));
    }, [temporaryPromptInputs]);

    const resetPromptState = () => {
        setPrompt(null);
        setPromptInputs([]);
        setPromptOutputs([]);
        setPromptSettings({
            model: modelOptions[0],
            maxTokens: 1000,
            temperature: TemperatureOptions[0],
        });
    };

    const resetPromptOutputs = (newValue) => {
        setPromptOutputs((prevPromptOutputs) => {
            const updatedPromptOutputs = prevPromptOutputs.map((output) => {
                return { ...output, value: newValue };
            });
            return updatedPromptOutputs;
        });
    };

    const updatePromptInputs = (newValue, index) => {
        const updatedPromptInputs = promptInputs.map((input, i) => {
            if (i === index) {
                return { ...input, value: newValue, state: 'default' };
            } else {
                return input;
            }
        });
        setPromptInputs(updatedPromptInputs);
        setAreInputEdits(true);
    };

    const handleScrollToInputs = () => {
        if (inputRef.current) {
            inputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const runPrompt = () => {
        setRunPromptState({ isLoading: true, errorMessage: null, errorStatusCode: null });
        updateQueryParameters({ resultId: null, clearFillId: true });

        // Add the patch call if arePromptChanges isn't null, user has models, and promptSettings.model is in modelOptions
        if (
            arePromptChanges &&
            modelOptions.length &&
            modelOptions.some((option) => option.model === promptSettings.model.model)
        ) {
            client
                .patch(`${API.ROUTES.library.prompt}${promptId}/`, editPromptData)
                .then(() => {
                    executePrompt();
                    setArePromptChanges(false);
                })
                .catch(() => {
                    setRunPromptState({
                        isLoading: false,
                        errorMessage: 'You have unsaved prompt edits. Please try again.',
                    });
                });
            // if arePromptChanges is true, but user has no models, we want to save the changes, but set to error
        } else if (arePromptChanges && !modelOptions.length) {
            client
                .patch(`${API.ROUTES.library.prompt}${promptId}/`, editPromptData)
                .then(() => {
                    setRunPromptState({
                        isLoading: false,
                        errorMessage: 'Please add an API key and select a model',
                    });
                    setArePromptChanges(false);
                })
                .catch(() => {
                    setRunPromptState({
                        isLoading: false,
                        errorMessage: 'You have unsaved prompt edits. Please try again.',
                    });
                });

            // if prompt settings model is not in modelOptions, set state to error; regardless of arePromptChanges
        } else if (
            modelOptions.length &&
            !modelOptions.some((option) => option.model === promptSettings.model.model)
        ) {
            setRunPromptState({
                isLoading: false,
                errorMessage: 'Please select a model you have access to.',
            });
        } else {
            executePrompt();
        }
    };

    const executePrompt = () => {
        setRunPromptState({ isLoading: true, errorMessage: null, errorStatusCode: null });
        updateQueryParameters({ resultId: null });
        // check if each promptInput has a value
        const promptInputsValid = promptInputs.every((input) => input.value);
        if (promptInputsValid) {
            // refresh PromptOutputs
            resetPromptOutputs('');
            // update prompt inputs to remove isRequired and state keys
            const updatedPromptInputs = promptInputs.map((input) => {
                const { isRequired, state, errorMessage, isMissedInPrompt, ...rest } = input;
                return rest;
            });
            let run_data = { sync: false, inputs: updatedPromptInputs };

            if (!websocket.isOpen()) {
                if (!websocket.attempt_reconnect()) {
                    console.log('websocket reconnect failed / using sync mode');
                    run_data = { sync: true, ...run_data };
                    setIsExpanded({ inputs: false, promptSettings: false });
                }
            }

            // execute task
            operateClient
                .post(`execute/${taskId}/`, run_data)
                .then((res) => {
                    if (!websocket.isOpen()) {
                        console.log('websocket not open / using sync response mode');
                        resetPromptOutputs(res.data.final_output);
                        setIsRunning(false);
                        setRunPromptState({
                            isLoading: false,
                            errorMessage: null,
                            errorStatusCode: null,
                        });
                        const resultId = res.data.id;
                        updateQueryParameters({ resultId });
                    } else {
                        console.log('streaming response');
                        setIsRunning(true);
                        setRunPromptState({
                            isLoading: false,
                            errorMessage: null,
                            errorStatusCode: null,
                        });
                        const generationId = res.data.run;
                        setGenerationId(generationId);
                        setResultId(res.data.id);
                    }
                })
                .then(() => {
                    setAreInputEdits(false);
                })
                .catch((error) => {
                    setIsRunning(false);
                    if (error.response.status === 400) {
                        if (
                            error.response.data?.error &&
                            error.response.data.error.includes("You don't have API credentials")
                        ) {
                            setRunPromptState({
                                isLoading: false,
                                errorMessage: `Add a valid API key for ${promptSettings.model.provider} to be able to run this prompt.`,
                            });
                        } else if (
                            error.response.data?.error &&
                            error.response.data.error.includes(
                                'Total token exceeds model token limit'
                            )
                        ) {
                            setRunPromptState({
                                isLoading: false,
                                errorMessage:
                                    'Oops! Your request is over the token limit for this model. Please try again with less tokens or a different model.',
                            });
                        } else {
                            setRunPromptState({
                                isLoading: false,
                                errorStatusCode: error.response.status,
                                errorMessage:
                                    'Oops! Something went wrong while running your prompt, please try again.',
                            });
                        }
                    } else {
                        setRunPromptState({
                            isLoading: false,
                            errorStatusCode: error.response.status,
                            errorMessage:
                                'Oops! Something went wrong while running your prompt, please try again.',
                        });
                    }
                });
        } else {
            // if promptInputs are not valid, set state to error
            setIsExpanded({ inputs: true, promptSettings: false });
            handleScrollToInputs();
            const updatedPromptInputs = promptInputs.map((input) => {
                if (!input.value) {
                    return {
                        ...input,
                        state: 'error',
                        errorMessage: 'Please fill in this field.',
                    };
                } else {
                    return input;
                }
            });
            setPromptInputs(updatedPromptInputs);
            setRunPromptState({ isLoading: false, errorMessage: null, errorStatusCode: null });
        }
    };

    const cancelStream = () => {
        if (websocket) {
            // reset prompt state
            setRunPromptState({ isLoading: false, errorMessage: null, errorStatusCode: null });
            setIsRunning(false);
            setGenerationId(null);
        }
    };

    const handleBackPressed = () => {
        if (accessLevel === 'viewer') {
            navigate(libraryLocation);
        } else {
            navigate(
                `/library/prompt/${promptId}${resultId ? `?result=${resultId}` : ''}${
                    fillId ? `?fill=${fillId}` : ''
                }`
            );
        }
    };

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white overflow-auto px-5 py-5 sm:py-[40px]">
            {prompt ? (
                <div className="w-full h-full max-w-[800px] mx-auto flex flex-col gap-3">
                    <div>
                        {accessLevel === 'viewer' ? (
                            <Button
                                type="link"
                                size="sm"
                                text="Back to Library"
                                onClick={handleBackPressed}
                                leadingIcon={ArrowGoBackLineIcon}
                            />
                        ) : (
                            <Button
                                type="link"
                                size="sm"
                                text="Back To Prompt Playground"
                                onClick={handleBackPressed}
                                leadingIcon={ArrowGoBackLineIcon}
                                trailingIcon={TerminalBoxLineIcon}
                            />
                        )}
                    </div>
                    <PromptViewPageHeader
                        taskId={taskId}
                        headerText={prompt.task.name}
                        emojiCode={prompt.task.icon_text}
                        subHeaderText={prompt.task.description}
                        setPrompt={setPrompt}
                        editable={false}
                        currentTaskCollectionsIds={prompt.task?.collections}
                        allCollections={collections}
                        setAllCollections={setCollections}
                        areCollectionsLoading={areCollectionsLoading}
                        collectionsEditable={false}
                    />
                    <CollapsableContainer
                        title="Inputs"
                        isExpanded={isExpanded.inputs}
                        toggleExpand={() =>
                            setIsExpanded((prevState) => ({ inputs: !prevState.inputs }))
                        }
                    >
                        <div className="flex flex-col gap-6">
                            <NewRunContainer
                                textAreaData={promptInputs}
                                updateTextAreaData={updatePromptInputs}
                                ref={inputRef}
                                withCopyButton
                                useKeyAsALabel
                            />
                            <NewRunContainer
                                textAreaData={context}
                                withCopyButton
                                useDoubleBracketsKeyAsALabel
                                leadingIcon={ArchiveLineIcon}
                            />
                        </div>
                    </CollapsableContainer>
                    <OutputContainer
                        promptOutputs={promptOutputs}
                        updatePromptOutputs={() => {}}
                        withFullScreenIcon={true}
                        onFullScreenIconClick={() => setIsOutputFocusMode(true)}
                        isRunning={isRunning}
                    />

                    <div className="justify-end fixed right-[10px] min-[380px]:right-[20px] bottom-[5px] lg:bottom-[20px] z-28 flex gap-[4px] xs:gap-[10px] items-center p-[7px]">
                        <div className="relative">
                            <ButtonIcon
                                type="secondary"
                                size="sm"
                                icon={More2FillIcon}
                                onClick={() => setShowMoreOptions(true)}
                            />
                            {showMoreOptions && (
                                <TaskOptions
                                    mode="viewOnly"
                                    onModalClose={() => setShowMoreOptions(false)}
                                    promptId={promptId}
                                    task={prompt.task}
                                    setAlert={setStatusAlert}
                                />
                            )}
                        </div>
                        <Button
                            type="primary"
                            size="sm"
                            text={isRunning ? 'Cancel Stream' : 'Run Prompt'}
                            leadingIcon={isRunning ? CloseCircleLineIcon : FlashlightFillIcon}
                            // helperText={isRunning ? null : '⌘Enter'}
                            onClick={isRunning ? cancelStream : runPrompt}
                            state={runPromptState.isLoading ? 'loading' : 'default'}
                        />
                    </div>
                    {isOutputFocusMode && (
                        <OutputFocusModeModal
                            promptOutputs={promptOutputs}
                            handleClose={() => setIsOutputFocusMode(false)}
                            isRunning={isRunning}
                        />
                    )}
                    {runPromptState.errorMessage && (
                        <Alert
                            status="critical"
                            message={runPromptState.errorMessage}
                            statusCode={runPromptState.errorStatusCode}
                            icon={ErrorWarningLineIcon}
                            handleClose={() =>
                                setRunPromptState({
                                    isLoading: false,
                                    errorMessage: null,
                                    errorStatusCode: null,
                                })
                            }
                        />
                    )}
                    {statusAlert && (
                        <Alert
                            status={statusAlert.status}
                            message={statusAlert.message}
                            icon={statusAlert.icon || CheckLineIcon}
                            statusCode={statusAlert.statusCode}
                            handleClose={() => setStatusAlert(null)}
                        />
                    )}
                    <ReactRouterPrompt when={isNavigationRestricted}>
                        {({ onConfirm, onCancel }) => (
                            <BlockingNavigationModal
                                edits={`input`}
                                onCancel={onCancel}
                                onConfirm={onConfirm}
                                runPrompt={runPrompt}
                            />
                        )}
                    </ReactRouterPrompt>
                </div>
            ) : (
                <div className="w-full h-full flex items-center justify-center ">
                    <Loading text="" />
                </div>
            )}
        </div>
    );
};

export default ViewOnlyPromptPage;
