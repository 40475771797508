import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API } from 'constants';
import operateClient from '../../services/operate-api';
import { Button } from '../../design-system';
import { ArrowGoBackLineIcon, ErrorWarningLineIcon } from '../../design-system/Icons';
import Loading from '../../components/Loading';
import PromptChainStepsBlock from './PromptChainStepsBlock/PromptChainStepsBlock';
import PromptChainRunOverviewPageHeader from './PromptChainRunOverviewPageHeader/PromptChainRunOverviewPageHeader';
import OutdatedWorkerBanner from '../../components/OutdatedWorkerBanner/OutdatedWorkerBanner';
import Alert from '../../design-system/Alert/Alert';
import { defaultErrorMessage } from '../../constants/errorMessages';

// navigate('/run/:id/overview', {state: { from: location }});  //go to this page like this

const PromptChainRunOverviewPage = () => {
    const { id: workerId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const [workerDetails, setWorkerDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, message: '', statusCode: null });

    const isOutdated = workerDetails && workerDetails.is_outdated;

    const handleBackClick = () => {
        const backLinkHref = location.state?.from ?? '/library';
        navigate(backLinkHref);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const { data } = await operateClient.get(
                    `${API.ROUTES.operate.worker}${workerId}/`
                );
                setWorkerDetails(data);
            } catch (e) {
                setErrorAlert({
                    show: true,
                    message: defaultErrorMessage,
                    statusCode: e.response.statusCode,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="bg-white w-full sm:w-[calc(100%-68px)] h-full px-[14px] pb-[40px] min-h-[calc(100vh-64px)]">
            {!isLoading && workerDetails && (
                <div className="w-full max-w-[800px] py-5 flex flex-col gap-4 items-start mx-auto">
                    <Button
                        type="link"
                        size="sm"
                        text="Back"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={handleBackClick}
                    />
                    {isOutdated && <OutdatedWorkerBanner workerDetails={workerDetails} />}
                    <div className="p-2 sm:p-5 w-full">
                        <PromptChainRunOverviewPageHeader
                            workerDetails={workerDetails}
                            setWorkerDetails={setWorkerDetails}
                            workerId={workerId}
                            setErrorAlert={setErrorAlert}
                        />
                        <PromptChainStepsBlock
                            steps={workerDetails?.config || []}
                            workerId={workerId}
                            isOutdated={isOutdated}
                        />
                    </div>
                </div>
            )}
            {isLoading && (
                <div className="fixed inset-0 flex justify-center items-center">
                    <div className="z-6 bg-white p-4 rounded-lg">
                        <Loading />
                    </div>
                </div>
            )}
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                />
            )}
        </div>
    );
};

export default PromptChainRunOverviewPage;
