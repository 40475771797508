import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { defaultErrorMessage } from '../../constants/errorMessages';

import SvgIcon from '../SvgIcon/SvgIcon';
import ErrorWarningLineIcon from '../Icons/ErrorWarningLineIcon';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Alert from '../Alert/Alert';
import CheckLineIcon from '../Icons/CheckLineIcon';
import AddToLibraryIcon from '../Icons/AddToLibraryIcon';
import EditLineIcon from '../Icons/EditLineIcon';
import Trash2Icon from '../Icons/Trash2Icon';
import { Badge, ButtonIcon } from '../index';
import { FileCopyLineIcon } from '../Icons';

FormFieldWrapper.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string || PropTypes.node]),
    showLabelAsBadge: PropTypes.bool,
    labelBadgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
    isRequired: PropTypes.bool,
    state: PropTypes.oneOf(['default', 'error']),
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    children: PropTypes.node,
    withCopyButton: PropTypes.bool,
    withDeleteButton: PropTypes.bool,
    withEditButton: PropTypes.bool,
    withDuplicateButton: PropTypes.bool,
    onDeleteButtonClick: PropTypes.func,
    onEditButtonClick: PropTypes.func,
    onDuplicateButtonClick: PropTypes.func,
    value: PropTypes.string,
    labelToCopyAlert: PropTypes.string,
    gap: PropTypes.number,
    bottomSpace: PropTypes.number,
    labelMarginBottom: PropTypes.number,
};

function FormFieldWrapper({
    children,
    state = 'default',
    label,
    showLabelAsBadge = false,
    labelBadgeColor = 'peach',
    isRequired,
    errorMessage,
    tipText,
    withCopyButton = false,
    withDeleteButton = false,
    withEditButton = false,
    withDuplicateButton = false,
    onDeleteButtonClick = () => {},
    onEditButtonClick = () => {},
    onDuplicateButtonClick = () => {},
    value, // if you set withCopyButton = true then this value will be copied to clipboard
    labelToCopyAlert, // if label is a React element, pass here label as a string to display in Success Copy Alert
    gap = 4, // (in px)
    bottomSpace = 0, // additional space between {children} and errorMessage or tipText
    labelMarginBottom = 0,
}) {
    const [copyStatusAlert, setCopyStatusAlert] = useState({
        status: '',
        message: '',
    });

    const handleCopy = () => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                setCopyStatusAlert({
                    status: 'positive',
                    message: `${labelToCopyAlert || label} copied to clipboard!`,
                });
            })
            .catch(() => {
                setCopyStatusAlert({ status: 'critical', message: defaultErrorMessage });
            });
    };

    const includesActionButton =
        withCopyButton || withDeleteButton || withEditButton || withDuplicateButton;

    return (
        <>
            <div className="flex flex-col w-full" style={{ gap: `${gap}px` }}>
                {(label || includesActionButton) && (
                    <div
                        className={`flex items-center gap-2 ${
                            label ? 'justify-between' : 'justify-end'
                        }`}
                        style={{ marginBottom: `${labelMarginBottom}px` }}
                    >
                        {label && (
                            <div className="font-body text-body-bold-s text-neutral-500">
                                {showLabelAsBadge ? (
                                    <Badge text={label} color={labelBadgeColor} />
                                ) : (
                                    label
                                )}
                                {isRequired && (
                                    <sup className="text-red-500 leading-1 ml-0.5">*</sup>
                                )}
                            </div>
                        )}
                        {includesActionButton && (
                            <div className="flex items-center gap-1 sm:gap-2">
                                {withCopyButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state={!value ? 'disabled' : 'default'}
                                        icon={FileCopyLineIcon}
                                        onClick={handleCopy}
                                    />
                                )}
                                {withDuplicateButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state="default"
                                        icon={AddToLibraryIcon}
                                        onClick={onDuplicateButtonClick}
                                    />
                                )}
                                {withEditButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state="default"
                                        icon={EditLineIcon}
                                        onClick={onEditButtonClick}
                                    />
                                )}
                                {withDeleteButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state="default"
                                        icon={Trash2Icon}
                                        onClick={onDeleteButtonClick}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}

                {children}

                {state === 'error' && errorMessage && (
                    <div style={{ marginTop: `${bottomSpace}px` }}>
                        <ErrorMessage message={errorMessage} />
                    </div>
                )}

                {tipText && (state !== 'error' || !errorMessage) && (
                    <div
                        className="flex items-center gap-1"
                        style={{ marginTop: `${bottomSpace}px` }}
                    >
                        <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                        <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                    </div>
                )}
            </div>

            {copyStatusAlert.message && (
                <Alert
                    status={copyStatusAlert.status}
                    message={copyStatusAlert.message}
                    icon={
                        copyStatusAlert.status === 'positive' ? CheckLineIcon : ErrorWarningLineIcon
                    }
                    autoCloseInMS={2000}
                    handleClose={() => setCopyStatusAlert({ status: '', message: '' })}
                />
            )}
        </>
    );
}

export default FormFieldWrapper;
