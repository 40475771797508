import React from 'react';
import { DateTime } from 'luxon';
import { threadStatusBadge } from '../../../constants/threads';

import { Badge, SvgIcon } from '../../../design-system';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import DiscussLineIcon from '../../../design-system/Icons/DiscussLineIcon';

const ThreadCard = ({ thread }) => {
    const { name, process, status, auto, updated_at, feedback_count } = thread;

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');
    const statusBadge = threadStatusBadge[status];

    return (
        <div className="w-full rounded-2 bg-white px-4 py-3 flex flex-col min-[915px]:flex-row min-[915px]:items-center gap-x-5 gap-y-2 xl:gap-x-8 justify-between">
            <div className="flex flex-col min-[730px]:flex-row min-[730px]:items-center gap-x-5 gap-y-2 xl:gap-x-8 justify-between w-full min-[915px]:w-[calc(100%-240px)] xl:w-[calc(100%-252px)]">
                <div className="flex flex-col-reverse lg:flex-row lg:items-center gap-y-1 gap-x-5 xl:gap-x-8 w-full min-[730px]:w-[calc(100%-222px)] xl:w-[calc(100%-258px)]">
                    <p className="font-body-bold text-body-bold-s text-black truncate w-full lg:w-[calc((100%-20px)*0.55)] xl:w-[calc((100%-32px)*0.55)]">
                        {name}
                    </p>
                    <div className="w-full lg:w-[calc((100%-20px)*0.45)] xl:w-[calc((100%-32px)*0.45)]">
                        <Badge
                            text={process.name}
                            color="neutral"
                            leadingIcon={PlayCircleFillIcon}
                            leadingIconColor={process.icon_color}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between min-[730px]:justify-end gap-5 xl:gap-8 w-full min-[730px]:w-[202px] xl:w-[226px]">
                    <div className="flex flex-row-reverse justify-end items-center min-[730px]:flex-row min-[730px]:justify-end gap-5 xl:gap-8 w-full min-[730px]:w-[124px] xl:w-[136px]">
                        {feedback_count > 0 && (
                            <div className="flex items-center justify-center w-[24px] min-w-[24px] h-[24px] relative">
                                <SvgIcon color="#1F2125" icon={DiscussLineIcon} size="medium" />
                                <span className="absolute top-[1px] right-[-3px] min-w-[10px] w-fit h-[10px] p-0.5 rounded-full bg-red-500 text-[8px] text-white font-medium font-body inline-flex justify-center items-center">
                                    {feedback_count}
                                </span>
                            </div>
                        )}
                        <div className="w-[80px] min-w-[80px] flex min-[730px]:justify-end">
                            <Badge text={statusBadge.text} color={statusBadge.color} />
                        </div>
                    </div>
                    <p className="font-body text-body-regular-xs text-neutral-500 w-[58px] min-w-[58px]">
                        {auto ? 'Automatic' : 'Manual'}
                    </p>
                </div>
            </div>
            <p className="font-body text-body-regular-xs text-neutral-300 truncate w-full min-[915px]:w-[220px] min-w-[220px] pt-2 border-t-1 border-neutral-200 min-[915px]:pt-0 min-[915px]:border-t-0">
                Last Updated{' '}
                <span className="font-body-bold text-body-bold-xs text-neutral-500">
                    {formattedTime}
                </span>
            </p>
        </div>
    );
};

export default ThreadCard;
