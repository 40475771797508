import React from 'react';
import { ListOption, ListOptionGroup } from '../../../design-system';

const MoreOptionsPopup = ({ isLive, setActionModal, onClose }) => {
    const handleEditClick = () => {
        setActionModal({ opened: true, type: 'editName' });
        onClose();
    };

    const handleToggleTemplateStatusClick = () => {
        setActionModal({ opened: true, type: 'toggleStatus' });
        onClose();
    };

    return (
        <div className="absolute right-0 top-[calc(100%+8px)] z-10">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editLineIcon"
                            text="Edit Name"
                            onClick={handleEditClick}
                        />
                        <ListOption
                            leadingIconName="checkSquareIcon"
                            text={isLive ? 'Mark as Draft' : 'Mark as Live'}
                            onClick={handleToggleTemplateStatusClick}
                        />
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default MoreOptionsPopup;
