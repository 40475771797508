import React, { forwardRef } from 'react';

import { Badge, ButtonIcon, Select, SvgIcon } from '../../../design-system';
import LinkIcon from '../../../design-system/Icons/LinkIcon';
import { DeleteBin4LineIcon, DraggableIcon } from '../../../design-system/Icons';

const StepCard = forwardRef(function DragAndDropTarget(
    {
        item,
        step,
        isDragging,
        handlerId,
        referenceOptions,
        handleReferenceChange,
        reference,
        handleCardDelete,
    },
    ref
) {
    const opacityStyle = isDragging ? 'opacity-0' : 'opacity-100';
    const selectOptions = referenceOptions.map((item) => {
        const { type, ...option } = item;
        return { ...option, badgeColor: type === 'input' ? 'peach' : 'blue' };
    });

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            className={`flex items-start gap-1.5 transform translate-x-[-14px] ${opacityStyle} pr-[6px]`}
        >
            <div className="cursor-grab">
                <ButtonIcon type="neutral" size="xs" icon={DraggableIcon} cursor="grab" />
            </div>
            <div className={`w-full p-[16px] bg-white rounded-2 border-1 border-neutral-200`}>
                <div className="flex gap-2 items-center justify-between mb-2">
                    <p className="font-body text-body-bold-m text-neutral-500 w-[calc(100%-38px)]">
                        {item.name}
                    </p>
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={() => handleCardDelete(item.id, item.step)}
                    />
                </div>

                <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2 mb-[8px]">
                    {item.description}
                </p>
                {!!item.inputs.length &&
                    <div className="flex flex-wrap items-center gap-[4px] mb-[8px]">
                        <p className="font-body text-body-regular-xs">Requires</p>
                        {item.inputs.map((input) => (
                            <Badge key={input.id} text={input.label} color="peach" />
                        ))}
                    </div>
                }
                <div className="flex flex-wrap items-center gap-[4px]">
                    <p className="font-body text-body-regular-xs">Output</p>
                    {item.outputs.map((output) => (
                        <Badge key={output.id} text={output.label} color="blue" />
                    ))}
                </div>
                {step !== 1 && !!item.inputs.length && (
                    <div className="mt-[16px]">
                        <div className="flex items-center gap-[56px] mb-[6px]">
                            <p className="w-[calc((100%-56px)/2)] font-body text-body-bold-xs text-neutral-300">
                                Map Inputs
                            </p>
                            <p className="w-[calc((100%-56px)/2)] font-body text-body-bold-xs text-neutral-300">
                                Reference Link
                            </p>
                        </div>

                        <div className="flex flex-col gap-[16px]">
                            {item.inputs.map((input) => (
                                <div key={input.id} className="flex items-center gap-[8px]">
                                    <div className="w-[calc((100%-56px)/2)] h-[40px] px-[8px] rounded-2 shadow-inset-1 shadow-neutral-300 flex items-center">
                                        <Badge text={input.label} color="peach" />
                                    </div>
                                    <div className="w-[40px] h-[40px] flex justify-center items-center">
                                        <SvgIcon color="#754DCF" size="medium" icon={LinkIcon} />
                                    </div>
                                    <div className="w-[calc((100%-56px)/2)]">
                                        <Select
                                            size="xs"
                                            onChange={(refId) =>
                                                handleReferenceChange(refId, input.id, item.id)
                                            }
                                            name="output"
                                            options={selectOptions}
                                            value={reference[input.id]}
                                            placeholder=""
                                            showAsBadge
                                            showOptionsAsBadge
                                            badgeColor="blue"
                                            placeholder="Select a reference"
                                            includeRemoveAction
                                            handleRemoveSelectedValue={() =>
                                                handleReferenceChange('', input.id, item.id)
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default StepCard;
