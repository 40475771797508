import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../services/report-api';

import { jobStatusFilterOptions } from '../../constants/jobs';
import { SORT_TYPES } from '../../constants/sort';
import { parseJobsSearchParams } from '../../helpers/parseJobsSearchParams';
import { getJobsRequestQueryParams } from '../../helpers/getJobsRequestQueryParams';
import { mergeNewDataIntoCurrent } from '../../helpers/mergeNewDataIntoCurrent';

import { useWindowSize } from '../../hooks/useWindowSize';
import useCustomPagination from '../../hooks/useCustomPagination';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useSwrPageRequestKeysManagingWithContextSaving } from '../../hooks/useSwrPageRequestKeysManagingWithContextSaving';

import JobsPageTopControls from './JobsPageTopControls/JobsPageTopControls';
import JobsDesktopPaginatedContainer from './JobsDesktopPaginatedContainer/JobsDesktopPaginatedContainer';
import JobsMobilePaginatedContainer from './JobsMobilePaginatedContainer/JobsMobilePaginatedContainer';
import JobsEmptyState from './JobsEmptyState/JobsEmptyState';

const JobsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseJobsSearchParams(searchParams), [searchParams]);

    const [page, setPage] = useState(parsedParams.page || 1);
    const [shouldSetNewData, setShouldSetNewData] = useState(false); // this state is for mobile version (true when we should not merge new data but replace existing data)

    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 640;

    useDocumentTitle('Jobs');

    const requestSearchParams = useMemo(
        () => getJobsRequestQueryParams({ parsedSearchParams: parsedParams }),
        [parsedParams]
    );

    const jobsHookResponse = useCustomPagination({
        pageIndex: page - 1,
        searchParams: requestSearchParams,
        client: client,
        route: API.ROUTES.report.job,
    });
    const { data, total, key, nextPageKey } = jobsHookResponse;

    // save request keys in Context
    useSwrPageRequestKeysManagingWithContextSaving({
        pageAccessor: 'jobs',
        key,
        nextPageKey,
    });

    useEffect(() => {
        const sort = Object.values(SORT_TYPES).includes(parsedParams.sort)
            ? parsedParams.sort
            : SORT_TYPES.created;

        if (parsedParams.sort !== sort) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('sort', sort);

            setSearchParams(urlSearchParams, { replace: true });
        }
    }, [parsedParams.sort, setSearchParams]);

    useEffect(() => {
        if (!parsedParams.sort) {
            return;
        }

        // update page state after user used pagination on Desktop Version or after page reload on Desktop Version
        if (parsedParams.page && page !== parsedParams.page) {
            setPage(parsedParams.page);
        }

        // if it is Mobile version remove page from search params
        if (isMobile && parsedParams.page) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.delete('page');

            setSearchParams(urlSearchParams, { replace: true });
        }

        // if it is Desktop version and there is no page in search params
        if (!isMobile && !parsedParams.page) {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('page', page || 1);

            setSearchParams(urlSearchParams, { replace: true });
        }
    }, [isMobile, parsedParams.page, parsedParams.sort]);

    useEffect(() => {
        // if there is an invalid status in search params remove it
        if (parsedParams.status) {
            const isStatusValid = jobStatusFilterOptions.includes(parsedParams.status);
            if (!isStatusValid) {
                const urlSearchParams = new URLSearchParams(searchParams);
                urlSearchParams.delete('status');

                setSearchParams(urlSearchParams, { replace: true });
            }
        }
    }, [parsedParams.status]);

    const [jobs, setJobs] = useState([]); // state containing all loaded job results, used for displaying jobs on the JobsMobilePaginatedContainer component

    // manage jobs state displayed on the JobsMobilePaginatedContainer
    useEffect(() => {
        setJobs((prevResults) => {
            if (data === null && !shouldSetNewData) {
                return prevResults;
            }

            // clear jobs when user submit search in SearchBar, or change sorting and new data is loading
            if (shouldSetNewData && data === null) {
                return [];
            }

            // when user submit search in SearchBar, or change sorting or filter, we don't have to merge new Data into current, but we should replace them
            if (shouldSetNewData && data !== null) {
                setShouldSetNewData(false);
                return data;
            }

            return mergeNewDataIntoCurrent(prevResults || [], data || []); // when user click show more
        });
    }, [data]);

    return (
        <div className="page-position bg-neutral-50 px-4 xs:px-5 pt-4 sm:px-8 sm:pt-8 pb-[40px] overflow-y-auto flex flex-col gap-5">
            <div>
                <h1 className="font-heading-bold text-heading-bold-l text-black">Jobs</h1>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    A comprehensive record of jobs in progress and completed for your business.
                </p>
            </div>

            <JobsPageTopControls
                isMobile={isMobile}
                setPage={setPage}
                setShouldSetNewData={setShouldSetNewData}
                totalJobs={total}
            />

            <JobsEmptyState jobsHookResponse={jobsHookResponse} parsedParams={parsedParams} />

            {!isMobile && (
                <JobsDesktopPaginatedContainer
                    jobsHookResponse={jobsHookResponse}
                    pageIndex={page - 1}
                />
            )}

            <JobsMobilePaginatedContainer
                jobs={jobs}
                jobsHookResponse={jobsHookResponse}
                setPage={setPage}
                isMobile={isMobile}
            />
        </div>
    );
};

export default JobsPage;
