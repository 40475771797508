import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import client from '../../../services/template-api';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { isValidJson } from '../../../helpers/isValidJson';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import {
    defaultBackLinkHref,
    successStatusChangeMessages,
    TEMPLATE_TYPES,
    templateAPIRoutes,
    versionAPIRoutes,
} from '../../../constants/template';

import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import TemplateActionBar from './TemplateActionBar/TemplateActionBar';
import Loading from '../../../components/Loading';
import TemplateDetailsBlock from './TemplateDetailsBlock/TemplateDetailsBlock';
import BranchSelectionBlock from './BranchSelectionBlock/BranchSelectionBlock';
import TemplateMainDetailBlock from './TemplateMainDetailBlock/TemplateMainDetailBlock';
import TemplateVersionDetailBlock from './TemplateVersionDetailBlock/TemplateVersionDetailBlock';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import CancelIssueConfirmationModal from './CancelIssueConfirmationModal/CancelIssueConfirmationModal';
import GoalTemplatesSidePanel from './GoalTemplatesSidePanel/GoalTemplatesSidePanel';
import PageHeader from './PageHeader/PageHeader';
import ProcessVersionPublishingModal from '../ProcessVersionPublishingModal/ProcessVersionPublishingModal';
import ConfirmGoalTemplateDeletionModal from './ConfirmGoalTemplateDeletionModal/ConfirmGoalTemplateDeletionModal';

const editableVersionStatuses = ['open', 'changes_requested', 'ready_for_review'];

const TemplateDetailPage = () => {
    const params = useParams();
    const goalTemplateId = Number(params.goalTemplateId);
    const processTemplateId = Number(params.processTemplateId);
    const versionId = Number(params.versionId);

    const navigate = useNavigate();
    const location = useLocation();

    const templateType = location.pathname.includes('/templates/goal/')
        ? TEMPLATE_TYPES.goal
        : TEMPLATE_TYPES.process;
    const templateId = templateType === TEMPLATE_TYPES.goal ? goalTemplateId : processTemplateId;
    const isMainBranch = !versionId;

    const backLinkHref = location.state?.from ?? defaultBackLinkHref[templateType];

    const [templateData, setTemplateData] = useState(null);
    const [templateVersionData, setTemplateVersionData] = useState(null);

    const [issueDetailsExpanded, setIssueDetailsExpanded] = useState(false);

    const isEditAccess =
        templateVersionData && editableVersionStatuses.includes(templateVersionData.status);

    // the data user can change viewing not main branch with status "open" or "changes_requested" or "ready_for_review"
    const [versionChangeableData, setVersionChangeableData] = useState({
        name: '', // issue title
        description: '', // issue description
        file_content: '',
    });
    const [shouldRefreshTemplateFile, setShouldRefreshTemplateFile] = useState(false); // after status is changed the template file should be refreshed
    const [versionInputsErrorMessages, setVersionInputsErrorMessages] = useState({});
    const [changedVersionFields, setChangedVersionFields] = useState([]);
    const [saveChangesLoading, setSaveChangesLoading] = useState(false);
    const [changeStatusLoading, setChangeStatusLoading] = useState({
        ready_for_review: false,
        canceled: false,
    });
    const [confirmCancelIssueModalOpened, setConfirmCancelIssueModalOpened] = useState(false);
    const [isMergeMainLoading, setIsMergeMainLoading] = useState(false);
    const [isProcessVersionPublishingModalOpened, setIsProcessVersionPublishingModalOpened] =
        useState(false);

    const [confirmTemplateDeletionModalOpened, setConfirmTemplateDeletionModalOpened] =
        useState(false);

    const [errorAlert, setErrorAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    const [headerHeight, setHeaderHeight] = useState(0);
    const { width: screenWidth } = useWindowSize();

    const [controller, setController] = useState(new AbortController());

    const currentVersionStatus = useMemo(() => {
        const status = templateData?.versions?.find((version) => version.id === versionId)?.status;
        return status || 'main';
    }, [templateData?.versions, versionId]);

    const isGoalTemplatesSidePanelDisplayed =
        templateType === TEMPLATE_TYPES.process &&
        editableVersionStatuses.includes(currentVersionStatus) &&
        screenWidth >= 1024;

    useDocumentTitle(templateData?.name);

    useEffect(() => {
        const fetchTemplateData = async () => {
            try {
                const { data } = await client.get(
                    `${templateAPIRoutes[templateType]}${templateId}/`
                );
                setTemplateData(data);
            } catch (e) {
                navigate(backLinkHref);
            }
        };

        fetchTemplateData();
    }, []);

    useEffect(() => {
        const fetchTemplateVersionData = async () => {
            try {
                resetVersionState();

                const newController = new AbortController();
                setController(newController);

                const { data } = await client.get(
                    `${versionAPIRoutes[templateType]}${versionId}/`,
                    { signal: newController.signal }
                );
                setTemplateVersionData(data);
                setVersionChangeableData({
                    name: data.name,
                    description: data.description,
                    file_content: data.file_content,
                });
            } catch (e) {
                if (e.message === 'canceled') {
                    return;
                }
                navigate(`/templates/${templateType}/${templateId}`);
            }
        };

        if (versionId) {
            fetchTemplateVersionData();
        }

        if (!versionId) {
            resetVersionState();
        }
    }, [versionId, templateType]);

    const resetVersionState = () => {
        controller.abort();
        setTemplateVersionData(null);
        setVersionChangeableData({});
        setChangedVersionFields([]);
        setVersionInputsErrorMessages({});
    };

    const isSaveChangesRequestBodyError = () => {
        if (!changedVersionFields?.length) {
            return true; // there is no data to save, so we don't have to make a request
        }

        // don't allow user to send empty fields
        const emptyFields = changedVersionFields.filter((field) => !versionChangeableData[field]);
        if (!!emptyFields.length) {
            // if there are error fields in Issue Details Block expand it so user can see error messages
            if (emptyFields.includes('name') || emptyFields.includes('description')) {
                setIssueDetailsExpanded(true);
            }
            const errorMessages = emptyFields.reduce(
                (acc, field) => ({ ...acc, [field]: 'Please fill in this field.' }),
                {}
            );
            setVersionInputsErrorMessages((prevMessages) => ({
                ...prevMessages,
                ...errorMessages,
            }));
            return true; // it means that there is an error, so we don't have to make a request
        }

        // validate file content JSON format if it's changed
        let isFileContentJsonValid = true;
        if (changedVersionFields.includes('file_content')) {
            isFileContentJsonValid = isValidJson(versionChangeableData.file_content);
            if (!isFileContentJsonValid) {
                setVersionInputsErrorMessages((prevMessages) => ({
                    ...prevMessages,
                    file_content: 'Invalid JSON format. Please check your syntax.',
                }));
                return true; // it means that there is an error, so we don't have to make a request
            }
        }

        return false; // it means there are no errors
    };

    const getSaveChangesRequestBody = () => {
        // this function constructs the requestBody. Only the modified fields are sent, but 'name' and 'description' are sent together (meaning if only "name" is edited, "description" will also be sent in the request)
        const fieldsForRequestBody =
            changedVersionFields?.includes('name') || changedVersionFields?.includes('description')
                ? [...new Set([...changedVersionFields, 'name', 'description'])]
                : changedVersionFields;

        const result = fieldsForRequestBody?.reduce(
            (acc, field) => ({
                ...acc,
                [field]:
                    field === 'file_content'
                        ? JSON.parse(versionChangeableData.file_content)
                        : versionChangeableData[field],
            }),
            {}
        );

        return result;
    };

    const refreshVersionDataAfterUpdates = (updatedData) => {
        // refresh version changeable data
        setVersionChangeableData({
            name: updatedData.name,
            description: updatedData.description,
            file_content: updatedData.file_content,
        });
        // update version state
        setTemplateVersionData(updatedData);

        setShouldRefreshTemplateFile(true);
    };

    const handleSaveChanges = async ({ shouldErrorBeThrown }) => {
        // this function save changeable goal request version data
        // the shouldErrorBeThrown parameter indicates that if the request fails, an error will be thrown to stop the wrapper function from running
        setVersionInputsErrorMessages({});
        const isDataToSaveError = isSaveChangesRequestBodyError();
        if (isDataToSaveError) {
            // stop execution this function and throw error to catch it in the wrapper function
            if (shouldErrorBeThrown) {
                throw new Error('save changes error');
            }
            return;
        }

        try {
            // do not set is Loading if handleSaveChanges is just a part of another function
            !shouldErrorBeThrown && setSaveChangesLoading(true);

            const requestBody = getSaveChangesRequestBody();
            const { data } = await client.patch(
                `${versionAPIRoutes[templateType]}${versionId}/`,
                requestBody
            );
            // reset Saved Data State
            setChangedVersionFields([]);
            refreshVersionDataAfterUpdates(data);

            setSaveChangesLoading(false);
        } catch (e) {
            const errorMessage = e.response?.data?.error;
            if (errorMessage) {
                if (errorMessage.includes('You cannot edit this version.')) {
                    // If the error message pertains to 'You cannot edit this version.', then display a general error alert
                    setErrorAlert({ message: errorMessage });
                } else {
                    // Otherwise, display the error message under the JSON Editor.
                    setVersionInputsErrorMessages((prevMessages) => ({
                        ...prevMessages,
                        file_content: errorMessage,
                    }));
                    setErrorAlert({
                        message: defaultErrorMessage,
                        statusCode: e.response?.statusCode,
                    });
                }
            } else {
                setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
            }
            setSaveChangesLoading(false);
            if (shouldErrorBeThrown) {
                // throw error to catch it in the wrapper function
                throw new Error('save changes error');
            }
        }
    };

    const handleStatusChange = async ({ status }) => {
        try {
            setChangeStatusLoading({ [status]: true });
            // save changes if there are
            if (!!changedVersionFields?.length) {
                await handleSaveChanges({ shouldErrorBeThrown: true });
            }

            const { data } = await client.patch(`${versionAPIRoutes[templateType]}${versionId}/`, {
                status,
            });

            if (status === 'canceled' && templateVersionData?.type === 'init') {
                // navigate to Templates Library
                navigate(backLinkHref);
                return;
            }

            setSuccessAlert({ message: successStatusChangeMessages[status] });
            refreshVersionDataAfterUpdates(data);

            // update status badge on Branch Select
            setTemplateData((prevData) => ({
                ...prevData,
                versions: prevData.versions?.map((version) =>
                    version.id === versionId ? { ...version, status } : version
                ),
            }));

            setChangeStatusLoading({ [status]: false });
        } catch (e) {
            if (e.message === 'save changes error') {
                // do nothing, this error already shown in handleSaveChanges()
            } else {
                const errorMessage = e.response?.data?.error;
                setErrorAlert({
                    message: errorMessage || defaultErrorMessage,
                    status: e.response?.statusCode,
                });
            }
            setChangeStatusLoading({ [status]: false });
        }
    };

    const handleMergeMain = async () => {
        try {
            setIsMergeMainLoading(true);
            // save changes if there are
            if (!!changedVersionFields?.length) {
                await handleSaveChanges({ shouldErrorBeThrown: true });
            }

            const { data } = await client.patch(`${versionAPIRoutes[templateType]}${versionId}/`, {
                merge_main: true,
            });

            setSuccessAlert({
                message: `Successfully merged main into ${templateVersionData.github_branch_name}`,
            });
            refreshVersionDataAfterUpdates(data);

            setIsMergeMainLoading(false);
        } catch (e) {
            if (e.message === 'save changes error') {
                // do nothing, this error already shown in handleSaveChanges()
            } else {
                const errorMessage = e.response?.data?.error;
                setErrorAlert({
                    message: errorMessage || defaultErrorMessage,
                    status: e.response?.statusCode,
                });
            }
            setIsMergeMainLoading(false);
        }
    };

    const templateContentLayoutStyles = classNames('flex justify-center', {
        'h-full bg-neutral-50 p-8 w-[calc(100%-320px)] max-w-[calc(100%-320px)]':
            isGoalTemplatesSidePanelDisplayed,
        'w-full max-w-full pt-4 sm:pt-6 px-3 pb-[66px]': !isGoalTemplatesSidePanelDisplayed,
    });

    const templateContentContainerStyles = classNames('w-full flex justify-center', {
        'overflow-y-auto px-6 pt-6 pb-[40px] bg-white hide-scrollbar':
            isGoalTemplatesSidePanelDisplayed,
    });

    return (
        <div
            className={classNames(
                'fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white flex flex-col hide-scrollbar',
                {
                    'overflow-y-auto': !isGoalTemplatesSidePanelDisplayed,
                    'overflow-hidden': isGoalTemplatesSidePanelDisplayed,
                }
            )}
        >
            {templateData && (
                <>
                    <PageHeader
                        templateType={templateType}
                        templateData={templateData}
                        backLinkHref={backLinkHref}
                        setHeaderHeight={setHeaderHeight}
                        setConfirmTemplateDeletionModalOpened={
                            setConfirmTemplateDeletionModalOpened
                        }
                    />
                    <div
                        className="w-full max-w-full flex-grow flex"
                        style={{
                            height: isGoalTemplatesSidePanelDisplayed
                                ? `calc(100vh - ${headerHeight}px)`
                                : 'auto',
                        }}
                    >
                        {isGoalTemplatesSidePanelDisplayed && (
                            <GoalTemplatesSidePanel
                                processTemplateVersionGoals={templateVersionData?.goals || []}
                            />
                        )}
                        <div className={templateContentLayoutStyles}>
                            <div className={templateContentContainerStyles}>
                                <div
                                    className="w-full px-4 flex flex-col gap-5 bg-white"
                                    style={{ maxWidth: '900px' }}
                                >
                                    <TemplateDetailsBlock
                                        templateType={templateType}
                                        templateData={templateData}
                                        editable={isMainBranch} // editable just for main branch
                                        setTemplateData={setTemplateData}
                                    />
                                    <BranchSelectionBlock
                                        templateType={templateType}
                                        templateId={templateId}
                                        versions={templateData.versions}
                                        isLive={templateData.is_live}
                                        selectedVersionId={versionId}
                                        backLinkHref={backLinkHref}
                                        isEditAccess={isEditAccess}
                                        setTemplateVersionData={setTemplateVersionData}
                                        handleMergeMain={handleMergeMain}
                                        isMergeMainLoading={isMergeMainLoading}
                                    />

                                    {isMainBranch && (
                                        <TemplateMainDetailBlock templateData={templateData} />
                                    )}

                                    {!isMainBranch &&
                                        (templateVersionData ? (
                                            <TemplateVersionDetailBlock
                                                isEditAccess={isEditAccess}
                                                templateVersionData={templateVersionData}
                                                versionChangeableData={versionChangeableData}
                                                setVersionChangeableData={setVersionChangeableData}
                                                setChangedVersionFields={setChangedVersionFields}
                                                versionInputsErrorMessages={
                                                    versionInputsErrorMessages
                                                }
                                                setVersionInputsErrorMessages={
                                                    setVersionInputsErrorMessages
                                                }
                                                issueDetailsExpanded={issueDetailsExpanded}
                                                setIssueDetailsExpanded={setIssueDetailsExpanded}
                                                shouldRefreshTemplateFile={
                                                    shouldRefreshTemplateFile
                                                }
                                                setShouldRefreshTemplateFile={
                                                    setShouldRefreshTemplateFile
                                                }
                                                isGoalTemplatesSidePanelDisplayed={
                                                    isGoalTemplatesSidePanelDisplayed
                                                }
                                            />
                                        ) : (
                                            <div className="pt-4">
                                                <Loading withText={false} />
                                            </div>
                                        ))}

                                    <TemplateActionBar
                                        templateType={templateType}
                                        isLive={templateData.is_live}
                                        isMainBranch={isMainBranch}
                                        isEditAccess={isEditAccess}
                                        branchStatus={templateVersionData?.status}
                                        isLoadingBranchChange={
                                            !!(versionId && !templateVersionData)
                                        }
                                        areChangesToSave={!!changedVersionFields.length}
                                        handleSaveChanges={handleSaveChanges}
                                        saveChangesLoading={saveChangesLoading}
                                        handleStatusChange={handleStatusChange}
                                        changeStatusLoading={changeStatusLoading}
                                        setConfirmCancelIssueModalOpened={
                                            setConfirmCancelIssueModalOpened
                                        }
                                        setIsProcessVersionPublishingModalOpened={
                                            setIsProcessVersionPublishingModalOpened
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!templateData && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
            {confirmCancelIssueModalOpened && (
                <CancelIssueConfirmationModal
                    handleStatusChange={handleStatusChange}
                    issueType={templateVersionData.type}
                    onClose={() => setConfirmCancelIssueModalOpened(false)}
                />
            )}
            {confirmTemplateDeletionModalOpened && (
                <ConfirmGoalTemplateDeletionModal
                    goalTemplateId={templateId}
                    goalTemplateName={templateData.name}
                    navigateToLibraryOnSuccess
                    backLinkHref={backLinkHref}
                    onClose={() => setConfirmTemplateDeletionModalOpened(false)}
                />
            )}
            {isProcessVersionPublishingModalOpened && (
                <ProcessVersionPublishingModal
                    versionData={templateVersionData}
                    setProcessTemplateData={setTemplateData}
                    setTemplateVersionData={setTemplateVersionData}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => setIsProcessVersionPublishingModalOpened(false)}
                />
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message || defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={6000}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
            {successAlert && (
                <Alert
                    status="positive"
                    message={successAlert.message}
                    icon={CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setSuccessAlert(null)}
                />
            )}
        </div>
    );
};

export default TemplateDetailPage;
