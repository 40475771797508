import React, { useState } from 'react';
import * as API from '../../../constants/api';
import client from '../../../services/knowledge-api';

import Modal from '../../../design-system/Modal/Modal';
import { Button } from '../../../design-system';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { useNavigate } from 'react-router-dom';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const DeleteBaseModal = ({
    onClose,
    baseName,
    baseId,
    setBases = () => {},
    navigateToBasePage = false,
}) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const handleDeleteBase = async () => {
        try {
            setIsLoading(true);
            await client.delete(`${API.ROUTES.knowledge.base}${baseId}/`);
            setBases((prevBases) => prevBases.filter((item) => item.id !== baseId));
            setIsLoading(false);
            onClose();
            if (navigateToBasePage) {
                navigate('/base');
            }
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ show: true, statusCode: e.response.status });
        }
    };

    return (
        <Modal size="extraSmall" onClose={onClose}>
            <p className="font-heading-bold text-heading-bold-m text-black py-2 mb-4">
                Delete Base
            </p>
            <p className="mb-8 font-body text-body-regular-m text-neutral-500">
                Are you sure you want to delete{' '}
                <span className="font-body-bold text-body-bold-m">{baseName}</span> base? This will
                delete all entries in the base and is not reversible.
            </p>
            <div className="flex items-center justify-between">
                <Button type="neutral" size="sm" text="Cancel" onClick={onClose} />
                <Button
                    type="secondary"
                    size="sm"
                    text="Yes, Delete"
                    state={isLoading ? 'loading' : 'default'}
                    onClick={handleDeleteBase}
                />
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </Modal>
    );
};

export default DeleteBaseModal;
