import React, { useState } from 'react';
import client from '../../../services/user-api';
import { API } from 'constants';

import Input from '../../../design-system/Input/Input';
import { Button, SvgIcon } from '../../../design-system';
import UpdateProfilePictureModal from '../UpdateProfilePictureModal/UpdateProfilePictureModal';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import { defaultErrorMessage } from '../../../constants/errorMessages';

const UserSettingsBlock = ({ user }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        first_name: user.first_name,
        last_name: user.last_name,
        profile_image: user.settings?.profile_image,
    });
    const [isLoading, setIsLoading] = useState({ first_name: false, last_name: false });
    const [isSaved, setIsSaved] = useState({ first_name: false, last_name: false });
    const [errorAlert, setErrorAlert] = useState({ show: false, message: '', statusCode: null });

    const handleInputChange = (e) => {
        setUserData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    const handleDataSave = async (name) => {
        try {
            setIsSaved((prevState) => ({ ...prevState, [name]: false }));
            setIsLoading((prevState) => ({ ...prevState, [name]: true }));
            const requestBody = {
                [name]: userData[name],
            };

            const authToken = localStorage.getItem('authKey');
            if (!authToken || !authToken.length) {
                throw new Error('User not logged in.'); // TODO: handle more gracefully
            }
            const options = {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            };

            await client.patch(`${API.ROUTES.user.userDetails}`, requestBody, options);

            setIsLoading((prevState) => ({ ...prevState, [name]: false }));
            setIsSaved((prevState) => ({ ...prevState, [name]: true }));
            setTimeout(() => {
                setIsSaved((prevState) => ({ ...prevState, [name]: false }));
            }, 1500);
        } catch (e) {
            setErrorAlert({
                show: true,
                message: defaultErrorMessage,
                statusCode: e.response.status,
            });
            setIsLoading((prevState) => ({ ...prevState, [name]: false }));
            setUserData((prevData) => ({ ...prevData, [name]: user[name] }));
        }
    };

    const [isUpdatePictureModalOpened, setIsUpdatePictureModalOpened] = useState(false);

    return (
        <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md w-full bg-white flex gap-x-2 gap-y-6 flex-wrap">
            <div className="flex flex-col space-y-5 w-full max-w-[444px]">
                <div className="font-body-bold text-body-bold-m">User Settings</div>
                <div className="w-[90%] relative">
                    <div className="w-full">
                        <Input
                            label="First Name"
                            name="first_name"
                            value={userData.first_name}
                            placeholder="Not yet Entered"
                            size="xs"
                            onChange={handleInputChange}
                            onBlur={handleDataSave}
                        />
                    </div>
                    {isLoading.first_name && (
                        <div className="absolute top-[27px] left-[calc(100%+5px)]">
                            <Loading withText={false} />
                        </div>
                    )}
                    {isSaved.first_name && (
                        <div className="absolute top-[30px] left-[calc(100%+5px)]">
                            <SvgIcon color="#79AC78" icon={CheckLineIcon} size="large" />
                        </div>
                    )}
                </div>
                <div className="w-[90%] relative">
                    <div className="w-full">
                        <Input
                            label="Last name"
                            name="last_name"
                            value={userData.last_name}
                            placeholder="Not yet Entered"
                            size="xs"
                            onChange={handleInputChange}
                            onBlur={handleDataSave}
                        />
                    </div>
                    {isLoading.last_name && (
                        <div className="absolute top-[27px] left-[calc(100%+5px)]">
                            <Loading withText={false} />
                        </div>
                    )}
                    {isSaved.last_name && (
                        <div className="absolute top-[30px] left-[calc(100%+5px)]">
                            <SvgIcon color="#79AC78" icon={CheckLineIcon} size="large" />
                        </div>
                    )}
                </div>
                <div className="w-[90%]">
                    <Input label="Email" value={user.email} state="disabled" size="xs" />
                </div>
                <div className="w-full max-w-[400px]">
                    <Button
                        type="primary"
                        text="Update Your Password"
                        size="sm"
                        onClick={() => navigate('/password-reset')}
                    />
                </div>
            </div>
            {/* removed for Kristin to fix */}
            {/* <div className="flex flex-col gap-5">
                <p className="font-body text-body-bold-m text-neutral-500">Profile Picture</p>
                <div className="w-[100px] h-[100px] rounded-[50%] overflow-hidden bg-purple-500 flex items-center justify-center">
                    {userData.profile_image ? (
                        <img
                            src={userData.profile_image}
                            alt={`Profile image of ${user.username}`}
                            className="object-contain"
                        />
                    ) : (
                        <div className="w-full h-full font-body text-[50px] text-white flex items-center justify-center uppercase">
                            {user.email[0]}
                        </div>
                    )}
                </div>
                <Button
                    type="primary"
                    size="sm"
                    text="Update"
                    onClick={() => setIsUpdatePictureModalOpened(true)}
                />
            </div> */}
            {isUpdatePictureModalOpened && (
                <UpdateProfilePictureModal
                    onClose={() => setIsUpdatePictureModalOpened(false)}
                    setUserData={setUserData}
                />
            )}
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    handleClose={() =>
                        setErrorAlert({ show: false, message: '', statusCode: null })
                    }
                    autoCloseInMS={3000}
                />
            )}
        </div>
    );
};

export default UserSettingsBlock;
