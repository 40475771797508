import React, { useState } from 'react';
import { Button, Input, Badge } from 'design-system';

function TaskForm({ onSave, onCancel, taskObject, workflowId }) {
    const [taskName, setTaskName] = useState(taskObject ? taskObject.task_name : '');
    const [taskDescription, setTaskDescription] = useState(
        taskObject ? taskObject.task_description : ''
    );
    const [inputs, setInputs] = useState(
        taskObject ? taskObject.task_inputs.map((input) => input.key).join(', ') : ''
    );
    const [outputs, setOutputs] = useState(
        taskObject ? taskObject.task_outputs.map((output) => output.key).join(', ') : ''
    );

    const inputBadges = inputs ? inputs.split(',').map((item) => item.trim()) : [];
    const outputBadges = outputs ? outputs.split(',').map((item) => item.trim()) : [];

    const handleSave = () => {
        // You can modify the logic inside this function to suit your needs
        const taskInputs = inputs.split(',').map((key) => ({
            key: key.trim(),
            value: '',
            type: 'str',
            name: key
                .trim()
                .replace(/-/g, ' ')
                .replace(/\b\w/g, (l) => l.toUpperCase()),
        }));
        const taskOutputs = outputs.split(',').map((key) => ({
            key: key.trim(),
            value: '',
            type: 'str',
            name: key
                .trim()
                .replace(/-/g, ' ')
                .replace(/\b\w/g, (l) => l.toUpperCase()),
        }));
        const data = {
            goal_id: workflowId,
            name: taskName,
            description: taskDescription,
            inputs: taskInputs,
            outputs: taskOutputs,
        };
        onSave(data);
    };

    return (
        <div className="flex flex-col space-y-4 py-8">
            <div className="font-body-bold text-body-bold-l">
                {taskObject ? 'Edit the task details' : 'Add a task to this workflow'}
            </div>
            <Input
                type="text"
                value={taskName}
                placeholder="Enter task name here"
                size="sm"
                label={taskObject ? 'Edit task name' : 'Give your task a name'}
                onChange={(e) => setTaskName(e.target.value)}
            />
            <Input
                type="text"
                value={taskDescription}
                placeholder="Enter task description here"
                size="sm"
                label={taskObject ? 'Edit task description' : 'Give your task a description'}
                onChange={(e) => setTaskDescription(e.target.value)}
            />
            <Input
                type="text"
                value={inputs}
                placeholder="Enter task inputs here"
                size="sm"
                label="Add your task inputs"
                onChange={(e) => setInputs(e.target.value)}
            />
            <div className="space-x-2">
                {inputs.length > 0 &&
                    inputBadges.map((badge, index) => (
                        <span key={index} className="badge">
                            <Badge text={badge} color="peach" />
                        </span>
                    ))}
            </div>
            <Input
                type="text"
                value={outputs}
                placeholder="Enter task output here"
                size="sm"
                label="Add your task output"
                onChange={(e) => setOutputs(e.target.value)}
            />
            <div className="space-x-2">
                {outputs.length > 0 &&
                    outputBadges.map((badge, index) => (
                        <span key={index} className="badge">
                            <Badge text={badge} color="peach" />
                        </span>
                    ))}
            </div>
            <div className="flex flex-row space-x-2 justify-end">
                <Button type="ghost" size="sm" text="Cancel" onClick={onCancel} />
                <Button
                    type="secondary"
                    size="sm"
                    text={taskObject ? 'Save Task' : 'Create Task'}
                    onClick={handleSave}
                />
            </div>
        </div>
    );
}

export default TaskForm;
