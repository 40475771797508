import React from 'react';
import { Button } from '../../../design-system';
import ChainStepSwitcher from '../ChainStepSwitcher/ChainStepSwitcher';
import { CloseCircleLineIcon, FlashlightFillIcon } from '../../../design-system/Icons';
import ArrowRightIcon from '../../../design-system/Icons/ArrowRightIcon';
import { useNavigate } from 'react-router-dom';

const ChainActionBar = ({
    workerDetails,
    stepState,
    onRunPrompt,
    onCancelStream,
    handleNextPromptClick,
    handleSavePrompt,
    arePromptChanges,
    isRunLoading,
    isRunning,
    currentStepData,
    handleStepSelect,
    currentStepOrderFromDB,
    isLoading,
    isOutdated,
}) => {
    const navigate = useNavigate();
    const handleBackToIndexClick = async () => {
        await handleNextPromptClick();
        navigate('/runs');
    };
    return (
        <div className="fixed bottom-0 left-0 sm:left-[68px] right-0 bg-white flex justify-between items-center flex-col md:flex-row px-[20px] lg:px-[32px] py-[8px] h-[110px] md:h-[64px]">
            <ChainStepSwitcher
                workerDetails={workerDetails}
                currentStepData={currentStepData}
                handleStepSelect={handleStepSelect}
            />
            <div className="px-2 flex items-center gap-1 md:gap-[10px]">
                {stepState.isPromptRun ? (
                    <>
                        <Button
                            type="secondary"
                            size="sm"
                            text={
                                isRunning
                                    ? 'Cancel Stream'
                                    : arePromptChanges
                                    ? 'Save & Re-Run'
                                    : 'Re-Run'
                            }
                            state={isOutdated ? 'disabled' : isRunLoading ? 'loading' : 'default'}
                            leadingIcon={FlashlightFillIcon}
                            onClick={isRunning ? onCancelStream : onRunPrompt}
                        />
                        <Button
                            type="primary"
                            size="sm"
                            text={stepState.lastStep ? 'Back to index' : 'Next Prompt'}
                            state={
                                isOutdated ? 'disabled' : isLoading.nextStep ? 'loading' : 'default'
                            }
                            trailingIcon={ArrowRightIcon}
                            onClick={
                                stepState.lastStep ? handleBackToIndexClick : handleNextPromptClick
                            }
                        />
                    </>
                ) : (
                    <>
                        {currentStepOrderFromDB < currentStepData.step ? (
                            <Button
                                type="primary"
                                size="sm"
                                text={'Save Prompt'}
                                state={
                                    isOutdated
                                        ? 'disabled'
                                        : isLoading.savePrompt
                                        ? 'loading'
                                        : arePromptChanges
                                        ? 'default'
                                        : 'disabled'
                                }
                                onClick={handleSavePrompt}
                            />
                        ) : (
                            <Button
                                type="primary"
                                size="sm"
                                text={
                                    isRunning
                                        ? 'Cancel Stream'
                                        : arePromptChanges
                                        ? 'Save & Run Prompt'
                                        : 'Run Prompt'
                                }
                                leadingIcon={isRunning ? CloseCircleLineIcon : FlashlightFillIcon}
                                state={
                                    isOutdated ? 'disabled' : isRunLoading ? 'loading' : 'default'
                                }
                                onClick={isRunning ? onCancelStream : onRunPrompt}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ChainActionBar;
