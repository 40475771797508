import React, { useEffect, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';
import {
    getStartNewJobFormDataInitialState,
    startNewJob,
} from '../../../helpers/startNewJobFormUtils';

import Loading from '../../../components/Loading';
import Alert from '../../../design-system/Alert/Alert';
import SuccessStartNewJobState from '../../ProcessPage/StartNewJobPage/SuccessStartNewJobState/SuccessStartNewJobState';
import NewJobForm from '../../../components/NewJobForm/NewJobForm';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

const StartNewJobForm = ({
    processId,
    setSelectedProcessId,
    formData,
    setFormData,
    startNewJobState,
    setStartNewJobState,
}) => {
    const [process, setProcess] = useState(null);
    const [newJobId, setNewJobId] = useState(null);

    const [isFormDataLoading, setIsFormDataLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const [controller, setController] = useState(new AbortController()); // state to manage the AbortController instance used for aborting requests

    useEffect(() => {
        const fetchProcessData = async () => {
            try {
                // clear previous process form data
                handleClearProcessData();
                controller.abort(); // cancel the previous request if it's still active

                // create and set the new controller as the active one
                const newController = new AbortController();
                setController(newController);

                setIsFormDataLoading(true);
                const { data } = await client.get(`${API.ROUTES.library.process}${processId}/`, {
                    signal: newController.signal,
                });

                setProcess(data);
                setFormData(getStartNewJobFormDataInitialState(data.inputs));

                setIsFormDataLoading(false);
            } catch (e) {
                if (e?.message === 'canceled') {
                    return;
                }
                //if error occurs clear selected Process and form data
                setSelectedProcessId(null);
                handleClearProcessData();
                setIsFormDataLoading(false);
            }
        };

        if (processId) {
            fetchProcessData();
        }
    }, [processId]);

    const handleClearProcessData = () => {
        setFormData(null);
        setProcess(null);
        setNewJobId(null);
    };

    const handleStartNewJob = async () => {
        await startNewJob({
            formData,
            setFormData,
            processId,
            setNewJobId,
            setStartNewJobState,
            setErrorAlert,
        });
    };

    const onStartFromSameProcess = () => {
        setFormData(getStartNewJobFormDataInitialState(process.inputs));
        setStartNewJobState('default');
        setNewJobId(null);
    };

    const onStartFromNewProcess = () => {
        setSelectedProcessId(null);
        handleClearProcessData();

        setStartNewJobState('default');
    };

    return (
        <>
            {formData && startNewJobState !== 'success' && (
                <div>
                    <div className="pb-5 border-b-1 border-neutral-200 mb-3">
                        <NewJobForm
                            formData={formData}
                            setFormData={setFormData}
                            containerGap={20}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            type="primary"
                            size="sm"
                            text="Start"
                            state={startNewJobState === 'loading' ? 'loading' : 'default'}
                            onClick={handleStartNewJob}
                        />
                    </div>
                </div>
            )}
            {startNewJobState === 'success' && (
                <SuccessStartNewJobState
                    jobId={newJobId}
                    processName={process.name}
                    viewMode="partialScreen"
                    onStartFromSameProcess={onStartFromSameProcess}
                    onStartFromNewProcess={onStartFromNewProcess}
                />
            )}
            {isFormDataLoading && (
                <div>
                    <Loading withText={false} />
                </div>
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default StartNewJobForm;
