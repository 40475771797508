import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickOutside } from '../../hooks';

import ClientSideFilteredSearchBar from '../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import Loading from '../../components/Loading';
import FilteringPopupOption from '../FilteringPopupOption/FilteringPopupOption';

FilteringPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            badgeColor: PropTypes.oneOf([
                'neutral',
                'blue',
                'purple',
                'peach',
                'lime',
                'white',
                'red',
            ]),
            trailingBadge: PropTypes.shape({
                text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
                    .isRequired,
                color: PropTypes.oneOf([
                    'neutral',
                    'blue',
                    'purple',
                    'peach',
                    'lime',
                    'white',
                    'red',
                ]),
            }),
            leadingIcon: PropTypes.func,
            leadingIconColor: PropTypes.string,
            bulletPointColor: PropTypes.string,
        })
    ).isRequired,
    selectedOptionsIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
        .isRequired,
    areOptionsLoading: PropTypes.bool,
    handleRemoveOption: PropTypes.func.isRequired,
    handleAddFilterOption: PropTypes.func.isRequired,
    showOptionsAsBadge: PropTypes.bool,
    badgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime', 'white', 'red']),
    position: PropTypes.string,
    totalOptionsCount: PropTypes.number,
    includeClientSideFiltering: PropTypes.bool,
    startingOptionCountForFilterBar: PropTypes.number,
    optionViewType: PropTypes.oneOf(['checkbox', 'button']),
    popupWidth: PropTypes.number,
};

function FilteringPopup({
    onClose,
    label,
    options,
    selectedOptionsIds = [],
    areOptionsLoading = false,
    handleRemoveOption,
    handleAddFilterOption,
    showOptionsAsBadge = false,
    badgeColor = 'neutral',
    position = 'absolute top-[110%] right-0 min-[700px]:right-auto min-[700px]:left-[16px]',
    totalOptionsCount = 0,
    includeClientSideFiltering = false,
    startingOptionCountForFilterBar = 6, // if includeClientSideFiltering = true, then the Filtering SearchBar will be displayed if options count >= startingOptionCountForFilterBar
    optionViewType = 'button',
    popupWidth = 392,
}) {
    const popupRef = useRef(null);
    const [filteredOptions, setFilteredOptions] = useState(options);

    const showClientSideFilterBar =
        includeClientSideFiltering && totalOptionsCount >= startingOptionCountForFilterBar;

    useEffect(() => {
        if (!showClientSideFilterBar) {
            setFilteredOptions(options || []);
        }
    }, [options, showClientSideFilterBar]);

    useClickOutside(popupRef, onClose);

    return (
        <div
            className={`${position} z-20 w-[85vw] min-[400px]:w-[80vw] max-h-[290px] sm:max-h-[328px] rounded-2 bg-white shadow-l1 border-1 border-neutral-200 overflow-y-auto`}
            style={{ maxWidth: `${popupWidth}px` }}
            ref={popupRef}
        >
            <div className="overflow-y-auto p-4 flex flex-col gap-3">
                {!!options?.length && (
                    <>
                        {showClientSideFilterBar && (
                            <ClientSideFilteredSearchBar
                                size="sm"
                                list={options || []}
                                setFilteredList={setFilteredOptions}
                                placeholder="Search ..."
                            />
                        )}
                        <p className="bont-body text-body-regular-xs text-neutral-300 uppercase mb-1">
                            {label}
                        </p>

                        {!!filteredOptions?.length && (
                            <ul className="flex flex-col">
                                {filteredOptions.map((option) => {
                                    const isOptionAdded = selectedOptionsIds?.includes(option.id);
                                    return (
                                        <FilteringPopupOption
                                            key={option.id}
                                            option={option}
                                            isAdded={isOptionAdded}
                                            showAsBadge={showOptionsAsBadge}
                                            badgeColor={badgeColor}
                                            handleAddFilterOption={handleAddFilterOption}
                                            handleRemoveOption={handleRemoveOption}
                                            optionViewType={optionViewType}
                                        />
                                    );
                                })}
                            </ul>
                        )}
                    </>
                )}

                {!options?.length && areOptionsLoading && (
                    <div className="h-[60px] flex justify-center items-center">
                        <Loading withText={false} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default FilteringPopup;
