import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Badge, ButtonIcon, SvgIcon } from '../../../design-system';
import { DeleteBin4LineIcon } from '../../../design-system/Icons';
import EditLineIcon from '../../../design-system/Icons/EditLineIcon';
import DeleteProcessConfirmModal from '../DeleteProcessConfirmModal/DeleteProcessConfirmModal';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';

const ProcessCard = ({ process, keysToMutate }) => {
    const { id, name, goals, created_at } = process;
    const location = useLocation();
    const navigate = useNavigate();
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);

    const activeGoalsCount = goals.length; // should be updated
    const formattedTime = DateTime.fromISO(created_at).toLocaleString();

    const handleEditProcess = () => {
        navigate(`/configure/process/${id}`, {
            state: {
                from: location,
                keysToMutate,
            },
        });
    };

    return (
        <li className="w-full px-3 lg:px-4 py-3 rounded-2 bg-white flex flex-row items-center justify-between gap-4">
            <div className="w-[calc(100%-338px)] flex items-center gap-3 sm:gap-4">
                <SvgIcon color={process.icon_color} icon={PlayCircleFillIcon} size="medium" />
                <p className="flex-grow font-body text-body-bold-xs text-neutral-500 truncate">
                    {name}
                </p>
            </div>
            <div className="flex items-center gap-4 justify-between">
                <Badge
                    text={`${activeGoalsCount} goal${activeGoalsCount !== 1 ? 's' : ''}`}
                    color="blue"
                />
                <p className="font-body text-body-regular-xs text-neutral-300 min-w-[136px] w-[136px]">
                    Created At{' '}
                    <span className="font-body text-body-bold-xs text-neutral-500">
                        {formattedTime}
                    </span>
                </p>
                <div className="flex gap-1">
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={EditLineIcon}
                        onClick={handleEditProcess}
                    />
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={DeleteBin4LineIcon}
                        onClick={() => setOpenDeleteConfirmModal(true)}
                    />
                </div>
            </div>
            {openDeleteConfirmModal && (
                <DeleteProcessConfirmModal
                    processId={id}
                    processName={name}
                    keysToMutate={keysToMutate}
                    onClose={() => setOpenDeleteConfirmModal(false)}
                />
            )}
        </li>
    );
};

export default ProcessCard;
