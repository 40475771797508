// import modelOptions from 'constants/modelOptions';

// export const getModelOptions = (user) => {
//   // console.log('user', user);
//     // Check if user data and available_models exist
//     if (user && user.organization && user.organization.available_models) {
//       // Map user.settings.available_models to the desired format
//       return user.organization.available_models.map((model) => ({
//         id: model.id,
//         model: model.model,
//         name: model.name,
//         provider: model.provider,
//         type: model.type,
//         token_limit: model.token_limit,
//       }));
//     } else {
//       return [];
//     }
//   };

import modelOptions from 'constants/modelOptions';

export const getModelOptions = (user) => {
  // Check if user data and available_models exist
  if (user && user.organization && user.organization.available_models) {
    // Filter user's available models to include only those also present in modelOptions
    const filteredModels = user.organization.available_models.filter(userModel => 
      modelOptions.some(option => option.id === userModel.id || option.model === userModel.model)
    );
    
    // Map the filtered available_models to the desired format
    return filteredModels.map((model) => ({
      id: model.id,
      model: model.model,
      name: model.name,
      provider: model.provider,
      type: model.type,
      token_limit: model.token_limit,
    }));
  } else {
    return [];
  }
};
  