import React from 'react';

import { addUniqueElementToArray } from '../../../../helpers/addUniqueElementToArray';
import { checkAndUpdateUserInputsIfUsedInMessagesArray } from '../../../../helpers/taskPlaygroundUtils';
import { useTaskPlaygroundMessagesHeight } from '../../../../hooks/useTaskPlaygroundMessagesHeight';

import NewTextAreaBox from '../../../../design-system/NewTextAreaBox/NewTextAreaBox';
import SvgIcon from '../../../../design-system/SvgIcon/SvgIcon';
import ErrorWarningLineIcon from '../../../../design-system/Icons/ErrorWarningLineIcon';

const AssistantMessagesBlock = ({
    assistantTaskChangeableData,
    setAssistantTaskChangeableData,
    setChangedTaskFields,
    setUserInputs,
}) => {
    const { messages } = assistantTaskChangeableData;

    const {
        containerRef,
        systemMessageRef,
        systemMessageHeight,
        scrollInsideFirstMessage,
        isDesktop,
    } = useTaskPlaygroundMessagesHeight({ messages });

    if (!messages) {
        return <></>;
    }

    const [instructions, userMessage] = messages;

    const handleChange = (e) => {
        const { value, name: role } = e.target;

        setAssistantTaskChangeableData((prevData) => ({
            ...prevData,
            messages: prevData.messages.map((item) =>
                item.role === role ? { role, content: value } : item
            ),
        }));
        setChangedTaskFields((prevFields) => addUniqueElementToArray('messages', prevFields));

        if (role === 'user') {
            checkAndUpdateUserInputsIfUsedInMessagesArray(
                [value],
                setUserInputs,
                'missedInUserMessage'
            );
        }
    };

    return (
        <div
            className="flex-grow w-full flex flex-col gap-5 max-lg:min-h-[460px]"
            ref={containerRef}
        >
            <div
                className="w-full flex flex-col max-lg:min-h-[240px]"
                ref={systemMessageRef}
                style={{ height: systemMessageHeight }}
            >
                <div className="flex-grow w-full flex flex-col">
                    <NewTextAreaBox
                        name="system"
                        value={instructions.content}
                        onChange={handleChange}
                        label="Instructions"
                        placeholder="Write here"
                        state={'default'}
                        withCopyButton
                        fullHeight={scrollInsideFirstMessage && isDesktop}
                        scrollInsideTextArea={scrollInsideFirstMessage && isDesktop}
                    />
                    <div
                        className={`flex items-center gap-1 ${
                            scrollInsideFirstMessage && isDesktop ? 'mt-0' : 'mt-[6px]'
                        } `}
                    >
                        <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                        <p className="text-extraSmall text-neutral-300 leading-1.25">
                            Instructions cannot contain mapped input variables.
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex-grow w-full flex flex-col min-h-[240px]">
                <NewTextAreaBox
                    name="user"
                    value={userMessage.content}
                    onChange={handleChange}
                    label="User Message"
                    placeholder="Write here"
                    state={'default'}
                    withCopyButton
                    fullHeight
                    scrollInsideTextArea={isDesktop}
                />
            </div>
        </div>
    );
};

export default AssistantMessagesBlock;
