import React from 'react';

import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { checkIfViewModeWithEmptyPlaybookContent } from '../../../helpers/playbookBuilderUtils';

import DraggablePlaybookSection from '../DraggablePlaybookSection/DraggablePlaybookSection';
import AddNewSectionZone from '../AddNewSectionZone/AddNewSectionZone';

const PlaybookContent = ({
    mode,
    playbookContentFormData,
    setPlaybookContentFormData,
    areSectionsExpandedState,
}) => {
    const isViewModeWithEmptyPlaybookContent = checkIfViewModeWithEmptyPlaybookContent(
        mode,
        playbookContentFormData
    );

    return (
        <div className="flex flex-col gap-6">
            {!!playbookContentFormData.length && !isViewModeWithEmptyPlaybookContent && (
                <div className="flex flex-col gap-6">
                    {playbookContentFormData.map((section) => (
                        <DraggablePlaybookSection
                            key={section.temporaryId}
                            sectionData={section}
                            setPlaybookContentFormData={setPlaybookContentFormData}
                            isTheOnlySection={playbookContentFormData.length === 1}
                            mode={mode}
                            areSectionsExpandedState={areSectionsExpandedState}
                        />
                    ))}
                </div>
            )}

            {mode === PLAYBOOK_BUILDER_MODE.edit && (
                <AddNewSectionZone
                    areSectionsExpandedState={areSectionsExpandedState}
                    setPlaybookContentFormData={setPlaybookContentFormData}
                />
            )}

            {isViewModeWithEmptyPlaybookContent && (
                <p className="font-heading-regular text-heading-regular-m text-black lg:pl-[27px]">
                    Add playbook sections to view them here
                </p>
            )}
        </div>
    );
};

export default PlaybookContent;
