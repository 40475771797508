import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { accessDeniedErrorMessage } from '../../constants/errorMessages';

import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import classNames from 'classnames';
import ButtonIcon from 'design-system/ButtonIcon/ButtonIcon';
import CloseLineIcon from 'design-system/Icons/CloseLineIcon';

Alert.propTypes = {
    position: PropTypes.oneOf(['top-center', 'top-right']),
    status: PropTypes.oneOf(['critical', 'attention', 'positive', 'informational', 'contrast'])
        .isRequired,
    statusCode: PropTypes.number,
    message: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.func.isRequired,
    autoCloseInMS: PropTypes.number,
    handleClose: PropTypes.func.isRequired,
};

const alertRoot = document.getElementById('alert-root');

function Alert(props) {
    const {
        position = 'top-right',
        status,
        statusCode, // statusCode received in the response indicates the specific status or error code associated with the request
        message,
        description,
        icon,
        autoCloseInMS = 6000,
        handleClose,
    } = props;

    useEffect(() => {
        const id = setTimeout(handleClose, autoCloseInMS);

        return () => {
            clearTimeout(id);
        };
    }, [autoCloseInMS]);

    const iconColor = {
        critical: '#E95B69',
        attention: '#F2AA85',
        positive: '#D9E56E',
        informational: '#17B4E5',
        contrast: '#1F2125',
    };

    const alertColor = {
        critical: 'bg-red-100',
        attention: 'bg-peach-100',
        positive: 'bg-lime-100',
        informational: 'bg-blue-100',
        contrast: 'bg-neutral-300',
    };

    const alertPosition = {
        'top-center': 'top-[40px] left-1/2 transform -translate-x-1/2',
        'top-right': 'top-[40px] right-[40px]',
    };

    const alertClassName = classNames(
        'fixed z-540 w-[395px] min-h-[56px] flex items-center gap-[12px] rounded-2 p-[8px]',
        alertColor[status],
        alertPosition[position]
    );

    const messageClassName = classNames('font-body text-body-bold-s', {
        'text-neutral-500': status !== 'contrast',
        'text-white': status === 'contrast',
    });

    const descriptionClassName = classNames('font-body text-body-regular-s', {
        'text-neutral-400': status !== 'contrast',
        'text-neutral-200': status === 'contrast',
    });

    return createPortal(
        <div className={alertClassName}>
            <div className="w-9 h-9 flex justify-center items-center bg-white rounded-2">
                <SvgIcon color={iconColor[status]} icon={icon} size="large" />
            </div>
            <div className="flex-1">
                <p className={messageClassName}>
                    {statusCode === 403 ? accessDeniedErrorMessage : message}
                </p>
                {description && <p className={descriptionClassName}>{description}</p>}
            </div>
            <div>
                <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={handleClose} />
            </div>
        </div>,
        alertRoot
    );
}

export default Alert;
