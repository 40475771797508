import PropTypes from 'prop-types';
import { Button } from 'design-system';
import {
    AddCircleLineIcon,
    LightbulbFlashLineIcon,
    TerminalBoxLineIcon,
} from 'design-system/Icons';
import { LIBRARY_VIEW_TYPES } from 'constants/library';

export function EmptyIndexPage({
    libraryType,
    onCreatePressed,
    hasCreateAccess = true,
    areFiltersApplied = false,
}) {
    const handleContactUsClick = () => {
        window.location.href = 'mailto:hello@promptfolio.ai';
    };

    return (
        <section
            id="empty-index"
            className="flex flex-col py-[80px] items-center gap-4 self-stretch"
        >
            <div className="flex w-[80px] h-[80px] justify-center items-center gap-5 bg-neutral-200 rounded-full">
                {libraryType === LIBRARY_VIEW_TYPES.personal && (
                    <TerminalBoxLineIcon width={32} height={32} />
                )}
                {libraryType === LIBRARY_VIEW_TYPES.shared && (
                    <LightbulbFlashLineIcon width={32} height={32} color={'#5E6470'} />
                )}
            </div>
            <div className="flex flex-col items-center gap-2">
                <p className="font-heading-bold text-heading-bold-m text-center">
                    {areFiltersApplied ? (
                        'Nothing found based on your filters.'
                    ) : (
                        <>
                            {libraryType === LIBRARY_VIEW_TYPES.personal &&
                                `You don’t have any Prompts yet.`}
                            {libraryType === LIBRARY_VIEW_TYPES.shared &&
                                'Contact us for team sharing features'}
                        </>
                    )}
                </p>

                <p className="font-body text-body-regular-m max-w-[440px] text-center text-neutral-400">
                    {areFiltersApplied ? (
                        'Try applying a different filter.'
                    ) : (
                        <>
                            {libraryType === LIBRARY_VIEW_TYPES.personal &&
                                hasCreateAccess &&
                                `Try creating one by clicking “New Prompt”.`}
                            {libraryType === LIBRARY_VIEW_TYPES.shared && (
                                <>
                                    You need to have a <strong>Pro Plan</strong> to enable teams and
                                    sharing. Contact us if you are interested in these features.
                                </>
                            )}
                        </>
                    )}
                </p>
            </div>
            {!areFiltersApplied && (
                <>
                    {libraryType === LIBRARY_VIEW_TYPES.personal && hasCreateAccess && (
                        <Button
                            onClick={onCreatePressed}
                            leadingIcon={AddCircleLineIcon}
                            text={`New Prompt`}
                            type="primary"
                            size="sm"
                        />
                    )}
                    {libraryType === LIBRARY_VIEW_TYPES.shared && (
                        <Button
                            onClick={handleContactUsClick}
                            text={`Contact Us`}
                            type="primary"
                            size="xs"
                        />
                    )}
                </>
            )}
        </section>
    );
}

EmptyIndexPage.propTypes = {
    libraryType: PropTypes.oneOf([LIBRARY_VIEW_TYPES.personal, LIBRARY_VIEW_TYPES.shared])
        .isRequired,
    onCreatePressed: PropTypes.func,
    isOrganizationMember: PropTypes.bool,
};
