import React, { useState, useEffect, useCallback } from 'react';
import client from 'services/library-api';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { API } from 'constants';
import ViewPageHeader from 'components/ViewPageHeader/ViewPageHeader';
import TaskForm from 'components/TaskForm/TaskForm';
import TaskDetailCard from 'components/TaskDetailCard/TaskDetailCard';
import useDocumentTitle from 'hooks/useDocumentTitle';

const WorkflowViewPage = () => {
    const [workflow, setWorkflow] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const librarySearchParams = location.state?.librarySearchParams || '';
    const params = useParams();
    const workflowId = parseInt(params.id, 10);
    const [showTaskForm, setShowTaskForm] = useState(
        (location.state && location.state?.showTaskForm) || false
    );
    const [editingTaskObject, setEditingTaskObject] = useState(null);

    useDocumentTitle(workflow?.name || '');

    const getWorkflow = useCallback(async () => {
        try {
            const authToken = localStorage.getItem('authKey');
            if (!authToken || !authToken.length) {
                throw new Error('User not logged in.'); // TODO: handle more gracefully
            }
            const options = {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            };
            const { data } = await client.get(
                `${API.ROUTES.library.workflow}${workflowId}/`,
                options
            );
            setWorkflow(data);
        } catch (error) {
            setWorkflow(null);
        }
    }, [workflowId]);

    useEffect(() => {
        getWorkflow();
    }, [getWorkflow]);

    const handleCreatePrompt = (taskId) => {
        navigate(`/create?workflow=${workflowId}&task=${taskId}`);
    };

    const handleEditTask = (taskId) => {
        const task = workflow.steps.find((step) => step.task_id === taskId);
        setEditingTaskObject(task);
        setShowTaskForm(true);
    };

    const handleNewTask = () => {
        setEditingTaskObject(null);
        setShowTaskForm(true);
    };

    function handleEmojiUpdated(emojiCode) {
        client.patch(`${API.ROUTES.library.workflow}${workflowId}/`, {
            icon_text: emojiCode,
        });
    }

    const handleWorkflowSave = (fieldName, fieldValue, workflowId) => {
        const data = {
            [fieldName]: fieldValue,
        };
        console.log(data);
        client
            .patch(`${API.ROUTES.library.workflow}${workflowId}/`, data)
            .then((response) => {
                // handle success
                console.log(response);
                getWorkflow();
            })
            .catch((error) => {
                // handle error
                console.error(error);
                getWorkflow();
            });
    };

    const handleTaskSave = (taskDetails) => {
        if (editingTaskObject && editingTaskObject.task_id) {
            // patch update
            client
                .patch(`${API.ROUTES.library.task}${editingTaskObject.task_id}/`, taskDetails)
                .then((response) => {
                    // handle success
                    console.log(response);
                    getWorkflow();
                    setShowTaskForm(false);
                })
                .catch((error) => {
                    // handle error
                    console.error(error);
                    getWorkflow();
                    setShowTaskForm(false);
                });
        } else {
            // post new and create step
            client
                .post(`${API.ROUTES.library.createStep}`, taskDetails)
                .then((response) => {
                    // handle success
                    console.log(response);
                    getWorkflow();
                    setShowTaskForm(false);
                })
                .catch((error) => {
                    // handle error
                    console.error(error);
                    getWorkflow();
                    setShowTaskForm(false);
                });
        }
    };

    const handleBackPressed = () => {
        navigate(`/library${librarySearchParams}`);
    };

    return (
        <div className="bg-white px-8 py-4 w-full flex flex-col min-h-screen">
            {workflow.steps ? (
                <div className="space-y-8">
                    <ViewPageHeader
                        emojiCode={workflow.icon_text}
                        headerText={workflow.name}
                        subHeaderText={workflow.description}
                        handleBackPressed={handleBackPressed}
                        icon_color={workflow.icon_color}
                        handleSave={(fieldName, fieldValue) =>
                            handleWorkflowSave(fieldName, fieldValue, workflowId)
                        }
                        handleEmojiUpdated={handleEmojiUpdated}
                        buttonText="Add Task"
                        handleButtonClick={handleNewTask}
                    />
                    {workflow.steps.map((step, index) => (
                        <TaskDetailCard
                            key={index}
                            step={step}
                            handleEditTask={handleEditTask}
                            showTaskForm={showTaskForm}
                            handleTaskSave={handleTaskSave}
                            setShowTaskForm={setShowTaskForm}
                            workflowId={workflowId}
                            editingTaskObject={editingTaskObject}
                            handleCreatePrompt={handleCreatePrompt}
                        />
                    ))}
                    {showTaskForm && (
                        <TaskForm
                            onSave={handleTaskSave}
                            onCancel={() => setShowTaskForm(false)}
                            taskObject={editingTaskObject}
                            workflowId={workflowId}
                        />
                    )}
                </div>
            ) : (
                'Loading...'
            )}
        </div>
    );
};

export default WorkflowViewPage;
