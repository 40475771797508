import React, { useEffect, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';

import {
    checkIfPlaybookContentFormDataChanged,
    getAreSectionsExpandedInitialState,
    getPlaybookContentInitialState,
    removeTemporaryIdFromList,
    updatePlaybookContentFormDataAfterSaveChanges,
} from '../../../helpers/playbookBuilderUtils';
import { PLAYBOOK_BUILDER_MODE } from '../../../constants/playbookBuilder';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import PlaybookDetailsModal from '../PlaybookDetailsModal/PlaybookDetailsModal';
import PlaybookPageLeftPanel from '../PlaybookPageLeftPanel/PlaybookPageLeftPanel';
import PlaybookPageRightPanel from '../PlaybookPageRightPanel/PlaybookPageRightPanel';
import PlaybookHeader from '../PlaybookHeader/PlaybookHeader';
import SaveIcon from '../../../design-system/Icons/SaveIcon';
import PlaybookContent from '../PlaybookContent/PlaybookContent';
import OutdatedAlertModal from '../OutdatedAlertModal/OutdatedAlertModal';
import Alert from '../../../design-system/Alert/Alert';
import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

export const pageColumnsCommonStyles = 'h-full hide-scrollbar pb-5 sm:pb-6 lg:pb-8 xl:pb-[40px]';

const PlaybookBuilderIndex = ({ playbookDetail, setPlaybookDetail, mode, height }) => {
    const { id, playbook_content } = playbookDetail;
    const [isPlaybookEditModalOpened, setIsPlaybookEditModalOpened] = useState(false);

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isSaveChangesLoading, setIsSaveChangesLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);
    const [isOutdatedAlertModalOpened, setIsOutdatedAlertModalOpened] = useState(false);

    // temporary ID is added to each section (and each block) to ensure every object has a unique identifier. Some sections have a null ID, and the order field cannot be used since it is changeable.
    // if a section is saved in the database, it has an ID, and the temporary ID will be set equal to this ID.
    // if it is a new section with a null ID, a temporary unique ID will be created.
    const [playbookContentFormData, setPlaybookContentFormData] = useState(() =>
        getPlaybookContentInitialState(playbook_content)
    );
    // originalPlaybookContentFormData contains the form initial state and is used for tracking changes
    const originalPlaybookContentFormData = useRef(playbookContentFormData);

    const areSectionsExpandedState = useState(() =>
        getAreSectionsExpandedInitialState(playbookContentFormData)
    );

    useEffect(() => {
        const isFormChanged = checkIfPlaybookContentFormDataChanged(
            originalPlaybookContentFormData.current,
            playbookContentFormData
        );

        if (isFormChanged !== isSaveEnabled) {
            setIsSaveEnabled(isFormChanged);
        }
    }, [playbookContentFormData]);

    const updateOriginalPlaybookContentFormData = (updatedData) => {
        originalPlaybookContentFormData.current = updatedData;
    };

    const getPlaybookContentRequestBody = ({ overwrite }) => {
        const requestBody = {
            playbook_content: playbookContentFormData.map(({ temporaryId, ...sectionData }) => ({
                ...sectionData,
                block_content: removeTemporaryIdFromList(sectionData.block_content),
            })),
        };

        if (overwrite) {
            requestBody.overwrite = true;
        }

        return requestBody;
    };

    const handleSaveChanges = async ({ overwrite = false }) => {
        try {
            setIsSaveChangesLoading(true);
            const requestBody = getPlaybookContentRequestBody({ overwrite });
            const { data } = await client.patch(
                `${API.ROUTES.library.playbook}${id}/`,
                requestBody
            );
            const { playbook_content, status } = data;

            if (status === 'outdated') {
                setIsOutdatedAlertModalOpened(true);
                setIsSaveChangesLoading(false);
                return;
            }

            updatePlaybookContentFormDataAfterSaveChanges(
                playbook_content,
                playbookContentFormData,
                setPlaybookContentFormData,
                updateOriginalPlaybookContentFormData,
                areSectionsExpandedState
            );
            setPlaybookDetail(data);
            setIsSaveChangesLoading(false);
        } catch (e) {
            setIsSaveChangesLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const saveButtonState = isSaveChangesLoading
        ? 'loading'
        : !isSaveEnabled
        ? 'disabled'
        : 'default';

    return (
        <>
            <div className="flex items-start gap-8 pt-5 flex-grow" style={{ height }}>
                <div className="flex items-start flex-grow gap-8 lg:gap-[5px] h-full">
                    <PlaybookPageLeftPanel
                        mode={mode}
                        playbookDetail={playbookDetail}
                        playbookContentFormData={playbookContentFormData}
                        areSectionsExpandedState={areSectionsExpandedState}
                    />

                    <div
                        className={`flex-grow flex flex-col gap-6 overflow-y-auto ${pageColumnsCommonStyles}`}
                    >
                        <PlaybookHeader
                            mode={mode}
                            playbookDetail={playbookDetail}
                            openPlaybookEditModal={() => setIsPlaybookEditModalOpened(true)}
                        />

                        <PlaybookContent
                            mode={mode}
                            playbookContentFormData={playbookContentFormData}
                            setPlaybookContentFormData={setPlaybookContentFormData}
                            areSectionsExpandedState={areSectionsExpandedState}
                        />
                    </div>
                </div>

                <PlaybookPageRightPanel mode={mode} />

                {mode === PLAYBOOK_BUILDER_MODE.edit && (
                    <div className="fixed bottom-7 right-5 p-2">
                        <Button
                            type="secondary"
                            size="sm"
                            text="Save"
                            leadingIcon={SaveIcon}
                            state={saveButtonState}
                            onClick={handleSaveChanges}
                        />
                    </div>
                )}
            </div>

            {isPlaybookEditModalOpened && (
                <PlaybookDetailsModal
                    mode="edit"
                    playbookData={playbookDetail}
                    setUpdatedPlaybookData={setPlaybookDetail}
                    onClose={() => setIsPlaybookEditModalOpened(false)}
                />
            )}
            {isOutdatedAlertModalOpened && (
                <OutdatedAlertModal
                    isSaveChangesLoading={isSaveChangesLoading}
                    handleSaveChanges={handleSaveChanges}
                    onClose={() => setIsOutdatedAlertModalOpened(false)}
                />
            )}
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    statusCode={errorAlert.statusCode}
                    icon={ErrorWarningLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </>
    );
};

export default PlaybookBuilderIndex;
