import React, { useEffect } from 'react';
import IntegrationCard from '../../components/IntegrationCard/IntegrationCard';
import { Button } from '../../design-system';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';
import Share2 from '../../design-system/Icons/Share2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { parsePromptChainsSearchParams } from '../../helpers/parsePromptChainsSearchParams';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { INTEGRATIONS_SORT_TYPES } from '../../constants/integrations';
import IntegrationsPaginatedContainer from './IntegrationsPaginatedContainer/IntegrationsPaginatedContainer';

const IntegrationsPage = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = parsePromptChainsSearchParams(searchParams);

    useDocumentTitle('Integrations');

    useEffect(() => {
        let pageNumber = Number.isNaN(parsedParams.page) ? 1 : parsedParams.page;
        let sort = Object.values(INTEGRATIONS_SORT_TYPES).includes(parsedParams.sort)
            ? parsedParams.sort
            : INTEGRATIONS_SORT_TYPES.created;

        if (Number.isNaN(parsedParams.page) || parsedParams.sort !== sort) {
            setSearchParams({
                ...parsedParams,
                page: pageNumber,
                sort: sort,
            });
        }
    }, [parsedParams, setSearchParams]);

    function handleNextPressed() {
        setSearchParams({ ...parsedParams, page: parsedParams.page + 1 });
    }

    function handlePreviousPressed() {
        if (parsedParams.page > 0) {
            setSearchParams({ ...parsedParams, page: parsedParams.page - 1 });
        }
    }

    return (
        <div className="flex flex-col gap-5 w-full sm:w-[calc(100%-68px)] max-sm:p-4">
            <div>
                <h1 className="font-heading-bold text-heading-bold-l text-black">Integrations</h1>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    Connect your prompts to 3rd party automation tools like Make
                </p>
            </div>
            <div className="flex flex-col l:flex-row gap-5">
                <IntegrationCard
                    title="Make.com"
                    description="Access your Promptfolio directly from your Make automations"
                    buttonControls={
                        <div className="flex flex-wrap items-center gap-[10px]">
                            <Button
                                type="secondary"
                                size="xs"
                                text="Set Up Integration"
                                trailingIcon={Share2}
                                onClick={() => navigate('/integrations/make/setup-1')}
                            />
                        </div>
                    }
                />
                <IntegrationCard
                    title="Zapier"
                    description="Access your Promptfolio directly from your Zapier automations"
                    buttonControls={
                        <Button
                            type="secondary"
                            size="xs"
                            text="Set Up Integration"
                            trailingIcon={Share2}
                            onClick={() => navigate('/integrations/zapier/setup-1')}
                        />
                    }
                />
            </div>
            <div>
                <IntegrationsPaginatedContainer
                    sortType={parsedParams.sort || INTEGRATIONS_SORT_TYPES.created}
                    pageIndex={parsedParams.page - 1 || 0}
                    onPreviousPressed={handlePreviousPressed}
                    onNextPressed={handleNextPressed}
                />
            </div>
        </div>
    );
};

export default IntegrationsPage;
