export const isFileSizeValid = (file, maxSizeInBytes, handleError = () => {}) => {
    if (!maxSizeInBytes) {
        return true; // if there are no limits return valid
    }

    if (file.size > maxSizeInBytes) {
        handleError();
        return false;
    }

    return true;
};
