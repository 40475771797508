import React, { useMemo } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { parseJobsSearchParams } from '../../../helpers/parseJobsSearchParams';
import {
    handleNextPageClick,
    handlePreviousPageClick,
} from '../../../helpers/handleUpdateSearchParams';
import { DEFAULT_LIMIT } from '../../../hooks/useCustomPagination';

import JobCard from '../JobCard/JobCard';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';
import Loading from '../../../components/Loading';

const JobsDesktopPaginatedContainer = ({ jobsHookResponse, pageIndex }) => {
    const { data, total, count, loading } = jobsHookResponse;

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => parseJobsSearchParams(searchParams), [searchParams]);

    const location = useLocation();

    const start = pageIndex * DEFAULT_LIMIT + 1;
    const end = total - start < count ? total : start + count - 1;

    const handlePreviousPressed = () => {
        handlePreviousPageClick({ searchParams, setSearchParams, currentPage: parsedParams.page });
    };

    const handleNextPressed = () => {
        handleNextPageClick({ searchParams, setSearchParams, currentPage: parsedParams.page });
    };

    return (
        <>
            {!!total && (
                <div className="flex flex-col gap-6">
                    <ul className="w-full flex flex-col gap-2">
                        {data.map((job) => (
                            <li key={job.id}>
                                <Link to={`/job/${job.id}`} state={{ from: location }}>
                                    <JobCard job={job} />
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <div className="flex flex-col">
                        <PaginationFooter
                            start={start}
                            end={end}
                            total={total}
                            onNextPressed={handleNextPressed}
                            onPreviousPressed={handlePreviousPressed}
                        />
                    </div>
                </div>
            )}
            {!total && loading && (
                <div className="pt-5">
                    <Loading />
                </div>
            )}
        </>
    );
};

export default JobsDesktopPaginatedContainer;
