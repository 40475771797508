import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Badge, Button, ButtonIcon } from '../../../design-system';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';
import MoreVerticalIcon from '../../../design-system/Icons/MoreVerticalIcon';
import MoreOptionsPopup from '../MoreOptionsPopup/MoreOptionsPopup';

const ProcessWorkReportTemplatePageHeader = ({
    processId,
    templateDetails,
    setPageHeaderHeight,
    setActionModal,
}) => {
    const { name, process, is_live } = templateDetails;
    const headerContainerRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from ?? `/configure/process/${processId}`;

    const [moreOptionsPopupOpened, setMoreOptionsPopupOpened] = useState(false);

    useEffect(() => {
        if (headerContainerRef.current && headerContainerRef.current?.offsetHeight) {
            setPageHeaderHeight(headerContainerRef.current.offsetHeight);
        }
    }, [headerContainerRef]);

    return (
        <div
            className="w-full px-6 py-3 bg-white flex gap-2 items-start sm:items-center justify-between border-b-1 border-neutral-200"
            ref={headerContainerRef}
        >
            <div>
                <div className="flex flex-col gap-x-3 items-start xs:items-center xs:flex-row mb-1 xs:mb-0">
                    <Button
                        type="link"
                        size="xs"
                        text="Back"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(backLinkHref)}
                    />
                    <p className="font-body text-body-regular-s text-neutral-300">
                        {process?.name} / Work Report Template
                    </p>
                </div>
                <div className="flex gap-x-3 gap-y-1 items-center flex-wrap">
                    <p className="font-body-bold text-body-bold-l text-black">{name}</p>
                    <Badge text={is_live ? 'Live' : 'Draft'} color={is_live ? 'lime' : 'peach'} />
                </div>
            </div>
            <div className="relative">
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={MoreVerticalIcon}
                    onClick={() => setMoreOptionsPopupOpened((prev) => !prev)}
                />
                {moreOptionsPopupOpened && (
                    <MoreOptionsPopup
                        isLive={is_live}
                        setActionModal={setActionModal}
                        onClose={() => setMoreOptionsPopupOpened(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default ProcessWorkReportTemplatePageHeader;
