import React, { useState } from 'react';
import client from '../../../services/organization-api';
import { API } from 'constants';

import ProviderCard from '../ProviderCard/ProviderCard';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../../constants/organization';
import {
    comingSoonProviders,
    providersForAgencyOrg,
    providersForNonAgencyOrg,
} from '../../../constants/organizationConnections';
import PaginationFooter from '../../LibraryPage/PaginationFooter/PaginationFooter';
import { useRecursiveDataFetch } from '../../../hooks/useRecursiveDataFetch';
import Alert from '../../../design-system/Alert/Alert';
import CheckLineIcon from '../../../design-system/Icons/CheckLineIcon';
import OrgConnectionActionModal from '../OrgConnectionActionModal/OrgConnectionActionModal';

const CONNECTIONS_PAGINATION_LIMIT = 6;

const OrganizationConnectionsBlock = ({ orgId, orgPlan, userRole }) => {
    const viewOnlyMode = userRole === USER_ROLE.member;

    const [connections, setConnections] = useState(null);
    const [page, setPage] = useState(1);

    // list of Providers for current organization plan
    const providersList =
        orgPlan === ORGANIZATION_PLAN.agency ? providersForAgencyOrg : providersForNonAgencyOrg;
    // list of Providers for current page
    const providersListForCurrentPage = providersList.slice(
        (page - 1) * CONNECTIONS_PAGINATION_LIMIT,
        page * CONNECTIONS_PAGINATION_LIMIT
    );

    const [connectionActionModal, setConnectionActionModal] = useState({
        opened: false,
        data: null,
        action: 'create',
        provider: null,
    });
    const [successAlertMessage, setSuccessAlertMessage] = useState(null);

    const total = providersList.length;
    // amount of displayed items for current page
    const countPerCurrentPage = providersListForCurrentPage?.length;
    const start = (page - 1) * CONNECTIONS_PAGINATION_LIMIT + 1;
    const end = total - start < countPerCurrentPage ? total : start + countPerCurrentPage - 1;

    useRecursiveDataFetch({
        client,
        route: API.ROUTES.organization.connection,
        searchParams: { organization: orgId },
        useExternalState: true,
        setData: setConnections,
    });

    return (
        <>
            {connections && (
                <div className="p-4 md:pt-4 md:pb-8 md:px-8 rounded-md flex flex-col w-full bg-white gap-8">
                    <p className="font-body-bold text-body-bold-l text-neutral-500">
                        Organization Connections
                    </p>
                    {/* <div className="grid grid-cols-1 gap-x-8 gap-y-8 min-[900px]:grid-cols-2 l:grid-cols-3 min-[1750px]:grid-cols-4"> */}
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
                        {providersListForCurrentPage.map((provider) => (
                            <ProviderCard
                                key={provider}
                                provider={provider}
                                connectionData={connections?.find(
                                    (item) => item.provider === provider
                                )}
                                hasCreateAccess={!viewOnlyMode}
                                comingSoon={comingSoonProviders?.includes(provider)}
                                setConnectionActionModal={setConnectionActionModal}
                            />
                        ))}
                    </div>
                    {orgPlan === ORGANIZATION_PLAN.agency && (
                        <div className="flex flex-col">
                            <PaginationFooter
                                start={start}
                                end={end}
                                total={total}
                                onNextPressed={() => setPage((page) => page + 1)}
                                onPreviousPressed={() => setPage((page) => page - 1)}
                            />
                        </div>
                    )}
                </div>
            )}
            {connectionActionModal.opened && (
                <OrgConnectionActionModal
                    data={connectionActionModal.data}
                    provider={connectionActionModal.provider}
                    action={connectionActionModal.action}
                    setOrgConnections={setConnections}
                    setSuccessAlertMessage={setSuccessAlertMessage}
                    onClose={() => setConnectionActionModal({ opened: false })}
                />
            )}
            {successAlertMessage && (
                <Alert
                    status="positive"
                    icon={CheckLineIcon}
                    message={successAlertMessage}
                    autoCloseInMS={3000}
                    handleClose={() => setSuccessAlertMessage(null)}
                />
            )}
        </>
    );
};

export default OrganizationConnectionsBlock;
